import { Add } from "@mui/icons-material"
import { Box, Fab, Tooltip } from "@mui/material"

type fabProps = {
    title: string,
    top?: string,
    right?: string,
    clickAction: any,
    children?: any,
    position?: "-webkit-sticky" | "absolute" | "fixed" | "relative" | "static" | "sticky" | undefined,
}
const AddFab = ({ title, top, right, clickAction, children, position = 'absolute' }: fabProps) => {
    return (
        <Box>
            <Tooltip title={title}>
                <Fab
                    onClick={() => clickAction()}
                    sx={{
                        background: '#e8e0ff',
                        color: '#7846ff',
                        zIndex: 0,
                        position: position,
                        top: top || '8px',
                        right: right || '15px',
                        borderRadius: '5px',
                        width: '35px',
                        height: '35px',
                        boxShadow: 'none',
                    }}
                >
                    {children || <Add />}
                </Fab>
            </Tooltip>
        </Box>
    )
}

export default AddFab