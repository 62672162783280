import React from 'react';
import { TextField, MenuItem, TextFieldProps } from '@mui/material';

const EthnicityField: React.FC<TextFieldProps> = (props) => {
  const ethnicities = ['Hispanic', 'Non-Hispanic', 'Asian', 'African American', 'Caucasian'];

  return (
    <TextField
      label="Ethnicity"
      select
      fullWidth
      variant="outlined"
      InputLabelProps={{
          shrink: true,
      }}
      {...props}
    >
      {ethnicities.map((ethnicity) => (
        <MenuItem key={ethnicity} value={ethnicity}>
          {ethnicity}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default EthnicityField;
