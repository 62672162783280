import { Button, Drawer, IconButton, Autocomplete, TextField, FormLabel, FormGroup, FormHelperText } from '@mui/material';
import { Bold, BoxContainer } from '../../../assets/styles/styledComponents';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { SearchBox } from '@mapbox/search-js-react';
import { MAPBOX_TOKEN } from '../../../data/constants';
import { Clear } from '@mui/icons-material';
import { useState } from 'react';

type officeList = {
    id: string;
    officeName: string;
};
export type CoordinatorOption = {
    id: string;
    name: string;
};
type FilterProps = {
    offices: officeList[]
    setSelectedOffices: any
    selectedOffices: officeList[]
    coordinators: CoordinatorOption[]
    selectedCoordinators: CoordinatorOption[]
    setSelectedCoordinators: any
    address: any
    setAddress: any
    selectedGender: any
    setSelectedGender: any
    selectedType: any
    setSelectedType: any
    open: boolean
    setOpen: any
    search: any
}

const VisitFilter = ({
    offices,
    setSelectedOffices,
    selectedOffices,
    coordinators,
    selectedCoordinators,
    setSelectedCoordinators,
    address,
    setAddress,
    selectedGender,
    setSelectedGender,
    selectedType,
    setSelectedType,
    open,
    setOpen,
    search
}: FilterProps) => {
    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (value: any, setter: any) => {
        setter(value);
    }

    const handleAddress = (e: any) => {
        if (e.features[0].properties.feature_type === 'address' || e.features[0].properties.feature_type === 'poi') {
            setAddress({
                addressLine1: e.features[0].properties.context.address?.name,
                city: e.features[0].properties?.context?.locality?.name || e.features[0].properties.context.place?.name,
                state: e.features[0].properties.context.region?.name || '',
                country: e.features[0].properties.context.country?.name,
                zipCode: e.features[0].properties.context.postcode?.name,
                latitude: e.features[0].geometry.coordinates[1],
                longitude: e.features[0].geometry.coordinates[0],
                full: e.features[0].properties.full_address
            })
        }
        if (e.features[0].properties.feature_type === 'postcode') {
            setAddress({
                addressLine1: '',
                city: e.features[0].properties.context.place.name,
                state: e.features[0].properties.context.region.name,
                country: e.features[0].properties.context.country.name,
                zipCode: e.features[0].properties.name,
                latitude: e.features[0].geometry.coordinates[1],
                longitude: e.features[0].geometry.coordinates[0],
                full: e.features[0].properties.place_formatted
            })
        }
    }

    const clearAddress = () => {
        setAddress({
            addressLine1: '',
            city: '',
            state: '',
            country: '',
            zipCode: '',
            latitude: '',
            longitude: '',
            full: ''
        })
    }

    const handleSave = () => {
        setOpen(false);
        search();
    };

    const genders = [{ label: 'Male' }, { label: 'Female' }]
    const types = [{ label: 'All' }, { label: 'Referral' }, { label: 'Hha Exchange' }]


    return (
        <div>
            <Drawer componentsProps={{ backdrop: { style: { backgroundColor: "rgba(255,255,255, 0)" } } }} elevation={10} open={open} onClose={handleClose} anchor='right' sx={{ zIndex: 100 }}>
                <div style={{ marginTop: '10px', display: "flex", justifyContent: "flex-end" }}>
                    <Bold
                        margin="10px auto 10px auto"
                        fontSize="25px"
                    >
                        Filters
                    </Bold>
                    <IconButton onClick={handleClose}>
                        <CancelOutlinedIcon color='primary' />
                    </IconButton>
                </div>
                <BoxContainer borderPx="none" margin="" padding="15px 15px 15px 15px" style={{ width: "350px" }}>
                    <Autocomplete
                        multiple
                        disableClearable
                        filterSelectedOptions
                        onChange={(e, option) => { handleChange(option.map(o => coordinators?.find((of: any) => of.name == o)), setSelectedCoordinators) }}
                        size='small'
                        getOptionLabel={(option) => option}
                        defaultValue={selectedCoordinators?.map((o: any) => o.name)}
                        renderInput={(params) => <TextField {...params} value={selectedCoordinators} label="Coordinator" />}
                        options={coordinators?.map((coordinator: any) => coordinator.name)}
                    />
                    <Autocomplete
                        multiple
                        disableClearable
                        filterSelectedOptions
                        onChange={(e, option) => { handleChange(option.map(o => offices.find((of: any) => of.officeName == o)), setSelectedOffices) }}
                        size='small'
                        getOptionLabel={(option) => option}
                        defaultValue={selectedOffices?.map((o: any) => o.officeName)}
                        sx={{ marginTop: "15px" }}
                        renderInput={(params) => <TextField {...params} value={selectedOffices} label="Office" />}
                        options={offices?.map((office: any) => office.officeName)}
                    />
                    <Autocomplete
                        onChange={(e, option) => { handleChange(option?.label, setSelectedGender) }}
                        size='small'
                        defaultValue={selectedGender}
                        sx={{ marginTop: "15px" }}
                        renderInput={(params) => <TextField {...params} value={selectedGender} label="Gender" />}
                        options={genders}
                    />
                    <Autocomplete
                        onChange={(e, option) => { handleChange(option?.label, setSelectedType) }}
                        size='small'
                        defaultValue={selectedType}
                        sx={{ marginTop: "15px" }}
                        renderInput={(params) => <TextField {...params} value={selectedType} label="Visit Type" />}
                        options={types}
                    />
                    <div style={{ marginTop: '10px' }}>
                        <FormLabel>Address</FormLabel>
                        <SearchBox options={{ country: 'us' }} value='' theme={{ variables: { boxShadow: 'none' } }} accessToken={MAPBOX_TOKEN} onRetrieve={(e) => handleAddress(e)} />
                        {<TextField
                            value={address?.full}
                            fullWidth
                            variant="standard"
                            sx={{ visibility: address?.full != '' ? 'visible' : 'hidden', border: 'none' }}
                            aria-readonly
                            InputProps={{
                                disableUnderline: true,
                                endAdornment:
                                    <IconButton onClick={() => clearAddress()}>
                                        <Clear fontSize='small' />
                                    </IconButton>
                            }}
                        />}
                    </div>
                </BoxContainer>
                <Button
                    variant="contained"
                    onClick={handleSave}
                    style={{ margin: "5px 5px 5px 5px" }}
                >
                    Apply & Load
                </Button>
            </Drawer>
        </div>
    )
}

export default VisitFilter
