import React, { useEffect, useState } from 'react';
import { Typography, Select, MenuItem, IconButton } from '@mui/material';

// chart
// import {
//   pieChartDataCaseSchedules, pieChartOptionsCaseSchedules,
//   pieChartDataVisitInvites, pieChartOptionsVisitInvites,
//   pieChartDataCaregiverEVVCompliance, pieChartOptionsCaregiverEVVCompliance,
//   pieChartDataCaseInvitesByStatus, pieChartOptionsCaseInvitesByStatus,
//   pieChartDataPatients, pieChartOptionsPatients,
//   pieChartDataCaregiver, pieChartOptionsCaregiver
// } from "../components/charts/variables-new";
import DashboardMetricCard from '../components/charts/DashboardMetricCard';
import { Grid } from '../lib/MUI/mui.components';

import { ApexOptions } from "apexcharts";
import DashboardBoxCard from '../components/Dashboard/DashboardBoxCard';
import { CaregiverIcon, PatientIcon, VisitIcon } from '../components/Dashboard/DashboardIcons';
import { AssignmentIndOutlined, BusinessOutlined, CalendarMonthOutlined, Clear, HistoryToggleOffOutlined, HourglassEmptyOutlined, PersonOutlineOutlined, Schedule } from '@mui/icons-material';
import DashboardBarChart from '../components/charts/DashboardBarChart';
import DashboardAreaChart from '../components/charts/DashboardAreaChart';
import DashboardDonutCard from '../components/charts/DashboardDonutCard';
import DashboardDoubleDonutCard from '../components/charts/DashboardDoubleDonutChart';
import useFetcher from '../hooks/useFetcher';
import { DateRangePicker, LocalizationProvider, SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { getUser } from '../helper/fetchHelpers';

const blue = '#4671ff';
const blue_light = '#a6bbff';
const green = '#21bf46';
const green_light = '#85da99';
const red = '#ff4f46';
const red_light = '#ff807a';
const app_purple = '#7846ff';
const app_lighter = '#a584ff';
const app_light = '#d2c1ff';
const purple = '#d146ff';
const purple_light = '#e393fd';
const orange = '#ffaf46';
const orange_light = '#ffc881';
const yellow = '#e0d313';
const yellow_light = '#e9e48d';

const mui_lightBlue = '#2e96ff';
const mui_lightPurple = '#b800d8';
const mui_blue = '#2731c8';
const mui_darkBlue = '#03008d';
const mui_purple = '#60009b';
const mui_greenblue = '#02b2af';

const rest: ApexOptions = {
  chart: {
    width: '200px',
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    pie: { // Replace 'donut' with 'pie' if using pie chart
      expandOnClick: false,
      // Remove the nested 'donut' if it's not a supported property
    },
  },
  fill: {
    colors: [app_purple, app_lighter, app_light, red, red_light, orange],
  },
  tooltip: {
    enabled: true,
    theme: 'dark',
  },
};

// Case Schedules / Scheduled Visits
const pieChartDataCaseSchedules: number[] = [337, 214, 3759];
const pieChartOptionsCaseSchedules: ApexOptions = {
  labels: ['Open', 'Missed', 'Staffed'],
  colors: [blue, green, red_light],
  ...rest
};

// Visit Invites
const pieChartDataVisitInvites: number[] = [57, 29, 15, 81];
const pieChartOptionsVisitInvites: ApexOptions = {
  labels: ['Acceptance Rate', 'Decline Rate', 'Ignore Rate', 'Click Rate'],
  colors: [blue, green, red, purple], // Replace with desired colors
  ...rest
};

// Case Invites By Status
const pieChartDataCaseInvitesByStatus: number[] = [18, 7, 24, 13];
const pieChartOptionsCaseInvitesByStatus: ApexOptions = {
  labels: ['Pending', 'Waiting For Info', 'Accepted', 'Declined'],
  // colors: ['#6AD2FF', '#FFCE56', '#4318FF', '#FF6384'], // Replace with desired colors
  colors: [app_purple, app_lighter, app_light, purple, purple_light], // Replace with desired colors
  ...rest
};

// Clock Ins 
const donutChartDataClockIn: number[] = [85, 10, 5];
const donutChartOptionsClockIn: ApexOptions = {
  labels: ['Early', 'On Time', 'Late', 'Missed'],
  // colors: ['#6AD2FF', '#FFCE56', '#4318FF', '#FF6384'], // Replace with desired colors
  colors: [app_lighter, app_purple, app_light, red], // Replace with desired colors
  legend: {
    position: 'bottom'
  },
  plotOptions: {
    pie: {
      donut: {
        size: '50%',
        // labels: {
        //   show: true,
        // }
      }
    }
  },
  dataLabels: {
    enabled: false,
  },
  // ...rest,
  fill: {
    colors: [app_lighter, app_purple, app_light, red],
  },
};

// Caregiver EVV Compliance
const pieChartDataCaregiverEVVCompliance: number[] = [19, 36, 7, 15];
const pieChartOptionsCaregiverEVVCompliance: ApexOptions = {
  labels: ['Missed Clock-Ins', 'Missed Clock-Outs', 'Non-compliant Clock-Ins', 'Non-compliant Clock-Outs'],
  colors: ['#FF6384', '#FFCE56', '#6AD2FF', '#4318FF'], // Replace with desired colors
  ...rest
};

// Patient Data
const pieChartDataPatients: number[] = [1461, 112, 33];
const pieChartOptionsPatients: ApexOptions = {
  labels: ['RN', 'PCA', 'HHA'],
  colors: [app_purple, app_lighter, app_light], // Replace with desired colors
  ...rest
};

// Caregiver Data
const pieChartDataCaregiver: number[] = [1685, 153, 472];
const pieChartOptionsCaregiver: ApexOptions = {
  labels: ['RN', 'PCA', 'HHA'],
  colors: [app_purple, app_lighter, app_light], // Replace with desired colors
  ...rest
};
// Case Schedules Component
interface CaseSchedule {
  open: number;
  missed: number;
  staffed: number;
}

// Visits Acceptance Rate Component
interface VisitAcceptanceRate {
  rate: number;
}

// Caregiver Visits EVV Compliance Component
interface CaregiverEVVCompliance {
  missedClockIns: number;
  missedClockOuts: number;
  nonCompliantClockIns: number;
  nonCompliantClockOuts: number;
}

// Patients Component
interface PatientData {
  onSchedule: number;
  notOnSchedule: number;
}


interface PatientFilter {
  dateRange: string; // 'Today', 'Tomorrow', 'This Weekend', 'Custom'
  coordinator: string; // COORD_NAME or 'All'
}

// ... other interfaces

const areaChartOptions: ApexOptions = {
  chart: {
    type: 'area',
    height: 350
  },
  colors: [app_lighter, red],
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth'
  },
  xaxis: {
    categories: getLastSixMonths().reverse(),
  },

  fill: {
    opacity: 1
  },

  tooltip: {
    y: {
      formatter: function (val) {
        return val + " visits"
      }
    }
  }
};

function getLastSixMonths() {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // Get the current date
  const currentDate = new Date();

  // Initialize an array to store the last 6 months
  const lastSixMonths = [];

  // Loop through the last 6 months and add the month strings to the array
  for (let i = 0; i < 7; i++) {
    // Calculate the index of the month to get
    const monthIndex = (currentDate.getMonth() - i + 12) % 12;

    // Add the month string to the array
    lastSixMonths.push(months[monthIndex]);
  }

  return lastSixMonths;
}

const barChartOptions: ApexOptions = {
  chart: {
    type: 'bar',
    height: 350
  },
  colors: [app_purple, red],
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
    },
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent']
  },
  xaxis: {
    categories: getLastSixMonths().reverse(),
  },
  yaxis: {
    title: {
      text: 'Visits'
    }
  },
  fill: {
    opacity: 1
  },

  tooltip: {
    y: {
      formatter: function (val) {
        return val + " visits"
      }
    }
  }
};
// const barChartData: ApexAxisChartSeries = [
//   {
//     name: "Staffed",
//     data: [256, 264, 271, 288, 301, 334, 343, 338],
//   },
//   {
//     name: "Missed",
//     data: [86, 79, 74, 66, 47, 24, 15, 17]
//   },
//   // Add more series if needed
// ];



interface MonthlyVisitCount {
  month: number;
  year: number;
  totalVisits: number;
  missedVisits: number;
  notMissedVisits: number;
}

interface ChartData {
  name: string;
  data: number[];
}
interface TransformedData {
  Staffed: number[];
  Missed: number[];
}

type dataType = {
  label: string,
  value: number,
  color?: string
}

// Define your main dashboard component
const Dashboard: React.FC = () => {
  // State hooks for each data type
  const [caseSchedules, setCaseSchedules] = useState<CaseSchedule[]>([]);
  const [visitAcceptanceRate, setVisitAcceptanceRate] = useState<VisitAcceptanceRate[]>([]);
  const [caregiverEVVCompliance, setCaregiverEVVCompliance] = useState<CaregiverEVVCompliance[]>([]);
  const [patientData, setPatientData] = useState<PatientData[]>([]);
  const [patientCount, setPatientCount] = useState<any>();
  const [caregiverCount, setCaregiverCount] = useState<any>();
  const [visitCount, setVisitCount] = useState<number>(0);
  const [visitHours, setVisitHours] = useState<number>(0);
  const [openVisits, setOpenVisits] = useState<number>(0);
  const [invitesWaiting, setInvitesWaiting] = useState<number>(0);
  const [noScheduleCare, setNoScheduleCare] = useState<number>(0);
  const [dates, setDates] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
  const [selectedCoordinator, setSelectedCoordinator] = useState<any>(null)
  const [selectedOffice, setSelectedOffice] = useState<any>(null)
  const [coordinators, setCoordinators] = useState<any[]>([])
  const [offices, setOffices] = useState<any[]>([])
  const [monthlyVisitCounts, setMonthlyVisitCounts] = useState<MonthlyVisitCount[]>([])
  const [inviteByStatus, setInviteByStatus] = useState<[dataType[], dataType[]]>([[], []])
  const [clockInPercent, setClockInPercent] = useState<any>([])
  const [barChartData, setBarChartData] = useState<ChartData[]>([]);
  const fetcher = useFetcher();
  const patientFetcher = useFetcher();
  const caregiverFetcher = useFetcher();
  const visitsFetcher = useFetcher();
  const invitesFetcher = useFetcher();
  const clockFetcher = useFetcher();
  const user = getUser();

  useEffect(() => {
    const transformedData = {
      Staffed: monthlyVisitCounts.map((v) => v.notMissedVisits),
      Missed: monthlyVisitCounts.map((v) => v.missedVisits),
    } as TransformedData;

    const chartData: ChartData[] = Object.keys(transformedData).map((name) => ({
      name,
      data: transformedData[name as keyof TransformedData],
    }));

    setBarChartData(chartData);
  }, [monthlyVisitCounts])

  const getCoordinators = () => {
    fetcher.get('coordinator/all').then((res: any) => {
      setCoordinators(res.data.data)
    })
  }

  const getOffices = () => {
    fetcher.get('filter/offices').then((res: any) => {
      setOffices(res.data.data)
    })
  }

  const getPatientCount = () => {
    patientFetcher.get('dashboard/patient_count').then(res => {
      setPatientCount(res.data.data)
    })
  }

  const getCaregiverCount = () => {
    caregiverFetcher.get('dashboard/caregiver_count').then(res => {
      setCaregiverCount(res.data.data)
    })
  }

  const getVisitCount = () => {
    fetcher.get('dashboard/visit_count').then(res => {
      setVisitCount(res.data.data)
    })
  }

  const getOpenVisits = () => {
    fetcher.get('dashboard/open_visits').then(res => {
      setOpenVisits(res.data.data)
    })
  }

  const getVisitHours = () => {
    fetcher.get('dashboard/visit_hours').then(res => {
      setVisitHours(res.data.data)
    })
  }

  const getWaitingInvites = () => {
    fetcher.get('dashboard/invites_waiting').then(res => {
      setInvitesWaiting(res.data.data)
    })
  }

  const getNoScheduleCaregivers = () => {
    fetcher.get('dashboard/no_schedule_caregivers').then(res => {
      setNoScheduleCare(res.data.data)
    })
  }

  const getMonthlyVisits = () => {
    visitsFetcher.get('dashboard/monthly_visits').then((res: any) => {
      setMonthlyVisitCounts(res.data.data)
    })
  }

  const getInviteByStatus = () => {
    invitesFetcher.get('dashboard/invite_by_status').then((res: any) => {
      if (res.data.data) {
        const { activityCounts, statusCounts, ...rest } = res.data.data;
        const result: [dataType[], dataType[]] = [statusCounts, activityCounts];
        setInviteByStatus(result);
      }
    })
  }

  const getClockInPercent = () => {
    clockFetcher.get('dashboard/clockIn_percent').then((res: any) => {
      setClockInPercent(res.data.data)
    })
  }

  useEffect(() => {
    getCoordinators()
    getOffices()
    getPatientCount()
    getCaregiverCount()
    getVisitCount()
    getVisitHours()
    getOpenVisits()
    getWaitingInvites();
    getNoScheduleCaregivers()
    getMonthlyVisits()
    getInviteByStatus();
    getClockInPercent()
  }, [])

  const [dashboardFilter, setDashboardFilter] = useState({
    dateRange: 'Today', // 'Today', 'Tomorrow', 'This Weekend', 'Custom'
    coordinator: 'All', // COORD_NAME or 'All'
  });

  // Fetch data from your backend here
  // useEffect to call APIs

  // console.log('chartData', pieChartDataVisitInvites);

  return (
    <div className="dashboard" style={{ backgroundColor: '#e8e0ff', padding: "20px 30px 20px 20px", height: '100%' }}>
      <div style={{ maxWidth: '1450px', margin: '0 auto', }}>
        <div style={{ alignItems: 'start', display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h4" component="h4" sx={{ fontSize: '25px', fontWeight: 'bold', marginBottom: '20px' }}>
            {'Welcome!'}
          </Typography>
          {/* Filters */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              value={selectedCoordinator}
              IconComponent={selectedCoordinator ? () => (<IconButton onClick={() => setSelectedOffice(null)}><Clear color='primary' fontSize='small' /></IconButton>) : undefined}
              onChange={(e: any) => setSelectedOffice(e.target.value)}
              startAdornment={<BusinessOutlined fontSize='small' color='primary' sx={{ marginRight: '10px' }} />}
              size='small'
              sx={{
                width: '235px',
                height: '30px',
                borderRadius: '5px',
                marginRight: '10px',
                color: app_purple,
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#7846ff',
                },
              }}
            >
              {offices.map((office: any) => (
                <MenuItem sx={{ maxWidth: '250px' }} id={office.id} value={office.id}>{office.officeName}</MenuItem>
              ))}
            </Select>
            <Select
              value={selectedCoordinator}
              IconComponent={selectedCoordinator ? () => (<IconButton onClick={() => setSelectedCoordinator(null)}><Clear color='primary' fontSize='small' /></IconButton>) : undefined}
              onChange={(e: any) => setSelectedCoordinator(e.target.value)}
              startAdornment={<PersonOutlineOutlined fontSize='small' color='primary' sx={{ marginRight: '10px' }} />}
              size='small'
              sx={{
                width: '235px',
                height: '30px',
                borderRadius: '5px',
                marginRight: '10px',
                color: app_purple,
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#7846ff',
                },
              }}
            >
              {coordinators.map((coordinator: any) => (
                <MenuItem sx={{ maxWidth: '250px' }} id={coordinator.id} value={coordinator.id}>{coordinator.name}</MenuItem>
              ))}
            </Select>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                calendars={1}
                slots={{ field: SingleInputDateRangeField, switchViewIcon: CalendarMonthOutlined }}
                slotProps={{
                  textField: {
                    InputProps: { startAdornment: <CalendarMonthOutlined color='primary' fontSize='small' sx={{ marginRight: '10px' }} /> },
                    size: 'small',
                    placeholder: '',
                    sx: {
                      '> .MuiOutlinedInput-root': {
                        height: '30px',
                        width: '235px',
                        color: app_purple,
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#7846ff',
                      },
                    }
                  }
                }}
                sx={{ width: '250px', marginLeft: '10px' }}
                value={dates}
                onChange={(value) => setDates(value)}
                closeOnSelect={false}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="filters">
          {/* Date and Coordinator filters */}
        </div>

        {/* Case Schedules Component */}
        {/* Visits Acceptance Rate Component */}
        {/* Caregiver EVV Compliance Component */}
        {/* Patients Component */}

        {/* TOP ROW */}
        <Grid marginBottom={'25px'} container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <DashboardBoxCard isLoading={fetcher.isLoading} title='Caregiver Without Schedule' value={noScheduleCare.toString()} cardIcon={<HistoryToggleOffOutlined fontSize='small' sx={{ color: "white" }} />} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <DashboardBoxCard isLoading={fetcher.isLoading} cardIcon={<HourglassEmptyOutlined fontSize='small' sx={{ color: 'white' }} />} title='Invites Awaiting Response' value={invitesWaiting.toString()} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <DashboardBoxCard isLoading={fetcher.isLoading} cardIcon={<VisitIcon />} title='Open Visits' value={openVisits.toString()} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <DashboardBoxCard isLoading={fetcher.isLoading} cardIcon={<AssignmentIndOutlined fontSize='small' sx={{ color: 'white' }} />} title='My Tasks' value='0' />
          </Grid>
        </Grid>

        {/* SECOND ROW */}
        <Grid marginBottom={'25px'} container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <DashboardBarChart
              title="Visits"
              value={monthlyVisitCounts.reduce((acc, visit) => acc + visit.totalVisits, 0)}
              secondaryTextTop='Shifts Overview'
              secondaryTextBottom='Total Visits Last 6 Months'
              chartData={barChartData}
              chartOptions={barChartOptions}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <DashboardDoubleDonutCard
              title="Case Invites Status"
              value="520" // Replace with actual total number
              secondaryTextBottom="Current Status"
              chartData={inviteByStatus}
              chartOptions={pieChartOptionsCaseInvitesByStatus}
              isLoading={invitesFetcher.isLoading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <DashboardBoxCard isLoading={fetcher.isLoading} height={'55px'} cardIcon={<PatientIcon />} title='Active Patients' value={patientCount?.count.toString()} />
            <DashboardBoxCard isLoading={fetcher.isLoading} marginTop='17px' height={'55px'} cardIcon={<CaregiverIcon />} title='Active Caregivers' value={caregiverCount?.count.toString()} />
            <DashboardBoxCard isLoading={fetcher.isLoading} marginTop='17px' height={'55px'} cardIcon={<VisitIcon />} title='Todays Visits' value={visitCount.toString()} />
            <DashboardBoxCard isLoading={fetcher.isLoading} marginTop='17px' height={'55px'} cardIcon={<Schedule fontSize='small' sx={{ color: 'white' }} />} title='Visit Hours' value={visitHours.toString()} />
          </Grid>
        </Grid>

        {/* THIRD ROW */}
        <Grid marginBottom={'25px'} container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <DashboardDonutCard
              isLoading={clockFetcher.isLoading}
              title='Clock In' value={''}
              chartData={Object.values(clockInPercent['clockIn'] || [])}
              chartOptions={donutChartOptionsClockIn}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <DashboardDonutCard
              isLoading={clockFetcher.isLoading}
              title='Clock Out'
              value={''}
              chartData={Object.values(clockInPercent['clockOut'] || [])}
              chartOptions={donutChartOptionsClockIn}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <DashboardAreaChart
              title="Visits"
              value={monthlyVisitCounts.reduce((acc, visit) => acc + visit.totalVisits, 0)}
              secondaryTextTop='Shifts Overview'
              secondaryTextBottom='Total Visits last 6 Months'
              chartData={barChartData}
              chartOptions={areaChartOptions}
            />
          </Grid>
        </Grid>

        {/* FOURTH ROW */}
        <Grid marginBottom={'25px'} container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <DashboardMetricCard
              title="Caregiver Data"
              value={caregiverCount?.count}// Replace with actual total number
              secondaryTextBottom="Active Caregivers"
              chartData={Object.values(caregiverCount?.data || [])}
              chartOptions={pieChartOptionsCaregiver}
              isLoading={caregiverFetcher.isLoading}
            />
            {/* <DashboardBoxCard marginTop='30px' height={'55px'} cardIcon={<CaregiverIcon />} title='Active Caregivers' value='6521' /> */}

            {/* <DashboardMetricCard
            title="EVV Compliance"
            value="43" // Replace with actual total number
            secondaryTextBottom="Compliance Overview"
            chartData={pieChartDataCaregiverEVVCompliance}
            chartOptions={pieChartOptionsCaregiverEVVCompliance}
          /> */}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <DashboardMetricCard
              isLoading={patientFetcher.isLoading}
              title="Patient Data"
              value={patientCount?.count} // Replace with actual total number
              secondaryTextBottom="Active Patients"
              chartData={Object.values(patientCount?.data || [])}
              chartOptions={pieChartOptionsPatients}
            />
          </Grid>
        </Grid>
        <div style={{ display: 'flex' }}>
        </div>
        {/* <DashboardText /> */}
      </div>
    </div >
  );
};

export default Dashboard;



const mockDashboardData = {
  caseSchedules: {
    open: 14, // now open in the date-range
    missed: 7, // total missed through date-range
    staffed: 20, // total staffed through date-range
  },
  visitInvites: {
    acceptanceRate: 57, // means matches are good enough to be accepted
    declineRate: 29,
    ignoreRate: 15,
    clickRate: 81, // means only interested in a job are getting invites
    avgTimeToGetStaffed: "1h 56m",
    avgTimeToResponse: "1h 13m",
  },
  caseInvitesByStatus: {
    pending: 18,
    waitingForInfo: 7,
    accepted: 24,
    declined: 13,
  },
  caregiverEVVCompliance: {
    missedClockIns: 19,
    missedClockOuts: 36,
    nonCompliantClockIns: 7,
    nonCompliantClockOuts: 15,
  },
  patientData: {
    onSchedule: 1461,
    onTempSchedule: 112,
    notOnSchedule: 33,
  },
  caregiverData: {
    onSchedule: 1685,
    onTempSchedule: 153,
    notOnSchedule: 472,
  },
};

const DashboardText: React.FC = () => {
  return (
    <div className="dashboard">
      {/* Filters - Placeholder for filters */}
      <div className="filters">
        <p>Filters: Date Range, Coordinator</p>
      </div>

      {/* Case Schedules */}
      <div className="case-schedules">
        <h2>Case Schedules</h2>
        <p>Open: {mockDashboardData.caseSchedules.open}</p>
        <p>Missed: {mockDashboardData.caseSchedules.missed}</p>
        <p>Staffed: {mockDashboardData.caseSchedules.staffed}</p>
      </div>

      {/* Visit Invites */}
      <div className="visit-invites">
        <h2>Visit Invites</h2>
        <p>Acceptance Rate: {mockDashboardData.visitInvites.acceptanceRate}%</p>
        <p>Decline Rate: {mockDashboardData.visitInvites.declineRate}%</p>
        <p>Ignore Rate: {mockDashboardData.visitInvites.ignoreRate}%</p>
        <p>Click Rate: {mockDashboardData.visitInvites.clickRate}%</p>
        <p>Average Time to Get Staffed: {mockDashboardData.visitInvites.avgTimeToGetStaffed}</p>
        <p>Average Time to Response: {mockDashboardData.visitInvites.avgTimeToResponse}</p>
      </div>

      {/* Case Invites By Status */}
      <div className="case-invites-by-status">
        <h2>Case Invites By Status</h2>
        <p>Pending: {mockDashboardData.caseInvitesByStatus.pending}</p>
        <p>Waiting for Info: {mockDashboardData.caseInvitesByStatus.waitingForInfo}</p>
        <p>Accepted: {mockDashboardData.caseInvitesByStatus.accepted}</p>
        <p>Declined: {mockDashboardData.caseInvitesByStatus.declined}</p>
      </div>

      {/* Caregiver EVV Compliance */}
      <div className="evv-compliance">
        <h2>Caregiver EVV Compliance</h2>
        <p>Missed Clock-Ins: {mockDashboardData.caregiverEVVCompliance.missedClockIns}</p>
        <p>Missed Clock-Outs: {mockDashboardData.caregiverEVVCompliance.missedClockOuts}</p>
        <p>Non-compliant Clock-Ins: {mockDashboardData.caregiverEVVCompliance.nonCompliantClockIns}</p>
        <p>Non-compliant Clock-Outs: {mockDashboardData.caregiverEVVCompliance.nonCompliantClockOuts}</p>
      </div>

      {/* Patients */}
      <div className="patients">
        <h2>Patients</h2>
        <p>On Schedule: {mockDashboardData.patientData.onSchedule}</p>
        <p>On Temp Schedule: {mockDashboardData.patientData.onTempSchedule}</p>
        <p>Not on Schedule: {mockDashboardData.patientData.notOnSchedule}</p>
      </div>

      {/* Caregivers */}
      <div className="caregivers">
        <h2>Caregivers</h2>
        <p>On Schedule: {mockDashboardData.caregiverData.onSchedule}</p>
        <p>On Temp Schedule: {mockDashboardData.caregiverData.onTempSchedule}</p>
        <p>Not on Schedule: {mockDashboardData.caregiverData.notOnSchedule}</p>
      </div>
    </div>
  );
};