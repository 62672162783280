import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, } from '../../../../lib/MUI/mui.components';
import NewModal from '../../NewModal';
import { BoldTitle, FlexJustifyEnd } from '../../../../assets/styles/styledComponents';
import { strings } from '../../../../helper/strings';

interface ConfirmationDialogProps {
  open: boolean;
  onClose: (confirmed: boolean) => void;
  action?: string;
  item?: string;
}

const ConfirmationDialog = ({ open, onClose, action, item }: ConfirmationDialogProps) => {
  const handleConfirmClick = () => {
    onClose(true);
  };

  const handleCancelClick = () => {
    onClose(false);
  };

  return (
    <NewModal title={`Confirm ${action || 'Action'}?`} btnTitle={strings.firstLetterUpperCase(action || 'Action')} open={open} onClose={handleCancelClick} height='150px' onSave={handleConfirmClick}>
      <BoldTitle>Are you sure you want to {action?.toLowerCase() || 'perform this action for '} this {item || 'Item'}?</BoldTitle>
    </NewModal>
  );
};

export default ConfirmationDialog;
