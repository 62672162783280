import { useNavigate } from "react-router-dom";
import { homePageUrl, loginPageUrl } from "../../../data/constants";
import { useEffect, useState } from "react";
import { AppyUser } from "../../../hooks/types";

const useAuth = () => {
  const navigate = useNavigate()

  // state userAccount
  const [userAccount, setUserAccount] = useState<any>();

  const BETA_USERS: string[] = [
    'dani@emerest.com'
  ]

  const getUser = (): AppyUser => {
    const user = localStorage.getItem("user") as any;
    if (!user || user == 'undefined')
      return {} as AppyUser;

    let userInfo = JSON.parse(user) as AppyUser;
    if (!userInfo)
      return {} as AppyUser;

    userInfo.isBetaUser =
      BETA_USERS.includes(userInfo?.username) ||
        userInfo?.username?.includes('@techonsoft.com') || userInfo?.username?.includes('@connectaide.ai') ? true : false;
    return userInfo;
  }

  const saveUserLogin = (response: any) => {
    const user: any = response.data;
    localStorage.setItem('user', JSON.stringify(user))
    setUserAccount(user);
    //navigate(homePageUrl)
  }

  const logoutUser = () => {
    // Clearing Cookies:
    document.cookie.split(";").forEach((c) => {
      document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    // Clearing LocalStorage:
    localStorage.clear();

    // Clearing SessionStorage:
    sessionStorage.clear();

    localStorage.removeItem('user');
    setUserAccount(undefined);
    //navigate(loginPageUrl)
  }

  return {
    saveUserLogin: saveUserLogin,
    getUser: getUser,
    logoutUser: logoutUser,
    userAccount: userAccount,
    setUserAccount
  }
}

export default useAuth