import { useEffect, useState, useRef } from 'react';
import { HubConnectionBuilder, HttpTransportType, HubConnection } from '@microsoft/signalr';
import useFetcher from './useFetcher';

type AccessInfo = {
    userId: number;
    url: string;
    accessToken: string;
}
export type EventConfig = {
    eventName: string;
    action: (...args: any[]) => void;
};

export const useSignalR = (events: EventConfig[], dependencies: any[]) => {
    const [connection, setConnection] = useState<HubConnection>();
    const [connState, setConnectionState] = useState<string>(''); // ['connecting', 'connected', 'reconnecting', 'disconnected'
    const [accessInfo, setAccessInfo] = useState<AccessInfo>();
    const latestEvents = useRef(events);

    useEffect(() => {
        latestEvents.current = events;
    }, [events]);


    // hooks
    const fetcher = useFetcher();

    useEffect(() => {
        if (!!accessInfo?.accessToken || connection?.state === 'Connected') return;

        fetcher.get('api/signalr/negotiate').then((response: any) => {
            console.log(response);
            setAccessInfo(response.data.data);

        }).catch((error: any) => {
            console.log(error);
        });
    }, [accessInfo, connection?.state]);

    // const [events, setEvents_set] = useState<EventConfig[]>([]);
    // const setEvents = (events: EventConfig[]) => {
    //     setEvents_set(events);
    // };
    useEffect(() => {
        if (connection?.state === 'Connected') return;
        if (!accessInfo?.url) return;

        console.debug("NEW CONNECTING");
        const newConnection = new HubConnectionBuilder()
            .withUrl(accessInfo.url, {
                accessTokenFactory: () => accessInfo.accessToken,
                transport: HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect([0, 2000, 5000, 10000, 15000, 15000, 15000]) // Reconnect immediately, then wait 2, 10, and 30 seconds respectively for subsequent retries.
            .build();

        newConnection.start()
            .then(() => {
                console.debug('Connected to SignalR hub.');

                //newConnection.off('NewMessage', );
                newConnection.on('NewMessage', (a: any, b: any, c: any, d: any) => {
                    console.debug(a);
                    latestEvents.current.find(event => event.eventName === 'NewMessage')?.action(a, b, c, d);
                });

                newConnection.on('unreadMessages', (a: any, b: any, c: any, d: any) => {
                    console.debug(a);
                    latestEvents.current.find(event => event.eventName === 'unreadMessages')?.action(a, b, c, d);
                });

                // latestEvents.current.forEach(event => {
                //     newConnection.on(event.eventName, event.action);
                // });

                // newConnection.onreconnected((connectionId?: string) => {
                //     console.debug(`Reconnected with connectionId ${connectionId}`);
                // });
                // newConnection.onclose((error?: Error) => {
                //     console.error(`Connection closed due to error: ${error}`);
                // });
            })
            .catch(err => console.error('Error connecting to SignalR hub:', err));

        setConnection(newConnection);

        return () => {
            latestEvents.current.forEach(event => {
                newConnection.off(event.eventName, event.action);
            });
            newConnection.stop()
                .then(() => console.debug('Disconnected from SignalR hub.'))
                .catch(err => console.error('Error disconnecting from SignalR hub:', err));
        };
    }, [accessInfo]);

    useEffect(() => {
        setConnectionState(connection?.state || '');
        console.log('connection state', connection?.state);
        if (!connection || connection.state === 'Disconnected' || connection.state === 'Connecting') {
            return;
            // } else if (connection.state === 'Connected') {
            //     // nothing, already connected
            // } else if (connection.state === 'Disconnected' && !!accessInfo?.accessToken) {
            //     connection?.start()
            //     .then(() => console.log('Re-connected to SignalR hub.'))
            //     .catch(err => console.error('Error re-connecting to SignalR hub:', err));
            //     return;
        }

        // connection.on('NewMessage', (a: any, b: any, c: any, d: any) => {
        //     console.log(a);
        // });

        // console.log('count', events);
        // events.forEach(event => {
        //     console.log(`useSignalR: adding event ${event.eventName}`);
        //     connection.on(event.eventName, event.action);
        // });

        // return () => {
        //     events.forEach(event => {
        //         connection.off(event.eventName, event.action);
        //     });
        //     connection.stop()
        //         .then(() => console.log('Disconnected from SignalR hub.'))
        //         .catch(err => console.error('Error disconnecting from SignalR hub:', err));
        // };
    }, [connection, connection?.state, events, dependencies]);

    return {
        connection: {
            state: connState,
            on: connection
                ? (methodName: string, newMethod: (...args: any[]) => void) => connection.on(methodName, newMethod)
                : () => { },
            off: connection
                ? (methodName: string, newMethod: (...args: any[]) => void) => connection.off(methodName, newMethod)
                : () => { },
            ...connection
        },
        //setEvents
    };
};