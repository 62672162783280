import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import useFetcher from '../../hooks/useFetcher';
import NewModal from '../../components/ui/NewModal';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, styled } from '@mui/material';
import { MatchTagType } from './types';
import { MenuOption } from '../../components/form/FactorSettings/FactorConstants';

interface PreferenceDialogProps {
  open: boolean;
  onClose: () => void;
  preference?: MatchTagType; // Optional preference for editing
  addEditPreference: Function;
  disciplines: string[];
}

const PreferenceDialog: React.FC<PreferenceDialogProps> = ({ open, onClose, preference, addEditPreference, disciplines }) => {
  const [name, setName] = useState(preference?.name || '');
  const [clientLabel, setClientLabel] = useState(preference?.clientLabel || '');
  const [providerLabel, setProviderLabel] = useState(preference?.providerLabel || '');
  const [appliedParty, setAppliedParty] = useState(preference?.appliedParty || '');
  const [discipline, setDiscipline] = useState(preference?.discipline || '');
  const [isRequired, setIsRequired] = useState(preference?.isRequiredAsk || false);
  const [isActive, setIsActive] = useState(preference?.isActive || false);
  const preferenceFetcher = useFetcher();
  const [isUpdating, setIsUpdating] = useState(false);

  const handleSave = async () => {
    setIsUpdating(true);
    const updatedPreference = { ...preference, name, clientLabel, providerLabel, appliedParty, discipline, isActive, isRequiredAsk: isRequired };

    try {
      const response = await preferenceFetcher.post('preference/save', updatedPreference);
      const data = { ...response.data.data };
      addEditPreference(data);
      onClose();
    } catch (err) {
      console.error("Error updating preference:", err);
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    if (!!preference) {
      setName(preference.name);
      setClientLabel(preference.clientLabel);
      setProviderLabel(preference.providerLabel);
      setAppliedParty(preference.appliedParty);
      setDiscipline(preference.discipline ?? "");
      setIsRequired(preference.isRequiredAsk ?? false);
      setIsActive(preference.isActive ?? false);
    }
  }, [preference]);

  const handleDisciplineChange = (event: React.ChangeEvent<{ value: unknown }> | any) => {
    const val = event.target.value.join(',');
    setDiscipline(val);
  };

  return (
    <NewModal title={'New/Edit Preference'} open={open} onClose={onClose} onSave={handleSave} height='460px'>
      <TextField
        autoFocus
        margin="dense"
        id="name"
        label="Preference Name"
        type="text"
        fullWidth
        value={name || ''}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        margin="dense"
        id="clientLabel"
        label="Client Label"
        type="text"
        fullWidth
        //multiline
        //rows={2}
        value={clientLabel || ''}
        onChange={(e) => setClientLabel(e.target.value)}
      />
      <TextField
        margin="dense"
        id="providerLabel"
        label="Provider Label"
        type="text"
        fullWidth
        //multiline
        //rows={2}
        value={providerLabel || ''}
        onChange={(e) => setProviderLabel(e.target.value)}
      />
      <FormControl
        fullWidth
        margin="dense"
      >
        <InputLabel id="discipline-label">Disciplines</InputLabel>
        <Select
          multiple
          label="Disciplines"
          value={discipline?.split(',') as string[] ?? []}
          onChange={handleDisciplineChange}
        >
          {disciplines?.map((d: string) =>
            <MenuItem key={d} value={d}>{d}</MenuItem>
          )}
        </Select>
      </FormControl>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormControl
            fullWidth
            margin="dense"
          >
            <InputLabel id="is-required-label">Require Ask</InputLabel>
            <Select
              label="Require Ask"
              value={isRequired}
              onChange={(e) => setIsRequired(e.target.value === 'true')}
            >
              {[{ key: 'Required', value: 'true' }, { key: 'Not Required', value: 'false' }].map((d: MenuOption) =>
                <MenuItem key={d.key} value={d.value}>{d.key}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl
            fullWidth
            margin="dense"
          >
            <InputLabel id="is-active-label">Is Active</InputLabel>
            <Select
              label="Is Active"
              value={isActive}
              onChange={(e) => setIsActive(e.target.value === 'true')}
            >
              {[{ key: 'Active', value: 'true' }, { key: 'Inactive', value: 'false' }].map((d: MenuOption) =>
                <MenuItem key={d.key} value={d.value}>{d.key}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </NewModal>

  );
};

export default PreferenceDialog;