import { Delete, Edit } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import { BoldTitle, FlexExactCenter, Text } from '../../assets/styles/styledComponents';
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import FeaturedIcon from "../../components/common/FeaturedIcon";
import { sortByStringProperty } from "../../helper/arrays";
import { datagridStyle } from "../../data/GridColumnDefinitions";
import { useState } from "react";
import ConfirmationDialog from "../../components/ui/Modals/components/ConfirmationDailog";
import { PersonPrefType } from "../../pages/Preference/types";
import PrefItemCheckBoxEdit from "./PrefItemCheckBoxEdit";

interface TagPreferencesProps {
    view?: string;
    module?: string;
    filteredRows: PersonPrefType[];
    isRequiredAsk: (id: number) => boolean;
    getPreferenceLabel: (id: number, party: string) => string;
    deletePreference: (selectedRowId: number) => void;
    updatePreference: (row: PersonPrefType, column: string) => void;
}
const TagPreferences: React.FC<TagPreferencesProps> = ({ view = 'grid', filteredRows, module, isRequiredAsk, getPreferenceLabel, deletePreference, updatePreference }) => {
    const [showDeleteConfirmation, set_showDeleteConfirmation] = useState<boolean>(false);
    const [selectedRowId, set_selectedRowId] = useState<number | undefined>(undefined);

    const valueColumns: GridColDef[] = [
        {
            field: 'name', headerName: 'Preference', flex: 0.04,
            renderCell: (params: any) => {
                return (
                    <Tooltip placement='left' title={params.row.description}>
                        <Text>{getPreferenceLabel(params.row.preferenceId, module ?? '')}</Text>
                    </Tooltip>
                )
            }
        },
        {
            field: 'value',
            headerName: 'Value',
            editable: true,
            type: 'singleSelect',
            valueOptions: [{ value: true, label: 'Yes' }, { value: false, label: 'No' }],
            flex: 0.04,
            cellClassName: 'editable',
            renderCell: (params: any) => {
                const rowId = params.row?.id;

                return (
                    <div style={{ position: 'relative', width: '100%', cursor: 'pointer' }}>
                        <Text
                            style={{ color: (rowId > 0 ? 'black' : 'red') }}>
                            {rowId < 1 ? (isRequiredAsk(rowId) ? '*' : '') : params.row.value == true ? "Yes" : params.row.value == false ? 'No' : ''}
                        </Text>
                        <Edit style={{ position: 'absolute', right: 0, width: '15px', height: '15px', color: 'gray' }} />
                    </div>
                )
            }
        },
        {
            field: 'delete',
            headerName: '',
            flex: 0.01,
            renderCell: (params: any) => {
                const rowId = params.row?.id;
                const handleEditClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
                    set_selectedRowId(rowId);
                    set_showDeleteConfirmation(true);
                };

                return (
                    <div style={{ position: 'relative', width: '100%', cursor: 'pointer', paddingBottom: '21px' }}
                        onClick={handleEditClick}
                    >
                        <Delete
                            style={{ position: 'absolute', color: (rowId > 0 ? 'gray' : '#8080805c'), width: '15px', height: '15px' }}>
                        </Delete>
                    </div>
                )
            }
        },
    ]

    return (
        <Box>
            {view === 'checkbox' ?
                <Box>
                    {sortByStringProperty(filteredRows, 'name', true)
                        .map((preference: PersonPrefType) => (
                            <PrefItemCheckBoxEdit
                                preference={preference}
                                updatePreference={updatePreference}
                            />
                        ))}
                </Box> :
                <DataGridPro
                    sx={(theme: any) => (datagridStyle(theme).root)}
                    columns={valueColumns}
                    rows={!filteredRows?.length ? [] : sortByStringProperty(filteredRows, 'name', true)}
                    rowHeight={40}
                    headerHeight={35}
                    hideFooter
                    autoHeight
                    components={{
                        Toolbar: () =>
                            <div>
                                <Tooltip title={`
                                        Preferences &${module == "patient" ? '' : ' required'} 
                                        skills ${module == "patient" ? 'the' : 'this'} 
                                        caregiver would ${module == "patient" ? 'need to ' : ''}meet`}>
                                    <div style={{ fontWeight: 'bold', textAlign: 'center', padding: '2px', color: "primary" }}>
                                        Scheduling Preferences (Tags)
                                        <FeaturedIcon icon="Info" fontSize="small" />
                                    </div>
                                </Tooltip>
                            </div>
                    }}
                    onCellEditCommit={(params) => updatePreference({ value: params.value, id: params.id } as PersonPrefType, 'value')}
                />
            }
            <ConfirmationDialog
                open={showDeleteConfirmation}
                onClose={(confirmed: boolean) => {
                    set_showDeleteConfirmation(false);
                    if (!confirmed)
                        return;
                    if (selectedRowId || -1 > 0)
                        deletePreference(selectedRowId || 0);
                }}
                action='Delete'
                item='Preference'
            />
        </Box>
    );
}

export default TagPreferences;