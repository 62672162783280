import React from 'react';
import { TextField, MenuItem, TextFieldProps } from '@mui/material';

const disciplines = ['PCA', 'HHA', 'PA'];

const DisciplineField: React.FC<Omit<TextFieldProps, 'value' | 'onChange'> & {
    value?: string[];
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ value = [], onChange, ...props }) => {
    return (
        <TextField
            label="Discipline"
            select
            fullWidth
            variant="outlined"
            value={value}
            onChange={onChange}
            SelectProps={{
                multiple: true,
            }}
            InputLabelProps={{
                shrink: true,
            }}
            {...props}
        >
            {disciplines.map((discipline) => (
                <MenuItem key={discipline} value={discipline}>
                    {discipline}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default DisciplineField;