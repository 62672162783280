import React from 'react'
import {MuiBox, MuiTypography} from '../../../lib/MUI/mui.components'

interface TabPanelProps {
    children?: React.ReactNode;
    disabled?: boolean,
    index: number | string;
    value: number | string;
  }
  
const TabPanel: React.FC<TabPanelProps> = (props) => {
    const { children, value, index, disabled, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index || disabled}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            >
            {value === index && (
                <MuiBox sx={{ px: 1, py: 2 }}>
                    <MuiTypography>{children}</MuiTypography>
                </MuiBox>
            )}
        </div>
    );

}

export default TabPanel