import { BASE_URL, LOCAL_URL, STAGING_URL, TEST_URL } from "../data/constants";
import { strings } from "../helper/strings";

//TODO: we need to make a global file that shares these constants. Currently it's in 2 files
const envMode = localStorage.getItem("envMode");

export const getUrl = () =>
	envMode === 'debug' ? LOCAL_URL :
		envMode === 'test' ? TEST_URL :
			envMode === 'prod' ? STAGING_URL : BASE_URL;

export const getUser = () => {
	const user = localStorage.getItem("user") as any;
	if (!user || user == 'undefined' || !strings.isValidJSON(user)) {
		logoutUser();
		return undefined;
	}
	return JSON.parse(user);
}

const logoutUser = () => {
    document.cookie.split(";").forEach((c) => {
      document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem('user');
  }

export const getFetchRequestObj = (urlPath: string, method: string) => {
	const user = getUser();

	const rootUrl = getUrl();

	const headers = new Headers({
		Authorization: `Bearer ${user?.token}`,
		"Content-Type": "application/json",
	});

	const request = new Request(rootUrl + urlPath, {
		method,
		headers: headers,
	});

	return request;
};

export const doFetch = async (request: Request) => {
	const response = await fetch(request);
	const jsonData = await response.json();

	return jsonData;
}

/* export const getAxiosInstance = (): AxiosInstance => {
	const user = getUser();

	const inst = axios.create({
		baseURL: getUrl(),
	});

	inst.defaults.headers.common["Authorization"] = `Bearer ${user?.token}`;

	return inst;
} */


