import { useEffect, useState } from "react";
import DataGrid from "../../components/ui/DataGrid"
import useApi_DEPRACATED from "../../hooks/useApi";
import useFetcher from "../../hooks/useFetcher";
import { BASE_URL, DELETE_USER_PATH, SAVE_USER_PATH, SEND_RESET_PASSWORD_LINK } from "../../data/constants";
import { BoldTitle, Flex, FlexAlignCenter, FlexEvenly, Text } from "../../assets/styles/styledComponents";
import { Avatar, Box, TextField, Button as MuiButton, Checkbox, FormControlLabel, Autocomplete, IconButton, Badge, Switch, styled, Select, Typography, FormControl, MenuItem, SelectChangeEvent, Tooltip, Grid } from "@mui/material";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import useAuth from "../../features/authentication/hooks/useAuth";
import useToast from "../../hooks/useToast";
import ReactLoader from "../../components/common/ReactLoader";
import AddFab from "../../components/ui/AddFab";
import NewModal from "../../components/ui/NewModal";
import { strings } from "../../helper/strings";
import Loader from "../../components/ui/Loader";
import { Delete, Key, ModeEdit } from "@mui/icons-material";
import FeaturedField from "../../components/common/FeaturedField";

const UsersList = () => {
    const { getList, isLoading, data } = useApi_DEPRACATED('user', BASE_URL)
    const fetcher = useFetcher()
    const coordinatorsFetcher = useFetcher()
    const supervisorsFetcher = useFetcher()
    const [rows, setRows] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [errorText, setErrorText] = useState(false);
    const { showError, showSuccess } = useToast();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isCoordinator, setIsCoordinator] = useState(false);
    const [isSupervisor, setIsSupervisor] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [options, setOptions] = useState<any[]>([]);
    const [selectedOption, setSelectedOption] = useState<any>();
    const [action, setAction] = useState('');
    const [actionRow, setActionRow] = useState('');
    const [open, setOpen] = useState<boolean>(false)
    const { getUser } = useAuth();
    const user = getUser();
    const [rolesFilter, set_rolesFilter] = useState<string[]>(['admin', 'coordinator', 'supervisor', 'superadmin']);

    const [userForm, setUserForm] = useState({
        id: { value: 0 },
        email: { value: '' },
        firstName: { value: '' },
        middleName: { value: '' },
        lastName: { value: '' },
        password: { value: '' },
        isActive: { value: true },
        role: { value: '' },
        roleId: { value: '' }
    })

    useEffect(() => {
        rows?.length < 1 && loadUsers();
        fetcher.showResponseToastAs(true);
    }, [])

    const loadUsers = () => {
        let endpoint = '/all';
        const roles_str = rolesFilter.join(",");
        let filtersParam = !!roles_str ? '?rolesFilter=' + roles_str : '';
        getList(endpoint + filtersParam).then(res => {
            setRows(res)
        })
    }

    const checkEmail = (email: string): boolean => {
        const regexPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regexPattern.test(email);
    };

    const createUser = () => {
        if (!checkEmail(userForm.email.value)) {
            setErrorText(true);
            return;
        } else {
            setErrorText(false)
        }
        if (!isAdmin && !isCoordinator && !isSupervisor) {
            setErrorMessage("Please select a Role")
            return;
        }

        const data = {
            "userId": userForm.id.value,
            "email": userForm.email.value,
            "firstName": userForm.firstName.value,
            "middleName": userForm.middleName.value,
            "lastName": userForm.lastName.value,
            "password": userForm.password.value,
            "isActive": userForm.isActive.value,
            "hasTempPassword": action === 'Create' ? true : false,
            "roles": [
                userForm.role.value
            ],
            "agencyId": 0,
            "ipAddresses": [
                ""
            ]
        }

        const option = options?.find(o => o.name == selectedOption);
        const type = isSupervisor ? 'supervisor' : isCoordinator ? 'coordinator' : '';
        let postData = { ...data, password: '' }

        fetcher.put(`${SAVE_USER_PATH}?${type == '' ? '' : `&positionType=${type}&refId=${option?.id > 0 ? option?.id : 0}`}${isAdmin ? '&isAdmin=true' : ''}`, postData)
            .then((data) => {
                showSuccess("User saved succesfully")
                handleCloseModal()
                loadUsers()
            }).catch(err => {
                //console.log(err)
                //showError(err.response?.data ?? err.message ?? err ?? 'Error saving user')
            });
    }

    const handleCloseModal = () => {
        setOpenModal(false)
        setUserForm({
            id: { value: 0 },
            email: { value: '' },
            firstName: { value: '' },
            middleName: { value: '' },
            lastName: { value: '' },
            password: { value: '' },
            isActive: { value: true },
            role: { value: '' },
            roleId: { value: '' }
        })
        setErrorText(false)
        setIsCoordinator(false)
        setIsSupervisor(false)
        setIsAdmin(false)
        setSelectedOption('')
        setAction('')
        setErrorMessage('')
    }

    useEffect(() => {
        if (!!isCoordinator && coordinatorsFetcher.data?.length > 0)
            setOptions(coordinatorsFetcher.data);
        if (!!isSupervisor && supervisorsFetcher.data?.length > 0)
            setOptions(supervisorsFetcher.data);
    }, [coordinatorsFetcher.data, supervisorsFetcher.data]);

    const handleCheckbox = (type: string) => {
        if (type === 'coordinator')
            handleCoordinator();
        if (type === 'supervisor')
            handleSupervisor();
        if (type === 'admin')
            setIsAdmin(!isAdmin)
        if (type === 'Active') {
            setUserForm({
                ...userForm,
                isActive: { value: !userForm.isActive.value }
            })
            console.log(userForm)
        }
    }

    const handleCoordinator = () => {
        setOptions([])
        setSelectedOption('')
        setIsCoordinator(!isCoordinator)

        if (!isCoordinator) {
            coordinatorsFetcher.get('filter/coordinators').then(res => {
                //setOptions(res.data)
            })
        }
    }

    const handleSupervisor = () => {
        setOptions([])
        setSelectedOption('')
        setIsSupervisor(!isSupervisor)

        if (!isSupervisor) {
            supervisorsFetcher.get('filter/supervisors').then(res => {
                //setOptions(res.data)
            })
        }
    }

    const handleOptionChange = (option: any) => {
        setSelectedOption(option)
        let fulloption = options?.find(o => o.name == selectedOption);

        if (fulloption.email && fulloption.email !== userForm.email.value)
            setUserForm({
                ...userForm,
                email: { value: fulloption.email }
            })
    }

    const handleAction = (type: string, params: any) => {
        setAction(type)
        setActionRow(params.row)
        if (type === "Create" || type === "Edit")
            setOpenModal(true)
        else
            setOpenConfirm(true)
    }

    const deleteUser = (row: any) => {
        fetcher.post(`${DELETE_USER_PATH}/${row.id}`, null).then(res => {
            showSuccess("User was successfully deleted.")
            loadUsers()
        }).catch(error => {
            showError(error.response.data)
        })
        setOpenConfirm(false)
        setActionRow('')
    }

    const resetPassword = (row: any) => {
        fetcher.get(`${SEND_RESET_PASSWORD_LINK}${row.id}`).then(res => {
            showSuccess("Reset password link was successfully sent.")
        })
        setOpenConfirm(false)
        setActionRow('')
    }

    const cancelAction = () => {
        setOpenConfirm(false)
        setAction('')
        setActionRow('')
    }

    const editUser = (row: any) => {
        setAction("Edit")
        setErrorMessage('')
        setUserForm({
            id: { value: row.id },
            email: { value: row.email },
            firstName: { value: row.firstName },
            middleName: { value: row.middleName },
            lastName: { value: row.lastName },
            password: { value: row.password },
            isActive: { value: row.isActive },
            role: { value: row.role },
            roleId: { value: '' }
        })
        if (row.role.toLowerCase().includes('coordinator'))
            handleCoordinator()
        if (row.role.toLowerCase().includes('supervisor'))
            handleSupervisor()
        if (row.role.toLowerCase().includes('admin'))
            setIsAdmin(true)

        const option = options?.find(o => o.id == row.roleId);
        setSelectedOption(option?.name)
        setOpenModal(true)
    }

    const handleChangeField = (e: any) => {
        setUserForm({
            ...userForm,
            [e.target.name]: {
                value: e.target.value,
            }
        })
    }

    const handleSave = () => {
        if (action === 'delete')
            deleteUser(actionRow)
        if (action === 'reset')
            resetPassword(actionRow)
    }

    const actions =
        <FlexAlignCenter>
            {user?.roles?.some((r: string) => r === 'Admin') &&
                <MuiButton style={{ margin: '5px' }} onClick={() => handleAction("Create", [])} endIcon={<AddOutlinedIcon />}>Add User</MuiButton>
            }
        </FlexAlignCenter>

    const usersCols = () => {
        return [
            {
                field: 'avatar', flex: 0.01, renderCell: (params: any) => (
                    <Avatar sx={{ width: '24px', height: '24px', backgroundColor: '#7846ff' }} />
                )
            },
            { field: 'id', headerName: 'User ID', flex: 0.025 },
            { field: 'name', headerName: 'Name' },
            { field: 'email', headerName: 'Email' },
            { field: 'phone', headerName: 'Phone' },
            // {field: 'password', headerName: 'Password', renderCell: (params: any) => (
            //         <>*********</>
            // )},
            {
                field: 'isActive', headerName: 'Status', renderCell: (params: any) => {
                    if (params.row.isActive)
                        return (<><Badge sx={{ marginRight: 2 }} color='primary' variant="dot" />Active</>)
                    if (!params.row.isActive)
                        return (<><Badge sx={{ marginRight: 2 }} color='error' variant="dot" />Inactive</>)
                }
            },
            { field: 'role', headerName: 'Role' },
            {
                field: 'actions', headerName: 'Actions', align: 'center', renderCell: (params: any) => {
                    if (!user?.roles.some((r: string) => r === 'Admin')) {
                        if (params.row.id !== user?.userId)
                            return <></>;
                    }

                    return (
                        <FlexEvenly>
                            <Tooltip title='Delete User'>
                                <IconButton onClick={() => handleAction('delete', params)}>
                                    <Delete fontSize="small" color="primary" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Send Reset Passwowd Email'>
                                <IconButton onClick={() => handleAction('reset', params)}>
                                    <Key fontSize="small" color="primary" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Edit User'>
                                <IconButton onClick={() => editUser(params.row)}>
                                    <ModeEdit fontSize="small" color="primary" />
                                </IconButton>
                            </Tooltip>
                        </FlexEvenly >
                    )
                }
            }
        ]
    }

    const roleOptions: string[] = [
        'Admin', 'Coordinator', 'SuperAdmin', 'Supervisor', 'Caregiver'
    ];
    const StyledSelect = styled(Select)`
        && {
        width: 150px; /* Increase width */
        border: 2px red; /* Remove border */
        border-radius: 0;
        max-height: 100%;
        padding: 0;
        margin-top: 0px;
        outline: none !important;
        height: 20px;
    }`;

    // useEffect(() => {
    //     loadUsers();
    // }, [rolesFilter])
    function handleRolesFilterChange(event: SelectChangeEvent<unknown>): void {
        const val = event.target.value;
        set_rolesFilter(val as string[]);
        setOpen(true)
    }

    function handleRolesFilterClose(): void {
        setOpen(false);
        loadUsers()

    }
    function handleOpen(): void {
        setOpen(true)
    }
    const RolesFilterSelect = () => {
        return (
            <div>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    Roles
                    <StyledSelect
                        label={'Roles'}
                        disableUnderline={true}
                        multiple
                        value={rolesFilter}
                        onChange={handleRolesFilterChange}
                        onClose={handleRolesFilterClose}
                        open={open}
                        onOpen={handleOpen}
                    >
                        {roleOptions?.map((d: string) =>
                            <MenuItem key={d.toLowerCase()} value={d.toLowerCase()}>{d}</MenuItem>
                        )}
                    </StyledSelect>
                </FormControl>
            </div>
        )
    }


    return (
        <Box padding='50px 10px 10px 10px'>
            <Loader isLoading={isLoading} />
            <RolesFilterSelect />
            <DataGrid
                title={'Users'}
                data={{
                    columns: usersCols(),
                    rows: rows
                }}
                isLoading={isLoading}
                disableColumnResize
                disableColumnReorder
                options={{
                    disableCloumnSorting: true,
                    disableColumnReorder: true,
                    hideToolbarIconText: true,
                    // toolbarComponent: actions,
                    hideExport: true
                }}
            />
            <AddFab title={'Create User'} clickAction={() => handleAction("Create", [])} />
            <NewModal
                open={openModal}
                title={`${action} User`}
                height="440px"
                onSave={() => createUser()}
                onClose={() => handleCloseModal()}
            >
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <TextField
                                label="First Name"
                                name="firstName"
                                variant="outlined"
                                value={userForm?.firstName.value}
                                onChange={handleChangeField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                autoComplete="disable"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Middle Name"
                                name="middleName"
                                variant="outlined"
                                value={userForm?.middleName.value}
                                onChange={handleChangeField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                autoComplete="disable"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label="Last Name"
                                name="lastName"
                                variant="outlined"
                                value={userForm?.lastName.value}
                                onChange={handleChangeField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                autoComplete="disable"
                            />
                        </Grid>
                    </Grid>
                    <EmailField value={userForm.email.value} onChange={handleChangeField} errorText={errorText} />
                    <div>
                        <Text>Status</Text>
                        <Flex>
                            <FormControlLabel sx={{ marginTop: 1, marginBottom: 1 }} label='Active' control={<Switch checked={userForm.isActive.value} value={userForm.isActive.value} onClick={(e) => handleCheckbox('Active')} />} />
                        </Flex>
                    </div>
                    <div>
                        <Text>Role</Text>
                        <Flex>
                            <FormControlLabel disabled={isSupervisor} control={<Checkbox checked={isCoordinator} onClick={() => handleCheckbox('coordinator')} />} label='Coordinator' />
                            <FormControlLabel disabled={isCoordinator} control={<Checkbox checked={isSupervisor} onClick={() => handleCheckbox('supervisor')} />} label='Supervisor' />
                            <FormControlLabel control={<Checkbox checked={isAdmin} onClick={() => handleCheckbox('admin')} />} label='Admin' />
                        </Flex>
                        {
                            (isCoordinator || isSupervisor) &&
                            (
                                (!supervisorsFetcher.isLoading && !coordinatorsFetcher.isLoading) ? <Autocomplete
                                    size='small'
                                    options={!!options?.length ? options?.map((option: any) => option?.name) : []}
                                    value={selectedOption}
                                    renderInput={(params) => <TextField {...params} value={selectedOption} />}
                                    onChange={(e, o) => handleOptionChange(o)}
                                /> :
                                    <ReactLoader height='24' width='24' isLoading={true} />
                            )
                        }
                    </div>
                    {
                        errorMessage &&
                        <BoldTitle fontSize='14px' color='red' textAlign='center'>{errorMessage} </BoldTitle>
                    }
                </Box>
            </NewModal >

            <NewModal
                height="160px"
                btnTitle="Confirm"
                onClose={() => setOpenConfirm(false)}
                open={openConfirm}
                title={`${strings.toTitleCase(action)} Confirmation`}
                onSave={() => handleSave()}
            >
                <BoldTitle textAlign='start' fontSize='18px'>Are you sure you want to {action === 'delete' ? 'delete this user?' : 'send a reset password link?'}</BoldTitle>
            </NewModal>
        </Box >
    )
}
const EmailField = (props: any) => {
    return (
        <div>
            <Text>Email</Text>
            <TextField
                size='small'
                name='email'
                fullWidth
                margin='normal'
                value={props.value}
                onChange={props.onChange}
                helperText={props.errorText ? 'Value must be a valid email address.' : ''}
                error={props.errorText}
                placeholder="name@company.com"
            />
        </div>
    )
}
export default UsersList