class AddressT {
    public Name: string | undefined = "";
    public Description: string | undefined = "";
    public Address1: string | undefined = "";
    public Address2: string | undefined = "";
    public City: string | undefined = "";
    public State: string | undefined = "";
    public Zip: string | undefined = "";
    public Country: string | undefined = "";
    public toString(): string {
        return `${this.Address1} ${this.Address2} ${this.City} ${this.State} ${this.Zip}`;
    }
}

class Entity {
    public Id: number | undefined = 0;
    public GuidId: string | undefined;
    public CreatedOn: Date | undefined;
    public IsDeleted: boolean | undefined;
    public CurrentInstanceType: typeof Entity | undefined;

    constructor() {
        this.CurrentInstanceType = Object.getPrototypeOf(this).constructor;
    }
}

class Person extends Entity {
    //public FirstName: string | undefined = "";
    //public LastName: string | undefined = "";
    public phone: string | undefined = "";
    public name: string | undefined = "";
    public email: string | undefined = "";
    public address1: string | undefined = "";
    public address2: string | undefined = "";
    public city: string | undefined = "";
    public state: string | undefined = "";
    public zip: string | undefined = "";
    public getFullAddress(): string {
        return `${this.address1} ${this.address2} ${this.city} ${this.state} ${this.zip}`;
    };
    get FullAddress() {
        return this.getFullAddress();
    }
}

class Patient extends Person {
    // additional properties and methods specific to Patient go here
}

class ScheduleEntryDto {
    public Id: number | undefined = 0;
    public RefId: number | undefined = 0;
    public StartTime: string | undefined = "12:00am";
    public EndTime: string | undefined = "12:00am";
}

export class ResultDto {
    public id: number | undefined = 0;
    public patientName: string | undefined = "";
    public providerName: string | undefined = "";
    public patientAddress: string | undefined = "";
    public scheduleStart: string | undefined = "12:00am";
    public scheduleEnd: string | undefined = "12:00am";
    public availableStart: string | undefined = "12:00am";
    public availableEnd: string | undefined = "12:00am";
    public availableTotalMins: number | undefined = 0;
    public travelDuration: string | undefined = "0 Minutes";
}

export { AddressT, Entity, Person, Patient, ScheduleEntryDto };