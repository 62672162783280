import React, { useState, useEffect } from "react";

export class KeyEvent {
    key: string = "";
    ctrlKey: boolean = false;
    altKey: boolean = false;
    shiftKey: boolean = false;
    action: Function = () => { console.log("no action provided for key-combination listener") };
}
export interface IKeyTrackerProps {
    keyEvents: KeyEvent[];
}

const useKeyTracker = (params: IKeyTrackerProps) => {
    const { keyEvents } = params;

    const [keyExecutions, setKeyExecutions] = useState<KeyEvent[]>([]);

    const addKeyExecution = (keyEvent: KeyEvent) => {
        if (!keyEvent)
            return;
        setKeyExecutions([...keyExecutions, keyEvent]);
    }

    useEffect(() => {
        const listener = (zEvent: KeyEvent | any) => {
            for (var i = 0; i < keyEvents.length; i++) {
                var keyCombination = keyEvents[i];
                if (
                    !!keyCombination.altKey == !!zEvent.altKey &&
                    !!keyCombination.ctrlKey == !!zEvent.ctrlKey &&
                    !!keyCombination.shiftKey == !!zEvent.shiftKey &&
                    keyCombination.key == zEvent.key.toLowerCase()
                ) {
                    addKeyExecution(keyCombination);
                    if (!!keyCombination.action)
                        keyCombination.action();
                    return; // don't exectue another action if one is already true
                }
            }
        }
        document.addEventListener("keyup", listener);

        return () => {
            document.removeEventListener("keyup", listener);
        }
    }, []);

    const hookHelper = {
        keyExecutions
    }

    return hookHelper;
};

export default useKeyTracker;
