import React from 'react'
import Freshdesk from './FreshdeskWidget'


// const widgetId = 150000000317;

const widgetId = 150000004385;
const FreshdeskWebWidget = (user) => {
    return <Freshdesk
        widgetId={widgetId}
        user={user}
    />
}

export default FreshdeskWebWidget;