import { useCallback, useContext, useEffect, useRef, useState } from "react";
import Map, { GeolocateControl, Layer, LayerProps, LngLat, MapRef, Source, } from "react-map-gl";
import './Map.css'
import 'mapbox-gl/dist/mapbox-gl.css';
import PopupInfo from "./PopupInfo";
import useFetcher from "../../hooks/useFetcher";
import ReactLoader from "../../components/common/ReactLoader";
import { Box, Fab, IconButton, List, ListItem, ListItemText, Paper, TextField, Tooltip, Typography } from "@mui/material";
import useToast from "../../hooks/useToast";
import ResultStats from "./ResultStats";
import { FiltersContext } from "../../utils/context/FiltersContext";
import Loader from "../../components/ui/Loader";
import { LocationsContext } from "../../utils/context/LocationsContext";
import { Close, DirectionsCarOutlined, ListOutlined, Replay } from "@mui/icons-material";
import axios from "axios";
import { Bold } from "../../assets/styles/styledComponents";
import { SearchBox } from "@mapbox/search-js-react";
import MapControlerPanel from "./MapControlPanel";
//import * as MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import DirectionsBar from "./DirectionsBar";
import { MAPBOX_TOKEN } from "../../data/constants";
import MapPage from "./MapPage";

type props = {
    setView?: any
}

const MapView = ({ setView }: props) => {

    return (
        <MapPage setView={setView} />
    )


    const fetcher = useFetcher();
    const { showError } = useToast();
    const officesFetcher = useFetcher("filter/offices");
    const [data, setData] = useState<any>()
    const [popupInfo, setPopupInfo] = useState(null);
    const { locations, setLocations } = useContext(LocationsContext)!;
    const [offices, setOffices] = useState([]);
    const { filters, setFilters } = useContext(FiltersContext)!;
    const mapRef = useRef<MapRef | null>(null);
    const directionsControlRef = useRef(null);
    const zoom = mapRef.current?.getZoom();
    const [zoomPosition, setZoomPosition] = useState<any>(zoom);
    const [LocationA, setLocationA] = useState<string[]>(['', ''])
    const [LocationB, setLocationB] = useState<string[]>(['', ''])
    const [LocationAddressA, setLocationAddressA] = useState<any>(null)
    const [LocationAddressB, setLocationAddressB] = useState<any>(null)
    const [mapDirections, setMapDirections] = useState<any>(null)
    const [changed, setChanged] = useState(false)
    const [viewState, setViewState] = useState({
        latitude: 40.690158,
        longitude: -73.982498,
        zoom: 12,
        bearing: 0,
        pitch: 0,
        padding: { top: 0, bottom: 0, right: 0, left: 0 },
        width: 100,
        height: 100,
    })

    useEffect(() => {
        setOffices(officesFetcher.data || []);
    }, [officesFetcher.data]);

    const getLocations = () => {
        const payload = {
            showCaregivers: filters.showCaregivers,
            showPatients: filters.showPatients,
            CStatus: filters.CStatus.toString(),
            PStatus: filters.PStatus.toString(),
            hasSchedule: filters.hasSched == 'yes' ? true : (filters.hasSched == 'no' ? false : null),
            seekingCare: filters.needsCare == 'yes' ? true : filters.needsCare == 'no' ? false : null,
            discipline: filters.discipline.toString(),
            office: filters.offices.toString(),
            coordinators: filters.coordinators.toString(),
            timing: filters.timing,
            gender: filters.gender,
            workedAfter: filters.workedAfter == 'all' ? null : filters.workedAfter,
            hiredAfter: filters.hiredAfter == 'all' ? null : filters.hiredAfter,
            addressFor: filters.addressFor,
            address: {
                addressLine1: filters.address.addressLine1,
                city: filters.address.city,
                state: filters.address.state,
                country: filters.address.country,
                zipCode: filters.address.zipCode,
                latitude: filters.address.latitude,
                longitude: filters.address.longitude,
            },
        }

        fetcher.post(`Map/locations`, payload).then(res => {
            setLocations(res.data.data || [])
        }).catch(error => {
            showError(error.data.message)
        })
    }

    useEffect(() => {
        if (locations.length < 1) {
            getLocations()
        }
    }, [])

    const handleSubmit = () => {
        setChanged(false)
        getLocations()
    }

    useEffect(() => {
        let data = {
            type: "FeatureCollection",
            features: locations.map((location: any) => ({
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [location?.address?.longitude, location?.address?.latitude],
                },
                properties: {
                    title: location.id,
                    type: location.type
                },
            })),
        }
        setData(data)
    }, [locations])

    const handleClick = (e: any) => {
        let location = locations.find((l: any) =>
            l.id == e?.features?.at(0)?.properties?.title &&
            l.type == e?.features?.at(0)?.properties?.type
        )
        if (location == null)
            return;

        setPopupInfo(location)
    }

    const handleLocationClick = (e: any) => {
        if (e.originalEvent.ctrlKey)
            handleDirectionsClick(e)
        else
            handleClick(e)
    }

    const handleDirectionsClick = (e: any) => {
        const { lng, lat } = e.lngLat;
        let location = locations.find((l: any) =>
            l.id == e?.features?.at(0)?.properties?.title &&
            l.type == e?.features?.at(0)?.properties?.type
        )

        if (LocationA[0] == '' && LocationB[0] == '') {
            setLocationA([lng, lat])

            if (location != null)
                setLocationAddressA(location.address)
            else
                setLocationAddressA(null)
        }
        else if (LocationA[0] != '' && LocationB[0] != '') {
            setLocationA([lng, lat])
            if (location != null)
                setLocationAddressA(location.address)
            else
                setLocationAddressA(null)

            setLocationB(['', ''])
            setLocationAddressB(null)
        }
        else if (LocationA[0] != '' && LocationB[0] == '') {
            setLocationB([lng, lat])

            if (location != null)
                setLocationAddressB(location.address)
            else
                setLocationAddressB(null)
        }
    }

    // showing 'street route line' when origin & destination variables have values
    useEffect(() => {
        if (LocationA[0] != '' && LocationB[0] != '') {
            // const directions = new MapboxDirections({
            //     accessToken: MAPBOX_TOKEN,
            //     unit: 'metric',
            //     profile: 'mapbox/driving',
            // });

            // directions.setOrigin([LocationA[0], LocationA[1]]);
            // directions.setDestination([LocationB[0], LocationB[1]]);

            // mapRef.current?.addControl(directions, 'top-left');
        }
    }, [LocationA, LocationB])

    useEffect(() => {
        setZoomPosition(zoom)
    }, [zoom])

    const symbolLayer: LayerProps = {
        id: 'anything',
        type: 'symbol',
        source: 'data',
        layout: {
            "icon-image": 'caregiverIcon',
            "icon-size": [
                'match',
                ['get', 'type'],
                'custom', 1.5,
                1
            ],
            "icon-allow-overlap": (zoomPosition >= 11 ? true : false),
        },
        paint: {
            "icon-color": [
                'match',
                ['get', 'type'],
                'Caregiver', '#c4040e',
                'Patient', '#3a02f2',
                'Nurse', '#068f0d',
                'custom', "#FF2400",
                '#000'
            ],
        }
    }

    const mapRefCallback = useCallback((ref: MapRef | null) => {
        if (ref !== null) {
            mapRef.current = ref;
            const map = ref;

            const loadImage = () => {
                if (!map.hasImage("caregiverIcon")) {
                    let img = new Image(24, 24);
                    img.crossOrigin = "Anonymous";
                    img.onload = () => {
                        map.addImage("caregiverIcon", img, { sdf: true });
                    };
                    img.src = 'caregiver.svg';
                }
            };

            loadImage();

            //TODO need this?
            map.on("styleimagemissing", (e) => {
                const id = e.id;
                loadImage();
            });
        }
    }, []);

    const closeDirections = () => {
        setMapDirections(null)
        setLocationA(['', ''])
        setLocationB(['', ''])
        setLocationAddressA(null)
        setLocationAddressB(null)
    }

    const handleRetrieve = (res: any) => {
        const coordinates = res.features[0].geometry.coordinates
        setViewState((prevViewport) => ({
            ...prevViewport,
            longitude: coordinates[0],
            latitude: coordinates[1],
            zoom: 15,
            padding: { top: 20, bottom: 20, right: 20, left: 20 }
        }))

        handleSearchLocation(coordinates)
    }

    const handleSearchLocation = (coordinates: any) => {
        const newFeature = {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [coordinates[0], coordinates[1]],
            },
            properties: {
                title: -1,
                type: 'custom'
            },
        };

        const filteredFeatures = data.features
            .filter((feature: any) => feature.properties.title !== -1 || feature.properties.type !== 'custom');

        const updatedFeatures = filteredFeatures.concat(newFeature);
        // const updatedFeatures = [...data.features, newFeature];

        const updatedData = {
            type: "FeatureCollection",
            features: updatedFeatures,
        };

        setData(updatedData);
    }

    const handleDrag = (event: any) => {
        const info = event.viewState;
        setViewState((prevViewport) => ({
            ...prevViewport,
            longitude: info.longitude,
            latitude: info.latitude,
            zoom: info.zoom,
            pitch: info.pitch,
            padding: { top: 20, bottom: 20, right: 20, left: 20 }
        }))
    }

    // hides the directions info being show on the left of the map along with directions control, when getting 'street route line' displayed
    //useEffect(() => {
    //    const mapboxTopLeftControl = document.querySelector('.mapboxgl-ctrl-top-left') as HTMLElement;
    //    if (mapboxTopLeftControl) {
    //        mapboxTopLeftControl.style.display = 'none';
    //    }
    //}, [LocationA, LocationB]);

    const isNumber = (value: any): value is number => typeof value === 'number';

    // we tried to have an approach here that would remove the 'street route line' and be able to put back when needed, 
    // currently, once you remove you can't put it again 
    // ideally, we'd remove it when clicking 'x' button on the directions-bar (DirectionsBar is our own component)
    // useEffect(() => {
    //     const directions = new MapboxDirections({
    //         accessToken: MAPBOX_TOKEN,
    //         unit: 'metric',
    //         profile: 'mapbox/driving',
    //         controls: {
    //             inputs: false,
    //             instructions: false,
    //         },
    //         interactive: false,
    //     });
    //     if (LocationA[0] != '' && LocationB[0] != '') {

    //         directions.setOrigin([LocationA[0], LocationA[1]]);
    //         directions.setDestination([LocationB[0], LocationB[1]]);
    //         if (mapRef.current && LocationA.length === 2 && isNumber(LocationA[0]) && isNumber(LocationA[1])) {
    //             mapRef.current.setCenter([LocationA[0], LocationA[1]]);
    //         }
    //         mapRef.current?.addControl(directions, 'top-left');
    //         directionsControlRef.current = directions;
    //     }

    //     return () => {
    //         // Cleanup: Remove the control when the component unmounts
    //         if (directionsControlRef.current && mapRef.current && mapRef.current.getMap().hasControl(directionsControlRef.current)) {
    //             if (LocationA[0] == '' || LocationB[0] == '') {
    //                 mapRef.current.getMap().removeControl(directionsControlRef.current);
    //             }
    //         }
    //     };
    // }, [LocationA, LocationB])

    return (
        <Box padding={'0px 0px 0px 0px'}>
            {/* <ReactLoader isLoading={fetcher.isLoading} /> */}
            <Loader isLoading={fetcher.isLoading} />
            <Map
                ref={mapRefCallback}
                trackResize={true}
                style={{ height: '100vh', width: '100%', justifyContent: 'center' }}
                onDrag={(e) => handleDrag(e)}
                mapStyle="mapbox://styles/mapbox/streets-v12?optimize=true"
                mapboxAccessToken={MAPBOX_TOKEN}
                onClick={(e) => handleLocationClick(e)}
                interactiveLayerIds={[symbolLayer.id!]}
                onZoom={(e) => handleDrag(e)}
                {...viewState}
            >
                <Source
                    id="data"
                    type="geojson"
                    data={data}
                />

                <div style={{ zIndex: 100000, width: '300px', position: 'absolute', top: '12px', left: '10px' }}>
                    <SearchBox placeholder="Enter Address" value="" onRetrieve={(res) => handleRetrieve(res)} accessToken={MAPBOX_TOKEN} />
                </div>
                <div style={{ zIndex: 100000, position: 'absolute', top: '12px', left: '320px' }}>
                    <Paper sx={{ borderRadius: '20px' }} elevation={4}>
                        <Tooltip title='Refresh results'>
                            <IconButton sx={{ background: '#fff' }} onClick={() => handleSubmit()}>
                                <Replay fontSize="small" color={changed ? 'error' : 'primary'} />
                            </IconButton>
                        </Tooltip>
                    </Paper>
                </div>

                <GeolocateControl position="bottom-right" />

                <Layer {...symbolLayer} />

                <ResultStats
                    locations={locations}
                    offices={offices}
                />

                <div style={{ position: 'absolute', top: '10px', right: '60px' }}>
                    <Tooltip title='List View'>
                        <Fab size="small" sx={{ background: '#fff', }} onClick={() => setView()}>
                            <ListOutlined color="primary" />
                        </Fab>
                    </Tooltip>
                </div>

                <MapControlerPanel
                    setFilters={setFilters}
                    filters={filters}
                    submit={handleSubmit}
                    setChanged={setChanged}
                />

                <DirectionsBar
                    LocationA={LocationA}
                    LocationB={LocationB}
                    LocationAddressA={LocationAddressA}
                    LocationAddressB={LocationAddressB}
                    closeDirections={closeDirections}
                />

                {
                    popupInfo && (
                        <PopupInfo
                            popupInfo={popupInfo}
                            setLocations={setLocations}
                            setPopupInfo={setPopupInfo}
                        />
                    )
                }
            </Map >
        </Box >
    );
};

export default MapView;
