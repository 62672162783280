import { Box } from "@mui/material"
import { useState } from "react"
import PersonnelList from "./PersonnelList"
import MapView from "../Map/MapView";

const Browse = () => {
    const [isMapView, setMapView] = useState(true);

    const setViewToMap = () => { setMapView(true) }
    const setViewToList = () => { setMapView(false) }

    return (
        <Box>
            {!isMapView && <PersonnelList setView={setViewToMap} />}
            {isMapView && <MapView setView={setViewToList} />}
        </Box>
    )
}

export default Browse