import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import { Bold, Container, DivContainer, FlexColExactCenter, FlexExactCenter } from '../../../assets/styles/styledComponents';
import { Button } from '../../../components/ui'
import { BASE_URL, LOCAL_URL, LOGIN_PATH, STAGING_URL, TEST_URL, USER_REGISTER_IP_PATH, homePageUrl } from '../../../data/constants';
import useAuth from '../hooks/useAuth';
import ReactLoader from '../../../components/common/ReactLoader';
import useFetcher from '../../../hooks/useFetcher';
import useToast from '../../../hooks/useToast';
import { Box, Paper, TextField, } from '@mui/material';

export default function Login() {
    const navigate = useNavigate();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    const { saveUserLogin } = useAuth()
    const { showError, showSuccess } = useToast()

    const envMode = localStorage.getItem("envMode");
    const login = useFetcher();//useFetcher(undefined, undefined, undefined, undefined, undefined, envMode || undefined);
    const getWebAppUrl = () =>
        envMode === 'debug' ? LOCAL_URL :
            envMode === 'test' ? TEST_URL :
                envMode === 'prod' ? STAGING_URL : STAGING_URL;

    const [loginForm, setLoginForm] = useState({
        email: {
            value: '',
            error: ''
        },
        password: {
            value: '',
            error: ''
        },
    })

    function onSubmit() {
        let email = loginForm.email.value;
        let password = loginForm.password.value;
        const payload = { userName: email, password: password };
        login.post(LOGIN_PATH, payload).then((response: any) => {
            if (!!response) {
                if (!!response.data?.redirectTo) {
                    console.log("redirectTo: " + response.data.redirectTo);
                    return;
                }
                else if (response.status === 200) {
                    saveUserLogin(response.data)
                    showSuccess("Login Successfully")
                    navigate(homePageUrl);
                } else {
                    showError(response?.message ?? "Login Failed, Response missing")
                }
            } else {
                showError(response?.message ?? "Login Failed")
            }
        }).catch((error: any) => {
            let statusClass = error.response?.headers?.get("X-Status-Class") ?? "";
            const url = getWebAppUrl() + `${USER_REGISTER_IP_PATH}?email=${email.toLowerCase()}`;
            if (error.code === "ERR_NETWORK" || statusClass === "IpAddressNotRegistered") {

                console.log("redirecting to url:", url)
                window.location.href = url;
                window.history.pushState({}, '', url);
                return;
            }
            const response = error.response?.data;
            showError(response?.message ?? error.message);
        })
    }

    useEffect(() => {
        const listener = (event: any) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                onSubmit();
            }
        };
        document.addEventListener("keyup", listener);
        return () => {
            document.removeEventListener("keyup", listener);
        };
    }, [loginForm, envMode]);


    const handleChangeField = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        setLoginForm({
            ...loginForm,
            [name]: {
                value: e.target.value,
                error: ''
            }
        })
    }

    return (
        <Box
            display={'flex'}
            justifyContent={'center'}
            sx={{
                height: '100vh',
                width: '100vw',
                overflowY: 'hidden',
                background: '#7846ff',
            }}
        >
            <ReactLoader isLoading={login.isLoading} />
            <Paper
                elevation={8}
                sx={{
                    width: '80%', // Default width for larger screens
                    maxWidth: '500px', // Added max-width
                    background: '#fff',
                    height: '50%',
                    marginTop: '50%',
                    justifyContent: 'center',
                    borderRadius: '20px',
                    padding: '20px',
                    '@media (min-width: 600px)': {
                        width: '30%', // Adjusted width for smaller screens
                        marginTop: '10%',
                    },
                }}
            >
                <div
                    style={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <div style={{ display: 'block', textAlign: 'center' }}>
                        <img
                            src='ConnectAide.png'
                            width={'80%'}
                            height={'auto'}
                            alt='logo'
                        />
                    </div>
                </div>
                <Box display={'flex'} justifyContent={'center'}>
                    <div
                        style={{
                            width: '100%',
                            padding: '20px',
                            position: 'relative',
                            marginTop: '35px',
                        }}
                    >
                        <DivContainer margin="20px 0px">
                            <TextField
                                id="email"
                                label="Email"
                                name="email"
                                onChange={(e: any) => handleChangeField(e, 'email')}
                                value={loginForm.email.value}
                                type="email"
                                size='small'
                                error={!!loginForm.email.error}
                                helperText={loginForm.email.error}
                                fullWidth
                            />
                        </DivContainer>
                        <DivContainer margin="20px 0px">
                            <TextField
                                id="password"
                                label="Password"
                                name="password"
                                size='small'
                                onChange={(e: any) => handleChangeField(e, 'password')}
                                value={loginForm.password.value}
                                type="password"
                                error={!!loginForm.password.error}
                                helperText={loginForm.password.error}
                                fullWidth
                            />
                        </DivContainer>
                        <DivContainer>
                            <Button
                                onClick={onSubmit}
                                type="submit"
                                variant="outlined"
                                color="primary"
                                label="Login"
                                style={{
                                    width: '100%'
                                }}
                                disable={!loginForm.email.value || !loginForm.password.value}
                                fullWidth={true}
                            />
                        </DivContainer>
                        <br />
                        <br />
                        <br />
                        <DivContainer style={{ textAlign: 'justify', fontFamily: 'ConnectAideSecondary', fontSize: '12px' }}>
                            <p>By signing in, creating an account, or using this platform as a guest, you are agreeing to our <a target='_blank' href='/terms-of-service'>Terms of Service</a></p>
                        </DivContainer>
                    </div>
                </Box>
            </Paper>
        </Box>
    )
}

export { Login };