import React from 'react';
import { Box, Typography, Paper, Skeleton } from '@mui/material';
import PieChart from './PieChart'; // Assuming PieChart is a separate component
import { ApexOptions } from "apexcharts";
import DonutDoubleChart from './DonutDoubleChart';

type dataType = {
    label: string,
    value: number,
    color?: string
}

interface DashboardDoubleDonutCardProps {
    title: string;
    secondaryTextTop?: string;
    value: string | number;
    secondaryTextBottom?: string;
    chartData: [dataType[], dataType[]];
    chartOptions: ApexOptions; // Import ApexOptions from 'apexcharts'
    isLoading?: boolean;
}

const DashboardDoubleDonutCard: React.FC<DashboardDoubleDonutCardProps> = ({
    title,
    secondaryTextTop,
    value,
    secondaryTextBottom,
    chartData,
    chartOptions,
    isLoading,
}) => {

    const LoadingSkeleton = () => {
        const six = [1, 2, 3, 4, 5, 6]
        return (
            <div>
                <Box>
                    <Skeleton animation="wave" height={30} width={250} />
                    <Skeleton animation="wave" height={10} width={50} />
                    <Skeleton animation="wave" height={30} width={100} />
                    <Skeleton animation="wave" height={15} width={200} />
                </Box>
                <Box display={'flex'} justifyContent={'center'} height={'240px'}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Skeleton animation="wave" variant="circular" height={175} width={175} sx={{ marginTop: '10%' }} />
                    </div>
                    <Box marginLeft={'10%'}>
                        {six.map(() =>
                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                <Skeleton animation="wave" height={40} width={20} />
                                <Skeleton animation="wave" height={40} width={100} sx={{ marginLeft: '10px' }} />
                            </div>
                        )
                        }
                    </Box>
                </Box>
            </div>
        )
    }

    return (
        <Paper elevation={4} sx={{ borderRadius: '10px', backgroundColor: 'white', padding: '10px' }}>
            {isLoading ? <LoadingSkeleton /> :
                <>
                    <Box>
                        <Typography variant="h4" component="h4" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                            {title}
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: '12px' }}>{secondaryTextTop || "-"}</Typography>
                        <Typography variant="body1" sx={{ fontSize: '17px' }}>{value}</Typography>
                        {secondaryTextBottom && (
                            <Typography variant="body2" sx={{ fontSize: '12px' }}>{secondaryTextBottom}</Typography>
                        )}
                    </Box>
                    <Box>
                        <DonutDoubleChart
                            colors={chartOptions.colors}
                            chartData={chartData}
                        />
                    </Box>
                </>}
        </Paper>
    );
};

export default DashboardDoubleDonutCard;