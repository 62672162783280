import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface ColumnChartProps {
  chartData: any[]; // Specify a more accurate type if possible
  chartOptions: ApexOptions;
}

const ColumnChart: React.FC<ColumnChartProps> = ({ chartData, chartOptions }) => {
  const [data, setData] = useState<any[]>([]); // Use a more specific type if known
  const [options, setOptions] = useState<ApexOptions>({});

  useEffect(() => {
    setData(chartData);
    setOptions(chartOptions);
  }, [chartData, chartOptions]);

  return (
    <Chart
      options={options}
      series={data}
      type="bar"
      height='240px'
    />
  );
}

export default ColumnChart;