import React from 'react';
import { BoxContainer, Header } from '../../../assets/styles/styledComponents';
import useApi_DEPRACATED from '../../../hooks/useApi';
import { BASE_URL } from '../../../data/constants';
import ImportButton from '../../../components/common/Import/ImportCard';
import { HorizontalRule } from '@mui/icons-material';
import ReactLoader from '../../../components/common/ReactLoader';

const Import = () => {
    const [caregiverId, setCaregiverId] = React.useState('');
    const [availabilityId, setAvailabilityId] = React.useState('');
    const [postAvailabilityId, setPostAvailabilityId] = React.useState('');
    const [cPreferenceId, setCPreferenceId] = React.useState('');
    const [patientId, setPatientId] = React.useState('');
    const [permanentScheduleId, setPermanentScheduleId] = React.useState('');
    const [pPreferenceId, setPPreferenceId] = React.useState('');
    const [visitDate, setVisitDate] = React.useState<Date>();
    const [visitId, setVisitId] = React.useState('');
    const [scheduleId, setScheduleId] = React.useState('');
    const [importType, setImportType] = React.useState('');

    const PatientImport = useApi_DEPRACATED("HhaData/patient/", BASE_URL)
    const CaregiverImport = useApi_DEPRACATED("HhaData/caregiver/", BASE_URL)
    const VisitImport = useApi_DEPRACATED("HhaData/visit/", BASE_URL)
    const PatientVisitImport = useApi_DEPRACATED("HhaData/patient/visits/", BASE_URL)

    const handleChange = (set: any, id: string) => {
        set(id)
    }

    const handleImport = (label: string, Id: string, set: any, type: string) => {
        setImportType(type)
        const labelSplit = label.split(" ")
        const newlabel = labelSplit[0].toLowerCase() + (labelSplit[1] ?? "") + (labelSplit[2] ?? "")
        type == "Patient" && PatientImport.create(null, `${((newlabel == "patient" || newlabel == "patientID") ? "" : newlabel + '/') + Id}`).then(set(''))
        type == "Caregiver" && CaregiverImport.create(null, `${(newlabel == "caregiver" ? "" : newlabel + '/') + Id}`).then(set(''))
        type == "Visit" && VisitImport.create(null, `${(newlabel.includes("Date") ? newlabel + '/' + Id : Id)}`).then(set(''))
        type == "PatientVisit" && PatientVisitImport.create(null, `${patientId}/${visitDate}/${visitDate}`).then(set(''))
        type == "Post Caregiver" && CaregiverImport.create(null, `${'post/' + (newlabel == "caregiver" ? "" : newlabel + '/') + Id}`).then(set(''))
    }

    return (
        <div>
            <ReactLoader isLoading={CaregiverImport.isLoading || PatientImport.isLoading || VisitImport.isLoading} />
            <BoxContainer
                padding="0px 10px"
                float="left"
                margin="5px"
                width="500px"
                borderPx="none"
            >
                <Header>Caregiver</Header>
                <div>
                    <ImportButton
                        label="All Caregivers"
                        type="Caregiver"
                        importId={''}
                        handleChange={handleChange}
                        handleImport={handleImport}
                    />
                    <ImportButton
                        label="Caregiver"
                        type="Caregiver"
                        importId={caregiverId}
                        set={setCaregiverId}
                        handleChange={handleChange}
                        handleImport={handleImport}
                    />
                    <ImportButton
                        label="Availability"
                        type="Caregiver"
                        importId={availabilityId}
                        set={setAvailabilityId}
                        handleChange={handleChange}
                        handleImport={handleImport}
                    />
                    <ImportButton
                        label="Preference"
                        type="Caregiver"
                        handleChange={handleChange}
                        importId={cPreferenceId}
                        set={setCPreferenceId}
                        handleImport={handleImport}
                    />
                    <Header>Post Caregiver Info</Header>
                    <ImportButton
                        label="All Availabilities"
                        type="Post Caregiver"
                        importId={''}
                        handleChange={handleChange}
                        handleImport={handleImport}
                    />
                    <ImportButton
                        label="Availability"
                        type="Post Caregiver"
                        importId={postAvailabilityId}
                        set={setPostAvailabilityId}
                        handleChange={handleChange}
                        handleImport={handleImport}
                    />
                </div>
            </BoxContainer>
            <BoxContainer
                padding="0px 10px"
                float="left"
                margin="5px"
                width="500px"
                borderPx="none"
            >
                <Header>Patient</Header>
                <div>
                    <ImportButton
                        label="All Patients"
                        type="Patient"
                        importId={''}
                        handleChange={handleChange}
                        handleImport={handleImport}
                    />
                    <ImportButton
                        label="Patient ID"
                        type="Patient"
                        handleChange={handleChange}
                        importId={patientId}
                        set={setPatientId}
                        handleImport={handleImport}
                    />
                    <ImportButton
                        label="Permanent Schedule"
                        type="Patient"
                        handleChange={handleChange}
                        importId={permanentScheduleId}
                        set={setPermanentScheduleId}
                        handleImport={handleImport}
                    />
                    <ImportButton
                        label="Preference"
                        type="Patient"
                        handleChange={handleChange}
                        importId={pPreferenceId}
                        set={setPPreferenceId}
                        handleImport={handleImport}
                    />
                </div>
            </BoxContainer>
            <BoxContainer
                padding="0px 10px"
                float="left"
                margin="5px"
                width="500px"
                borderPx="none"
            >
                <Header>Visit</Header>
                <div>
                    <ImportButton
                        label="Visit By Date"
                        type="Visit"
                        importId={visitDate}
                        set={setVisitDate}
                        handleChange={handleChange}
                        handleImport={handleImport}
                    />
                    <ImportButton
                        label="Visit Schedule"
                        type="Visit"
                        handleChange={handleChange}
                        importId={scheduleId}
                        set={setScheduleId}
                        handleImport={handleImport}
                    />
                    <ImportButton
                        label="Visit"
                        type="Visit"
                        handleChange={handleChange}
                        importId={visitId}
                        set={setVisitId}
                        handleImport={handleImport}
                    />

                </div>
            </BoxContainer>
            <BoxContainer
                padding="0px 10px"
                float="left"
                margin="5px"
                width="500px"
                borderPx="none"
            >
                <Header>Patient Visits</Header>
                <div>
                    <ImportButton
                        label="Patient ID"
                        type="PatientVisit"
                        handleChange={handleChange}
                        importId={patientId}
                        set={setPatientId}
                        handleImport={handleImport}
                    />
                    <ImportButton
                        label="PatientVisitDate"
                        type="PatientVisit"
                        importId={visitDate}
                        set={setVisitDate}
                        handleChange={handleChange}
                        handleImport={handleImport}
                    />
                </div>
            </BoxContainer>
        </div>
    )


}

export default Import