import React from 'react';
import {Dialog, DialogContent} from '../../../lib/MUI/mui.components';
import ReactLoader from '../../common/ReactLoader';
import ModalFooter from './components/ModalFooter';
import ModalHeader from './components/ModalHeader';

type ModalContainerProps = {
  open: boolean,
  title: string,
  loading?: boolean,
  fullWidth?: boolean,
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  onSave?: Function,
  onCancel: Function,
  children: any
}
const ModalContainer: React.FC<ModalContainerProps> = ({open,fullWidth, loading=false, onSave, maxWidth, onCancel, title="", children}) => {
  return (
    <Dialog open={open} maxWidth={maxWidth} fullWidth={fullWidth} >
       <ModalHeader title={title} onClear={onCancel}/>
       <ReactLoader isLoading={loading} />
       <DialogContent>
          {children}
       </DialogContent>
       <ModalFooter onSave={typeof onSave === 'function' ? onSave : undefined} onCancel={onCancel}/>
    </Dialog>
  )
}

export default ModalContainer