export const newSettingDefaults: FactorSettingI = {
	settingId: 0,
	profileId: 0,
	severityLevel: 0,
	factorType: '',
	factor: '',
	operator: '',
	value: 75,
	points: 20,
	isRequired: false
};

export interface ProfileI {
	factorProfileId: number;
    name: string | undefined; 
    description: string | undefined;
}

export interface FactorSettingLevelI {
    level: number,
    factorSettings: FactorSettingI[]
}

export interface FactorSettingI {
    profileId: number;
    settingId: number;
    severityLevel: number;
    
    factor: string;
    factorType: string;
    operator: string;
    value: number | string | undefined;
    points: number;
    isRequired: boolean;
}