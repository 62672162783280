import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import useFetcher from "../../hooks/useFetcher"
import { useEffect, useState } from "react";
import { datagridStyle } from "../../data/GridColumnDefinitions";
import { BoldTitle, DivContainer, FlexExactCenter, Text } from "../../assets/styles/styledComponents";
import { Box, Grid, InputLabel, MenuItem, Select, Switch, Tab, Tabs, TextField, Tooltip, styled } from "@mui/material";
import useToast from "../../hooks/useToast";
import FeaturedIcon from "../../components/common/FeaturedIcon";
import Loader from "../../components/ui/Loader";
import AddFab from "../../components/ui/AddFab";
import UpdatePreferenceDialog from "./EditPreferenceDialog";
import ConfirmationDialog from "../../components/ui/Modals/components/ConfirmationDailog";
import { MenuOption } from "../../components/form/FactorSettings/FactorConstants";
import { MatchTagType } from "./types";
import MatchTag from "./MatchTag";
import { sortByStringProperty } from "../../helper/arrays";

const StyledDataGrid = styled(DataGridPro)`
  &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
  &.MuiDataGrid-root .MuiDataGrid-cell:focus,
  &.css-1bizjjt-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
  &.css-1bizjjt-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  &.css-1bizjjt-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
  &.css-1bizjjt-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  &.css-11u53oe-MuiSelect-select .MuiInpueBase-input-MuiOutlinedInput-input.MuiSelect-select {
    outline: none !important;
  }
`;

const ManagePreferencePage = () => {
    const { showSuccess, showError } = useToast();
    const getFetcher = useFetcher();
    const postFetcher = useFetcher();
    const disciplinesFetcher = useFetcher();
    const [preferences, setPreferences] = useState<MatchTagType[]>([])
    const [filteredRows, setFilteredRows] = useState<MatchTagType[]>([])
    const [selectedPreference, setSelectedPreference] = useState<MatchTagType>();
    const [open_EditPreference, setOpen_EditPreference] = useState<boolean>(false);
    const [open_DeletePreference, setOpen_DeletePreference] = useState<boolean>(false);
    const [disciplines, setDisciplines] = useState<string[]>([]);

    // preference party
    const preferenceParty_Options: MenuOption[] = [
        { key: 'patient', value: 'Patient' },
        { key: 'caregiver', value: 'Caregiver' }
    ];
    const [partyModule, setPartyModule] = useState<string>('patient');
    const updateModule = (newValue: string) => {
        setPartyModule(newValue);
    };

    useEffect(() => {
        !preferences.length && getPreferences()
    }, [])
    useEffect(() => {
    }, [preferences])
    useEffect(() => {
        setFilteredRows(preferences.filter(pr => pr.appliedParty == partyModule || !pr.appliedParty));
    }, [partyModule]);

    const getPreferences = () => {
        getFetcher.get('preference').then(res => {
            setPreferences(res.data.data)
        })
    }

    useEffect(() => {
    }, [selectedPreference])

    const updatePreference = (params: any, updateState?: Boolean) => {
        const { id, field, value } = params;

        let allPreferences = [...preferences]; // Create a shallow copy to ensure state immutability

        let prefIndex = allPreferences.findIndex(p => p?.id === id);
        if (prefIndex !== -1) {
            let updatedPref: MatchTagType = { ...allPreferences[prefIndex], [field]: value }; // Create an updated preference object
            allPreferences[prefIndex] = updatedPref; // Update the preference in the copied array

            // Optionally update state if necessary
            if (!!updateState) {
                setPreferences([...allPreferences]);
            }

            return { updatedPref, allPreferences };
        } else {
            console.error("Preference not found.");
            return { updatedPref: null, allPreferences };
        }
    };

    useEffect(() => {
        disciplinesFetcher.get('caregiver/disciplines').then((response: any) => {
            setDisciplines(response.data.data);
        });
    }, []);

    const switchPreference = (id: number, field: string, value: string) => {
        let updatedPref: any;

        const updatedPreference = updatePreference({ id, field, value });
        updatedPref = updatedPreference.updatedPref;

        if (updatedPref !== null) {
            postFetcher.post('preference/update', updatedPref).then(res => {
                showSuccess("Preference was successfully updated.");
                // update state
                updatePreference({ id, field, value }, true);
            }).catch(error => {
                console.error(error);
                showError(error.message);
            });
        }
    };

    function deletePreference(id: number): void {
        postFetcher.delete(`preference/delete/${id}`).then(res => {
            showSuccess("Preference was successfully deleted.");
            setPreferences((currentPreferences) => currentPreferences.filter(pref => pref.id !== id));
        }).catch(error => {
            console.error(error);
            showError(error.message);
        });;
    }
    function setSelectedPreferenceState(id: number) {
        const _selectedPreference = preferences.find(pref => pref.id === id);
        if (_selectedPreference) {
            setSelectedPreference(_selectedPreference);
        }
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: '', flex: 0.01 },
        { field: 'name', headerName: 'Preference', flex: 0.04 },
        // {
        //     field: 'severityPoints',
        //     headerName: 'Severity Points',
        //     //label: 'How severe and important is this tag when matching',
        //     flex: 0.01,
        // },
        {
            field: 'discipline',
            headerName: 'Disciplines',
            //label: 'For what type of caregivers should this preference show up',
            flex: 0.05,
        },
        {
            field: 'clientLabel',
            headerName: 'Client Label',
            flex: 0.04,
        },
        {
            field: 'providerLabel',
            headerName: 'Provider Label',
            flex: 0.04,
        },
        {
            field: 'isRequiredAsk',
            headerName: 'Require',
            flex: 0.02,
            renderCell: (params: any) => {
                return (
                    <Tooltip title="This info is required">
                        <FeaturedIcon
                            color={!!params.row.isRequiredAsk ? "primary" : "#989898a3"}
                            fontSize={"small"}
                            icon={!!params.row.isRequiredAsk ? "Check" : "Clear"}
                        />
                    </Tooltip>
                )
            }
        },
        {
            field: 'isActive',
            headerName: 'Active',
            flex: 0.02,
            renderCell: (params: any) => {
                return (
                    <Tooltip title="">
                        <FeaturedIcon
                            color={!!params.row.isActive ? "primary" : "#989898a3"}
                            fontSize={"small"}
                            icon={!!params.row.isActive ? "Check" : "Clear"}
                        />
                    </Tooltip>
                )
            }
        },
        {
            field: 'edit',
            headerName: '',
            flex: 0.01,
            renderCell: (params: any) => {
                return (
                    <Tooltip title="Edit Preference">
                        <DivContainer>
                            <FeaturedIcon
                                onClick={() => {
                                    setSelectedPreferenceState(params.row.id);
                                    setOpen_EditPreference(!open_EditPreference);
                                }}
                                color={"primary"}
                                icon="Edit"
                            />
                        </DivContainer>
                    </Tooltip>
                )
            }
        },
        {
            field: 'delete',
            headerName: '',
            flex: 0.01,
            renderCell: (params: any) => {
                return (
                    <Tooltip title="Delete Preference">
                        <DivContainer>
                            <FeaturedIcon
                                onClick={() => {
                                    setSelectedPreferenceState(params.row.id);
                                    setOpen_DeletePreference(true);
                                }}
                                color={"primary"}
                                icon="Delete"
                            />
                        </DivContainer>
                    </Tooltip>
                )
            }
        }
    ]

    const Header = (title: string) => {
        return (
            <Box height={'35px'}>
                <FlexExactCenter>
                    <BoldTitle>{title}</BoldTitle>
                </FlexExactCenter>
            </Box>
        )
    }

    function addEditPreference(updatedPreference: any): void {
        setPreferences((currentPreferences) => {
            const index = currentPreferences.findIndex((pref) => pref.id === updatedPreference.id);
            if (index > -1) {
                // Update existing preference
                const updatedPreferences = [...currentPreferences];
                updatedPreferences[index] = { ...updatedPreferences[index], ...updatedPreference };
                return updatedPreferences;
            } else {
                // Add new preference
                return [...currentPreferences, updatedPreference];
            }
        });
    }

    return (
        <Box padding={'10px'}>
            <Loader isLoading={getFetcher.isLoading} />
            <AddFab title={'New Preference'} clickAction={() => {
                setOpen_EditPreference(true);
                setSelectedPreference({ id: 0, name: '', clientLabel: '', providerLabel: '', appliedParty: partyModule, discipline: '', isActive: false, isRequiredAsk: false });
            }} />
            <div style={{ height: '50px' }}>
                {/* <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    value={partyModule}
                    onChange={(event, newValue) => {
                        updateModule(newValue);
                    }}
                >
                    {
                        preferenceParty_Options.map((op: MenuOption) => <Tab label={op.value} value={op.key} sx={{ padding: '0px', margin: '0' }} />)
                    }
                </Tabs> */}
            </div>
            <Grid container spacing={2}>
                {/* <Grid item xs={6}>
                    {preferences?.map((p: MatchTagType) => (
                        <MatchTag
                            key={p.id}
                            id={p.id}
                            name={p.name}
                            clientLabel={p.clientLabel}
                            providerLabel={p.providerLabel}
                            appliedParty={p.appliedParty}
                            discipline={p.discipline}
                            disciplines={disciplines}
                            isRequiredAsk={p.isRequiredAsk}
                            isActive={p.isActive}
                            requisiteLevel={p.requisiteLevel}
                            severityPoints={p.severityPoints}
                            setSelectedPreferenceState={setSelectedPreferenceState}
                            setOpen_EditPreference={setOpen_EditPreference}
                            setOpen_DeletePreference={setOpen_DeletePreference}
                            switchPreference={switchPreference}
                        />
                    ))}
                </Grid> */}
                <Grid item xs={12}>
                    <DataGridPro
                        sx={(theme: any) => (datagridStyle(theme).root)}
                        columns={columns}
                        rows={sortByStringProperty(preferences, 'name', true)}
                        rowHeight={40}
                        headerHeight={35}
                        hideFooter
                        autoHeight
                        components={{ Toolbar: () => Header('Manage Preferences') }}
                        onCellEditCommit={(params) => updatePreference(params)}
                    />
                </Grid>
            </Grid>
            <UpdatePreferenceDialog
                open={open_EditPreference}
                onClose={() => { setOpen_EditPreference(false) }}
                preference={selectedPreference}
                addEditPreference={addEditPreference}
                disciplines={disciplines}
            ></UpdatePreferenceDialog>
            <ConfirmationDialog
                action="Delete"
                item="Preference"
                open={open_DeletePreference}
                onClose={(confirmed: boolean) => {
                    if (!!confirmed && !!selectedPreference?.id) {
                        deletePreference(selectedPreference?.id);
                    }
                    setOpen_DeletePreference(false);
                }}
            />
        </Box>
    )
}

export default ManagePreferencePage
