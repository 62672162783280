// Profile
export const urlProfiles: string = "api/FactorProfile/profiles";
export const urlProfileAdd: string = `api/FactorProfile/add`;
export const urlProfileUpdate: string = `api/FactorProfile/update`;
export const urlDefaultProfile: string = `api/FactorProfile/default`;
export const urlSetDefaultProfile: string = `FactorProfile/setDefault`;

// Setting
export const urlSettingAll: string = "api/FactorSetting/all";
export const urlSettingAdd: string = "api/FactorSetting/add";
export const urlSettingUpdate: string = `api/FactorSetting/update`;
export const urlSettingDelete: string = "api/FactorSetting/delete";