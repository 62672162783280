import React from 'react'
import { IconButton } from '@mui/material';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ArticleIcon from '@mui/icons-material/Article';
import CheckIcon from '@mui/icons-material/Check';
import AdjustIcon from '@mui/icons-material/Adjust';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import WorkHistory from '@mui/icons-material/WorkHistory';
import PendingActions from '@mui/icons-material/PendingActions';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PaymentIcon from '@mui/icons-material/Payment';
import PauseIcon from '@mui/icons-material/Pause';
import BusinessIcon from '@mui/icons-material/Business';
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';
import CancelScheduleSendOutlinedIcon from '@mui/icons-material/CancelScheduleSendOutlined';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import GroupIcon from '@mui/icons-material/Group';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MenuIcon from '@mui/icons-material/Menu';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Groups2Icon from '@mui/icons-material/Groups2';
import SendIcon from '@mui/icons-material/Send';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ScheduleIcon from '@mui/icons-material/Schedule';

const Icon = (props: any) => {
    switch (props.icon) {
        case "Check":
            return <CheckCircleOutlineOutlinedIcon {...props} />;
        case "Save":
            return <SaveIcon {...props} />;
        case "Cross":
            return <CancelOutlinedIcon {...props} />;
        case "VpnKey":
            return <VpnKeyIcon {...props} />;
        case "Edit":
            return <EditIcon {...props} />;
        case "Delete":
            return <DeleteIcon {...props} />;
        case "Block":
            return <BlockIcon {...props} />;
        case "Article":
            return <ArticleIcon {...props} />;
        case "MoreVert":
            return <MoreVertIcon {...props} />
        case "Search":
            return <SearchIcon {...props} />
        case "Menu":
            return <MenuIcon {...props} />
        case "Info":
            return <InfoIcon {...props} />
        case "CheckOutlined":
            return <CheckOutlinedIcon  {...props} />
        case "Warning":
            return <WarningIcon  {...props} />
        case "Clear":
            return <ClearIcon  {...props} />
        case "InfoOutlined":
            return <InfoOutlinedIcon style={{ opacity: 0.54 }} {...props} />
        case "Drag":
            return <DragIndicatorIcon {...props} />
        case "ListAlt":
            return <ListAltIcon {...props} />
        case "AccessTime":
            return <AccessTimeFilledIcon {...props} />
        case "WorkHistory":
            return <WorkHistory {...props} />
        case "PendingActions":
            return <PendingActions {...props} />
        case "Account":
            return <AccountCircleIcon {...props} />
        case "Money":
            return <AttachMoneyIcon {...props} />
        case "Payment":
            return <PaymentIcon {...props} />
        case "Pause":
            return <PauseIcon {...props} />
        case "Bussiness":
            return <BusinessIcon {...props} />
        case "PlayCircle":
            return <PlayCircleFilledOutlinedIcon {...props} />
        case "CancelSchedule":
            return <CancelScheduleSendOutlinedIcon {...props} />
        case "ProgressChart":
            return <ShowChartOutlinedIcon {...props} />
        case "Group":
            return <GroupIcon {...props} />
        case "Employee":
            return <ManageAccountsIcon {...props} />
        case "Portal":
            return <WysiwygIcon {...props} />
        case "Next":
            return <NavigateNextIcon {...props} />
        case "Before":
            return <NavigateBeforeIcon {...props} />
        case "ArrowForward":
            return <ArrowForwardIcon {...props} />
        case "ArrowBack":
            return <ArrowBackIcon {...props} />
        case "ExpandMore":
            return <ExpandMoreIcon {...props} />
        case "ExpandLess":
            return <ExpandLessIcon {...props} />
        case "Comfort":
            return <span style={{ fontSize: '12px', padding: '0px 5px' }}>CH</span>
        case "People":
            return <Groups2Icon {...props} />
        case "Open":
            return <OpenInFullIcon {...props} />
        case "Checklist":
            return <ChecklistIcon {...props} />
        case "Send":
            return <SendIcon {...props} />
        case "Schedule":
            return <ScheduleIcon {...props} />
        default:
            return <AdjustIcon {...props} />;
    }
}
const FeaturedIcon = (props: any) => {
    const { icon, params, field, onClick, disableClick, fontSize, color, style, showCheck } = props;
  
    const disabledClickStyles = {
      ml: 1,
      "&.MuiButtonBase-root:hover": {
        bgcolor: "transparent"
      },
    };
  
    return (
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <IconButton
          className="icon"
          size={fontSize}
          disableFocusRipple={true}
          style={style || { padding: "5px" }}
          disableRipple={disableClick}
          sx={disableClick && disabledClickStyles}
          color={color || 'default'}
          onClick={!!props.onClick && props.onClick}
        >
          <Icon {...props} />
        </IconButton>
        {/* {showCheck && (
          <CheckIcon
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              fontSize: '1rem',
              color: 'green'
            }}
          />
        )} */}
      </div>
    );
  };
  
  export default FeaturedIcon;