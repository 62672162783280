import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface PieChartProps {
    chartData: number[];
    chartOptions: ApexOptions;
}

const DonutChart: React.FC<PieChartProps> = ({ chartData, chartOptions }) => {
    const [data, setData] = useState<number[]>([]);
    const [options, setOptions] = useState<ApexOptions>({});

    useEffect(() => {
        console.log('chartData', chartData);
        setData(chartData);
        setOptions(chartOptions);
    }, [chartData, chartOptions]);

    return (
        <div>
            <ReactApexChart
                options={options}
                series={data || {}}
                type='donut'
            />
        </div>
    );
}

export default DonutChart;