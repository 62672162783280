import React, { useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

const ZipCodeField: React.FC<TextFieldProps> = (props) => {
  const [zipCode, setZipCode] = useState('');
  const [error, setError] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length <= 5 && /^\d*$/.test(value)) {
      setZipCode(value);
      setError(value.length === 5 ? false : true);
    }
  };

  return (
    <TextField
      label="Zip Code"
      fullWidth
      variant="outlined"
      value={zipCode}
      onChange={handleChange}
      error={error}
      helperText={error ? "Zip Code must be 5 digits" : ""}
      InputLabelProps={{
          shrink: true,
      }}
      {...props}
    />
  );
};

export default ZipCodeField;