import React, { useEffect, useState } from 'react';
import { Button } from '../../ui';
import { appModules, BASE_URL, explorerTypesConst } from '../../../data/constants';
import { Card, CardContent } from '../Card';
import { ExplorerTabs } from '../../../data/constants';
import Tabs from '../Tabs/Tabs';
import TabPanel from '../Tabs/TabPanel';
import { DivContainer } from '../../form/style';
import { FlexExactCenter, Text } from '../../../assets/styles/styledComponents';
import ScheduleList from '../../../pages/Patients/ScheduleList';
import { useParams } from 'react-router-dom';
import { Person } from '../../../pages/Patients/Patient';
import Visits from './Tabs/Visits';
import General from './Tabs/General';
import Preferences from './Tabs/Preferences';
import Availability from './Tabs/Availability';
import ReactLoader from '../../common/ReactLoader';
import PatientInfo from './Tabs/PatientInfo';
import OldPreferences from './Tabs/OldPreferences';
import Details from './Tabs/Details';
import Loader from '../Loader';
import DeclinedCaregivers from './Tabs/DeclinedCaregivers';
import { Breadcrumbs, Link } from '@mui/material';
import useFetcher from '../../../hooks/useFetcher';
import WorkAvailability from './Tabs/NewAvailability';

type tabsType = { isError: boolean, label: string, value: string }
const Explorer = () => {
    const [selectedTab, setSelectedTab] = useState<string>(ExplorerTabs[0].value)
    const [item, setItem] = useState<any>();
    const exploringType = 'patient';
    const { module, id } = useParams();
    const fetcher = useFetcher();
    const [visitPatientProps, set_visitPatientProps] = useState<{patientId?: number, patientName?: string, patientAddress?: string}>({});

    useEffect(() => {
        if (!id)
            return;

        fetcher.get(`${module}/${id}`).then(response => {
            setItem(response.data?.data ?? response.data);
        })
    }, [module, id])

    useEffect(() => {
        const numVal: number = Number(id);
        if (module === 'patient' && !Number.isNaN(numVal) && !!item) {
            set_visitPatientProps({
                patientId: numVal,
                patientName: item.name,
                patientAddress: item.address,
            })
        }
    }, [id, module, item])

    const filteredTabs = (tabs: tabsType[]) => {
        switch (module) {
            case appModules.caregiver:
                return tabs.filter((tab: tabsType) => tab.value !== 'caregivers')
            case appModules.patient:
                return tabs.filter((tab: tabsType) => tab.value !== 'availability' && tab.value !== 'patients');
            default: return []
        }
    }

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue)
    }

    return (
        <DivContainer>
            <Loader isLoading={fetcher.isLoading} />
            <Breadcrumbs sx={{ color: 'black', marginTop: '10px', marginLeft: '20px' }} separator='/'>
                <Link color={'black'} href={`/${module}s`} underline='hover'>{module && module?.charAt(0).toUpperCase() + module?.substring(1)}s</Link>
                <Link color={'black'} onClick={() => setSelectedTab('general')} underline='hover'>{item?.name || '. . . .'}</Link>
            </Breadcrumbs>
            <FlexExactCenter>
                {/* {(exploringType === explorerTypesConst.patient) && <Button
                    onClick={() => { }}
                    label={"Enrollment"}
                    variant="text"
                />} */}
            </FlexExactCenter>
            <Card variant="contained">
                <CardContent>
                    <Tabs
                        tabs={filteredTabs(ExplorerTabs)}
                        value={selectedTab}
                        variant="standard"
                        ariaLabel="explorer"
                        onChange={handleChangeTab}
                        centered={true}
                    />
                    <TabPanel value={selectedTab} index={ExplorerTabs[0].value}>
                        <Details />
                    </TabPanel>
                    {/* <TabPanel value={selectedTab} index={ExplorerTabs[1].value}>
                        <OldPreferences />
                    </TabPanel> */}
                    <TabPanel value={selectedTab} index={ExplorerTabs[1].value}>
                        <Preferences />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={ExplorerTabs[2].value}>
                        <Visits 
                        module={module} title="Visits" 
                        {...visitPatientProps}
                        />
                    </TabPanel>

                    <TabPanel value={selectedTab} index={ExplorerTabs[3].value}>
                        <WorkAvailability />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={ExplorerTabs[4].value}>
                        <PatientInfo />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={ExplorerTabs[5].value}>
                        <PatientInfo />
                    </TabPanel>
                    {/* <TabPanel value={selectedTab} index={ExplorerTabs[6].value}>
                        <DeclinedCaregivers />
                    </TabPanel> */}

                </CardContent>
            </Card>
        </DivContainer>
    )
}

export default Explorer