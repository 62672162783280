import React, { useEffect } from 'react';
import { Modal, Box, Card, CardContent, List, ListItem, IconButton, Grid, SvgIcon, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BoldTitle, Text } from '../../../../../assets/styles/styledComponents';

const CheckIcon: React.FC = () => (
    <svg width="20" height="20" style={{ marginRight: '5px' }}>
        <circle cx="10" cy="10" r="9" stroke="green" strokeWidth="0.5" fill="none" />
        <polyline points="6,10 8,12 14,6" stroke="green" strokeWidth="1.5" fill="none" />
    </svg>
);

const RemoveIcon: React.FC = () => (
    <svg width="20" height="20" style={{ marginRight: '5px' }}>
        <circle cx="10" cy="10" r="9" stroke="red" strokeWidth="0.5" fill="none" />
        <line x1="6" y1="10" x2="14" y2="10" stroke="red" strokeWidth="1.5" />
    </svg>
);

type CaregiverDetailsProps = {
    pros: string[];
    cons: string[];
    details: generalResponseType;
    openModal: boolean;
    closeModal: () => void;
    caseBased: boolean
};

type generalResponseType = {
    totalHoursWithThisVisit: number;
    lastWorkedDate?: string; // e.g., '10/02/2023'
    totalWorkedWithPatient?: number; // e.g., 109
};

const CaregiverMatch: React.FC<CaregiverDetailsProps> = ({ pros, cons, details, openModal, closeModal, caseBased }) => {


    return (
        <Modal open={openModal} onClose={closeModal}>
            <Box sx={{
                top: '10%',
                left: '23%',
                width: { xs: '90%', sm: '54%' },
                position: "absolute",
                padding: 4,
            }}>
                <Card>
                    <CardContent sx={{ padding: '10px 30px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <BoldTitle>Caregiver Match</BoldTitle>
                            <IconButton onClick={closeModal}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <List>
                                    {pros.map((pro, index) => (
                                        <ListItem key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                            <CheckIcon />
                                            <Text style={{ flex: 1, textAlign: 'left' }}>{pro}</Text>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <List>
                                    {cons.map((con, index) => (
                                        <ListItem key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                            <RemoveIcon />
                                            <Text style={{ flex: 1, textAlign: 'left' }}>{con}</Text>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                        <Divider sx={{ my: 2 }} />
                        <div style={{ padding: '0px' }}>
                            <BoldTitle>Details</BoldTitle>
                            <br></br>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Text><strong>Total week hours incl. {caseBased ? 'all shifts' : 'this visit'}:</strong> {details.totalHoursWithThisVisit}</Text>
                                </Grid>
                                <Grid item xs={6}>
                                    <Text><strong>Last worked with this patient:</strong> {details.lastWorkedDate} (Total visits: {details.totalWorkedWithPatient || 0})</Text>
                                </Grid>
                            </Grid>
                        </div>
                    </CardContent>
                </Card>
            </Box>
        </Modal>
    );
};

export default CaregiverMatch;
