import { BoldTitle, FlexBetween } from "../../assets/styles/styledComponents";
import { Switch } from "@mui/material";
import { PersonPrefType } from "../../pages/Preference/types";

interface PrefItemCheckBoxEditProps {
    preference: PersonPrefType;
    updatePreference: (row: PersonPrefType, column: string) => void;
}
const PrefItemCHeckBoxEdit: React.FC<PrefItemCheckBoxEditProps> = ({ preference, updatePreference }) => {
    return (
        <FlexBetween padding={'0px 0px 5px 0px'} borderWidth={'0px 0px 1px 0px'}>
            <BoldTitle padding={'6px 0px 0px 0px'}>
                {preference.name}
            </BoldTitle>
            <Switch
                onClick={(e: any) => updatePreference({ id: preference.id, value: e.target.checked } as PersonPrefType, "value")}
                checked={preference.value}
                sx={{
                    width: 58,
                    height: 33,
                    marginTop: '-5px',
                    paddingRight: '0px',
                    '& .MuiSwitch-track': {
                        borderRadius: 1 / 1,
                        height: '20px',
                        width: 70,
                    },
                    '& .MuiSwitch-thumb': {
                        borderRadius: '3px',
                        boxShadow: 'none',
                        height: 17,
                        width: 23,
                        marginTop: 0.5,
                        marginLeft: 0.5,
                    },

                }} />
        </FlexBetween>
    )
}

export default PrefItemCHeckBoxEdit;