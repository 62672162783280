import { useEffect, useState } from 'react';
import { TextField, TextFieldProps as MuiTextFieldPropsType } from '@mui/material';
import { Select, MenuItem, FormControl, Container, InputLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Tooltip, Chip } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DivContainer } from '../../assets/styles/styledComponents';
import { strings } from '../../helper/strings';
import { Button } from '../../components/ui';
import { CAREGIVER_COLUMNS, CAREGIVER_VISITS_MODAL } from '../../data/GridColumnDefinitions';
import List from '../../components/ui/List';
import { Person } from '../Patients/Patient';
import DataGrid from '../../components/ui/DataGrid';
import ModalContainer from '../../components/ui/Modals/ModalContainer';
import useApi_DEPRACATED from '../../hooks/useApi';
import FeedbackModal from '../../components/ui/Modals/components/FeedbackModal';
import MenuPopup from '../../components/ui/Modals/components/MenuPopup';
import FeaturedIcon from '../../components/common/FeaturedIcon';
import { FlexAlignCenter } from '../../assets/styles/styledComponents';
import { appModules, BASE_URL } from '../../data/constants';
import SearchInput from '../../components/common/SearchInput/SearchInput';
import useFetcher from '../../hooks/useFetcher';
import useAuth from '../../features/authentication/hooks/useAuth';
import Loader from '../../components/ui/Loader';
import useToast from '../../hooks/useToast';
import AddFab from '../../components/ui/AddFab';
import ContactEdit, { ContactFormData } from './ContactEdit';
import FilterSelect from '../../components/FilterSelect';

type caregiverListType = {
  address: string,
  caregiverCode: string,
  caregiverId: number,
  dateOfBirth: string,
  gender: string,
  hhaCaregiverId: number,
  hhaOfficeId: number,
  languages: string,
  name: string,
  officeName: string,
  phone: string,
  source: string
}

const CaregiversList = () => {
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const [visitRow, setVisitRow] = useState<caregiverListType | null>(null);
  const [feedbackRow, setFeedbackRow] = useState<any>(null);
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date())
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open_contactEdit, set_open_contactEdit] = useState<boolean>(false);
  const [feedbackModalAction, setFeedbackModalAction] = useState(null);
  const feedbackApi = useApi_DEPRACATED('match', BASE_URL);
  const caregiverMatchApi = useApi_DEPRACATED('match', BASE_URL);
  const [caregiverList, setCaregiverList] = useState<caregiverListType[]>([])
  const [modalRows, setModalRows] = useState<any>([])
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchModalTerm, setSearchModalTerm] = useState<string>('');
  const [availInfoStatus, setAvailInfoStatus] = useState<string>('any');
  const [scheduleStatus, setScheduleStatus] = useState<string>('any');
  const [employeeType, setEmployeeType] = useState<string>('employee');
  const [filterByStatus, set_filterByStatus] = useState<string>('active');
  const { getUser } = useAuth();
  const user = getUser();
  const { showSuccess, showError } = useToast();

  const settingsFetcher = useFetcher();
  const [settings, setSettings] = useState<any[]>([])

  const { getList, data, isLoading } = useApi_DEPRACATED(appModules.caregiver, BASE_URL);

  const getQuery = () => {
    return `?availInfoStatus=${availInfoStatus}&scheduleStatus=${scheduleStatus}&employeeType=${employeeType}&status=${filterByStatus}`
  }


  useEffect(() => {
    settingsFetcher.get('Settings/all').then(res => {
      setSettings(res.data)
    })
  }, [])
  useEffect(() => {
    // just in case state doesn't have data, reload
    //caregiverList?.length < 1 && data?.list?.length < 1 && getList(getQuery())

    // after reloaded, this use-effect will get triggered again, fetcher-state will already have data, this line will reach and set the state
    if (!!data?.list?.length)
      setCaregiverList(data.list)
  }, [data])
  const loadRows = () => {
  }
  useEffect(() => {
    reload();
  }, [availInfoStatus, scheduleStatus, employeeType, filterByStatus])
  const reload = () => {
    getList(getQuery())
  }
  // useEffect(() => {
  //   const results = data?.list?.filter((item: caregiverListType) => {
  //     const values = Object.values(item);
  //     return values.some((value) => String(value).toLowerCase().includes(searchTerm.toLowerCase()));
  //   });

  useEffect(() => {
    const exactMatchAttributes: string[] = [
      'hhaCaregiverId', 'caregiverCode', 'caregiverId', 'phone',
    ];
    const partialMatchAttributes: string[] = [
      'name'//, 'address',
    ];
    const results = data?.list?.filter((item: any) => {
      return exactMatchAttributes.some((key) => {
        const value = item[key as keyof caregiverListType];
        const stringValue = String(value).toLowerCase();
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        return !!value && stringValue === lowerCaseSearchTerm;
      }) || partialMatchAttributes.some((key) => {
        const value = item[key as keyof caregiverListType];
        const stringValue = String(value).toLowerCase();
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        return value !== null && stringValue.includes(lowerCaseSearchTerm);
      });
    });

    const list = results?.map((item: caregiverListType) => getWithSuffix(item));

    setCaregiverList(list)
  }, [searchTerm, data])

  useEffect(() => {
    const results = loadVisitsRows()?.filter((item: any) => {
      const values = Object.values(item);
      return values.some((value) => String(value).toLowerCase().includes(searchModalTerm.toLowerCase()));
    });
    setModalRows(results ?? [])
  }, [searchModalTerm, caregiverMatchApi?.data])

  // useEffect(() => {
  //   if (visitRow) {
  //     getMatches()
  //   }
  // }, [visitRow])

  useEffect(() => {
    modalRows.length < 1 && setModalRows(loadVisitsRows())
  }, [caregiverMatchApi?.data?.list, caregiverMatchApi?.data?.list])

  const statusOptions = [
    { value: 'wants case', name: 'Wants Case' },
    { value: 'wants More', name: 'Wants More' },
    { value: 'not available', name: 'Not Available' },
    { value: 'available', name: 'Available' },
  ]

  const updateAvailabilityStatus = (row: any, value: any) => {
    fetcher.get(`caregiver/availabilityStatus/${row.caregiverId}?status=${value}`,).then(res => {
      showSuccess("Availability Status Successfuly Updated.");
      getList(getQuery());
    }).catch(err => {
      showError(err.message)
    })
  }


  const getCareGiverCols = () => {
    const availStatusCol = {
      field: "availabilityStatus", headerName: 'Availability Status', flex: 0.6,
      renderCell: (params: any) => (
        <Select fullWidth size='small' margin='dense' sx={{ height: '30px' }} onChange={(e: any) => updateAvailabilityStatus(params.row, e.target.value)} value={params.row.availabilityStatus}>
          {statusOptions.map(option =>
            <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
          )}
        </Select>
      )
    };
    const findVisisCol = {
      field: "findVisit", renderCell: (params: any) => (<Button label="Find Visit" onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        setVisitRow(params.row)
      }} />), flex: 0.5
    };

    return user?.isBetaUser ? [...CAREGIVER_COLUMNS, availStatusCol
      //  , findVisisCol 
    ] : [...CAREGIVER_COLUMNS]
  }

  const handleCloseFeedbackModal = (data?: { action: string | null, description: string }) => {
    if (!!data) {
      feedbackApi.create({
        visitId: feedbackRow?.visitId,
        description: data.description,
        updateType: data.action,
        caregiverId: feedbackRow?.caregiverId,
        reportedBy_UserId: user?.userId
      }, `/${data.action}`).then(() => {
        if (data.action?.toLowerCase() === 'decline')
          caregiverMatchApi.removeCaregiverMatch(feedbackRow?.visitId)
        closeFeedbackModal()
      })
    } else {
      closeFeedbackModal()
    }
  }



  const closeFeedbackModal = () => {
    setFeedbackModalAction(null)
    setFeedbackRow(null)
  }

  const handleCloseMenu = (selected = null) => {
    if (selected) setFeedbackModalAction(selected);
    setAnchorEl(null)
  }

  const getVisitModalCol = () => {
    return [
      ...CAREGIVER_VISITS_MODAL,
      {
        field: "isAssignee", headerName: "Is Assignee", flex: 0.04, renderCell: (params: any) => {
          if (params.row.isAssignee) {
            return <span> Yes </span>
          }
          return <span> No </span>
        }
      },

      {
        field: 'overTime', flex: 0.04, renderCell: (params: any) => {
          if (params.row.flags?.length > 0) {
            return <Tooltip title={params.row.flags[0]}>
              <span style={{ color: 'red' }}>{params.row.flags[0]}</span>
            </Tooltip>
          }
          return null
        }
      },
      {
        field: 'bestMatch', flex: 0.04, renderCell: (params: any) => {
          if (params.row.isBestMatch) {
            return <Chip label="Best Match" color="success" />
          }
          return null
        }
      },
      {
        field: "infoIcon",
        flex: 0.2,
        renderCell: (params: any) => (
          <Tooltip title={params.row.summary}>
            <DivContainer>
              <FeaturedIcon
                fontSize={"small"}
                params={params}
                icon={"Checklist"}
                color={!!params.row.summary ? "primary" : "default"}
              />
            </DivContainer>
          </Tooltip>
        ),
      },
      {
        field: 'menu', flex: 0.02, renderCell: (params: any) => (
          <FeaturedIcon
            fontSize={"small"}
            params={params}
            icon={'Menu'}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              setFeedbackRow(params.row)
              setAnchorEl(e.currentTarget)
            }}
          />
        )
      },
    ]
  }

  // const reshuffle = () => {
  //   getMatches()
  // }

  const getMatches = () => {
    caregiverMatchApi.getList(`/${selectedMonth.toISOString()}/${visitRow?.caregiverId}`)
  }

  const loadVisitsRows = () => {
    let matches: any = [];
    let recommended: any = { ...caregiverMatchApi?.data?.list?.recommendedMatch }
    matches = caregiverMatchApi?.data?.list?.matches.map((row: any) => {
      return { ...row.visit, flags: row.flags, isAssignee: row.isAssignee, minutesAvailable: row.minutesAvailable, percentAvailable: `${row.percentAvailable}%`, ...row?.caregiver, summary: row.summary }
    })
    matches = matches?.filter((match: any) => match.visitId !== recommended?.visit?.visitId);
    if (matches?.length > 0 || !!recommended.visit) {
      if (!!recommended.visit) {
        matches.unshift({ ...recommended?.visit, isAssignee: recommended.isAssignee, ...recommended?.caregiver, minutesAvailable: recommended.minutesAvailable, percentAvailable: `${recommended.percentAvailable}%`, flags: recommended?.flags, summary: recommended?.summary, isBestMatch: true },)
      }
    }
    return matches ?? []
  }

  const customActionButton = <FlexAlignCenter>
    <Button
      label="Load"
      onClick={getMatches}
    />
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Select Date"
        value={selectedMonth}
        onChange={(newValue) => {
          setSelectedMonth(newValue as Date);
        }}
        renderInput={(params: MuiTextFieldPropsType) => <TextField sx={{
          '& .MuiOutlinedInput-input': {
            padding: '4px 10px',
          }
        }}
          InputLabelProps={{
            shrink: true,
          }}
          {...params} helperText={null} />}
      />
    </LocalizationProvider>

  </FlexAlignCenter>

  const handleRowClick = (param: any) => {
    navigate(`/caregiver/${param.row.caregiverId}/details`);
  }

  const handleSearch = (query: string) => {
    setSearchTerm(query)
  }

  const handleModalSearch = (query: string) => {
    setSearchModalTerm(query)
  }

  const options = {
    rowHeight: 36,
    headerHeight: 40,
    hideToolbarIconText: true,
    hideExport: true
  }
  const handleAvailInfoStatusChange = (event: any) => {
    setAvailInfoStatus(event.target.value);
  };
  const handleScheduleStatusChange = (event: any) => {
    setScheduleStatus(event.target.value);
  };
  const handleEmployeeTypeChange = (event: any) => {
    setEmployeeType(event.target.value);
  };
  const handle_filterByStatusChange = (event: any) => {
    set_filterByStatus(event.target.value);
  };

  return (
    <div style={{ padding: "10px 10px 10px 10px" }}>
      <Loader isLoading={isLoading} />
      <div style={{ display: 'flex', justifyContent: 'space-between', minWidth: '220px' }}>
        <div>
          <SearchInput getSearchQuery={handleSearch} />
        </div>
        <div style={{ width: '70%' }}></div>
        {
          <div style={{ marginLeft: '5px' }}>
            <FilterSelect
              label='Status'
              value={filterByStatus}
              handleChange={handle_filterByStatusChange}
              options={['All', 'Active', 'Inactive', 'Hold', 'On Leave', 'Terminated']}
              sx={{ minWidth: '180px', fontSize: '14px' }}
            />
          </div>
        }
        {
          //getShowApplicantCaregivers() &&
          <div style={{ marginLeft: '5px' }}>
            <FilterSelect
              label='Employee Type'
              value={employeeType}
              handleChange={handleEmployeeTypeChange}
              options={['Employee', 'Applicant', 'Candidate', 'Any']}
              sx={{ minWidth: '180px', fontSize: '14px' }}
            />
          </div>
        }
        <div style={{ marginLeft: '5px' }}>
          <FormControl fullWidth>
            <InputLabel shrink id="schedule-status-label">Schedule Status</InputLabel>
            <Select
              labelId="schedule-status-label"
              id="schedule-status"
              value={scheduleStatus}
              label="Schedule Status"
              size='small'
              onChange={handleScheduleStatusChange}
              sx={{ minWidth: '180px', fontSize: '14px' }}
            >
              <MenuItem value={'on-schedule'}>On Schedule</MenuItem>
              <MenuItem value={'not-on-schedule'}>Not on schedule</MenuItem>
              <MenuItem value={'any'}>Any</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div style={{ marginLeft: '5px' }}>
          <FormControl fullWidth>
            <InputLabel shrink id="avail-info-status-label">Availability Info Status</InputLabel>
            <Select
              labelId="avail-info-status-label"
              id="avail-info-status"
              value={availInfoStatus}
              label="Availability Info Status"
              size='small'
              onChange={handleAvailInfoStatusChange}
              sx={{ minWidth: '180px', fontSize: '14px' }}
            >
              <MenuItem value={'any'}>Any</MenuItem>
              <MenuItem value={'no-schedule'}><b>No Schedule</b></MenuItem>
              <MenuItem value={'outdated'}><b>Outdated</b></MenuItem>
              <MenuItem value={'up-to-date'}><b>Up-to-date</b></MenuItem>
              <MenuItem value={'today'}>Today</MenuItem>
              <MenuItem value={'yesterday'}>Yesterday</MenuItem>
              <MenuItem value={'this-week'}>This week</MenuItem>
              <MenuItem value={'last-week'}>Last week</MenuItem>
              <MenuItem value={'this-month'}>This month</MenuItem>
              <MenuItem value={'last-month'}>Last month</MenuItem>
              <MenuItem value={'last-7-days'}>Last 7 days</MenuItem>
              <MenuItem value={'last-15-days'}>Last 15 days</MenuItem>
              <MenuItem value={'last-30-days'}>Last 30 days</MenuItem>
              <MenuItem value={'last-60-days'}>Last 60 days</MenuItem>
            </Select>
          </FormControl>
        </div>
        {
          !user?.isAdminOrSuperAdmin ? null :
            <div style={{ marginLeft: '5px' }}>
              <AddFab
                top='0' right='0' position='relative' title={'Create Caregiver'}
                clickAction={() => { set_open_contactEdit(true) }}
              />
            </div>
        }
      </div>
      <DataGrid
        title="Caregiver List"
        onRowClick={handleRowClick}
        data={{
          columns: getCareGiverCols(),
          rows: caregiverList
        }}
        options={options}
      />
      <ModalContainer
        open={!!visitRow}
        title='Matches'
        fullWidth={true}
        loading={caregiverMatchApi.isLoading}
        maxWidth={'xl'}
        onCancel={() => {
          setSelectedMonth(new Date())
          setModalRows([])
          setVisitRow(null)
        }}
      >
        <DivContainer>
          <SearchInput getSearchQuery={handleModalSearch} />
          <DataGrid
            data={{
              columns: getVisitModalCol(),
              rows: modalRows
            }}
            options={{
              hideExport: true,
              hideFiltering: true,
              hideColumns: true,
              toolbarComponent: customActionButton
            }}
          />
        </DivContainer>
      </ModalContainer>
      <FeedbackModal
        title={strings.firstLetterUpperCase(feedbackModalAction ?? '')}
        action={feedbackModalAction}
        open={!!feedbackModalAction}
        // isLoading={feedbackApi.isLoading}
        onClose={handleCloseFeedbackModal}
      />
      <MenuPopup
        id={'mtch-febck'}
        open={!!anchorEl}
        anchorEl={anchorEl}
        options={[
          { label: 'Accept', value: 'accept' },
          { label: 'Decline', value: 'decline' },
          { label: 'Feedback', value: 'feedback' },
          { label: 'Update', value: 'update' },
        ]}
        onCloseMenu={handleCloseMenu}
      />
      <ContactEdit
        open={open_contactEdit}
        onSave={() => { set_open_contactEdit(false); loadRows(); }}
        onClose={() => { set_open_contactEdit(false) }}
        formData={{} as ContactFormData}
        module={'Caregiver'}
      />
    </div>
  )
}

export default CaregiversList

const getWithSuffix = (item: caregiverListType): caregiverListType => {
  const suffix = item.source == 'Candidate' ? '[CANDIDATE]' : '';
  const updated: caregiverListType = { ...item, name: `${item.name}  ${suffix}` }
  return updated;
}

