import React from 'react';
import {MuiTab, MuiTabs, MuiBox } from '../../../lib/MUI/mui.components';


type TabsProps = {
    value: any,
    variant: "standard" | "scrollable" | "fullWidth" | undefined,
    onChange: any,
    ariaLabel: string,
    centered?: boolean,
    scrollButtons?: boolean,
    tabs: tabsType[],

}

type tabsType = {
    label: string | number,
    value: string | number,
    isError: boolean
}

const Tabs: React.FC<TabsProps> = (props) => {
    const { value, variant, ariaLabel, centered, scrollButtons, tabs, onChange, } = props;

    function a11yProps(index: number) {
        return {
          id: `${ariaLabel}-${index}`,
          'aria-controls': `${ariaLabel}-${index}`,
        };
    }
      
    return (
        <MuiBox sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <MuiTabs
                value={value}
                onChange={onChange}
                variant={variant}
                scrollButtons= {scrollButtons}
                allowScrollButtonsMobile
                aria-label={ariaLabel}
                centered={centered}
            >
                {
                    tabs.map((tab:tabsType, index: number) => {
                        return(  
                            <MuiTab 
                                label={tab.label}  
                                value={tab.value} 
                                {...a11yProps(index)} 
                            />
                        )
                    })
                }

            </MuiTabs>
        </MuiBox>
    )
}

export default Tabs