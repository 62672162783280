import { Box, Divider, Tooltip } from "@mui/material"
import useFetcher from "../../../../../hooks/useFetcher"
import { useEffect, useState } from "react"
import { BoldTitle } from "../../../../../assets/styles/styledComponents"
import ReactLoader from "../../../../../components/common/ReactLoader"

type PreferenceProps = {
    caregiverId: string,
    patientId?: string
}

const CheckIcon: React.FC = () => (
    <svg width="20" height="20" style={{ marginRight: '5px' }}>
        <circle cx="10" cy="10" r="9" stroke="green" strokeWidth="0.5" fill="none" />
        <polyline points="6,10 8,12 14,6" stroke="green" strokeWidth="1.5" fill="none" />
    </svg>
);

const RemoveIcon: React.FC = () => (
    <svg width="20" height="20" style={{ marginRight: '5px' }}>
        <circle cx="10" cy="10" r="9" stroke="red" strokeWidth="0.5" fill="none" />
        <line x1="6" y1="6" x2="14" y2="14" stroke="red" strokeWidth="1.5" />
        <line x1="6" y1="14" x2="14" y2="6" stroke="red" strokeWidth="1.5" />
    </svg>
);

const QuestionMarkIcon: React.FC = () => (
    <svg width="20" height="20" style={{ marginRight: '5px' }}>
        <circle cx="10" cy="10" r="9" stroke="black" strokeWidth="0.5" fill="none" />
        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="14" fill="black">
            -
        </text>
    </svg>
);


const PreferenceModal = ({ caregiverId, patientId }: PreferenceProps) => {
    const fetcher = useFetcher();
    const [caregiverPreferences, setCaregiverPreferences] = useState<any[]>([]);
    const [patientPreferences, setPatientPreferences] = useState<any[]>([]);
    const [filteredCaregiverPrefs, setFilteredCaregiverPrefs] = useState<any[]>([]);
    const [filteredPatientPrefs, setFilteredPatientPrefs] = useState<any[]>([]);

    const getCaregiverPreferences = () => {
        if (!caregiverId)
            return;

        fetcher.get(`preference/caregiver/${caregiverId}`).then((res: any) => {
            setCaregiverPreferences(res.data.data)
        })
    }

    const getPatientPreferences = () => {
        if (!patientId)
            return;

        fetcher.get(`preference/patient/${patientId}`).then((res: any) => {
            setPatientPreferences(res.data.data)
        })
    }

    useEffect(() => {
        getCaregiverPreferences();
        getPatientPreferences();
    }, [caregiverId, patientId])

    useEffect(() => {
        const filteredCaregiver = caregiverPreferences?.filter((p) => p.value == true);
        setFilteredCaregiverPrefs(filteredCaregiver)

        const filteredPatients = patientPreferences?.filter((p) => p.value == true);
        setFilteredPatientPrefs(filteredPatients)

    }, [caregiverPreferences, patientPreferences])

    const customScrollbarStyles = {
        '&::-webkit-scrollbar': {
            width: '7px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'lightgray',
            borderRadius: '6px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'grey',
        },
    };

    return (
        <Box width={'450px'} height={'300px'} sx={{ overflowY: 'scroll', display: 'flex', justifyContent: 'center', ...customScrollbarStyles }} padding={'20px'} textAlign={'center'}>
            <ReactLoader isLoading={fetcher.isLoading} />
            {patientId && <div>
                <Box>
                    <BoldTitle>Patient Preference Tags</BoldTitle>
                    <Box sx={{ paddingTop: 1 }}>
                        {patientPreferences.map((preference: any) => {
                            const p = caregiverPreferences.find(pref => pref.preferenceId === preference.preferenceId)
                            const cgMeetsPref = preference.value === true && p?.value === true;
                            const cgDoesNotMeetPref = preference.value === true && p?.value === false;
                            const situation: string = cgMeetsPref ? "Caregiver can handle it" :
                                cgDoesNotMeetPref ? "Caregiver can not handle this" : "Patient doesn't require this"
                            return (
                                <Tooltip title={situation} placement="left">
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '2px 0px' }}>
                                        <BoldTitle fontWeight='200px'>{preference.name}</BoldTitle>
                                        {
                                            preference.value === true && p?.value === true ? <CheckIcon /> :
                                                preference.value === true && p?.value === false ? <RemoveIcon /> : <QuestionMarkIcon />
                                        }
                                    </div>
                                </Tooltip>
                            )
                        })}
                    </Box>
                </Box>
                {/* <Divider orientation='vertical' /> */}
            </div>}
            {/* <Box>
                <BoldTitle>Caregivers Preferences {patientId ? 'Patient Meets' : ''}</BoldTitle>
                <Box>
                    {filteredCaregiverPrefs.map((preference: any) => {
                        const p = patientPreferences.find(pref => pref.preferenceId == preference.preferenceId)
                        return (
                            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px' }}>
                                <BoldTitle fontWeight='200px'>{preference.name}</BoldTitle>
                                {p?.canMeetRequirement == true ? <CheckIcon /> : p?.canMeetRequirement == false ? <RemoveIcon /> : <QuestionMarkIcon />}
                            </div>)
                    })}
                </Box>
            </Box> */}
        </Box >
    )
}

export default PreferenceModal