import React from 'react';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Avatar, IconButton, Tooltip } from '@mui/material';
import { Close, EmojiEmotionsOutlined, MarkChatRead, MessageOutlined, MoreVert, PhoneOutlined } from "@mui/icons-material";
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { Bold, BoldTitle, FlexBetween, Text } from "../../../assets/styles/styledComponents";
import ReactLoader from '../../common/ReactLoader';
import { dateTime } from "../../../helper/datetime";

interface Chat {
    id: string;
    chatName: string;
    unreadMessages?: number;
    lastMessage: {
        sent: Date;
        message: string;
    };
}

interface ChatsListProps {
    title: string;
    filteredRows: Chat[];
    selectedChat: Chat | null;
    getMessages: (chat: Chat) => void;
    customScrollbarStyles: object;
    markingRead: any[]; // Replace 'any[]' with a more specific type if possible
    markAsRead: (chatId: string) => void;
}

const ChatsList: React.FC<ChatsListProps> = ({ title, filteredRows, selectedChat, getMessages, customScrollbarStyles, markingRead, markAsRead }) => {
    return (
        <>
            <p style={{ fontSize: 'smaller', marginTop: 0, marginBottom: 0 }}>
                {title}
            </p>
            <List sx={{ height: '88vh', overflowY: 'scroll', ...customScrollbarStyles }}>
                {filteredRows.map((chat) => (
                    <ListItem disablePadding divider key={chat.id}>
                        <ListItemButton
                            dense
                            selected={!!selectedChat && selectedChat.id === chat.id}
                            onClick={() => getMessages(chat)}
                            sx={{ borderRadius: '0px', margin: '0px' }}
                        >
                            <ListItemIcon>
                                {/* <Badge
                                        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                                        badgeContent={chat?.unreadMessages ? '' : 0}
                                        sx={{
                                            '& .MuiBadge-badge': {
                                            color: 'transparent',
                                            height: '10px', minWidth: '4px', padding: 0, width: '10px',
                                            border: '2px solid red', borderRadius: '50%', //7a1111
                                        }
                                        }} // Apply the color here
                                    > */}
                                <Avatar sx={{ bgcolor: '#7846ff' }}>{chat.chatName[0]}</Avatar>
                                {/* </Badge> */}
                            </ListItemIcon>
                            <ListItemText
                                sx={{ textOverflow: 'ellipsis' }}
                                primary={
                                    <FlexBetween padding='0px'>
                                        <div style={{ width: '200px', overflowX: 'clip' }}>
                                            <BoldTitle fontWeight={chat?.unreadMessages ? 600 : 400}>{chat.chatName}</BoldTitle>
                                        </div>
                                        <BoldTitle width='70px' textAlign='right' fontWeight={chat?.unreadMessages ? 600 : 200} fontSize='10px'>{dateTime.getDisplayValue(chat.lastMessage.sent)}</BoldTitle>
                                    </FlexBetween>}
                                secondary={
                                    <FlexBetween padding='0px'>
                                        <div style={{ width: '200px', overflowX: 'clip' }}>
                                            <Bold color={chat?.unreadMessages ? '#000' : 'unset'} fontWeight={chat?.unreadMessages ? 600 : 100}>{chat?.lastMessage?.message?.substring(0, 15)}</Bold>
                                        </div>
                                        {chat?.unreadMessages &&
                                            <Tooltip title='Mark as read'>
                                                <IconButton sx={{ zIndex: 10000, marginRight: '4px', padding: 0 }} onClick={() => markAsRead(chat.id)}>
                                                    <MarkEmailUnreadIcon sx={{ color: '#7846ff', height: '18px', padding: 0, margin: 0 }} />
                                                    <ReactLoader width="20px" height="20px" isLoading={markingRead.some((id: any) => id === chat.id)} />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        {/* <Badge
                                                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                                                badgeContent={chat?.unreadMessages ? '' : 0}
                                                sx={{
                                                    '& .MuiBadge-badge': {
                                                        color: 'transparent',
                                                        height: '10px', minWidth: '4px', padding: 0, marginLeft: '-14px', width: '10px',
                                                        border: '5px solid #7846ff', borderRadius: '50%', //7a1111
                                                    }
                                                }} 
                                            /> */}
                                    </FlexBetween>}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </>
    );
};

export default ChatsList;