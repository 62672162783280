import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Loader from '../../Loader';
import { useEffect, useState } from 'react';
import EditAdditionalPreferences from '../../../../pages/Preference/EditAdditionalPreferences';
import LanguagesContainer from '../../../../features/schedules/components/LangaugesContainer';
import GeneralPreferences from '../../../../pages/Preference/GeneralPreferences';
import CommutePreferencesBox from '../../../../pages/Preference/CommutePreferencesDialog';
import EditCommutePreferences, { CommutePreferences } from '../../../../pages/Preference/EditCommutePreferences';
import usePreferences from '../../../../features/Preferences/usePreferences';
import useScreenSizes from '../../../../hooks/useScreenSizes';
import TagPreferences from '../../../../features/Preferences/TagPreferences';
import PreferencesNotesContainer from '../../../../pages/Preference/PreferencesNotesContainer';

interface PreferencesProps {
  view?: string;
  moduleProp?: string;
  refId?: string | undefined;
}
const Preferences: React.FC<PreferencesProps> = ({ view = "", moduleProp = "", refId = null }) => {
  // object
  const { module, id } = useParams();
  const currId = refId || id;
  const currModule = moduleProp || module;

  // params
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get('token');

  // data
  const prefsHook = usePreferences({ module: currModule, moduleRefRowId: currId, token });
  const {
    isPrefsLoading,
    personalPreferences,
    setPersonalPreferences
  } = prefsHook;

  // ui components
  const [editAddtPrefs_open, set_editAddtPrefs_open] = useState<boolean>(false);
  const [commutePreferencesOpen, setCommutePreferencesOpen] = useState<boolean>(false);
  const { isLgPlus } = useScreenSizes();

  return (
    <Box>
      <Loader isLoading={isPrefsLoading} />
      <Grid container spacing={2}>
        {
          !!currModule && view !== 'mobile' &&
          <Grid item sm={12} md={6} lg={4} style={{ minWidth: '300px' }}>
            <TagPreferences
              {...prefsHook}
              module={currModule}
            />
          </Grid>
        }
        <Grid item sm={12} md={view === 'mobile' ? 12 : 6} lg={view === 'mobile' ? 12 : 4}>
          {!!currModule &&
            <Box sx={{ marginBottom: 2 }}>
              <GeneralPreferences
                module={currModule}
                personalPreferences={{ ...personalPreferences }}
                set_editAddtPrefs_open={set_editAddtPrefs_open}
              />
            </Box>
          }
          {!!currModule && currModule === 'caregiver' &&
            <Box sx={{ marginBottom: 2 }}>
              <CommutePreferencesBox
                personalPreferences={{ ...personalPreferences }}
                setOpen={setCommutePreferencesOpen}
              />
            </Box>
          }
          {
            (!isLgPlus || view === 'mobile') &&
            <Box sx={{ border: '1px solid #e0e0e0', borderRadius: '3px', marginBottom: 2 }}>
              <LanguagesContainer module={currModule} id={currId} />
            </Box>
          }
          {
            // (!isLgPlus || view === 'mobile') &&
            // <Box sx={{ border: '1px solid #e0e0e0', borderRadius: '3px' }}>
            //   <PreferencesNotesContainer module={currModule} refRowId={currId} />
            // </Box>
          }
        </Grid>
        {
          !!isLgPlus && view != 'mobile' &&
          <Grid item sm={12} md={6} lg={4}>
            <Box sx={{ border: '1px solid #e0e0e0', borderRadius: '3px', marginBottom: 2 }}>
              <LanguagesContainer module={currModule} id={currId} />
            </Box>
            {/* <Box sx={{ border: '1px solid #e0e0e0', borderRadius: '3px' }}>
              <PreferencesNotesContainer module={currModule} refRowId={currId} />
            </Box> */}
          </Grid>
        }
      </Grid>
      {personalPreferences?.preferences && currModule && currId && (
        <EditAdditionalPreferences
          open={editAddtPrefs_open}
          onClose={() => { set_editAddtPrefs_open(false) }}
          additionalPreferences={personalPreferences}
          setAdditionalPreferences={setPersonalPreferences}
          refModule={currModule || ""}
          refRowId={currId || ""}
        />
      )}
      {personalPreferences?.preferences && currModule && currId && (
        <EditCommutePreferences
          open={commutePreferencesOpen}
          onClose={() => { setCommutePreferencesOpen(false) }}
          commutePreferences={{ ...personalPreferences } as CommutePreferences}
          onUpdate={(pref: CommutePreferences) => {
            setPersonalPreferences({ ...personalPreferences, ...pref })
          }}
          refModule={currModule || ""}
          refRowId={currId || ""}
        />
      )}
    </Box>
  );
}

export default Preferences;