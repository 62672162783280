import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import PieChart from './PieChart'; // Assuming PieChart is a separate component
import { ApexOptions } from "apexcharts";
import BarChart from './BarChart';

interface OfficeDashboardBarChartProps {
    title: string;
    value: string | number;
    secondaryTextBottom?: string;
    chartData: ApexAxisChartSeries;
    chartOptions: ApexOptions; 
}

const OfficeDashboardBarChart: React.FC<OfficeDashboardBarChartProps> = ({
    title,
    value,
    secondaryTextBottom,
    chartData,
    chartOptions,
}) => {

    return (
        <Paper elevation={4} sx={{ borderRadius: '10px', backgroundColor: 'white', padding: '10px', height: 'px'}}>
            <Box>
                <Typography variant="h4" component="h4" sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                    {title}
                </Typography>
                
                
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <Typography variant="body1" sx={{ fontSize: '140px' , fontWeight: 'bold'}}>{value}</Typography>
</Box>
       
        </Paper>
    );
};

export default OfficeDashboardBarChart;