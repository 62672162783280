import React from 'react';

const TermsOfService: React.FC = () => {
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Align items to the start (left) for the container
    justifyContent: 'center',
    padding: '20px',
    maxWidth: '800px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '50px',
    marginBottom: '50px',
    background: '#f9f9f9',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    borderRadius: '8px',
  };

  const textStyle: React.CSSProperties = {
    textAlign: 'justify', // Justify text for alignment
    textJustify: 'inter-word', // Improve spacing between words
    width: '100%', // Ensure the text spans the full width of its container
  };

  const headingStyle: React.CSSProperties = {
    color: '#333',
    marginBottom: '20px',
  };

  return (
    <div style={containerStyle}>
      <h1 style={{ ...textStyle, textAlign: 'left' }}>CONNECTAIDEAI INC. SUBSCRIPTION TERMS OF SERVICE</h1>
      <p style={textStyle}>
        ConnectAideAI Inc. (<strong>“We”</strong> or <strong>“ConnectAideAI”</strong>) provides a software-as-a-service platform (the <strong>“Platform”</strong>).
      </p>
      <p style={textStyle}>
        These ConnectAideAI Subscription Terms of Service (the <strong>“Terms”</strong>), together with an Order Form, if
        entered into between you and ConnectAideAI, govern your access and use of the Services.
      </p>
      <p style={textStyle}>
        Please read these Terms carefully as they govern your use of the subscription to the SaaS Services
        (<strong>“Subscription”</strong>). The Enrollment, together with these Terms (collectively, the <strong>“Agreement”</strong>)
        constitute the complete agreement between ConnectAideAI and you (each a <strong>“Party”</strong> and
        collectively, the <strong>“Parties”</strong>) and supersede any prior discussions or representations regarding your
        order or use of the SaaS Services. Any additional terms and/or conditions you add or incorporate
        into any purchase orders, statements of work, order forms, or any other document are excluded and
        shall be null and void.
      </p>
      <p style={textStyle}>
        BY EXECUTING OR CONFIRMING AN ORDER FORM TO WHICH THESE TERMS HAVE BEEN
        INCORPORATED, OR BY ASSENTING TO THESE TERMS ONLINE, YOU EXPRESSLY
        ACKNOWLEDGE AND AGREE THAT YOU HAVE READ THESE TERMS AND AGREE TO BE
        BOUND BY THEM. IF YOU ARE AN INDIVIDUAL ENTERING INTO THESE TERMS ON BEHALF
        OF A BUSINESS ORGANIZATION, YOU HEREBY REPRESENT AND WARRANT TO US THAT
        YOU ARE AUTHORIZED TO ENTER INTO THESE TERMS ON BEHALF OF SUCH BUSINESS
        ORGANIZATION AND BIND IT TO THESE TERMS, IN WHICH CASE, THE TERMS <strong>“YOU”</strong> AND
        <strong>“YOUR”</strong> AND <strong>“ORGANIZATION”</strong> WILL REFER TO BUSINESS ORGANIZATION. IF YOU DO NOT
        ACCEPT ALL THE TERMS HEREIN OR ARE NOT AN AUTHORIZED REPRESENTATIVE FOR
        THE BUSINESS ORGANIZATION THAT WISHES TO ENTER INTO THESE TERMS, YOU MAY
        NOT ENTER INTO THESE TERMS AND MAY NOT ACCESS AND USE THE PLATFORM.
      </p>
      <p style={textStyle}>
        <strong>1. Definitions.</strong> As used in this Agreement, the following terms shall be defined as follows:
        <br /><br /><strong>1.1 “Authorized Users”</strong> mean the Organization's employees or authorized contractors of the Organization who are acting on the Organization’s behalf in the internal operation of Organization's business and who are subject to a confidentiality agreement with the Organization who are granted access and the right to use the SaaS Services.
        <br /><br /><strong>1.2 “Confidential Information”</strong> means information designated as confidential in writing or any information a reasonable person would understand to be confidential.
        <br /><br /><strong>1.3 “Data”</strong> means any proprietary, confidential, and/or personal data provided by you to ConnectAideAI in connection with your use of the SaaS Services, including, for example, testimonials provided by a representative of a company's customer, information about a company's prospects, and information about reference calls scheduled between a company's representative and the representative of the company's prospect.
        <br /><br /><strong>1.4 “Documentation”</strong> means the written materials, manuals, and other materials supplied by ConnectAideAI and related to the Platform.
        <br /><br /><strong>1.5 “Effective Date”</strong> has the meaning set forth in the applicable Order Form, or, if no Order Form exists, then the date you first entered into these Terms.
        <br /><br /><strong>1.6 “Enrollment”</strong> means either: (i) an Order Form, or (ii) one or more online webpages that specifies the particulars of your subscription to the SaaS Services, such as fees and metrics.
        <br /><br /><strong>1.7 “Feedback”</strong> means any suggestion, enhancement, recommendation, request, correction, or other feedback that you or Authorized Users provide to ConnectAideAI relating to using the Subscription SaaS Services.
        <br /><br /><strong>1.8 “Intellectual Property Rights”</strong> mean all proprietary information including, without limitation, patents, patent applications, trademarks, trade names, service marks, certification marks, collective marks, designs, processes, inventions, licenses, copyrights, know-how and trade secrets relating to the origin, design, manufacture, programming, operations, function, configuration, or service of the SaaS Services.
        <br /><br /><strong>1.9 “Order Form”</strong> means the applicable order form entered into between ConnectAideAI and the Organization to which these Terms are incorporated as an attachment or through a link.
        <br /><br /><strong>1.10 “Platform”</strong> means the software-as-a-service platform provided by ConnectAideAI that helps businesses to improve their coordination process between patients and caregivers.
        <br /><br /><strong>1.11 “SaaS Services”</strong> mean functionality of the Platform and all related features and services provided by ConnectAideAI as part of the Platform.
      </p>
      <p style={textStyle}>
        <strong>2. Use.</strong> The Platform is available only to registered users. Your user account will be created either once you sign-up and agree to these Terms, or when your Organization enrolls you to the Platform (the <strong>“Account”</strong> or <strong>“User Account”</strong>). You are solely responsible and liable for all activities performed with or through your User Account. You are prohibited from selling or transferring your Account in any way, to another user, entity or any third party, and must keep your Account login credentials confidential.
        <br /><br />ConnectAideAI hereby grants to you and Authorized Users a non-exclusive, non-transferable, non-sublicensable, revocable, limited right to access and use the functionality of the Platform and all related features and services provided by ConnectAideAI as part of the Platform, subject to the use limitations indicated in the Enrollment. You may only use the SaaS Services in accordance with the Documentation, solely for its own internal purposes, and subject to the use limitations indicated in the Enrollment and applicable laws.
      </p>
      <p style={textStyle}>
        <strong>3. Evaluation Service.</strong> To the extent mutually agreed upon in an applicable Order Form, ConnectAideAI may grant you access to the SaaS Services on a trial basis (the <strong>“Evaluation Service”</strong>). You may only use the Evaluation Service for your internal evaluation purposes for a period of thirty (30) days, unless otherwise stated in an Order Form (the <strong>“Evaluation Period”</strong>). Notwithstanding any other provision of this Agreement, you understand and acknowledge that ConnectAideAI is providing you with access to the Evaluation Service (i) on an “AS IS” basis; (ii) with no maintenance or support. ConnectAideAI disclaims all warranties and makes no representations in connection with your access and use of the Evaluation Service. ConnectAideAI may restrict and limit your access to the Evaluation Service and may block some features that may be provided as part of the Subscription Service. At the end of the Evaluation Period, your access to the Evaluation Service will be terminated unless: (i) your Order Form included an auto-renewal clause which converts your Evaluation Service to a full Enrollment of the SaaS Services; or (ii) you execute a new Order Form.
      </p>
      <p style={textStyle}>
        <strong>4. Restrictions on Use.</strong> You will not: (a) create or enable the creation of derivative works, modifications, or adaptations of the SaaS Services or Documentation; (b) decompile, reverse engineer or disassemble the SaaS Services; (c) distribute or disclose the SaaS Services or the Documentation to third parties other than as expressly permitted hereunder; (d) use any robot, spider, other automatic device or program or manual process to monitor, copy or reproduce the SaaS Services or Documentation; (e) rent, sublicense or otherwise allow any third party to use the SaaS Services, or (f) use the SaaS Services or Documentation for any purpose other than as set forth in this Agreement.
      </p>
      <p>When you use the Platform, you must refrain from:</p>
      <ul>
        <li>Engaging in any activity that constitutes or encourages conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any applicable law;</li>
        <li>Interfering with, burdening or disrupting the functionality of the SaaS Services;</li>
        <li>Breaching the security of the SaaS Services or identifying any security vulnerabilities in it;</li>
        <li>Circumventing or manipulating the operation or functionality of the SaaS Services, or attempting to enable features or functionalities that are otherwise disabled, inaccessible or undocumented in the SaaS Services;</li>
        <li>Abusing, harassing, threatening or intimidating other users of the SaaS Services;</li>
        <li>Impersonating any person or entity, or making any false statement pertaining to your identity, employment, agency or affiliation with any person or entity; or</li>
        <li>Collecting, harvesting, obtaining or processing personal information of or about other users of the SaaS Services.</li>
        <li>Providing content which:
          <ul>
            <li>May infringe or violate intellectual property rights of other parties, including copyrights, patents, trade secrets and trademarks;</li>
            <li>May identify minors, their personal details or their address and ways to contact them;</li>
            <li>Is software viruses, Trojan Horses, worms, vandals, spyware, ransomware and any other malicious applications;</li>
            <li>Constitutes a violation of a person's right to privacy or right of publicity; or</li>
            <li>Is threatening, abusive, harassing, defamatory, libelous, vulgar, violent, obscene or racially, ethnically or otherwise objectionable.</li>
          </ul>
        </li>
      </ul>
      <p>In addition to any remedies that may be available to us under any applicable law, we may, upon notice to you, temporarily or permanently deny, limit, suspend, or terminate your User Account, prohibit you from accessing the SaaS Services and take technical and legal measures to keep you off the SaaS Services, if we determine, in our reasonable discretion that:</p>
      <ul>
        <li>You abused your rights to use the SaaS Services;</li>
        <li>You breached these Terms;</li>
        <li>You performed any act or omission that violates any applicable law, rules, or regulations;</li>
        <li>You have performed any act or omission which is harmful or likely to be harmful to us, or any other third party, including other users of the SaaS Services;</li>
        <li>You used the SaaS Services to perform an illegal act, or for the purpose of enabling, facilitating, assisting or inducing the performance of such act;</li>
        <li>You deliberately submitted false information or have failed to comply with our requests for information regarding your identity;</li>
        <li>You transferred your User Account to another person or entity;</li>
        <li>ConnectAideAI's provision of the SaaS Services to Customer or any Authorized User is prohibited by applicable law;</li>
        <li>Any vendor of ConnectAideAI has suspended or terminated ConnectAideAI's access to or use of any third-party services or products required to enable Customer to access the Subscription Services.</li>
      </ul>

      <p style={textStyle}>
        5.<strong> Ownership.</strong> ConnectAideAI retains all rights and title to the SaaS Services and the
        Documentation and any copies thereof in any form. You have a limited subscription to access and
        use the SaaS Services. All Intellectual Property Rights in and to the SaaS Services and
        Documentation are retained by ConnectAideAI. You agree not to remove, deface, or destroy any
        copyright, patent notice, trademark, service mark, other proprietary markings, or confidential legends
        placed on or within the SaaS Services, the Documentation, and any copies thereof in any form. All
        rights not granted hereunder by ConnectAideAI are expressly reserved by ConnectAideAI.
      </p>
      <br />
      <div style={textStyle}>
        6.<strong> Data.</strong> The Organization retains all ownership of the Data that you create and the Data that is
        business-proprietary to the Organization. ConnectAideAI may aggregate and de-identify your Data (<strong>“Anonymized Data”</strong>). ConnectAideAI may use the Anonymized Data for ConnectAideAI’s internal business purposes, including enhancements to the SaaS Services, and to analyze, improve, support and operate the SaaS Services. Any Anonymized Data which constitutes protected health information (<strong>“PHI”</strong>) will be subject to the terms of the Business Associate Agreement (<strong>“BAA”</strong>). ConnectAideAI retains all ownership in and to the Anonymized Data.

        <p>
          We invite data subjects to onboard the Platform by email that we send them. We send these
          invitations based on the contact information that the Organization provides to us regarding its
          Authorized Users and its business customers. When the Organization provides us this contact
          information, we rely on the consent that the Organization has obtained from the data subject to receive invitations to the Platform. Therefore,
          it is the Organization's responsibility to obtain this consent. If the Organization has not obtained the consent of the data subject to receive
          invitations to the Platform, the Organization must not enter the data subject's contact information in the Platform.
        </p>

        <p>
          By entering into this Agreement, you hereby consent to ConnectAideAI and its authorized representatives contacting your users, coordinators,
          caregivers, patients, or any individuals interacting with the SaaS Services on your behalf for any and all purposes. These purposes include,
          but are not limited to, addressing Platform issues, updating preferences, or obtaining the availability of users, or collecting Feedback.
        </p>
        <p>
          You are responsible and liable for ensuring the accuracy, quality, integrity, legality, reliability, and appropriateness of all Data submitted to the
          Platform by your users, including Data imported through seamless integration with third-party services.
        </p>
        <p>
          We take no part in, or responsibility for, the interactions between you and other users of the
          Platform. These interactions and communications, and all consequences resulting therefrom, are
          strictly between you and the other users participating in those interactions and communications.
        </p>
        <p>
          While we are committed to maintaining robust security measures to protect Data on the Platform, you agree to ensure your users manage and handle Data responsibly and in compliance with all applicable laws and regulations.
        </p>
        <p>
          You agree to indemnify and hold ConnectAideAI harmless from any claims, damages, or losses incurred as a result of the actions of your users of the Platform, including, but not limited to, the management and handling of Data entered or imported by you into the Platform.
        </p>
      </div>
      <br />
      <div style={textStyle}>
        7.<strong> Feedback; Changes in the SaaS Services.</strong> You may provide ConnectAideAI with Feedback regarding the SaaS Services. If you give ConnectAideAI Feedback, then you hereby grant to ConnectAideAI a worldwide, royalty-free, fully paid-up, exclusive, irrevocable, perpetual, transferable, sub-licensable license to: (a) adapt, modify, and create derivative works of the Feedback; and, (b) to make, have made, use, copy, offer to sell, sell, perform, display, distribute, import, and otherwise dispose of the Feedback (and adaptations, modifications, and derivative works of the Feedback) and any product, technology, or service that incorporates, is combined or used with, or marketed for use or combination with, any Feedback.

        <p>We may maintain the SaaS Services with periodic releases of updates or upgrades. We will determine, in our discretion, the frequency and scope of such releases. We may also, at any time and without prior notice, change the layout, design, scope, features or availability of the SaaS Services. If you have a paid subscription to the SaaS Services, we will only do so in a manner that does not materially diminish the performance or features available on the SaaS Services.</p>

        <p>We may temporarily suspend the operation of the SaaS Services for maintenance purpose, and will aim to do so in a fashion that minimizes the impact on the Authorized Users of the SaaS Services.</p>
      </div>
      <div style={textStyle}>
        <p>8. <strong>Confidentiality.</strong> A party (<strong>“Receiving Party”</strong>) may, during the course of this Agreement, acquire information of the other party (<strong>“Disclosing Party”</strong>) that is Confidential Information. The Receiving Party will, during the term of this Agreement and for three (3) years thereafter, treat the Disclosing Party’s Confidential Information as confidential and will not disclose any such Confidential Information to any third party nor use such Confidential Information other than in connection with this Agreement. Confidential Information shall not include information that the Receiving Party can document was independently developed by the Receiving Party, is or becomes publicly available without breach of this Agreement, is known prior to disclosure by the Receiving Party, or is acquired by the Receiving Party from a third party free of disclosure obligations. The obligations set forth in this section will survive the termination of this Agreement.</p>
      </div>
      <div style={textStyle}>
        <p>9. <strong>Fees and Payment Terms.</strong> By entering into this Agreement, you hereby agree to pay the fees specified in the Enrollment in accordance with the payment terms set forth herein. ConnectAideAI will invoice you, upon execution of this Agreement, the Fees or as otherwise provided in an Enrollment. You shall pay each invoice due and submitted to you by ConnectAideAI within fifteen (15) days of the date of receipt of the invoice.</p>
      </div>
      <div style={textStyle}>
        <p>10. <strong>Taxes.</strong> To the extent applicable, you will pay any and all taxes pertaining to the SaaS Services or the use of the SaaS Services during the term of this Agreement and will reimburse ConnectAideAI on ConnectAideAI’s request if ConnectAideAI is required to pay any such tax.</p>
      </div>
      <div style={textStyle}>
        <p>11. <strong>Term.</strong> The term of this Agreement will commence on the Effective Date and will remain in effect for as long as a Subscription to the SaaS Services remains in effect, unless earlier terminated in accordance with Section 12 below. If automatic renewal is specified in the Enrollment, this Agreement will automatically renew for successive Subscription cycles of equal duration, unless and until a party notifies the other party in writing of its decision not to renew the Subscription, at least ten (10) days before the end of the then-current Subscription cycle.</p>
      </div>
      <div style={textStyle}>
        <p>12. <strong>Termination; Survival.</strong> ConnectAideAI may terminate this Agreement, and/or any Order Form for convenience upon thirty (30) days’ prior written notice. The Agreement may be terminated by either party, if the other party commits a material breach of any provision of the Agreement and such breach continues for a period of ten (10) days following a written request to cure such breach; or by either party, effective immediately, if the other party files, or has filed against it, a petition for voluntary or involuntary bankruptcy or pursuant to any other insolvency law or makes or seeks to make a general assignment for the benefit of its creditors or applies for or consents to the appointment of a trustee, receiver or custodian for a substantial part of its property.</p>
        <p>The following sections shall survive any termination, or expiration of the Terms: Ownership, Data, Feedback; Changes in the SaaS Services, Confidentiality, Audit Rights, Limitation of Liability, Rights and Remedies, Governing Law and Dispute Resolution, Severability, Entire Agreement.</p>
      </div>
      <br />
      <div style={textStyle}>
        13.<strong> Audit Rights.</strong> During the Enrollment and for a period of two (2) years thereafter, ConnectAideAI, directly or through an agent, may audit you to the extent necessary to verify your compliance with the terms of this Agreement. If any such audit reveals that you have exceeded your maximum number of Authorized Users or otherwise underpaid any fees payable hereunder, you shall promptly (i) make payment to ConnectAideAI for any such outstanding amounts, and (ii) reimburse ConnectAideAI for all reasonable costs and expenses of the audit.
      </div>
      <br />
      <div style={textStyle}>
        14.<strong> Business Associate Agreement.</strong> If and to the extent ConnectAideAI receives or processes PHI under this Agreement, ConnectAideAI shall comply with the Health Insurance Portability and Accountability Act, and all rules and regulations promulgated thereunder (<strong>“HIPAA”</strong>), as detailed in the BAA attached hereto as Exhibit A.
      </div>
      <br />
      <div style={textStyle}>
        15.<strong> No Warranties.</strong> CONNECTAIDEAI MAKES NO REPRESENTATIONS OR WARRANTIES WHATSOEVER AND YOU ACKNOWLEDGE THAT YOU HAVE NOT RELIED ON ANY REPRESENTATION OR WARRANTY, EXPRESS OR IMPLIED, REGARDING THE SAAS SERVICES. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, YOU ACKNOWLEDGE AND AGREE THAT YOU ARE GRANTED THE RIGHT TO ACCESS AND USE THE SAAS SERVICES ON AN “AS IS” BASIS WITH ALL FAULTS AND WITHOUT ANY EXPRESS, IMPLIED OR STATUTORY WARRANTIES WHATSOEVER INCLUDING, WITHOUT ANY LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, ENFORCEABILITY, NON-INFRINGEMENT, OR ARISING FROM COURSE OF PERFORMANCE, DEALING, USAGE, OR TRADE.
      </div>
      <br />
      <div style={textStyle}>
        16.<strong> Limitation of Liability.</strong> IN NO EVENT WILL CONNECTAIDEAI BE LIABLE TO YOU FOR ANY INCIDENTAL, INDIRECT, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOST REVENUES OR PROFITS, DAMAGES RELATED TO LOSS OF GOODWILL, USE, OR DATA ARISING FROM OR RELATING TO THIS AGREEMENT, REGARDLESS OF WHETHER CONNECTAIDEAI WAS ADVISED, HAD OTHER REASON TO KNOW, OR IN FACT KNEW OF THE POSSIBILITY THEREOF, EVEN IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. IN NO EVENT WILL CONNECTAIDEAI’S LIABILITY TO YOU UNDER THIS AGREEMENT EXCEED THE TOTAL AMOUNT PAID TO CONNECTAIDEAI BY YOU IN THE SIX (6) MONTHS IMMEDIATELY PRECEDING THE DATE IN WHICH THE CAUSE OF ACTION AROSE, OR FIVE DOLLARS ($5), WHICHEVER IS HIGHER.
      </div>
      <br />
      <div style={textStyle}>
        17.<strong> Rights and Remedies.</strong> All rights and remedies conferred by this Agreement or by law are cumulative and may be exercised singularly or concurrently. You acknowledge that any unauthorized use, copying, disclosure, or distribution of the SaaS Services or any related methods, algorithms, techniques, processes, or other information will cause ConnectAideAI irreparable harm for which there is no adequate remedy at law, entitling ConnectAideAI to injunctive relief in addition to any other legal or equitable remedies.
      </div>
      <br />
      <div style={textStyle}>
        18.<strong> Assignability.</strong> Neither this Agreement nor any of the rights, interests, or obligations under this Agreement may be assigned or delegated, in whole or in part, by operation of law or otherwise, by you without the prior written consent of ConnectAideAI, and any such assignment without such prior written consent will be null and void. This Agreement will be binding upon and inure to the benefit of the parties named herein and their respective successors and permitted assigns.
      </div>
      <br />
      <div style={textStyle}>
        19.<strong> Amendments; Waivers.</strong> This Agreement may not be modified or amended except by a written instrument signed by the parties. In addition, no waiver of any provision of this Agreement will be binding unless set forth in a writing and signed by the Party granting the waiver. Any waiver will be limited to the circumstance or event specifically referenced in the written waiver document and will not be deemed a waiver of any other term of this Agreement or of the same circumstance or event upon any recurrence thereof.
      </div>
      <br />
      <div style={textStyle}>
        20.<strong> Notices.</strong> Any notice required or permitted to be given under this Agreement will be in writing and be deemed given when delivered by hand or received by registered or certified mail, postage prepaid, by a nationally recognized overnight courier service addressed to the party to receive such notice at the address provided in the applicable order form, or by email with delivery confirmation.
      </div>

      <br />
      <div style={textStyle}>
        21.<strong> Governing Law and Dispute Resolution.</strong>

        <p>Regardless of your place of residence or where you access or use the SaaS Services from, this Agreement and your use of the SaaS Services will be governed by and construed solely in accordance with the laws of the State of New York, excluding any otherwise applicable rules of conflict of laws, which would result in the application of the laws of a jurisdiction other than the State of New York.</p>

        <p>If you have a complaint about the SaaS Services or about ConnectAideAI, please first contact us at <a href="mailto:support@connectaide.ai">support@connectaide.ai</a>. We will carefully review and consider your complaint.</p>

        <p>SUBJECT TO THE EXCEPTIONS BELOW, ANY AND ALL DISPUTES, CLAIMS OR CONTROVERSIES BETWEEN YOU AND CONNECTAIDEAI REGARDING THIS AGREEMENT OR THE SAAS SERVICES, WHICH ARE NOT AMICABLY RESOLVED, SHALL BE SETTLED THROUGH BINDING ARBITRATION (RATHER THAN IN COURT) BY TELEPHONE, ONLINE OR BASED SOLELY UPON WRITTEN SUBMISSIONS WITHOUT IN-PERSON APPEARANCE, ADMINISTERED BY THE AMERICAN ARBITRATION ASSOCIATION (AAA), UNDER ITS CONSUMER ARBITRATION RULES, OR, IF YOU ARE AN ORGANIZATIONAL CUSTOMER, THEN UNDER ITS COMMERCIAL ARBITRATION RULES (WHICH ARE AVAILABLE AT <a href="https://www.adr.org" target="_blank">WWW.ADR.ORG</a>). THE SUBSTANTIVE LAW OF ARBITRATION SHALL BE THE LAWS OF THE STATE OF NEW YORK.</p>

        <p>JUDGMENT ON THE AWARD RENDERED BY THE ARBITRATOR(S) MAY BE ENTERED IN ANY COURT HAVING JURISDICTION THEREOF.</p>

        <p>HOWEVER, YOU MAY LODGE CLAIMS IN SMALL CLAIMS COURT IF YOUR CLAIM QUALIFIES.
          THE FEDERAL ARBITRATION ACT AND FEDERAL ARBITRATION LAW APPLY TO THIS AGREEMENT.</p>

        <p>THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED. HOWEVER, AN ARBITRATOR CAN AWARD ON AN INDIVIDUAL BASIS THE SAME DAMAGES AND RELIEF AS A COURT (INCLUDING INJUNCTIVE AND DECLARATORY RELIEF OR STATUTORY DAMAGES), AND MUST FOLLOW THIS AGREEMENT AS A COURT WOULD.</p>

        <p>PAYMENT OF FILING, ADMINISTRATION AND ARBITRATOR FEES WILL BE GOVERNED BY THE AAA'S CONSUMER ARBITRATION RULES. THESE FEES WILL BE SHARED AS FOLLOWS: ONE THIRD BY YOU AND TWO THIRDS BY US, UNLESS THE ARBITRATOR: (I) DETERMINES THAT THE CLAIMS ARE FRIVOLOUS, IN WHICH CASE THE CLAIMANT SHALL BEAR ALL SUCH FEES ARISING FROM THE FRIVOLOUS CLAIM; OR (II) DETERMINES THAT THE FEES SHOULD BE ALLOCATED DIFFERENTLY.</p>

        <p>THE ARBITRATION WILL BE A CONFIDENTIAL PROCEEDING. NEITHER PARTY WILL MAKE ANY PUBLIC ANNOUNCEMENT OR PUBLIC COMMENT OR RELEASE ANY PUBLICITY CONCERNING THE ARBITRATION INCLUDING THE FACT THAT THE PARTIES ARE IN DISPUTE, THE EXISTENCE OF THE ARBITRATION OR ANY DECISION OR AWARD OF THE ARBITRATOR. ALL CLAIMS MUST BE BROUGHT IN THE PARTIES’ OWN CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING, AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS. YOU AGREE THAT, BY ENTERING INTO THIS AGREEMENT, YOU AND THE COMPANY ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.</p>

        <p>YOU MAY OPT-OUT OF THE ABOVE ARBITRATION CLAUSE BY EMAILING US TO <a href="mailto:support@connectaide.ai">support@connectaide.ai</a>, WITHIN SEVEN (7) DAYS OF YOU ENTERING INTO THIS AGREEMENT FOR THE FIRST TIME, AN OPT-OUT NOTICE THAT IDENTIFIES YOURSELF AND CLEARLY SETS OUT YOUR CHOICE TO OPT OUT OF DISPUTE RESOLUTION BY ARBITRATION. IN CASE OF SUCH OPT-OUT, ANY AND ALL DISPUTES, CLAIMS OR CONTROVERSIES BETWEEN YOU AND US REGARDING THIS AGREEMENT OR THE USE OF THE SERVICE, WHICH ARE NOT AMICABLY RESOLVED, SHALL BE SUBJECT TO THE EXCLUSIVE JURISDICTION AND VENUE OF THE STATE COURTS IN NEW YORK COUNTY IN THE STATE OF NEW YORK, AND THE FEDERAL DISTRICT COURT FOR THE SOUTHERN DISTRICT OF NEW YORK.</p>

        <p>NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS SECTION, EITHER PARTY MAY ASSERT: (A) AN IMPLEADER CLAIM AGAINST THE OTHER PARTY IN ANY COURT OF COMPETENT JURISDICTION ADJUDICATING A THIRD PARTY CLAIM THAT IS SUBJECT TO INDEMNIFICATION; (B) A CLAIM ALLEGING INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS (INCLUDING COPYRIGHTS AND TRADE SECRETS), IN ANY COURT HAVING GENERAL OR SPECIFIC JURISDICTION OVER THE DEFENDANT; AND (C) A COURT CLAIM FOR INJUNCTIVE OR OTHER EQUITABLE RELIEF, IN ANY COURT HAVING GENERAL OR SPECIFIC JURISDICTION OVER THE DEFENDANT.</p>
      </div>
      <div style={textStyle}>
        22.<strong> Severability.</strong> Each provision of these Terms is intended to be severable. If any term or provision hereof is illegal or invalid for any reason whatsoever, such illegality or invalidity will not affect the legality or validity of the remainder of the Terms.
      </div>
      <br />
      <div style={textStyle}>
        23.<strong> Headings.</strong> All sections and other headings contained in the terms are for reference purposes only and are not intended to describe, interpret, define, or limit the scope, extent, or intent of the Terms or any provision hereof.
      </div>
      <br />
      <div style={textStyle}>
        24.<strong> Entire Agreement.</strong> The Agreement contains the entire understanding among the parties and supersedes any prior written or oral agreements between them respecting the subject matter of the Agreement. There are no representations, agreements, arrangements, or understandings, oral or written, between the Parties relating to the subject matter of the Agreement that is not fully set forth herein.
      </div>

    </div>
  );
};

export default TermsOfService;
