import React from 'react';
import './Loader.css';
import useAuth from '../../features/authentication/hooks/useAuth';

type ReactLoaderProps = {
    isLoading: boolean,
    style?: any
}

const Loader = ({ isLoading, style }: ReactLoaderProps) => {
    const { getUser } = useAuth();
    const user = getUser();

    return (
        <div
            style={{
                display: isLoading ? 'flex' : 'none',
                position: 'absolute',
                width: '100%',
                height: '100%',
                background: 'rgba(256, 256, 256, 0.5)',
                justifyContent: 'center',
                alignItems: 'center',
                top: '0px',
                left: '0px',
                zIndex: 1000,
                color: '#7846ff',
                ...style
            }}
        >
            <div className="loader" >
                <div className="circle" style={{ visibility: isLoading ? 'visible' : 'hidden' }}></div>
                <img src={`/${user?.agency?.replace(' ', '')}.png`} alt="Loader Image" className="center-image" />
            </div>
        </div>
    );
};

export default Loader;

