import { Alert, IconButton, Snackbar } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import React from "react";

type SnackProps = {
    type?: 'success' | 'info' | 'error' | 'warning',
    message?: string,
    openSnack?: boolean,
    handleCloseSnack?: any
}

const MySnackbar: React.FC<SnackProps> = ({type, message, openSnack, handleCloseSnack}) => {
    const action = (
        <IconButton
          size="small"
          aria-label="close"
          color={type}
          onClick={handleCloseSnack}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
    );

    return (
        <Snackbar 
          open={openSnack}  
          autoHideDuration={3000}
          onClose={handleCloseSnack}
          action={action}
        >
        <Alert 
          variant="filled" 
          onClose={handleCloseSnack} 
          severity={type}
        >
          {message}
        </Alert>
      </Snackbar>
    )
}

export default MySnackbar