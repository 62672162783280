import React from "react"
import { ToastOptions, toast } from "react-toastify";

const options : ToastOptions = {
    autoClose: 2400,
    pauseOnHover: false,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: true,
    position: toast.POSITION.TOP_CENTER,
};

const useToast = () => {
    const showInfo = (msg: string) => {
        toast.info(msg, options)
    };
    const showError = (msg: string) => {
        toast.error(msg, options)
    }
    const showSuccess = (msg: string) => {
        toast.success(msg, options)
    }
    const showWarning = (msg: string) => {
        toast.warning(msg, options)
    }
    
    return { showInfo, showError, showSuccess, showWarning }
};

export default useToast;    