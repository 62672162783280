import { Box, Button, Chip, Fab, List, ListItem, ListItemButton, ListItemText, Modal, TextField, TextFieldProps, Tooltip } from "@mui/material"
import DataGrid from "../../components/ui/DataGrid"
import { useContext, useEffect, useState } from "react"
import useFetcher from "../../hooks/useFetcher"
import { PERSONNEL_COLS } from "../../data/GridColumnDefinitions"
import ControlPanel from "../Map/ControlPanel"
import ReactLoader from "../../components/common/ReactLoader"
import SearchInput from "../../components/common/SearchInput/SearchInput"
import { FilterAltOffOutlined, MapOutlined, SearchOutlined } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import { isEqual } from "lodash"
import { FiltersContext } from "../../utils/context/FiltersContext"
import Loader from "../../components/ui/Loader"
import { LocationsContext } from "../../utils/context/LocationsContext"
import NewModal from "../../components/ui/NewModal"
import { dateTime } from "../../helper/datetime"
import { BoldTitle } from "../../assets/styles/styledComponents"
import VisitMatches from "../../features/schedules/components/VisitMatches"
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"

type props = {
    setView?: any
}
const PersonnelList = ({ setView }: props) => {
    const fetcher = useFetcher();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [filteredRows, setFilteredRows] = useState<any[]>([]);
    const { filters, setFilters } = useContext(FiltersContext)!;
    const { locations, setLocations } = useContext(LocationsContext)!;
    const [cleared, setCleared] = useState(0);
    const [custom, setCustom] = useState(false);
    const [visits, setVisits] = useState<any[]>([]);
    const [openModal, setOpenModal] = useState(false);
    const [openMatches, setOpenMatches] = useState(false);
    const [selectedVisit, setSelectedVisit] = useState<any>();
    const [customVisit, setCustomVisit] = useState({
        patientId: undefined,
        visitDate: new Date(),
        from: new Date(),
        to: new Date()
    })
    const [originalFilters, setOriginalFilters] = useState({
        showCaregivers: true,
        showPatients: false,
        CStatus: ['Active'],
        PStatus: ['Active'],
        hasSched: '',
        needsCare: '',
        discipline: [''],
        offices: [],
        coordinators: [],
        timing: [],
        gender: '',
        hiredAfter: '',
        workedAfter: '',
        addressFor: 'patient',
        address:
        {
            addressLine1: '',
            city: '',
            state: '',
            country: '',
            zipCode: '',
            latitude: '',
            longitude: '',
            full: '',
        },
    });
    const [changed, setChanged] = useState(false)

    useEffect(() => {
        if (!isEqual(filters, originalFilters)) {
            setChanged(true)
        }
        if (isEqual(filters, originalFilters)) {
            setChanged(false)
        }
    }, [filters]);

    const handleClear = () => {
        setFilters(originalFilters)
        setCleared(cleared + 1)
    }

    const handleSubmit = () => {
        getLocations()
    }

    useEffect(() => {
        if (cleared > 0)
            getLocations()
    }, [cleared])

    useEffect(() => {
        if (locations.length < 1) {
            getLocations()
        }
    }, [])

    const handleSearch = (query: string) => {
        setSearchTerm(query)
    }

    useEffect(() => {
        const results = locations?.filter((item: any) => {
            const values = Object.values(item);
            return values.some((value) => String(value).toLowerCase().includes(searchTerm.toLowerCase()));
        });

        setFilteredRows(results.map((r) => {return {...r, typeLower: r.type.toLowerCase()}}))
    }, [searchTerm, locations])

    const handleOpenModal = (row: any) => {
        setCustomVisit({
            ...customVisit,
            patientId: row.id
        })
        setOpenModal(true)
        getUpcomingVisits(row);
    }

    const handleCloseModal = () => {
        setOpenModal(false)
        setCustom(false)

        setCustomVisit({
            patientId: undefined,
            visitDate: new Date(),
            from: new Date(),
            to: new Date()
        })
    }

    const getUpcomingVisits = (row: any) => {
        const date = new Date();
        fetcher.get(`Visit/${row.type.toLowerCase()}/by_month/${row.id}/${date.toDateString()}`).then((res: any) => {
            console.log(res)
            setVisits(res.data.data)
        })
    }

    const handleOpenMatches = (visit: any) => {
        setSelectedVisit(visit)
        setOpenMatches(true)
    }

    const handleCustomSearch = () => {
        setCustom(!custom)
    }

    const getLocations = () => {
        const payload = {
            showCaregivers: filters.showCaregivers,
            showPatients: filters.showPatients,
            CStatus: filters.CStatus.toString(),
            PStatus: filters.PStatus.toString(),
            hasSchedule: filters.hasSched == 'yes' ? true : (filters.hasSched == 'no' ? false : null),
            seekingCare: filters.needsCare == 'yes' ? true : filters.needsCare == 'no' ? false : null,
            discipline: filters.discipline.toString(),
            office: filters.offices.toString(),
            coordinators: filters.coordinators.toString(),
            timing: filters.timing,
            gender: filters.gender,
            workedAfter: filters.workedAfter == 'all' ? null : filters.workedAfter,
            hiredAfter: filters.hiredAfter == 'all' ? null : filters.hiredAfter,
            addressFor: filters.addressFor,
            address: {
                addressLine1: filters.address.addressLine1,
                city: filters.address.city,
                state: filters.address.state,
                country: filters.address.country,
                zipCode: filters.address.zipCode,
                latitude: filters.address.latitude, // Note the typo here, it should be 'latitude'
                longitude: filters.address.longitude,
            },
        }
        fetcher.post(`Map/locations`, payload).then(res => {
            setLocations(res.data.data || [])
        })
    }

    const getStatusColor = (status: string) => {
        switch (status) {
            case 'Active':
                return 'green';
            case 'Inactive':
                return 'red';
            case 'On Leave':
                return 'orange';
            case 'Discharged':
                return 'skyblue';
            default:
                return 'info';
        }
    }

    const getTypeColor = (type: string) => {
        switch (type) {
            case 'Patient':
                return '#3a02f2';
            case 'Caregiver':
                return '#c4040e';
            case 'Nurse':
                return '#068f0d';
            default:
                return 'info';
        }
    }

    const getPersonnelCols = () => {
        return [
            ...PERSONNEL_COLS,
            {
                field: 'type', headerName: 'Type', renderCell: ((params: any) => {
                    return (
                        <Chip
                            size="small"
                            label={params.row.type}
                            sx={{
                                width: '90px',
                                borderRadius: '3px',
                                color: getTypeColor(params.row.type),
                                border: `1px solid ${getTypeColor(params.row.type)}`,
                                background: 'transparent'
                            }}
                        />
                    )
                })
            },
            {
                field: 'status', headerName: 'Status', renderCell: ((params: any) => {
                    return (
                        <Chip
                            size="small"
                            label={params.row.status}
                            sx={{
                                width: '90px',
                                borderRadius: '3px',
                                color: getStatusColor(params.row.status),
                                border: `1px solid ${getStatusColor(params.row.status)}`,
                                background: 'transparent'
                            }}
                        />
                    )
                })
            },
            {
                field: 'action', headerName: '', renderCell: ((params: any) => {
                    if (params.row.type == 'Patient') {
                        return (
                            <Button size="small" variant="text" onClick={() => handleOpenModal(params.row)} endIcon={<SearchOutlined fontSize="small" />}>{params.row.type == 'Caregiver' ? 'Find Visit' : 'Find Caregiver'}</Button>
                        )
                    }
                })
            }
        ]
    }

    const headerAction =
        <div style={{ height: '30px' }}>
            {changed &&
                <Button
                    size="small"
                    onClick={() => handleClear()}
                    endIcon={<FilterAltOffOutlined />}
                >
                    Clear Filters
                </Button>
            }

        </div>

    const customScrollbarStyles = {
        '&::-webkit-scrollbar': {
            width: '7px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'lightgrey',
            borderRadius: '6px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#7846ff',
        },
    };

    return (
        <Box padding={'10px'}>
            <Loader isLoading={fetcher.isLoading} />
            <div style={{ zIndex: 999, position: 'absolute', top: '10px', right: '60px' }}>
                <Tooltip title='Map View'>
                    <Fab size="small" sx={{ background: '#fff', }} onClick={() => setView()}>
                        <MapOutlined color="primary" />
                    </Fab>
                </Tooltip>
            </div>

            <ControlPanel
                setFilters={setFilters}
                filters={filters}
                submit={handleSubmit}
            />

            <div style={{ zIndex: 100 }}>
                <SearchInput getSearchQuery={handleSearch} />
                <DataGrid
                    title="Personnel"
                    data={{
                        columns: getPersonnelCols(),
                        rows: filteredRows
                    }}
                    options={{
                        toolbarComponent: headerAction,
                        hideExport: true,
                        hideFiltering: true,
                        hideColumns: true,
                    }}
                />
            </div>
            <NewModal open={openModal} title="Select Visit" onClose={() => handleCloseModal()} onSave={() => handleOpenMatches(null)} hideBtn={custom ? false : true} btnTitle="Search">
                <Box>
                    <List sx={{ maxHeight: '225px', overflowY: 'scroll', ...customScrollbarStyles }}>
                        <ListItem>
                            <ListItemButton onClick={() => { handleCustomSearch() }} sx={{ textAlign: 'center', backgroundColor: 'rgb(232,224,255)', borderRadius: '5px' }}>
                                <ListItemText><BoldTitle fontWeight='400'>Custom Search</BoldTitle></ListItemText>
                            </ListItemButton>
                        </ListItem>
                        {!custom && visits.map((visit: any) =>
                            <ListItem>
                                <ListItemButton onClick={() => handleOpenMatches(visit)} sx={{ textAlign: 'center', backgroundColor: 'rgb(232,224,255)', borderRadius: '5px' }}>
                                    <ListItemText><BoldTitle fontWeight='400'>{dateTime.getYearDate(visit?.visitDate)} {dateTime.getTimeString(visit.scheduleStartTime)} - {dateTime.getTimeString(visit.scheduleEndTime)}</BoldTitle></ListItemText>
                                </ListItemButton>
                            </ListItem>
                        )}
                    </List>
                    {custom &&
                        <div style={{ marginTop: '-10px', width: '475px', display: 'block', justifyContent: 'space-between' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div style={{ margin: '10px', textAlign: 'center' }}>
                                    <DatePicker
                                        label="Select Date"
                                        value={customVisit.visitDate}
                                        onChange={(newValue) => {
                                            if (newValue === null) return;
                                            var newDate = new Date(newValue as Date);
                                            setCustomVisit({ ...customVisit, visitDate: newDate });
                                        }}
                                        renderInput={(params: TextFieldProps) => <TextField sx={{
                                            '& .MuiOutlinedInput-input': {
                                                padding: '4px 10px',
                                                width: '225px',
                                                height: '40px'
                                            }
                                        }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            {...params} helperText={null} />}
                                    />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ margin: '10px' }}>
                                        <TimePicker
                                            value={customVisit.from || null}
                                            onChange={(e: any) => setCustomVisit({ ...customVisit, from: e })}
                                            label='From'
                                            renderInput={(params) =>
                                                <TextField
                                                    sx={{
                                                        '& .MuiOutlinedInput-input': {
                                                            padding: '4px 10px',
                                                            width: '100%',
                                                            height: '40px'
                                                        }
                                                    }}
                                                    {...params}
                                                />}
                                        />
                                    </div>
                                    <div style={{ margin: '10px' }}>
                                        <TimePicker
                                            value={customVisit.to || null}
                                            onChange={(e: any) => setCustomVisit({ ...customVisit, to: e })}
                                            label='To'
                                            renderInput={(params) =>
                                                <TextField
                                                    sx={{
                                                        '& .MuiOutlinedInput-input': {
                                                            padding: '4px 10px',
                                                            width: '100%',
                                                            height: '40px'
                                                        }
                                                    }}
                                                    {...params}
                                                />}
                                        />
                                    </div>
                                </div>
                            </LocalizationProvider>
                        </div>
                    }
                </Box>
            </NewModal >
            <Modal sx={{ display: 'flex', justifyContent: 'center' }} open={openMatches} onClose={() => setOpenMatches(false)}>
                <Box width={'80%'} height='725px' marginTop='5%' padding='10px' sx={{ background: 'white', borderRadius: '5px' }}>
                    <VisitMatches
                        caseBased={false}
                        visit={custom ? 0 : selectedVisit}
                        visitDates={custom ? null : [
                            new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
                            new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString()
                        ]}
                        factorSettingsId={undefined}
                        patientId={custom ? customVisit.patientId : undefined}
                        visitDate={custom ? customVisit.visitDate.toISOString() : undefined}
                        start={custom ? dateTime.getFormattedDateTime(customVisit.from) : undefined}
                        end={custom ? dateTime.getFormattedDateTime(customVisit.to) : undefined}
                    />
                </Box>
            </Modal>
        </Box >
    )
}

export default PersonnelList