import { Box, Button as MuiButton, Chip, IconButton, List, ListItem, MenuItem, Select, TextField, FormControl, InputLabel, Tooltip, SelectChangeEvent, Avatar, SvgIcon, SvgIconProps, Grid } from "@mui/material"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useToast from "../../../../hooks/useToast";
import { useState, useEffect, ChangeEvent } from "react";
import useFetcher from "../../../../hooks/useFetcher";
import { Bold, BoldTitle, FlexBetween } from "../../../../assets/styles/styledComponents";
import { Add, DeleteOutlineOutlined, House, Info, InfoOutlined, RestoreFromTrash, SendOutlined, StickyNote2Outlined } from "@mui/icons-material";
import Button from '../../Button';
import NewModal from "../../NewModal";
import { WeekAvailabilityType, WorkWindowType } from "../../../../features/WorkAvailability/types";
import DayAvailability from "../../../../features/WorkAvailability/DayAvailability";
import Loader from "../../Loader";
import useAuth from "../../../../features/authentication/hooks/useAuth";
import { generateTempId } from "../../../../helper/arrays";
import { dateTime } from "../../../../helper/datetime";

const newAvail: WeekAvailabilityType = {
    caregiverWeekAvailabilityId: 0,
    lastUpdated: "",
    workWindows: [],
    fridayAvailabilityType: "",
    fridayFrom: "",
    fridayLiveIn: "",
    fridayTo: "",
    mondayAvailabilityType: "",
    mondayFrom: "",
    mondayLiveIn: "",
    mondayTo: "",
    saturdayAvailabilityType: "",
    saturdayFrom: "",
    saturdayLiveIn: "",
    saturdayTo: "",
    sundayAvailabilityType: "",
    sundayFrom: "",
    sundayLiveIn: "",
    sundayTo: "",
    thursdayAvailabilityType: "",
    thursdayFrom: "",
    thursdayLiveIn: "",
    thursdayTo: "",
    tuesdayAvailabilityType: "",
    tuesdayFrom: "",
    tuesdayLiveIn: "",
    tuesdayTo: "",
    wednesdayAvailabilityType: "",
    wednesdayFrom: "",
    wednesdayLiveIn: "",
    wednesdayTo: ""
}

interface WorkAvailabilityProps {
    view?: string;
    authToken?: string | null;
}
function removeAfterDot(str: string) {
    const dotIndex = str.indexOf('.');
    return dotIndex === -1 ? str : str.substring(0, dotIndex);
}
const cleanDtString = (dt: Date | null | undefined) => {
    const updated = dt == null ? null : dateTime.getDateTime(dt ?? new Date())

    return updated;
}
const cleanDate = (originalDate: string) => {
    const truncatedDate = originalDate.includes('.')
        ? originalDate.split('.')[0]
        : originalDate;

    console.log(truncatedDate);

    return truncatedDate;
}
// this fixes an issue that used to move the timing somehow with a couple of minutes every time when saving
const getCleanDate = (dt: Date | null | undefined) => {
    if (!dt)
        return null;

    let newStr = '';
    if (typeof dt === 'string') {
        let dtStr: string = dt ?? '';
        dtStr = removeAfterDot(dtStr);
        if (dtStr.includes('0001-') === true)
            newStr = dtStr.replace('0001-', '1900-')
        else
            newStr = dtStr
    }

    const newDt = new Date(newStr);

    return !!newStr ? newStr : null
}
const WorkAvailability: React.FC<WorkAvailabilityProps> = ({ view = "", authToken = "" }) => {
    const [isWindowsDirty, set_isWindowsDirty] = useState<boolean>(false);
    const [dirtyItems, set_dirtyItems] = useState<number[]>([]);
    const [workWindows, setWorkWindows] = useState<WorkWindowType[]>([]);
    const [weekAvailability, set_weekAvailability] = useState<WeekAvailabilityType>(newAvail);
    const [formFields, setFormFields] = useState<any>([]);
    const { id } = useParams();
    const caregiverId = id;
    const [settings, setSettings] = useState<any[]>([])
    const [modalOpen, setModalOpen] = useState(false);
    const [action, setAction] = useState<string>('');
    const [lastUpdatedDaysAgo, setLastUpdatedDaysAgo] = useState<number>(-1);
    const handleCloseModal = () => { setModalOpen(false); setAction('') };
    const handleOpenModal = (action: string) => { setModalOpen(true); setAction(action) };
    const [openDelete, setOpenDelete] = useState(false);
    const [selectedWindow, setSelectedWindow] = useState<any>(null)
    const auth = useAuth();
    const user = auth.getUser();

    // params
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const token = queryParams.get('token');

    // fetcher
    const { get, post, isLoading, data: data } = useFetcher(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, token);
    const syncFetcher = useFetcher();
    const postFetcher = useFetcher(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, token);
    const timeFetcher = useFetcher();

    // note field
    const [modalNoteText, setModalNoteText] = useState<string>('');
    const [openNote, setOpenNote] = useState<boolean>(false);

    const { showSuccess, showError } = useToast();
    const controller = '';

    const loadAvailability = () => {
        get(`availability/` + id).then((res: any) => {
            set_weekAvailability(res.data.data);
            const res_workWindows = res.data.data.workWindows
                //?.map((ww: WorkWindowType) => ww)
                ?.map((ww: WorkWindowType) => {
                    return {
                        ...ww,
                        timeFrom: getCleanDate(ww.timeFrom),
                        // timeFrom: !!ww.timeFrom && toUtcISO(ww.timeFrom),
                        timeTo: getCleanDate(ww.timeTo),
                        // timeTo: !!ww.timeTo && toUtcISO(ww.timeTo),
                        dateFrom: getCleanDate(ww.dateFrom),
                        // dateFrom: !!ww.dateFrom && ww.dateFrom + 'Z',
                        dateTo: getCleanDate(ww.dateTo),
                        // dateTo: !!ww.dateTo && ww.dateTo + 'Z',
                    }
                })
                .sort((a: WorkWindowType, b: WorkWindowType) => sortWindows(a, b))
            setWorkWindows(res_workWindows)
            set_isWindowsDirty(false);
            set_dirtyItems([]);
        })
    }

    useEffect(() => {
        loadAvailability();
    }, [])
    useEffect(() => {
        if (!selectedWindow)
            return;
        setModalNoteText(selectedWindow.note);
    }, [selectedWindow])

    const getWeekAvailabilityObject = () => {
        // TODO: Validation
        let payload: any = {};
        for (var i = 0; i < formFields.length; i++) {
            for (const property in formFields[i]) {
                const value = `${formFields[i][property].value}` === 'Select' ? 'NULL' : `${formFields[i][property].value}`
                payload[`${property}`] = value;
            }
        }

        const id: number = data?.caregiverWeekAvailabilityId ?? -1;
        payload['caregiverWeekAvailabilityId'] = id;
        payload['lastUpdated'] = data?.lastUpdated;
        payload['hhaPermenantWeekId'] = data?.hhaPermenantWeekId;
        return payload
    }

    const syncAvailability = () => {
        syncFetcher.post('HhaData/caregiver/availability/' + caregiverId, {}).then((data) => {
            loadAvailability()
        })
    }
    const postAvailability = () => {
        const payload: any = getWeekAvailabilityObject();
        postFetcher.post(controller + `availability/post-to-hhax/${caregiverId}`, payload).then((res) => {
            showSuccess(res)
            get(controller + `availability/` + id)
        }).catch(error => {
            showError(error.response.data.message)
        })
    }

    const sendToCaregiver = () => {
        post(`availability/send/${id}`, null).then(res => {
            showSuccess("A link has been sent to the caregiver")
            handleCloseModal()
            loadAvailability()
        }).catch(error => {
            showError(error.response.message)
        })
    }

    const confirmCurrentAndReload = () => {
        post(controller + 'availability/confirm/' + data?.caregiverWeekAvailabilityId + (authToken != "" ? `?token=${authToken}` : ""), null).then(res => {
            showSuccess("Availability has been confirmed")
            handleCloseModal()
            loadAvailability()
        }).catch(error => {
            showError(error.response.message)
        })
    }

    const confirmSetting = () => {
        const setting = settings.find(s => s.key == 'AvailabilityFollowUp_EveryXDays');

        return setting?.value.split(',') ?? [60, 90]
    }

    const getLastUpdated = () => {
        if (data != null && data.lastUpdated == null)
            return -1;

        const lastUpdated = new Date(data?.lastUpdated ?? new Date())

        const today = new Date();
        const timeDiff = Math.abs(today.getTime() - lastUpdated.getTime());
        const daysDiff = Math.round(timeDiff / (1000 * 60 * 60 * 24));
        return daysDiff;
    }

    useEffect(() => {
        setLastUpdatedDaysAgo(getLastUpdated);
    }, [data])

    const handleAddWindow = (day: string) => {
        const tempId = generateTempId(workWindows);

        const newWindow: WorkWindowType = {
            id: tempId,
            weekDay: day,
            prefLevel: 'Preferred'
        }

        // Add the new window
        setWorkWindows([...workWindows, newWindow]);
        set_dirtyItems([...dirtyItems, tempId])
    }
    const handleChangeField = (value: string | Date, field: string, window: WorkWindowType) => {
        const updatedWindow = {
            ...window,
            [field]: value,
        }

        const winIndex = workWindows.findIndex(w => w.id === window.id);
        if (winIndex !== -1) {
            // Update the existing window
            const updatedTimeWindows = [...workWindows];
            updatedTimeWindows[winIndex] = updatedWindow;
            setWorkWindows(updatedTimeWindows);
            if (dirtyItems.includes(window.id) === false)
                set_dirtyItems([...dirtyItems, window.id])
        }
    }
    const save = () => {
        const payload = {
            ...weekAvailability,
            workWindows: workWindows.map((ww: WorkWindowType) => {
                return {
                    ...ww,
                    timeFrom: cleanDtString(ww.timeFrom),
                    timeTo: cleanDtString(ww.timeTo),
                    dateFrom: cleanDtString(ww.dateFrom),
                    dateTo: cleanDtString(ww.dateTo),
                }
            }),
        }
        timeFetcher.post(`Availability/${id}` + (authToken != "" ? `?token=${authToken}` : ""), payload).then(res => {
            confirmCurrentAndReload()
        })
    }

    const handleDeleteWindow = (window: WorkWindowType) => {
        handleChangeField('true', 'isDeleted', window);

        setOpenDelete(false);
        setSelectedWindow(null)
    }

    const onChangeSelect = (e: SelectChangeEvent<any>, day: string, fieldName: string) => {
        if (!weekAvailability)
            return;
        const fullFieldName = day.toLocaleLowerCase() + fieldName;

        const updated = {
            ...weekAvailability,
            workWindows: workWindows,
            [fullFieldName]: e.target.value
        };
        set_weekAvailability(updated);
        set_isWindowsDirty(true);
    }

    useEffect(() => {
        if (dirtyItems.length > 0)
            set_isWindowsDirty(true);
    }, [dirtyItems])

    const commonProps = {
        availability: weekAvailability,
        windows: workWindows,
        handleChangeField,
        setOpenDelete,
        setOpenNote,
        handleAddWindow,
        setSelectedWindow,
        onChangeSelect,
        dirtyItems,
        view: view
    };

    return (
        <Box>
            <Loader isLoading={isLoading || timeFetcher.isLoading} />
            <FlexBetween padding={'0px'}>
                <Chip
                    label={
                        !weekAvailability?.caregiverWeekAvailabilityId ? 'Loading data...' :
                            isNaN(Number(lastUpdatedDaysAgo)) ? 'Availability info is likely out of date' :
                                lastUpdatedDaysAgo === -1 ? 'Last updated a while ago' :
                                    lastUpdatedDaysAgo === 0 ? 'Last updated today' :
                                        `Last updated ${lastUpdatedDaysAgo} day${lastUpdatedDaysAgo === 1 ? '' : 's'} ago`
                    }
                    style={{
                        backgroundColor: 'whitesmoke',
                        color:
                            lastUpdatedDaysAgo === -1 || getLastUpdated() >= confirmSetting()[0] ?
                                getLastUpdated() >= confirmSetting()[1] || lastUpdatedDaysAgo === -1 ? 'red' : 'orange' : 'grey',
                        borderRadius: '5px'
                    }}
                />

                <FlexBetween padding={'0px'}>
                    {
                        (user?.isAdminOrSuperAdmin || user?.isSupervisor || user?.isCoordinator) &&
                        <Tooltip title="Invite caregiver to update availability">
                            <MuiButton variant='outlined' onClick={() => handleOpenModal('send')} style={{ margin: '5px' }}>
                                <SendOutlined />
                            </MuiButton>
                        </Tooltip>
                    }

                    <Button
                        label={isWindowsDirty ? 'Save' : 'Confirm'}
                        variant='outlined'
                        onClick={() => handleOpenModal(isWindowsDirty ? 'save' : 'confirm')}
                        style={{ marginRight: '5px' }}
                        disable={!data || (lastUpdatedDaysAgo != null && lastUpdatedDaysAgo !== -1 && lastUpdatedDaysAgo === 0 && !isWindowsDirty)}
                    />
                    {/* <Button
                        label='Post To HHAX'
                        onClick={postAvailability}
                        isLoading={postFetcher.isLoading}
                        style={{ marginRight: '5px' }}
                        variant="outlined"
                    /> */}
                    {/* <Button
                        label='Import From HHAX'
                        onClick={syncAvailability}
                        isLoading={syncFetcher.isLoading}
                        style={{ marginRight: '5px' }}
                        variant="outlined"
                    /> */}
                </FlexBetween>
            </FlexBetween>
            <List sx={{ display: 'flex', flexDirection: 'row' }}>
                <Grid container spacing={0.5}>
                    <DayAvailability day={'Sunday'} liveIn={weekAvailability.sundayLiveIn} availabilityType={weekAvailability.sundayAvailabilityType} {...commonProps} />
                    <DayAvailability day={'Monday'} liveIn={weekAvailability.mondayLiveIn} availabilityType={weekAvailability.mondayAvailabilityType} {...commonProps} />
                    <DayAvailability day={'Tuesday'} liveIn={weekAvailability.tuesdayLiveIn} availabilityType={weekAvailability.tuesdayAvailabilityType} {...commonProps} />
                    <DayAvailability day={'Wednesday'} liveIn={weekAvailability.wednesdayLiveIn} availabilityType={weekAvailability.wednesdayAvailabilityType} {...commonProps} />
                    <DayAvailability day={'Thursday'} liveIn={weekAvailability.thursdayLiveIn} availabilityType={weekAvailability.thursdayAvailabilityType} {...commonProps} />
                    <DayAvailability day={'Friday'} liveIn={weekAvailability.fridayLiveIn} availabilityType={weekAvailability.fridayAvailabilityType} {...commonProps} />
                    <DayAvailability day={'Saturday'} liveIn={weekAvailability.saturdayLiveIn} availabilityType={weekAvailability.saturdayAvailabilityType} {...commonProps} />
                </Grid>
            </List>

            <NewModal
                btnTitle="Delete"
                title={'Confirm Delete?'}
                height="200px" open={openDelete}
                onClose={() => { setOpenDelete(false); setSelectedWindow(null) }}
                onSave={() => handleDeleteWindow(selectedWindow)}
            >
                <BoldTitle>Are you sure you want to delete this availability window?</BoldTitle>
            </NewModal>

            <NewModal
                height='200px'
                open={modalOpen}
                title={action == 'send' ? 'Send Link?' : action == 'save' ? 'Save Availability' : 'Confirm Availability'}
                btnTitle={action == 'send' ? 'Send' : action == 'save' ? "Save" : 'Confirm'}
                onSave={() => action == 'send' ? sendToCaregiver() : action == 'save' ? save() : confirmCurrentAndReload()}
                onClose={() => handleCloseModal()}
                disabled={timeFetcher.isLoading || isLoading}
            >
                <Box>
                    {action == 'save' && <Bold>You are updating availability work windows. This will update the 'last updated' as current.</Bold>}
                    {action == 'confirm' && <Bold>You are confirming that the current availability is verified and up to date?</Bold>}
                    {action == 'send' && <Bold>Do you want to send a link to the caregiver to update their availability?</Bold>}
                </Box>
            </NewModal>

            {
                !!selectedWindow &&
                <NewModal
                    height='220px'
                    open={openNote}
                    title={'Edit Note'}
                    btnTitle={'Done'}
                    onSave={() => {
                        handleChangeField(modalNoteText, 'note', selectedWindow);
                        setModalNoteText('');
                        setOpenNote(false)
                    }}
                    onClose={() => {
                        setModalNoteText('');
                        setOpenNote(false)
                    }}
                >
                    <Box>
                        <TextField
                            value={modalNoteText}
                            onChange={(e) => { setModalNoteText(e.target.value) }}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            InputProps={{
                                sx: {
                                    padding: 0,
                                    '& .MuiOutlinedInput-input': {
                                        padding: '4px 8px',
                                        fontSize: '14px',
                                        whiteSpace: 'nowrap',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        padding: 0,
                                    },
                                    '& textarea': {
                                        overflow: 'auto',
                                        '&::-webkit-scrollbar': {
                                            width: '6px',
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: '#888',
                                            borderRadius: '2px',
                                        },
                                        '&::-webkit-scrollbar-thumb:hover': {
                                            backgroundColor: '#555',
                                        },
                                        '&::-webkit-scrollbar-button': {
                                            display: 'none',
                                        },
                                    },
                                },
                            }}
                        />
                    </Box>
                </NewModal>
            }
        </Box >
    )
}

const sortWindowsByDate = (a: WorkWindowType, b: WorkWindowType): number => {
    const dateFromA = a.dateFrom ? new Date(a.dateFrom) : null;
    const dateFromB = b.dateFrom ? new Date(b.dateFrom) : null;

    if (dateFromA && dateFromB) {
        return dateFromA.getTime() - dateFromB.getTime();
    } else if (dateFromA) {
        return 1;
    } else if (dateFromB) {
        return -1;
    }

    return 0;
};

const getTimeOfDay = (date: Date | null | undefined): number | null => {
    const result = date instanceof Date && !isNaN(date.getTime())
        ? date.getHours() * 60 + date.getMinutes()
        : null;

    return result;
};
const sortWindowsByTime = (a: WorkWindowType, b: WorkWindowType): number => {
    const timeFromA = getTimeOfDay(a.timeFrom);
    const timeFromB = getTimeOfDay(b.timeFrom);
    let timeToA = getTimeOfDay(a.timeTo);
    let timeToB = getTimeOfDay(b.timeTo);

    if (timeFromA !== null && timeToA !== null && timeFromA > timeToA) {
        timeToA += 24 * 60; // Add 24 hours in minutes
    }

    if (timeFromB !== null && timeToB !== null && timeFromB > timeToB) {
        timeToB += 24 * 60; // Add 24 hours in minutes
    }

    if (timeFromA !== null && timeFromB !== null) {
        if (timeFromA !== timeFromB) {
            return timeFromA - timeFromB;
        }
    } else if (timeFromA !== null) {
        return -1;
    } else if (timeFromB !== null) {
        return 1;
    }

    if (timeToA !== null && timeToB !== null) {
        return timeToA - timeToB;
    } else if (timeToA !== null) {
        return -1;
    } else if (timeToB !== null) {
        return 1;
    }

    return 0;
};

const sortWindows = (a: WorkWindowType, b: WorkWindowType): number => {
    const dateComparison = sortWindowsByDate(a, b);
    if (dateComparison !== 0) {
        return dateComparison;
    }
    return sortWindowsByTime(a, b);
};

export default WorkAvailability