import { Box, FormControlLabel, IconButton, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip } from "@mui/material"
import useFetcher from "../../hooks/useFetcher"
import { useEffect, useState } from "react";
import DataGrid from "../../components/ui/DataGrid";
import { SETTINGS_COLS } from "../../data/GridColumnDefinitions";
import { EditOutlined } from "@mui/icons-material";
import { BoldTitle } from "../../assets/styles/styledComponents";
import { strings } from "../../helper/strings";
import AddFab from "../../components/ui/AddFab";
import NewModal from "../../components/ui/NewModal";
import Loader from "../../components/ui/Loader";
import useAuth from "../../features/authentication/hooks/useAuth";

const SettingsList = () => {
    const fetcher = useFetcher();
    const disciplineFetcher = useFetcher();
    const profilesFetcher = useFetcher();
    const officesFetcher = useFetcher();
    const [settings, setSettings] = useState<any[]>([]);
    const [disciplines, setDisciplines] = useState<any[]>([]);
    const [offices, setOffices] = useState<any[]>([]);
    const [profiles, setProfiles] = useState<any[]>([]);
    const [selectedSetting, setSelectedSetting] = useState<any>(null);
    const [openModal, setOpenModal] = useState(false);
    const [action, setAction] = useState('');
    const { getUser } = useAuth();
    const user = getUser();

    const getSettings = () => {
        fetcher.get('Settings/all2').then((res: any) => {
            setSettings(res.data)
        })
    }

    const getDsiciplines = () => {
        disciplineFetcher.get('Caregiver/disciplines').then((res: any) => {
            const maped = res.data.data.map((item: any) => {
                return { key: item, value: item.toUpperCase() }
            })
            setDisciplines(maped)
        })
    }
    const getProfiles = () => {
        profilesFetcher.get('api/FactorProfile/profiles').then((res: any) => {
            const maped = res.data.data.map((item: any) => {
                return { key: item.factorProfileId.toString(), value: item.name }
            })
            setProfiles(maped)
        })
    }
    const getOffices = () => {
        officesFetcher.get('filter/offices').then((res: any) => {
            const maped = res.data.data.map((item: any) => {
                return { key: item.id.toString(), value: item.officeName }
            })
            setOffices(maped)
        })
    }

    useEffect(() => {
        getSettings()
        getDsiciplines()
        getProfiles()
        getOffices()
    }, [])

    const coordinatorEmailOptions = [
        { value: 'Supervisor' },
        { value: 'Coordinator' }
    ]

    const googleMapsOptions = [
        { value: 'Driving' },
        { value: 'Transit' }
    ]

    const inviteMaxOptions = [
        { value: 'Prevent' },
        { value: 'Warning' },
        { value: 'Allow Admin With Warning' }
    ]

    const getOptions = ((settingsKey: string) => {
        let options = [];
        switch (settingsKey) {
            case 'google_travel_mode':
                options = googleMapsOptions;
                break;
            case 'send_coordinators_emails':
                options = coordinatorEmailOptions;
                break;
            case 'discipline_to_be_staffed':
                options = disciplines;
                break;
            case 'default_factor_settings_profile_id':
                options = profiles;
                break;
            case 'match_selected_offices':
                options = offices;
                break;
            case 'action_when_reaching_max_invites':
                options = inviteMaxOptions;
                break;
        }
        return options
    })

    const handleClickEdit = (params: any) => {
        if (user?.isBetaUser) {
            setAction('Edit')
            if (params.editorType === 'Multi' && !Array.isArray(params.value)) {
                params.value = params.value.split(', ')
            }
            setSelectedSetting(params)
            setOpenModal(true)
        }
    }

    const handleClickAdd = () => {
        setAction('Add')
        setSelectedSetting({ name: '', description: '', value: null, dataType: '' })
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setAction('')
        setSelectedSetting(null)
        setOpenModal(false)
    }

    const handleChangeField = (value: any, field?: string) => {
        setSelectedSetting({
            ...selectedSetting,
            [field || 'value']: value
        })
    }

    const handleSave = () => {
        handleCloseModal()
        if (selectedSetting.editorType == 'Multi' && Array.isArray(selectedSetting.value)) {
            selectedSetting.value = selectedSetting.value.join(', ');
        }

        fetcher.post('Settings/update', selectedSetting).then((res) => {
            getSettings();
        })
    }

    const getSettingsCols = () => {
        return [
            ...SETTINGS_COLS,
            {
                field: 'value', headerName: 'Value', renderCell: ((params: any) => {
                    if (params.row.value == 'true')
                        return 'Yes'
                    if (params.row.value == 'false')
                        return 'No'
                    if (typeof params?.row?.value === 'string')
                        return strings.toTitleCase(params?.row?.value || '')
                })
            },
            {
                field: "actions", renderCell: (params: any) => (
                    <Tooltip title='Edit Setting'>
                        <IconButton disabled={!user?.isBetaUser} onClick={() => handleClickEdit(params.row)}>
                            <EditOutlined fontSize="small" />
                        </IconButton>
                    </Tooltip>
                )
            },
        ]
    }

    return (
        <Box>
            <Loader isLoading={fetcher.isLoading} />
            <div style={{ marginTop: '40px' }}>
                <DataGrid
                    title="Settings"
                    data={{
                        columns: getSettingsCols(),
                        rows: settings
                    }}
                    options={{
                        hideExport: true,
                        hideFiltering: true,
                        hideColumns: true,
                    }}
                />
            </div>

            {/* <AddFab toolTip={'Add Setting'} clickAction={handleClickAdd} /> */}

            <NewModal
                open={openModal}
                height={action == 'Add' ? '400px' : '300px'}
                title={`${action} Setting`}
                onClose={() => handleCloseModal()}
                onSave={() => handleSave()}
            >
                <Box>
                    {action == 'Edit' &&
                        <div>
                            <BoldTitle fontSize='20px'>{strings.toTitleCase(selectedSetting?.name || '')}</BoldTitle>
                            <BoldTitle>Description</BoldTitle>
                            <TextField
                                onChange={(e) => handleChangeField(e.target.value, 'description')}
                                size="small"
                                fullWidth
                                sx={{ paddingBottom: '20px' }}
                                value={selectedSetting?.description}
                            />
                        </div>
                    }
                    {action == 'Add' &&
                        <div>
                            <BoldTitle>Name</BoldTitle>
                            <TextField
                                onChange={(e) => handleChangeField(e.target.value, 'name')}
                                size="small"
                                fullWidth
                                value={selectedSetting?.name}
                            />
                            <BoldTitle>Description</BoldTitle>
                            <TextField
                                onChange={(e) => handleChangeField(e.target.value, 'description')}
                                size="small"
                                fullWidth
                                value={selectedSetting?.description}
                            />
                            <BoldTitle>Value</BoldTitle>
                            <RadioGroup row value={selectedSetting.dataType} onChange={(e) => handleChangeField(e.target.value, 'dataType')}>
                                <FormControlLabel value={'String'} label={'Text'} control={<Radio />} />
                                <FormControlLabel value={'Int'} label={'Number'} control={<Radio />} />
                                <FormControlLabel value={'Boolean'} label={'Boolean'} control={<Radio />} />
                            </RadioGroup>
                        </div>
                    }
                    {selectedSetting?.editorType == 'Int' &&
                        <>
                            <BoldTitle>Enter Amount</BoldTitle>
                            <TextField
                                onChange={(e) => handleChangeField(e.target.value)}
                                size="small"
                                fullWidth
                                type="number"
                                defaultValue={selectedSetting?.value}
                            />
                        </>
                    }
                    {selectedSetting?.editorType == 'String' &&
                        <>
                            <BoldTitle>Enter Value</BoldTitle>
                            <TextField
                                onChange={(e) => handleChangeField(e.target.value)}
                                size="small"
                                fullWidth
                                type="text"
                                defaultValue={selectedSetting?.value}
                                helperText='Enter values commma separated'
                            />
                        </>
                    }
                    {selectedSetting?.editorType == 'Boolean' &&
                        <>
                            <BoldTitle>Select Value</BoldTitle>
                            <Select
                                defaultValue={selectedSetting?.value}
                                onChange={(e) => handleChangeField(e.target.value)}
                                size="small"
                                fullWidth
                            >
                                <MenuItem key={'true'} value={'true'}>Yes</MenuItem>
                                <MenuItem key={'false'} value={'false'}>No</MenuItem>
                            </Select>
                        </>
                    }

                    {selectedSetting?.editorType == 'Select' &&
                        <>
                            <BoldTitle>Select Value</BoldTitle>
                            <Select
                                defaultValue={selectedSetting?.value}
                                onChange={(e) => handleChangeField(e.target.value)}
                                size="small"
                                fullWidth
                            >
                                {getOptions(selectedSetting.key)?.map((value: any) =>
                                    <MenuItem key={value.key ?? value.value} value={value.key?.toLowerCase() ?? value.value?.toLowerCase()}>{value.value}</MenuItem>
                                )}
                            </Select>
                        </>
                    }
                    {selectedSetting?.editorType == 'Multi' &&
                        <>
                            <BoldTitle>Select Value/s</BoldTitle>

                            <Select
                                multiple
                                value={selectedSetting.value}
                                defaultValue={selectedSetting?.value}
                                onChange={(e) => handleChangeField(e.target.value)}
                                size="small"
                                fullWidth
                            >
                                {getOptions(selectedSetting.key)?.map((value: any) =>
                                    <MenuItem key={value.key ?? value.value} value={value.key?.toLowerCase() ?? value.value?.toLowerCase()}>{value.value}</MenuItem>
                                )}
                            </Select>
                        </>
                    }
                </Box>
            </NewModal >
        </Box >
    )
}

export default SettingsList