import React from 'react';
import { TextField, MenuItem, TextFieldProps } from '@mui/material';

type MenuOption = {
  key: string;
  value: string;
};

const genders: MenuOption[] = [
  { key: 'Male', value: 'Male' },
  { key: 'Female', value: 'Female' },
  { key: 'Other', value: 'Other' }
];

const GenderField: React.FC<TextFieldProps> = (props) => {
  return (
    <TextField
      label="Gender"
      select
      fullWidth
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
    >
      {genders.map((gender) => (
        <MenuItem key={gender.key} value={gender.value}>
          {gender.value}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default GenderField;