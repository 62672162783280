import React from 'react'
import {MuiButton} from '../../lib/MUI/mui.components';
import ReactLoader from '../common/ReactLoader';

type ButtonProps = {
  id?: string | number | any,
  label: string,
  variant?: string | any,
  disable?: boolean,
  type?: "button" | "submit" | "reset" | undefined,
  color?: string | any,
  size?: string | any,
  fullWidth?: boolean, 
  isLoading?: boolean, 
  ariaControls?: string | any,
  ariaHaspopup? : string | any,
  ariaExpanded? : string | any,
  onClick: Function,
  style?: any
}

const Button: React.FC<ButtonProps> = (props) => {
  const {label, id, variant, disable, isLoading= false, color, size, ariaControls, type, ariaHaspopup, ariaExpanded, onClick, style} = props;
  return (
    <MuiButton sx={{...style}} id={id} type={type} aria-controls={ariaControls} aria-haspopup={ariaHaspopup} aria-expanded={ariaExpanded} variant={variant} disabled={disable} size={size} color={color} onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => 
      {
        if (!isLoading) onClick(e)
      }}>
      <ReactLoader height='24' width='24' isLoading={isLoading}/>
      {label}
    </MuiButton>
  )
}

export default Button
