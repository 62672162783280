import { Box, Grid, Typography } from '@mui/material';
import FeaturedIcon from '../../components/common/FeaturedIcon';
import { useEffect } from 'react';
import GeneralPreferenceField from './GeneralPreferencesField';
import CommutePreferenceField from './CommutePreferenceField';

interface CommutePreferencesBoxProps {
  personalPreferences: {
    commuteByCar_MaxMinutes?: number;
    commuteByCar_PreferenceLevel?: string;
    commuteByPublicTransit_MaxMinutes?: number;
    commuteByPublicTransit_PreferenceLevel?: string;
    commuteByWalk_MaxMinutes?: number;
    commuteByWalk_PreferenceLevel?: string;
    commuteByBike_MaxMinutes?: number;
    commuteByBike_PreferenceLevel?: string;
  };
  setOpen: (open: boolean) => void;
}

const CommutePreferencesBox: React.FC<CommutePreferencesBoxProps> = ({ personalPreferences, setOpen }) => {
  return (
    <div style={{ border: '1px solid #ccc', borderRadius: '3px', padding: '10px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center" style={{ borderBottom: '1px solid #d1d1d1', paddingBottom: '5px' }}>
            <Grid item xs={2}>
              {/* Placeholder for left-aligned content */}
            </Grid>
            <Grid item xs={7.5}>
              <Typography variant="subtitle1" align="center" color="black" fontSize={'16px'} style={{ fontWeight: 'bold' }}>
                Commuting Preferences
              </Typography>
            </Grid>
            <Grid item xs={2.5}>
              <FeaturedIcon icon="Edit" fontSize="small" onClick={() => setOpen(true)} />
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ marginLeft: 2, marginTop: 1, width: '100%' }}>
          <Grid container spacing={1}>
            <CommutePreferenceField
              label='Public Transit'
              preferenceLevel={personalPreferences?.commuteByPublicTransit_PreferenceLevel}
              maxMinutes={personalPreferences?.commuteByPublicTransit_MaxMinutes}
            />
            <CommutePreferenceField
              label='Car'
              preferenceLevel={personalPreferences?.commuteByCar_PreferenceLevel}
              maxMinutes={personalPreferences?.commuteByCar_MaxMinutes}
            />
            <CommutePreferenceField
              label='Walk'
              preferenceLevel={personalPreferences?.commuteByWalk_PreferenceLevel}
              maxMinutes={personalPreferences?.commuteByWalk_MaxMinutes}
            />
            <CommutePreferenceField
              label='Bike'
              preferenceLevel={personalPreferences?.commuteByBike_PreferenceLevel}
              maxMinutes={personalPreferences?.commuteByBike_MaxMinutes}
            />
          </Grid>
        </Box>
      </Grid>
    </div>
  );
};

export default CommutePreferencesBox;