import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import NewModal from '../../components/ui/NewModal';
import useFetcher from '../../hooks/useFetcher';
import { toast } from 'react-toastify';
import EditCommutePreferenceField from './EditCommutePreferenceField';
import Loader from '../../components/ui/Loader';
import { useLocation } from 'react-router-dom';
import { prefLevelValue } from './types';

export interface CommutePreferences {
    commuteByCar_MaxMinutes: number;
    commuteByCar_PreferenceLevel: prefLevelValue;
    commuteByPublicTransit_MaxMinutes: number;
    commuteByPublicTransit_PreferenceLevel: prefLevelValue;
    commuteByWalk_MaxMinutes: number;
    commuteByWalk_PreferenceLevel: prefLevelValue;
    commuteByBike_MaxMinutes: number;
    commuteByBike_PreferenceLevel: prefLevelValue;
}

interface EditCommutePreferencesDialogProps {
    open: boolean;
    onClose: () => void;
    commutePreferences: CommutePreferences;
    onUpdate: (updatedPreferences: CommutePreferences) => void; // Renamed to onUpdate
    refModule: string;
    refRowId: string;
}

const EditCommutePreferences: React.FC<EditCommutePreferencesDialogProps> = ({
    open,
    onClose,
    commutePreferences,
    onUpdate, // Renamed prop
    refModule,
    refRowId,
}) => {
    const [localPreferences, setLocalPreferences] = useState(commutePreferences); // Local state for editing
    const [isUpdating, setIsUpdating] = useState(false);

    // params
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const token = queryParams.get('token');

    const preferenceFetcher = useFetcher(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, token);

    useEffect(() => {
        // Initialize local state from props whenever props change
        setLocalPreferences(commutePreferences);
    }, [commutePreferences]);

    const handleSave = async () => {
        setIsUpdating(true);

        try {
            const response = await preferenceFetcher.post(`preference/personal/${refModule}/${refRowId}`, localPreferences);
            onUpdate(response.data); // Update prop after successful save
            toast.success("Saved");
            onClose();
        } catch (err) {
            console.error('Error updating preferences:', err);
        } finally {
            setIsUpdating(false);
        }
    };

    const handleMaxMinutesChange = (field: keyof CommutePreferences, value: number) => {
        const updated: CommutePreferences = {
            ...localPreferences,
            // [field]: value
            [field]: isNaN(value) || value < 1441 ? value : 1440
        };
        setLocalPreferences(updated);
    };

    const handlePreferenceLevelChange = (field: keyof CommutePreferences, value: prefLevelValue) => {
        const updated: CommutePreferences = {
            ...localPreferences,
            [field]: value
        };
        setLocalPreferences(updated);
    };

    return (
        <NewModal title="Edit Commute Preferences" open={open} onClose={onClose} onSave={handleSave} height='400px'>
            <Loader isLoading={preferenceFetcher.isLoading} />
            <Grid container spacing={2}>
                <EditCommutePreferenceField
                    label="Public Transit"
                    maxMinutes={localPreferences.commuteByPublicTransit_MaxMinutes}
                    preferenceLevel={localPreferences.commuteByPublicTransit_PreferenceLevel}
                    onMaxMinutesChange={(value) => handleMaxMinutesChange('commuteByPublicTransit_MaxMinutes', value)}
                    onPreferenceLevelChange={(value) => handlePreferenceLevelChange('commuteByPublicTransit_PreferenceLevel', value)}
                />
                <EditCommutePreferenceField
                    label="Car"
                    maxMinutes={localPreferences.commuteByCar_MaxMinutes}
                    preferenceLevel={localPreferences.commuteByCar_PreferenceLevel}
                    onMaxMinutesChange={(value) => handleMaxMinutesChange('commuteByCar_MaxMinutes', value)}
                    onPreferenceLevelChange={(value) => handlePreferenceLevelChange('commuteByCar_PreferenceLevel', value)}
                />
                <EditCommutePreferenceField
                    label="Walk"
                    maxMinutes={localPreferences.commuteByWalk_MaxMinutes}
                    preferenceLevel={localPreferences.commuteByWalk_PreferenceLevel}
                    onMaxMinutesChange={(value) => handleMaxMinutesChange('commuteByWalk_MaxMinutes', value)}
                    onPreferenceLevelChange={(value) => handlePreferenceLevelChange('commuteByWalk_PreferenceLevel', value)}
                />
                <EditCommutePreferenceField
                    label="Bike"
                    maxMinutes={localPreferences.commuteByBike_MaxMinutes}
                    preferenceLevel={localPreferences.commuteByBike_PreferenceLevel}
                    onMaxMinutesChange={(value) => handleMaxMinutesChange('commuteByBike_MaxMinutes', value)}
                    onPreferenceLevelChange={(value) => handlePreferenceLevelChange('commuteByBike_PreferenceLevel', value)}
                />
            </Grid>
        </NewModal>
    );
};

export default EditCommutePreferences;