import { useState, useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { toast } from 'react-toastify';
import useAuth from '../features/authentication/hooks/useAuth';
import axios from 'axios';
import { BASE_URL } from '../data/constants';
import useFetcher from './useFetcher';

const useApi_DEPRACATED = (module: string, serverUrl?: string, refId?: number | string | undefined, token?: string | null) => {
    const defData = { entity: null, list: null };

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState<any>(null);
    const [data, setData] = useState<any>(defData);

    const handleError = useErrorHandler()
    const { getUser, logoutUser } = useAuth();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Fetcher
    const fetcher = useFetcher(undefined, undefined, undefined, undefined, undefined, undefined, serverUrl, undefined, undefined, token);
    const requestAccessFetcher = useFetcher(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, token);
    //https://localhost:7007/clients
    const controller = `${module}`;

    const executePromise = (promise: Promise<any>) => {
        setIsLoading(true);
        return promise
            .then((res) => {
                const resData = res.data.data ?? res.data;
                // setData(resData);
                setIsLoading(false);
                return res;
            })
            .catch((err) => {
                if (err.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    let statusClass = err.response.headers.get("X-Status-Class");
                    if (statusClass == "IpAddressNotRegistered") {
                        setIsLoading(false)
                        let email = prompt("Access Denied!\nYour Ip Address Is Not Authorized.\nPlease Enter Your Email To Request Access.")
                        if (email !== null) {
                            if (emailRegex.test(email ?? "") === false) {
                                alert(`You have not entered a valid email`)
                                //throw err
                            }
                            requestAccessFetcher.post(BASE_URL + `api/Auth/request_access?email=${email}&ip=${err.response.headers.get('X-Client-Ip')}`, null);
                            //axios.post(BASE_URL + `api/Auth/request_access?email=${email}&ip=${err.response.headers.get('X-Client-Ip')}`);
                        }
                        throw err;
                    }
                    if (statusClass == "UserNotFound" || statusClass == "IncorrectPassword") {

                        setIsLoading(false)
                        showToast("These Credentials Don't Match")
                        //throw err
                    }

                    // console.log('%c' + err.response.data, 'background: #222; color: #FFF');
                    // console.log('%cStatus:' + err.response.status, 'background: #222; color: #FFF');
                    // console.log(err.response.headers);
                } else if (err.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    // console.log(err.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', err.message);
                }
                // console.log(err.config);

                if (err.code === 'ERR_ABORTED' || err.code === 'ERR_CANCELED') {
                    return { data: { list: [] } };
                }
                setErrors(err);
                setIsLoading(false);

                const token = getUser()?.token;
                // if (!!token) {
                //     // try to get any test data
                //     testFetch.get('api/validate-token').then((r) => {
                //         const d = r.data;
                //         if (d == true) {
                //             // if success, just ignore and return
                //             return
                //         }
                //     });
                //     // try to reauthenticate by refresh token and then do the same
                // } 

                showToast("An error has occurred, if it continues, please try logout and log back in.")
                //handleError(err)
                //logoutUser();
                //throw err;
            });
    };

    const withRefId = () => {
        return (refId ? "/" + refId : "");
    }
    const getList = (query: string = "") => {
        return executePromise(fetcher.get(controller + query + withRefId())).then((res) => {
            const resData = res.data.data ?? res.data;
            setData({ ...data, list: resData });
            return resData;
        });
    };

    const get = (id: number | string) => {
        return executePromise(fetcher.get(`${controller + withRefId()}/${id}`)).then((res) => {
            const resData = res.data.data ?? res.data;
            setData({ ...data, entity: resData });
            return resData;
        });
    };

    const create = (payload: any, query = "") => {
        return executePromise(fetcher.post(controller + query, payload)).then((res) => {
            toast.success('Success !', {
                position: toast.POSITION.TOP_CENTER
            });
            const resData = res.data.data ?? res.data;
            return resData;
        });
    };

    const update = (id: number | string, payload: any) => {
        return executePromise(fetcher.put(`${controller + withRefId()}/${id}`, payload)).then((res) => {
            const resData = res.data.data ?? res.data;
            toast.success('Success !', {
                position: toast.POSITION.TOP_CENTER
            });
            return resData;
        });
    };

    const remove = (id: number | string) => {
        return executePromise(fetcher.delete(`${controller + withRefId()}/${id}`)).then((res) => {
            toast.success('Success !', {
                position: toast.POSITION.TOP_CENTER
            });
            const resData = res.data.data ?? res.data;
            return resData;
        });
    };
    const abort = () => {
        fetcher.abort();
    }
    const updateList = (list: any) => {
        setData({ ...data, list: { data: { matches: list } } });
    }
    const clearData = () => {
        setData(defData);
    }

    // matches related, NEED TO REFACTOR
    const removeVisitMatch = (caregiverId: number) => {
        if (!!data.list.matches) {
            const listMatches = data.list.matches.filter((item: any) => item.caregiver.caregiverId !== caregiverId);
            updateList(listMatches);
        } else if (!!data.list.data.matches) {
            const listMatches = data.list.data.matches.filter((item: any) => item.caregiver.caregiverId !== caregiverId);
            updateList(listMatches);
        } else if (!!data.list.data) {
            const listMatches = data.list.data.filter((item: any) => item.caregiver.caregiverId !== caregiverId);
            updateList(listMatches);
        } else {
            const listMatches = data.list.filter((item: any) => item.caregiver.caregiverId !== caregiverId);
            updateList(listMatches);
        }
    }
    const removeCaregiverMatch = (visitId: number) => {
        if (!!data.list.matches) {
            const listMatches = data.list.matches.filter((item: any) => item.visit.visitId !== visitId);
            updateList(listMatches);
        } else {
            const listMatches = data.list.data.matches.filter((item: any) => item.visit.visitId !== visitId);
            updateList(listMatches);
        }
    }

    const showToast = (msg: string) => {
        toast.error(msg,
            {
                autoClose: 2400,
                pauseOnHover: false,
                hideProgressBar: true,
                closeOnClick: true,
                position: toast.POSITION.TOP_CENTER
            })
    }

    return {
        getList,
        get,
        create,
        update,
        remove,
        abort,
        errors,
        isLoading,
        data,
        clearData,

        // matches related NEED TO REFACTOR
        removeVisitMatch,
        removeCaregiverMatch,
    };
};

export default useApi_DEPRACATED;


//create me a react hook that I can pass just a module name, such as 'clients' or 'employees',
//and it handles all crud operations according to rest api rules, like for getting a list of clients
//the url will be 'https://localhost:7007/clients', for getting a client it will be
//'https://localhost:7007/clients/{id}', for saving post/put it will be with a payload,
//the url should be as most reusable as possible not hard coded every time again, and then the
//hook should return properties like getList, get, create, update, delete, and properties like fetchList,
//fetchGet, fetchCreate, etc. it should also return a property errors, and an 'isLoading' property,
//it should use axios for the api calls, and write the code in typescript, and it should be reusable
//the 'then' and 'catch' parts reusable, such as creating a separate executePromise function