import React, { useState, useEffect } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

interface EmailFieldProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const EmailField: React.FC<EmailFieldProps> = ({ value = '', onChange, ...props }) => {
  const [email, setEmail] = useState(value);
  const [error, setError] = useState(false);

  useEffect(() => {
    setEmail(value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setEmail(newValue);
    setError(!validateEmail(newValue));

    if (onChange) {
      onChange(event);
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <TextField
      label="Email"
      placeholder="Enter email"
      fullWidth
      variant="outlined"
      value={email}
      onChange={handleChange}
      error={error}
      helperText={error ? "Please enter a valid email address" : ""}
      {...props}
    />
  );
};

export default EmailField;