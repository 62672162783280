import { useMediaQuery, useTheme } from "@mui/material";

const useScreenSizes = () => {
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const isMd = useMediaQuery(theme.breakpoints.only('md'));
    const isLg = useMediaQuery(theme.breakpoints.only('lg'));
    const isXl = useMediaQuery(theme.breakpoints.only('xl'));
    const isLgPlus = isLg || isXl;
    const isMdPlus = isMd || isLgPlus;
    const isSmPlus = isSm || isMdPlus;
    const isXsPlus = isXs || isSmPlus;

    const hookHelper = {
        isXs, isSm, isMd, isLg, isXl,
        isLgPlus, isMdPlus, isSmPlus, isXsPlus
    }

    return hookHelper;
}

export default useScreenSizes;