export const strings = {
    toTitleCase,
    firstLetterLowerCase,
    firstLetterUpperCase,
    getRichTextObjFromString,
    getStringFromRichTextObj,
    parseStringToObj,
    parseObjToString,
    removeEmptySpaces,
    convertArrToString,
    convertStringToArr,
    isValidJSON,
    convertToMinutes
}

function toTitleCase(str: string): string {
    return str.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
}

function isValidJSON(text: string): boolean {
    try {
        JSON.parse(text);
        return true;
    } catch {
        console.log('FALSE JSON');
        return false;
    }
}

function firstLetterLowerCase(string: string): string {
    if (!string) return string;
    return string.charAt(0).toLowerCase() + string.slice(1);
}

function firstLetterUpperCase(string: string): string {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
}

interface RichText {
    value: string;
    formatting: any;
}

function getRichTextObjFromString(str: string): RichText {
    let result: RichText = { value: "", formatting: {} };
    if (!str) {
        return result;
    }
    const formattingEnd = str.toString().indexOf('*');

    if (formattingEnd < 0 || !isValidJSON(str.substring(0, formattingEnd))) {
        result.formatting = {};
        result.value = str;
    } else {
        result.formatting = JSON.parse(str.substring(0, formattingEnd));
        result.value = str.substring(formattingEnd + 1);
    }
    return result;
}

function getStringFromRichTextObj(richtextObj: RichText): string {
    const formattingStr = JSON.stringify(richtextObj.formatting);
    const value = richtextObj.value ? richtextObj.value : '';
    return formattingStr + '*' + value;
}

function parseStringToObj(filterStr: string, propSep: string, valueSep: string): { [key: string]: string } {
    return filterStr.split(propSep).reduce((obj, str) => {
        let strParts = str.split(valueSep);
        if (strParts[0] && strParts[1]) {
            obj[strParts[0].replace(/\s+/g, '')] = strParts[1].trim();
        }
        return obj;
    }, {} as { [key: string]: string });
}

function parseObjToString(filterObj: { [key: string]: any }, propSep: string, valueSep: string): string {
    let strRes = "";
    for (const property in filterObj) {
        strRes = !!strRes
            ? `${strRes + propSep}`.concat(`${property + valueSep}${filterObj[property]}`)
            : "".concat(`${property}${valueSep}${filterObj[property]}`);
    }
    return strRes;
}

function removeEmptySpaces(str: string): string {
    return str.replace(/\s/gm, '');
}

function convertArrToString(arr: any[], separator: string = ","): string {
    return arr.join(separator);
}

function convertStringToArr(str: string, separator: string = ","): string[] {
    return str.split(separator);
}

function convertToMinutes(time: string): number {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
}

export const multiplyString = (str: string, num: number): string => {
    if (num === 0) {
        return "";
    }
    let result = "";
    for (let i = 0; i < num; i++) {
        result += str;
    }
    return result;
}
export const space = (num: number) => {
    const emptyChar = multiplyString('‍‍ ‍', 1);
    return multiplyString(emptyChar, num);
}