import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Button from '../../ui/Button';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import NewModal from '../../ui/NewModal';
import { Button as MuiButton, Box } from '@mui/material';
import { Bold } from '../../../assets/styles/styledComponents';
import { useState } from 'react';


const ImportCard = (props: any) => {
    const [modalOpen, setModalOpen] = useState(false);
    return (
        <div style={{ width: '350px', display: 'block', margin: 'auto' }}>
            <div>
                {!props.label.includes("Date") && <TextField
                    size='small'
                    margin='dense'
                    disabled={props.label.includes("All") || props.label.includes("Date")}
                    helperText={(props.label.includes("All") ? " " : `Enter HHA ${props.type.replace("Post ", "")} Id`)}
                    value={props.importId}
                    label={props.label}
                    onChange={(e: any) => props.handleChange(props.set, e.target.value)}
                />}
                {props.label.includes("Date") &&

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Select Date"
                            value={props.importId}
                            disablePast
                            onChange={(newValue) => {
                                props.set(newValue as Date);
                            }}
                            renderInput={(params: any) => <TextField sx={{
                                mt: 1, mb: 2,
                                '& .MuiOutlinedInput-input': {
                                    padding: '4px 10px',
                                }
                            }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                {...params} helperText={null} />}
                        />
                    </LocalizationProvider>}
                <Button
                    disable={!props.label.includes("Date") && !props.label.includes("All") && (props.importId == null || props.importId == '')}
                    label={'Sync'}
                    onClick={() => props.label.includes("Post") ? setModalOpen(true) : props.handleImport(props.label, props.importId, props.set, props.type)}
                    variant="contained"
                    isLoading={props.isLoading}
                    style={{ float: 'right', margin: !props.label.includes("Date") ? "8px 0px 3px 0px" : "7px 0px 7px 0px" }}
                />
            </div>
            <div>
                {props.label.includes("Post") && <NewModal
                    open={modalOpen}
                    title={"PLEASE CONFIRM"}
                    btnTitle="Post"
                    height="250px"
                    hideBtn={true}
                    onSave={() => props.handleImport(props.label, props.importId, props.set, props.type)}
                    onClose={() => setModalOpen(false)}
                >
                    <Box>
                        <div>
                            <Bold fontFamily={'ConnectAideSecondary'} fontSize="20px" >
                                Do you want to post ALL Availabilities to HHAX?
                            </Bold>
                            <p>
                                All your availability data in HHAX will be updated and replaced with the data you have in ConnectAide, are you sure you want to run this process?
                            </p>
                        </div>
                        <div style={{ position: 'absolute', bottom: '0px', right: '0px' }}>
                            <MuiButton
                                onClick={() => props.handleImport(props.label, props.importId, props.set, props.type)}
                                variant='outlined'
                                sx={{
                                    float: 'right',
                                    margin: '10px'
                                }}
                            >
                                {'Confirm'}
                            </MuiButton>
                        </div>
                    </Box>
                </NewModal>}
            </div>
        </div>
    )
}

export default ImportCard