import axios from 'axios';
import { ToastOptions, toast } from 'react-toastify';
import { getUser, getUrl } from './fetchHelpers'

const user = getUser();

const axiosClient = axios.create({
    baseURL: getUrl() + 'api',
});

axiosClient.defaults.headers.common["Authorization"] = `Bearer ${user?.token}`;

axiosClient.interceptors.response.use(
	(response) => response,
	(error) => {
		handleError(error);
		return Promise.reject(error);
	}
);

console.debug(["user", user]);

const handleError = (error: Error) => {
	const options : ToastOptions = {
		autoClose: 2400,
		pauseOnHover: true,
		hideProgressBar: false,
		closeOnClick: true,
		draggable: true,
		position: toast.POSITION.TOP_CENTER,
	};

	console.error(error);
	toast.error(error.message, options);
};

export default axiosClient;