import React from 'react';
import { Box, Typography, Paper, Skeleton } from '@mui/material';
import PieChart from './PieChart'; // Assuming PieChart is a separate component
import { ApexOptions } from "apexcharts";

interface DashboardMetricCardProps {
    title: string;
    secondaryTextTop?: string;
    value: string | number;
    secondaryTextBottom?: string;
    chartData: number[];
    chartOptions: ApexOptions; // Import ApexOptions from 'apexcharts'
    isLoading?: boolean;
}

const DashboardMetricCard: React.FC<DashboardMetricCardProps> = ({
    title,
    secondaryTextTop,
    value,
    secondaryTextBottom,
    chartData,
    chartOptions,
    isLoading
}) => {

    const LoadingSkeleton = () => {
        return (
            <div>
                <Skeleton variant='rounded' width={100} />
                <Skeleton variant='rounded' width={80} sx={{ marginTop: '2%' }} />
                <Skeleton variant='rounded' width={120} sx={{ marginTop: '2%' }} />
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10%' }}>
                    <Skeleton animation='wave' sx={{}} variant='circular' width={225} height={225} />
                </div>
            </div>
        )
    }

    return (
        <Paper elevation={4} sx={{ borderRadius: '10px', backgroundColor: 'white', padding: '10px' }}>
            {isLoading ? <LoadingSkeleton /> :
                <>
                    <Box>
                        <Typography variant="h4" component="h4" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                            {title}
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: '12px' }}>{secondaryTextTop || "-"}</Typography>
                        <Typography variant="body1" sx={{ fontSize: '17px' }}>{value}</Typography>
                        {secondaryTextBottom && (
                            <Typography variant="body2" sx={{ fontSize: '12px' }}>{secondaryTextBottom}</Typography>
                        )}
                    </Box>
                    <Box>
                        <PieChart
                            chartData={chartData}
                            chartOptions={chartOptions}
                        />
                    </Box>
                </>}
        </Paper>
    );
};

export default DashboardMetricCard;