export const numbers = {
    isConsecutive,
    getOrdinalString,
    isEven,
    isOdd,
    aIsDivisibleByB,
    getLargestNumber,
    getSmallestNumber,
    largerNumberIsDevisableBySmallerNumber,
    numbersHaveDifferentParity,
    tryParseInt,
    addZeroes,
    isWholeInt,
    isNumeric,
    isNumericOrEmpty,
    roundNumber
}

global.numbers = numbers;

function isConsecutive(array) {
    var max = Math.max(...array);
    var min = Math.min(...array);
    for (var i = min; i < max; i++) {
        if (array.includes(i) == false) {
            return false;
        }
    }
    return true;
}

/**
 * @param {Integer} number      
 * @returns {string}
 */
function getOrdinalString(number) {
    const ordinalRules = new Intl.PluralRules('en', {type: "ordinal"});

    const suffixes = {
        one: 'st',
        two: 'nd',
        few: 'rd',
        other: 'th'
    };

    const suffix = suffixes[ordinalRules.select(number)];
    return (number + suffix);
}

function isEven(number){
    return number % 2 === 0;
}

function isOdd(number){
    return number % 2 !== 0;
}

/**
 * function to determine whether inputted numbers are a mix of both odd and even
 * @param {number[]} numbers 
 * @return {bool}
 */
 function numbersHaveDifferentParity(numbers){
    let someEven = false;
    let someOdd = false;

    return numbers.some(number => {
        if(isEven(number)){
            someEven = true;
        } else {
            someOdd = true;
        }

        return someEven && someOdd;
    });
}

/**
 * 
 * @param {number} a 
 * @param {number} b 
 */
function aIsDivisibleByB(a, b) {
    return a % b == 0;
}

function largerNumberIsDevisableBySmallerNumber(a, b){
    return aIsDivisibleByB(getLargestNumber([a, b]) ?? 0, getSmallestNumber([a, b]) ?? 1);
}

/**
 * 
 * @param {number[]} numbers
 */
function getLargestNumber(numbers){
    if(numbers.length == 0){
        return null;
    }

    let largestNumber = 0;
    numbers.forEach(number => {
        largestNumber = largestNumber > number ? largestNumber : number;
    });

    return largestNumber;
}

/**
 * 
 * @param {number[]} numbers
 */
function getSmallestNumber(numbers){
    if(numbers.length == 0){
        return null;
    }

    let smallestNumber = 0;
    numbers.forEach(number => {
        smallestNumber = smallestNumber < number ? smallestNumber : number;
    });

    return smallestNumber;
}

function isNumeric(str) {
    //if (typeof str != "string") return false // we only process strings!  
    str = str.toString();
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

function isNumericOrEmpty(str) {
    return isNumeric(str) || str == undefined || str == null || str.toString().trim() == "";
}


function tryParseInt(str, defaultValue, radix = 10) {
    let retValue = defaultValue;

    if(str !== null) {
        if(str.length > 0) {
            if (!isNaN(str)) {
                retValue = parseInt(str, radix);
            }
        }
    }
    return retValue;
}

/**
 * add two decimal zeros when the decimal value has less than two digits
 * @param {number} value
 * @return {number}
 */
function addZeroes(num) {
    // Convert input to a number and store as a variable.
    var value = Number(num);
    // Convert input to a string
    var numStr = String(num);
    // Split the input string into two arrays containing integers/decimals
    var res = numStr.includes(".") ? numStr.split(".") : [numStr, "0"];
    // If there is no decimal point or only one decimal place found.
    if (res.length == 1 || res[1].length < 3) {
        // Set the number to two decimal places
        value = value.toFixed(2);
    }
    // Return updated or original number.
    return value;
}

function isWholeInt(val) {
    if (!val) return false;
    let intVal = parseInt(val);
    if (isNaN(intVal))
        return false;
    let isFloat = val.toString().indexOf('.') !== -1;
    return !isFloat;
}

function roundNumber(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}