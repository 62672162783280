import React from 'react'
import {MuiCard} from '../../../lib/MUI/mui.components';

const Card = (props: any) => {
  return (
    <MuiCard {...props}>
        {props.children}
    </MuiCard>
  )
}

export default Card