import { Close, DirectionsCarOutlined } from "@mui/icons-material"
import { Box, IconButton, List, ListItem, ListItemText, Paper, Typography } from "@mui/material"
import { Bold, BoldTitle } from "../../assets/styles/styledComponents"
import { useEffect, useState } from "react"
import axios from "axios"
import { MAPBOX_TOKEN } from "../../data/constants"

type DirectionsProps = {
    LocationA: any,
    LocationB: any,
    LocationAddressA: any,
    LocationAddressB: any,
    closeDirections: any
}

const DirectionsBar = ({ LocationA, LocationB, LocationAddressA, LocationAddressB, closeDirections }: DirectionsProps) => {
    const [mapDirections, setMapDirections] = useState<any>(null)

    useEffect(() => {
        if (LocationA[0] != '' && LocationB[0] != '') {
            axios.get(`https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${LocationA[0]},${LocationA[1]};${LocationB[0]},${LocationB[1]}?geometries=geojson&access_token=${MAPBOX_TOKEN}`).then((res: any) => {
                setMapDirections(res.data)
            })
        } else {
            setMapDirections(null)
        }
    }, [LocationA, LocationB])

    const getTravelTime = (time: any) => {
        if (isNaN(time))
            return 0

        const formatted = Math.round(time / 60);

        return formatted
    }

    const convertMinutesToHoursAndMinutes = (totalMinutes: number): { hours: number; remainingMinutes: number } => {
        const hours = Math.floor(totalMinutes / 60);
        const remainingMinutes = Math.round(totalMinutes % 60);

        return { hours, remainingMinutes };
    };

    const getOrigin = () => {
        return LocationAddressA != null ? LocationAddressA.addressLine1 == undefined ? LocationAddressA :
            `${LocationAddressA.addressLine1} ${LocationAddressA.addressLine2}, ${LocationAddressA.city} ${LocationAddressA.state}, ${LocationAddressA.zipCode}`
            : `${LocationA[0].toString()} ${LocationA[1].toString()}`
    }

    const getDestination = () => {
        return LocationAddressB != null ? LocationAddressB.addressLine1 == undefined ? LocationAddressB :
            `${LocationAddressB.addressLine1} ${LocationAddressB.addressLine2}, ${LocationAddressB.city} ${LocationAddressB.state}, ${LocationAddressB.zipCode}`
            : `${LocationB[0].toString()} ${LocationB[1].toString()}`
    }

    return (
        <Paper
            elevation={4}
            style={{
                padding: '0px 20px',
                zIndex: 10000,
                display: LocationA[0] != '' || LocationB[0] != '' ? 'block' : 'none',
                background: 'white',
                height: '120px',
                width: '350px',
                position: 'absolute',
                bottom: '10px',
                left: '10px',
                borderRadius: '10px'
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', margin: '10px' }}>
                    <DirectionsCarOutlined />
                    {/* <Typography variant='subtitle2' marginLeft={'15px'} marginTop={'2px'}>{getTravelTime(mapDirections?.routes[0]?.duration)} Min.</Typography> */}
                    <Typography variant='subtitle2' marginLeft={'15px'} marginTop={'2px'}>{convertMinutesToHoursAndMinutes(getTravelTime(mapDirections?.routes[0]?.duration)).hours} hr {convertMinutesToHoursAndMinutes(getTravelTime(mapDirections?.routes[0]?.duration)).remainingMinutes} min</Typography>
                </div>
                <IconButton onClick={() => closeDirections()}>
                    <Close fontSize="small" />
                </IconButton>
            </div>

            <div>
                <List disablePadding>
                    <ListItem disablePadding>
                        <ListItemText sx={{ width: '100px' }}>
                            <Bold fontSize='12px'>Origin:</Bold>
                        </ListItemText>
                        <Box sx={{ overflowX: 'clip' }}>
                            <BoldTitle width='300px' fontWeight='200px'>{getOrigin()}</BoldTitle>
                        </Box>
                        {/* <TextField
                            fullWidth
                            margin='dense'
                            disabled
                            size="small"
                            // value={LocationAddressA != null ? `${LocationAddressA.addressLine1} ${LocationAddressA.addressLine2}, ${LocationAddressA.city} ${LocationAddressA.state}, ${LocationAddressA.zipCode}` : `${LocationA[0].toString()} ${LocationA[1].toString()}`}
                            value={getOrigin()}
                        /> */}
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemText sx={{ width: '100px' }}>
                            <Bold fontSize='12px'>Destination:</Bold>
                        </ListItemText>
                        <Box sx={{ overflowX: 'clip' }}>
                            <BoldTitle width='300px' fontWeight='200px'>{getDestination()}</BoldTitle>
                        </Box>
                        {/* <TextField
                            fullWidth
                            margin='dense'
                            disabled
                            size="small"
                            value={LocationAddressB != null ? `${LocationAddressB.addressLine1} ${LocationAddressB.addressLine2}, ${LocationAddressB.city} ${LocationAddressB.state}, ${LocationAddressB.zipCode}` : `${LocationB[0].toString()} ${LocationB[1].toString()}`} /> */}
                    </ListItem>
                </List>
            </div>
        </Paper>
    )
}

export default DirectionsBar