import { Add, CancelOutlined, Check, DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Box, Button, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, Select, Tooltip } from "@mui/material"
import { useEffect, useState } from "react";
import { BoldTitle } from "../../../assets/styles/styledComponents";
import { useLocation, useParams } from "react-router-dom";
import useFetcher from "../../../hooks/useFetcher";
import { MenuOption } from "../../../components/form/FactorSettings/FactorConstants";

type LanguageDto = {
    id: number,
    languageId: number,
    scheduling: string,
    speaking: string
}
type LanguagesContainerProps = {
    module: string | undefined,
    id: string | undefined
}

const LanguagesContainer = ({ module, id }: LanguagesContainerProps) => {
    const [languages, setLanguages] = useState<any[]>([]);
    const [editing, setEditing] = useState<number | null>(null);
    const [selectedLanguage, setSelectedLanguage] = useState<any>();
    const [allLanguages, setLanguageOptions] = useState<any[]>([]);
    const [errorMessage, setErrorMessage] = useState('')
    const speakingOptions: MenuOption[] = [
        { value: 'Primary', key: "My primary language is this language" },
        { value: 'Fluent', key: "I'm fluently speaking this language" },
        { value: 'Somewhat', key: "I'm somewhat fluent in this language" },
        { value: 'None', key: "I do not really know this language" },
    ];
    const otherParty = module === 'caregiver' ? 'patient' : 'caregiver';
    const schedulingOptions: MenuOption[] = [
        { value: 'No', key: `I don't prefer ${otherParty}s speaking this language` },
        { value: 'Is Okay', key: `I'm okay with ${otherParty}s speaking this language` },
        { value: 'Preferred', key: `I prefer ${otherParty}s speaking this language` },
    ];

    // params
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const token = queryParams.get('token');

    const fetcher = useFetcher(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, token);
    
    useEffect(() => {
        getLanguages()
        getLanguageOptions()
    }, [])

    const getLanguages = () => {
        fetcher.get(`Language/${module}/${id}`).then((res: any) => {
            setLanguages(res.data.data)
        })
    }

    const getLanguageOptions = () => {
        fetcher.get('Language/all').then((res: any) => {
            setLanguageOptions(res.data.data)
        })
    }

    const updateLanguage = () => {
        if (selectedLanguage == null)
            return;

        if (selectedLanguage.languageId == null || selectedLanguage.languageId == 0) {
            setErrorMessage('Language Is Required')
            return;
        }
        setErrorMessage('')

        fetcher.post(`Language/${module}/update/${id}`, selectedLanguage).then(() => {
            getLanguages()
            setEditing(null)
            setSelectedLanguage(null)
        })
    }

    const deleteLanguage = (languageId: any) => {
        fetcher.delete(`Language/${module}/delete/${languageId}`).then(res => {
            setLanguages(languages.filter(l => l.id != languageId))
        })
    }

    const handleSelectChange = (field: any, value: any) => {
        setSelectedLanguage({
            ...selectedLanguage,
            [field]: value
        })
    };

    const handleClickAdd = () => {
        const newLanguage: LanguageDto = {
            id: 0,
            languageId: 0,
            speaking: '',
            scheduling: ''
        }
        setLanguages(languages.concat(newLanguage))
        setEditing(0);
        setSelectedLanguage(newLanguage)
    }

    const handleClickEdit = (lang: any) => {
        setEditing(lang.id)
        setSelectedLanguage(lang)
    }

    const handleClickCancel = () => {
        setLanguages(languages.filter(l => l.id != 0))
        setSelectedLanguage(null);
        setEditing(null)
    }

    const getLangName = (langId: number) => {
        return allLanguages.find(l => l.id == langId)?.name || 'this language';
    }

    return (
        <Box padding={'10px'}>
            <BoldTitle textAlign='center' fontSize='16px'>Languages</BoldTitle>
            <List>
                <ListItem disablePadding sx={{ display: 'flex', borderBottom: '3px solid lightgrey' }}>
                    <ListItemText primary={'Language'} primaryTypographyProps={{ fontSize: '14px', fontWeight: 'bold' }} />
                    <Tooltip title={"How fluent is this language to you?"} placement="top">
                        <ListItemText primary={'Speaking'} primaryTypographyProps={{ fontSize: '14px', fontWeight: 'bold' }} />
                    </Tooltip>
                    <Tooltip title={`Do you prefer ${otherParty}s speaking this language?`} placement="top">
                        <ListItemText primary={'Scheduling'} primaryTypographyProps={{ fontSize: '14px', fontWeight: 'bold' }} />
                    </Tooltip>
                    <ListItemText primary={''} />
                </ListItem>
                {languages.map((lang: any) =>
                    <ListItem disablePadding sx={{ paddingTop: '5px', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid lightgrey' }}>
                        <Grid container spacing={0.3}>
                            <Grid item xs={3.5}>
                                <ListItemText primary={editing == 0 && lang.id == 0 ? null : allLanguages.find(l => l.id == lang.languageId)?.name}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>Language</InputLabel>
                                        <Select
                                            error={errorMessage.length > 0}
                                            size="small"
                                            value={selectedLanguage?.id == lang.id ? selectedLanguage?.languageId : lang.languageId}
                                            onChange={(e: any) => handleSelectChange('languageId', e.target.value)}
                                            label={'Language'}
                                        >
                                            {allLanguages.map((option, index) => (
                                                <MenuItem key={index} value={option.id} disabled={languages.some(l => l.languageId == option.id)}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </ListItemText>
                            </Grid>
                            <Grid item xs={3.5}>
                                <Tooltip
                                    title={"How fluent is " + getLangName(lang.languageId || selectedLanguage?.languageId) + " to you?"}
                                    placement="top"
                                >
                                    <ListItemText primary={editing == lang.id ? null : lang.speaking}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel>Speaking</InputLabel>
                                            <Select
                                                size="small"
                                                value={selectedLanguage?.id == lang.id ? selectedLanguage?.speaking : lang.speaking}
                                                onChange={(e: any) => handleSelectChange('speaking', e.target.value)}
                                                label="Speaking"
                                                fullWidth
                                            >
                                                {speakingOptions.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>
                                                        {option.key.replace('this language', getLangName(lang.languageId || selectedLanguage?.languageId))}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </ListItemText>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={3.2}>
                                <Tooltip
                                    title={`Do you prefer ${otherParty}s speaking ` + getLangName(lang.languageId || selectedLanguage?.languageId) + "?"}
                                    placement="top"
                                >
                                    <ListItemText primary={editing == lang.id ? null : lang.scheduling}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel>Scheduling</InputLabel>
                                            <Select
                                                size="small"
                                                value={selectedLanguage?.id == lang.id ? selectedLanguage?.scheduling : lang.scheduling}
                                                onChange={(e: any) => handleSelectChange('scheduling', e.target.value)}
                                                label="Scheduling"
                                                fullWidth
                                            >
                                                {schedulingOptions.map((option, index) => (
                                                    <MenuItem key={index} value={option.value}>
                                                        {option.key.replace('this language', getLangName(lang.languageId || selectedLanguage?.languageId))}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </ListItemText>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={1.8}>
                                {editing == lang.id ?
                                    <div>
                                        <IconButton onClick={() => updateLanguage()}>
                                            <Check fontSize="small" color="success" />
                                        </IconButton>
                                        <IconButton onClick={() => { handleClickCancel() }}>
                                            <CancelOutlined fontSize="small" color="error" />
                                        </IconButton>
                                    </div>
                                    :
                                    <div>
                                        <IconButton onClick={() => handleClickEdit(lang)}>
                                            <EditOutlined fontSize="small" color="primary" />
                                        </IconButton>
                                        <IconButton onClick={() => deleteLanguage(lang.id)}>
                                            <DeleteOutline fontSize="small" color="primary" />
                                        </IconButton>
                                    </div>
                                }
                            </Grid>
                        </Grid>
                    </ListItem>
                )}
            </List>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button disabled={editing == 0} onClick={() => handleClickAdd()} sx={{ textTransform: 'none' }} size="small" endIcon={<Add fontSize="small" sx={{ color: editing == 0 ? "#00000042" : "primary" }} />}>
                    Add
                </Button>
            </div>
        </Box >
    )
}

export default LanguagesContainer