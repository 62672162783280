import React, { useState, useEffect, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import { EmojiEmotionsOutlined, SendOutlined } from '@mui/icons-material';

interface RichTextFieldProps {
    disabled: boolean;
    value: string;
    onChange: (value: string) => void;
    onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
    handleShowEmoji: (e: React.MouseEvent<HTMLButtonElement>) => void;
    sendMessage: () => void;
    fullWidth: boolean;
    fontSize?: string;
    placeholder?: string;
    style?: React.CSSProperties;
    // Add any other props you need
}

const RichTextField: React.FC<RichTextFieldProps> = ({
    disabled,
    value,
    onChange,
    onKeyDown,
    handleShowEmoji,
    sendMessage,
    fullWidth,
    fontSize = '1rem', // default font size
    placeholder = 'Write something...', // default placeholder
    style,
    // ...other props
}) => {
    const [content, setContent] = useState<string>(value);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setContent(value);
    }, [value]);

    const handleContentChange = () => {
        const newValue = ref.current?.innerHTML;
        setContent(newValue || '');
        onChange(newValue || '');
    };
    const clearContent = () => {
        setContent('');
        if (ref.current) {
            ref.current.innerHTML = '';
        }
    };

    const submitSend = () => {
        sendMessage();
        clearContent();
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key.toLowerCase() === 'enter' && !e.shiftKey) {
            e.preventDefault();
            submitSend();
        } else if (e.ctrlKey && (e.key === 'B' || e.key === 'I' || e.key === 'U')) {
            document.execCommand(e.key.toLowerCase(), false);
            e.preventDefault();
        }
        else {
            onKeyDown(e);
        }
    };

    const showPlaceholder = !content || content === '<br>';

    return (
        <div style={{ display: 'flex', alignItems: 'center', ...style }}>
            <IconButton disabled={disabled} sx={{ marginRight: '10px' }} onClick={handleShowEmoji}>
                <EmojiEmotionsOutlined />
            </IconButton>

            <div
                ref={ref}
                contentEditable={!disabled}
                onInput={handleContentChange}
                onKeyDown={handleKeyDown}
                style={{
                    fontFamily: 'ConnectAideSecondary',
                    flexGrow: 1,
                    padding: '5px',
                    // border: '1px solid grey',
                    fontSize: fontSize,
                    color: showPlaceholder ? 'grey' : 'inherit',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    width: fullWidth ? '100%' : 'auto',
                }}
                data-placeholder={showPlaceholder ? placeholder : ''}
            />

            <IconButton
                disabled={!content || content === '<br>'}
                onClick={(e: any) => {
                    submitSend();
                }}
                sx={{ marginLeft: '10px' }}
            >
                <SendOutlined color={!content || content === '<br>' ? 'disabled' : "primary"} />
            </IconButton>
        </div>
    );
};

export default RichTextField;