import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const FilterSelect = (props: any) => {
    const { label, value, handleChange, options } = props;

    const id = label.toLowerCase().replace(' ', '-');
    const labelId = id + "-label";

    return <div>
        <FormControl fullWidth>
            <InputLabel shrink id={labelId}>{label}</InputLabel>
            <Select
                labelId={labelId}
                id={id}
                value={value}
                label={label}
                size='small'
                onChange={handleChange}
                {...props}
            >
                {options.map((option: any) =>
                    <MenuItem value={option.toLowerCase().replace(' ', '-')}>{option}</MenuItem>
                )}
            </Select>
        </FormControl>
    </div>;
}

export default FilterSelect;