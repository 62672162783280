import { Box, Dialog, IconButton, Paper, Tooltip } from "@mui/material"
import MapComponent from "../../features/schedules/components/Map"
import useFetcher from "../../hooks/useFetcher"
import { useContext, useEffect, useState } from "react";
import MapControlerPanel from "./MapControlPanel";
import { FiltersContext } from "../../utils/context/FiltersContext";
import useToast from "../../hooks/useToast";
import DetailsPopUp from "./DetailsPopup";
import { Replay } from "@mui/icons-material";
import CaregiverWeek from "../../features/schedules/components/Modals/CaregiverWeek/CaregiverWeek";
import PreferenceModal from "../../features/schedules/components/Modals/PreferenceModal/PreferenceModal";

type MapProps = {
    view?: boolean
    setView?: any
}

const MapPage = ({ view, setView }: MapProps) => {
    const fetcher = useFetcher();
    const [pins, setPins] = useState<any[]>([]);
    const { filters, setFilters } = useContext(FiltersContext)!;
    const [filterChanged, setFilterChanged] = useState(false);
    const [showCaregiverSchedule, setShowCaregiverSchedule] = useState(false)
    const [showCaregiverPreference, setShowCaregiverPreference] = useState(false)
    const [selectedPin, setSelectedPin] = useState<any>()
    const { showError } = useToast();

    useEffect(() => {
        getPins()
    }, [])

    const getPayload = () => {
        const newPayload = {
            showCaregivers: filters.showCaregivers,
            showPatients: filters.showPatients,
            CStatus: filters.CStatus.toString(),
            PStatus: filters.PStatus.toString(),
            hasSchedule: filters.hasSched == 'yes' ? true : (filters.hasSched == 'no' ? false : null),
            seekingCare: filters.needsCare == 'yes' ? true : filters.needsCare == 'no' ? false : null,
            discipline: filters.discipline.toString(),
            office: filters.offices.toString(),
            coordinators: filters.coordinators.toString(),
            timing: filters.timing,
            gender: filters.gender,
            workedAfter: filters.workedAfter == 'all' ? null : filters.workedAfter,
            hiredAfter: filters.hiredAfter == 'all' ? null : filters.hiredAfter,
            addressFor: filters.addressFor,
            address: {
                addressLine1: filters.address.addressLine1,
                city: filters.address.city,
                state: filters.address.state,
                country: filters.address.country,
                zipCode: filters.address.zipCode,
                latitude: filters.address.latitude,
                longitude: filters.address.longitude,
            },
        }

        return newPayload
    }

    const getPins = () => {
        const payload = getPayload();

        fetcher.post(`Map/locations`, payload).then(res => {
            setPins(res.data.data || [])
        }).catch(error => { showError(error.data.message) })
    }

    const handleFilters = () => {

    }

    const handleSubmit = () => {
        setFilterChanged(false)
        getPins()
    }

    return (
        <Box >
            <MapComponent
                isLoading={fetcher.isLoading}
                MapPopUp={DetailsPopUp}
                data={pins}
                view={view}
                setView={setView}
                searchBox
                height="100vh"
                latitude={40.690158}
                longitude={-73.982498}
                setRow={setSelectedPin}
                setShowCaregiverSchedule={setShowCaregiverSchedule}
                setShowCaregiverPreference={setShowCaregiverPreference}
            >
                <MapControlerPanel
                    filters={filters}
                    setFilters={setFilters}
                    submit={handleFilters}
                    setChanged={setFilterChanged}
                />

                {/* Reload */}
                <div style={{ zIndex: 100000, position: 'absolute', top: '12px', left: '320px' }}>
                    <Paper sx={{ borderRadius: '20px' }} elevation={4}>
                        <Tooltip title='Refresh results'>
                            <IconButton
                                sx={{
                                    background: filterChanged ? '#b90000' : '#fff',
                                    color: '#fff',
                                    '&:hover': {
                                        background: filterChanged ? '#b90000' : 'transparent',
                                    },
                                }}
                                onClick={() => handleSubmit()}>
                                <Replay fontSize="small" color={filterChanged ? 'inherit' : 'primary'} />
                            </IconButton>
                        </Tooltip>
                    </Paper>
                </div>

                <Dialog open={showCaregiverSchedule} onClose={() => setShowCaregiverSchedule(false)} sx={{ zIndex: 1000001 }}>
                    <CaregiverWeek
                        caregiverId={selectedPin?.id}
                        weekDates={[new Date, new Date]}
                    />
                </Dialog>

                <Dialog open={showCaregiverPreference} onClose={() => setShowCaregiverPreference(false)}>
                    <PreferenceModal
                        caregiverId={selectedPin?.id}
                    />
                </Dialog>
            </MapComponent>
        </Box>
    )
}

export default MapPage