import { CloseOutlined, ExpandLessOutlined, InfoOutlined } from "@mui/icons-material"
import { Paper, Typography, IconButton, Divider, Box, Slider, Select, MenuItem, FormControlLabel, Checkbox, Autocomplete, TextField, Tooltip, RadioGroup, Radio, FormControl, Button, InputLabel, SelectChangeEvent, Grid, InputAdornment, Link } from "@mui/material"
import { FlexBetween, BoldTitle, FlexJustifyEnd, Bold, Title } from "../../../assets/styles/styledComponents"
import { useEffect, useState } from "react"
import useFetcher from "../../../hooks/useFetcher"
import { CUSTOM_SCROLL, CUSTOM_THIN_SCROLL, app_Light_bg, app_lighter } from "../../../data/constants"
import { MatchTagType, PersonPrefType } from "../../../pages/Preference/types"
import ExpandableWidget from "../../../components/common/ExpandableWidget"
import { MatchFilterType } from "../models/MatchFilterType"

type FilterProps = {
    openFilters: boolean
    setOpenFilters: any
    filters: MatchFilterType | undefined
    setFilters: any
    load: any
    patientId?: number
}

const MatchesFilters = ({ openFilters, setOpenFilters, filters, setFilters, load, patientId }: FilterProps) => {
    const fetcher = useFetcher();
    const [languages, setLanguages] = useState<any[]>([])
    const [ethnicities, setEthnicities] = useState<any[]>([])
    const [advancedTravel, setAdvancedTravel] = useState<any>(false)
    const [preferences, setPreferences] = useState<MatchTagType[]>([])
    const [patientPrefs, setPatientPrefs] = useState<PersonPrefType[]>([]);
    const [filterPreferences, setFilterPreferences] = useState<PersonPrefType[]>([])
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
    const [originalFilters, setOriginalFilters] = useState<MatchFilterType | undefined>(filters);

    useEffect(() => {
        if (!originalFilters)
            setOriginalFilters(filters);
    }, [filters, originalFilters])

    const getLanguages = () => {
        fetcher.get('Language/all').then((res: any) => {
            const langs = res.data.data.map((lang: any) => lang.name)
            setLanguages(langs)
        })
    }
    const getPreferences = () => {
        fetcher.get('preference').then((res: any) => {
            const prefs = res.data.data.filter((p: MatchTagType) => !!p.isActive)
            setPreferences(prefs)
        })
    }
    const getPatientPreferences = () => {
        fetcher.get(`preference/patient/${patientId}`).then((res: any) => {
            const ptPrefs = res.data.data
            setPatientPrefs(ptPrefs)
        })
    }

    const getEthnicity = () => {
        fetcher.get('filter/ethnicities').then((res: any) => {
            setEthnicities(res.data.data)
        })
    }
    const getDefaultFilters = () => {
        fetcher.get(`match/default-filters/${patientId}`).then(res => {
            console.log(res.data);
            if (!!res.data)
                setFilters(res.data)
        })
    }

    useEffect(() => {
        getLanguages()
        getEthnicity()
        getPreferences()
        getPatientPreferences()
    }, [])

    useEffect(() => {
        initFilterPreferences()
    }, [preferences, patientPrefs])

    const handleFilterChange = (filter: any, value: any) => {
        setFilters({
            ...filters,
            [filter]: value
        })
    }

    useEffect(() => {
        const prefs = filterPreferences?.filter(fp => {
            return fp.value != undefined && fp.value != null
        })
        if (prefs?.length > 0) {
            setFilters({
                ...filters,
                ['filterPrefs']: prefs
            })
        }
    }, [filterPreferences])

    const initFilterPreferences = () => {
        if (preferences.length < 1) return;
        let init = [...patientPrefs];
        preferences?.forEach(pref => {
            if (!init.some(patientPref => patientPref.name === pref.name)) {
                init.push({
                    name: pref.name,
                    value: undefined,
                    appliedParty: '',
                    canMeetRequirement: false,
                    isRequired: null,
                    mandatory: false,
                    preferenceId: pref.id
                });

            }
        })
        setFilterPreferences(init)
    }

    const resetFilters = () => {
        initFilterPreferences()
        getDefaultFilters();
    }

    const sliderText = (value: number, text: string) => {
        return `${value} ${text}`;
    }

    const expandIndexes = {
        'preferences': 0,
    }

    const handleWidgetToggle = (index: number) => {
        setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    const handleSetValue = (label: string, value: boolean | undefined) => {
        setFilterPreferences(prevFilterPreferences =>
            prevFilterPreferences.map(pref =>
                pref.name === label ? { ...pref, value: value } : pref
            )
        );
    };


    return (
        <Paper elevation={0} sx={{ minWidth: '275px', maxWidth: '275px !important', border: '1px solid lightgray', height: '84.5vh', display: openFilters ? 'block' : 'none', marginLeft: '5px', position: 'relative' }}>
            <FlexBetween margin='0px 0px 10px 0px'>
                <Typography fontSize={'17px'} >
                    Filters
                    <Tooltip title="Reset to do patient's original filters">
                        <Button
                            sx={{ fontSize: '13px' }}
                            onClick={() => {
                                resetFilters();
                            }}
                        >
                            reset
                        </Button>
                    </Tooltip>
                </Typography>
                <IconButton onClick={() => setOpenFilters(false)}>
                    <ExpandLessOutlined fontSize='small' color='primary' />
                </IconButton>
            </FlexBetween>
            <Divider />
            <Box sx={{ padding: '10px', height: '85%', overflowX: 'hidden', overflowY: 'scroll', ...CUSTOM_SCROLL }}>

                {/* EMPLOYEE TYPE */}
                <Box marginTop={'10px'}>
                    <Box display="flex" alignItems="center" marginTop="10px">
                        <BoldTitle fontSize='12px'>Employee Type</BoldTitle>
                        <Tooltip title="Filtering which employee types to include in match results">
                            <InfoOutlined sx={{ height: '13px' }} color="primary"></InfoOutlined>
                        </Tooltip>
                    </Box>
                    <div style={{ marginTop: '5px', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <FormControlLabel
                            sx={{ height: '25px', marginRight: '20px' }}
                            label={<BoldTitle fontSize='12px' fontWeight='200'>Caregivers</BoldTitle>}
                            control={
                                <Checkbox
                                    size='small'
                                    onClick={(e: any) => handleFilterChange('showCaregivers', e.target.checked)}
                                    checked={filters?.showCaregivers}
                                />}
                        />
                        <FormControlLabel
                            sx={{ height: '25px' }}
                            label={<BoldTitle fontSize='12px' fontWeight='200'>Candidiates</BoldTitle>}
                            control={
                                <Checkbox
                                    size='small'
                                    onClick={(e: any) => handleFilterChange('showCandidates', e.target.checked)}
                                    checked={filters?.showCandidates}
                                />}
                        />
                    </div>
                </Box>

                {/* Availability Info Status */}
                <Box marginTop={'10px'}>
                    <Box display="flex" alignItems="center" marginTop="10px">
                        <BoldTitle fontSize='12px'>Up-do-date work availability</BoldTitle>
                        <Tooltip title="Work-hours availability is/isn't up-to-date, or never filled">
                            <InfoOutlined sx={{ height: '13px' }} color="primary"></InfoOutlined>
                        </Tooltip>
                    </Box>
                    <div style={{ marginTop: '5px', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <FormControlLabel
                            sx={{ height: '25px', marginRight: '7px' }}
                            label={<BoldTitle fontSize='12px' fontWeight='200'>Updated</BoldTitle>}
                            control={
                                <Checkbox
                                    size='small'
                                    onClick={(e: any) => handleFilterChange('includeWorkScheduleUpdated', e.target.checked)}
                                    checked={filters?.includeWorkScheduleUpdated}
                                    sx={{ paddingRight: '3px' }}
                                />}
                        />
                        <FormControlLabel
                            sx={{ height: '25px', marginRight: '7px' }}
                            label={<BoldTitle fontSize='12px' fontWeight='200'>Outdated</BoldTitle>}
                            control={
                                <Checkbox
                                    size='small'
                                    onClick={(e: any) => handleFilterChange('includeWorkScheduleOutdated', e.target.checked)}
                                    checked={filters?.includeWorkScheduleOutdated}
                                    sx={{ paddingRight: '3px' }}
                                />}
                        />
                        <FormControlLabel
                            sx={{ height: '25px' }}
                            label={<BoldTitle fontSize='12px' fontWeight='200'>Blank</BoldTitle>}
                            control={
                                <Checkbox
                                    size='small'
                                    onClick={(e: any) => handleFilterChange('includeWorkScheduleBlank', e.target.checked)}
                                    checked={filters?.includeWorkScheduleBlank}
                                    sx={{ paddingRight: '3px' }}
                                />}
                        />
                    </div>
                </Box>

                {/* Schedule Status */}
                <Box marginTop={'10px'}>
                    <Box display="flex" alignItems="center" marginTop="10px">
                        <BoldTitle fontSize='12px'>Schedule status</BoldTitle>
                        <Tooltip title="The aide is already on a schedule">
                            <InfoOutlined sx={{ height: '13px' }} color="primary"></InfoOutlined>
                        </Tooltip>
                    </Box>
                    <div style={{ marginTop: '5px', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <FormControlLabel
                            sx={{ height: '25px', marginRight: '7px' }}
                            label={<BoldTitle fontSize='12px' fontWeight='200'>On Schedule</BoldTitle>}
                            control={
                                <Checkbox
                                    size='small'
                                    onClick={(e: any) => handleFilterChange('includeOnSchedule', e.target.checked)}
                                    checked={filters?.includeOnSchedule}
                                    sx={{ paddingRight: '3px' }}
                                />}
                        />
                        <FormControlLabel
                            sx={{ height: '25px', marginRight: '7px' }}
                            label={<BoldTitle fontSize='12px' fontWeight='200'>Not on schedule</BoldTitle>}
                            control={
                                <Checkbox
                                    size='small'
                                    onClick={(e: any) => handleFilterChange('includeNotOnSchedule', e.target.checked)}
                                    checked={filters?.includeNotOnSchedule}
                                    sx={{ paddingRight: '3px' }}
                                />}
                        />
                    </div>
                </Box>

                {/* GENDER */}
                <Box
                    marginTop={'20px'}
                >
                    <FormControl size='small' sx={{ width: '90px', height: '30px' }}>
                        <InputLabel id="gender-label" sx={{ fontSize: '16px', color: '#333', transform: 'translate(0, -1.5px) scale(0.75)', position: 'absolute', top: '-7px', left: '0px' }}>Gender</InputLabel>
                        <Select
                            labelId="gender-label"
                            value={filters?.gender}
                            onChange={(e: any) => handleFilterChange('gender', e.target.value)}
                            label="Gender"
                            sx={{
                                fontSize: '14px',
                                height: '30px',
                                padding: '0px 0px',
                                color: '#7846FF',
                                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 0 },
                                '&.Mui-focused .MuiSelect-select': { backgroundColor: 'transparent' },
                                '& .MuiSelect-select': { paddingTop: '0px', paddingLeft: '0px', paddingBottom: '0px' }
                            }}
                        >
                            <MenuItem value='Any' sx={{ fontSize: '14px', padding: "2px 0px 2px 6px" }}>Any</MenuItem>
                            <MenuItem value='Male' sx={{ fontSize: '14px', padding: "2px 0px 2px 6px" }}>Male</MenuItem>
                            <MenuItem value='Female' sx={{ fontSize: '14px', padding: "2px 0px 2px 6px" }}>Female</MenuItem>
                        </Select>
                    </FormControl>

                    {/* <FormControl size='small' sx={{ maxWidth: '160px', height: '30px' }}>
                        <InputLabel id="status-label" sx={{ fontSize: '16px', color: '#333', transform: 'translate(0, -1.5px) scale(0.75)', position: 'absolute', top: '-7px', left: '0px' }}>Status</InputLabel>
                        <Select
                            labelId="status-label"
                            value={filters?.employeeStatus || []}
                            onChange={(e: any) => handleFilterChange('employeeStatus', e.target.value)}
                            label="Status"
                            multiple
                            sx={{
                                fontSize: '14px',
                                height: '30px',
                                padding: '0px 0px',
                                color: '#7846FF',
                                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 0 },
                                '&.Mui-focused .MuiSelect-select': { backgroundColor: 'transparent' },
                                '& .MuiSelect-select': { paddingTop: '0px', paddingLeft: '0px', paddingBottom: '0px' }
                            }}
                        >
                            <MenuItem value='active' sx={{ fontSize: '14px', padding: "2px 0px 2px 6px" }}>Active</MenuItem>
                            <MenuItem value='inactive' sx={{ fontSize: '14px', padding: "2px 0px 2px 6px" }}>Inactive</MenuItem>
                            <MenuItem value='hold' sx={{ fontSize: '14px', padding: "2px 0px 2px 6px" }}>Hold</MenuItem>
                            <MenuItem value='on leave' sx={{ fontSize: '14px', padding: "2px 0px 2px 6px" }}>On Leave</MenuItem>
                            <MenuItem value='terminated' sx={{ fontSize: '14px', padding: "2px 0px 2px 6px" }}>Terminated</MenuItem>
                        </Select>
                    </FormControl> */}
                </Box>

                {/* LANGUAGE */}
                <Box marginTop={'0px'}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <BoldTitle fontSize="12px">Language</BoldTitle>
                        <div>
                            <Tooltip placement="top" title={'Check this box to have those language(s) filter caregivers by their "primary speaking language"'}>
                                <div style={{ display: 'flex', justifyContent: 'end' }}>
                                    <Checkbox
                                        size="small"
                                        onClick={() => handleFilterChange('isPrimaryLanguages', !filters?.isPrimaryLanguages)}
                                        sx={{ width: '25px', height: '15px' }}
                                        checked={!!filters?.isPrimaryLanguages}
                                    />
                                    <BoldTitle fontWeight='regular' fontSize='12px'>As Primary</BoldTitle>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                    <Autocomplete
                        multiple
                        disableClearable
                        onChange={(e, option) => { handleFilterChange('languages', option.map(o => languages?.find((of: any) => of == o))) }}
                        value={filters?.languages || []}
                        size='small'
                        getOptionLabel={(option) => option}
                        renderInput={(params) => <TextField {...params} value={filters?.languages} />}
                        options={languages?.map((lang: any) => lang)}
                        sx={{
                            width: '100%',
                            '& .MuiAutocomplete-inputRoot': {
                                fontSize: '12px',
                                padding: '1px !important',
                            },
                            '& .MuiInputBase-root': {
                                padding: '1px !important',
                            },
                            '& .MuiInputBase-input': {
                                fontSize: '12px',
                                padding: '1px !important',
                            },
                            '& .MuiChip-root': {
                                fontSize: '12px',
                                height: '20px',
                            },
                            '& .MuiAutocomplete-tag': {
                                margin: '2px',
                            },
                        }}
                    />
                </Box>

                {/* ETHNICITY */}
                <Box marginTop={'10px'} marginBottom={'10px'}>
                    <Title fontSize="12px">Ethnicity</Title>
                    <Autocomplete
                        multiple
                        disableClearable
                        onChange={(e, option) => { handleFilterChange('ethnicity', option.map(o => ethnicities?.find((of: any) => of == o))) }}
                        value={filters?.ethnicity || []}
                        size='small'
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                value={filters?.ethnicity}
                                label=""
                                InputProps={{
                                    ...params.InputProps,
                                    sx: {
                                        fontSize: '12px',
                                        padding: '0px !important',
                                    },
                                }}
                            />
                        )}
                        options={ethnicities?.map((lang: any) => lang)}
                        sx={{
                            width: '100%',
                            '& .MuiAutocomplete-inputRoot': {
                                fontSize: '12px',
                                padding: '1px !important',
                            },
                            '& .MuiInputBase-root': {
                                padding: '1px !important',
                            },
                            '& .MuiInputBase-input': {
                                fontSize: '12px',
                                padding: '1px !important',
                            },
                            '& .MuiChip-root': {
                                fontSize: '12px',
                                height: '20px',
                            },
                            '& .MuiAutocomplete-tag': {
                                margin: '2px',
                            },
                        }}
                    />
                </Box>

                {/* PREFERENCES */}
                <ExpandableWidget
                    title={"Preferences - " + (filterPreferences.filter(fp => !!fp.value && !!preferences.find(p => p.id == fp.preferenceId)?.isActive).length.toString() + "/" + preferences.filter(p => !!p.isActive).length.toString())}
                    index={expandIndexes['preferences']} expandedIndex={expandedIndex} onToggle={handleWidgetToggle} padding={'0px'}
                    containerStyle="background" titleSize="12px"
                >
                    <Box
                        sx={{
                            paddingRight: '0px', marginTop: '0px', maxHeight: '35vh', overflowY: 'scroll',
                            ...CUSTOM_THIN_SCROLL
                        }}
                    >
                        {filterPreferences.map(fp => {
                            return (
                                <div>
                                    <InlineSelect
                                        key={fp.name}
                                        label={fp.name}
                                        value={fp.value}
                                        setValue={handleSetValue}
                                        tootlip={preferences.find(p => p.id == fp.preferenceId)?.clientLabel}
                                    />
                                </div>
                            )
                        })}
                    </Box>
                </ExpandableWidget>


                <Box
                    marginTop={'20px'}
                    sx={{ borderTop: '0.5px solid ' + app_Light_bg }}
                ></Box>

                <Box width={'100%'} marginTop={'10px'} display={'flex'} justifyContent={'space-between'}>
                    <Box display="flex" alignItems="center">
                        <Title fontSize='12px'>Last worked within </Title>
                        <TextField
                            sx={{
                                width: '50px',
                                '& .MuiOutlinedInput-input': {
                                    padding: '3px 0px 0px 3px',
                                },
                                '& .MuiInputAdornment-root .MuiTypography-root': {
                                    fontSize: '12px', // Adjust the font size here
                                    padding: '0px',
                                    margin: '0px',
                                }
                            }}
                            size="small"
                            type="number"
                            onChange={(e: any) => e.target.value > 999 ? null : handleFilterChange('lastWorkedWithinXDays', e.target.value)}
                            value={filters?.lastWorkedWithinXDays}
                        />
                        <Title fontSize='12px' margin="0 0 0 5px"> days</Title>
                        <Tooltip title="Filtering away caregivers who did not work in the last X days">
                            <InfoOutlined sx={{ height: '13px' }} color="primary"></InfoOutlined>
                        </Tooltip>
                    </Box>
                </Box>

                <Box width={'100%'} marginTop={'10px'} display={'flex'} justifyContent={'space-between'}>
                    <Box display="flex" alignItems="center">
                        <Title fontSize='12px'>Max pending invites</Title>
                        <Tooltip title="Filtering away caregivers already having too many invites still pending">
                            <InfoOutlined sx={{ height: '13px' }} color="primary"></InfoOutlined>
                        </Tooltip>
                    </Box>
                    <TextField
                        sx={{
                            width: '80px',
                            '& .MuiOutlinedInput-input': {
                                padding: '3px 0px 0px 3px',
                            },
                            '& .MuiInputAdornment-root .MuiTypography-root': {
                                fontSize: '12px', // Adjust the font size here
                                padding: '0px',
                                margin: '0px',
                            }
                        }}
                        size="small"
                        type="number"
                        onChange={(e: any) => e.target.value > 99 ? null : handleFilterChange('maxPendingInvites', e.target.value)}
                        value={filters?.maxPendingInvites}
                    />
                </Box>

                {/* CAREGIVER AGE */}
                {/* <Box width={'100%'} marginTop={'10px'} >
                    <BoldTitle>Caregiver Age</BoldTitle>
                    <Slider
                        value={filters?.age}
                        size="small"
                        min={1}
                        max={120}
                        onChange={(e: any, value: any) => {
                            let minAge = value[0];
                            let prevMinAge = filters?.minAge ?? 0;
                            if (minAge != prevMinAge)
                                handleFilterChange('minAge', minAge)

                            let maxAge = value[1];
                            let prevMaxAge = filters?.maxAge ?? 100;
                            if (maxAge != prevMaxAge)
                                handleFilterChange('maxAge', maxAge)
                        }}
                        step={1}
                        valueLabelFormat={(value: any) => sliderText(value, 'yrs')}
                        valueLabelDisplay="auto"
                        sx={{ marginBottom: '0px', '&.MuiSlider-root': { padding: '0px' } }}
                    />
                </Box> */}


                {/* AVAILABILITY */}


                <Box
                    width={'100%'}
                    marginTop={'20px'}
                    paddingTop={'10px'}
                    sx={{ borderTop: '0.5px solid ' + app_Light_bg }}>
                </Box>
                <Box width={'100%'}>
                    <Box display="flex" alignItems="center">
                        <BoldTitle fontSize="13px">Availability</BoldTitle>
                        <Tooltip title='Filtering caregivers who are available for this visit, for X percent/hours of the duration of the visit' placement="left">
                            <InfoOutlined sx={{ height: '13px' }} color="primary"></InfoOutlined>
                        </Tooltip>
                    </Box>
                    <FormControl margin='none'>
                        <RadioGroup onChange={(e: any) => handleFilterChange('availabilityType', e.target.value)} value={filters?.availabilityType} row>
                            <FormControlLabel value={'percent'} label={<BoldTitle fontSize='12px' fontWeight='200px'>Percent</BoldTitle>} control={<Radio size="small" />} />
                            <FormControlLabel value={'hours'} label={<BoldTitle fontSize='12px' fontWeight='200px'>Hours</BoldTitle>} control={<Radio size="small" />} />
                        </RadioGroup>
                    </FormControl>
                    <Slider
                        step={filters?.availabilityType == 'percent' ? 5 : undefined}
                        max={filters?.availabilityType == 'percent' ? 100 : 24}
                        size="small"
                        valueLabelDisplay="auto"
                        value={[filters?.minAvailability ?? 80, filters?.maxAvailability ?? 100]}
                        valueLabelFormat={(value: any) => sliderText(value, filters?.availabilityType == 'percent' ? '%' : 'hrs')}
                        onChange={(e: any, value: any) => {
                            let minAvail = value[0];
                            let prevMinAvail = filters?.minAvailability ?? 0;
                            if (minAvail != prevMinAvail)
                                handleFilterChange('minAvailability', minAvail)
                            
                            let maxAvail = value[1];
                            let prevMaxAvail = filters?.maxAvailability ?? 100;
                            if (maxAvail != prevMaxAvail)
                                handleFilterChange('maxAvailability', maxAvail)
                        }}
                        sx={{ marginTop: '0px', '&.MuiSlider-root': { padding: '0px' } }}
                    />
                </Box>

                {/* FLEXIBLE SHIFT */}
                <Box
                    width={'100%'}
                    paddingTop={'10px'}>
                </Box>
                <Box>
                    <FormControlLabel
                        label={
                            <Box display="flex" alignItems="center">
                                <BoldTitle fontSize='12px'>Shift has flexible window</BoldTitle>
                                <Tooltip title="Flexible to adjust shift timing based on caregiver availability, even if the exact hours don't match the availability as is">
                                    <InfoOutlined sx={{ height: '13px' }} color="primary"></InfoOutlined>
                                </Tooltip>
                            </Box>
                        }
                        control={
                            <Checkbox
                                size='small'
                                onClick={(e: any) => handleFilterChange('flexibleShift', e.target.checked)}
                                checked={filters?.flexibleShift}
                            />}
                    />
                    <div style={{ display: filters?.flexibleShift ? 'flex' : 'none', justifyContent: 'space-between' }}>
                        <div style={{ maxWidth: '50%', margin: '0px 5px' }}>
                            <BoldTitle fontSize='12px'>Between</BoldTitle>
                            <TextField
                                value={filters?.flexibleWindowStart?.toTimeString().slice(0, 5)}
                                onChange={(e: any) => handleFilterChange('flexibleWindowStart', new Date(`1999-01-01T${e.target.value}`))}
                                inputProps={{ style: { height: '25px', padding: '1px' } }}
                                type='time'
                                size="small"
                                fullWidth
                            />
                        </div>
                        <div style={{ maxWidth: '50%', margin: '0px 5px' }}>
                            <BoldTitle fontSize='12px'>And</BoldTitle>
                            <TextField
                                value={filters?.flexibleWindowEnd?.toTimeString().slice(0, 5)}
                                onChange={(e: any) => handleFilterChange('flexibleWindowEnd', new Date(`1999-01-01T${e.target.value}`))}
                                inputProps={{ style: { height: '25px', padding: '1px' } }}
                                type='time'
                                size="small"
                                fullWidth
                            />
                        </div>
                    </div>
                </Box>

                {/* MIN BETWEEN VISIT */}
                <Box width={'100%'} marginTop={filters?.flexibleShift ? '25px' : '10px'} display={'flex'} justifyContent={'space-between'}>
                    <Box display="flex" alignItems="center">
                        <BoldTitle fontSize="12px">Visits break</BoldTitle>
                        <Tooltip title='Filtering away any caregivers who come already from another visits, and don not have the minimum break of X-minutes between them' placement="left">
                            <InfoOutlined sx={{ height: '13px' }} color="primary"></InfoOutlined>
                        </Tooltip>
                    </Box>
                    <TextField
                        sx={{
                            width: '100px',
                            '& .MuiOutlinedInput-input': {
                                padding: '3px 0px 0px 3px',
                            },
                            '& .MuiInputAdornment-root .MuiTypography-root': {
                                fontSize: '12px', // Adjust the font size here
                                padding: '0px',
                                margin: '0px',
                            }
                        }}
                        size="small"
                        type="number"
                        onChange={(e: any) => e.target.value > 60 ? null : handleFilterChange('minBetweenVisits', e.target.value)}
                        value={filters?.minBetweenVisits}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    min
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>

                {/* OVERTIME */}
                <Box marginTop={'20px'}>
                    <div style={{ width: '100%' }}>
                        <FormControlLabel
                            sx={{ height: '25px' }}
                            label={<BoldTitle fontSize='12px'>Include Overtime</BoldTitle>}
                            control={
                                <Checkbox
                                    size='small'
                                    onClick={(e: any) => handleFilterChange('matchOvertime', e.target.checked)}
                                    checked={filters?.matchOvertime}
                                />}
                        />
                        <FormControlLabel
                            sx={{ height: '25px' }}
                            label={<BoldTitle fontSize='12px'>Include Potential Overtime</BoldTitle>}
                            control={
                                <Checkbox
                                    size='small'
                                    onClick={(e: any) => handleFilterChange('matchPotentialOvetime', e.target.checked)}
                                    checked={filters?.matchPotentialOvetime}
                                />}
                        />
                    </div>
                </Box>


                <Box
                    width={'100%'}
                    marginTop={'10px'}
                    paddingTop={'10px'}
                    sx={{ borderTop: '0.5px solid ' + app_Light_bg }}>
                </Box>

                {/* TRAVEL TRESHOLD */}
                {/* <Box
                    width={'100%'}
                    marginTop={filters?.flexibleShift ? '5px' : '0px'}
                    paddingTop={'5px'}
                    display={'flex'}
                    justifyContent={'space-between'}
                    sx={{ borderTop: '0.5px solid ' + app_Light_bg }}
                >
                    <Box display="flex" alignItems="center">
                        <BoldTitle fontSize='12px'>Travel Threshold Flexibility</BoldTitle>
                        <Tooltip title="Set the maximum allowed travel time. If the calculated travel time slightly exceeds this limit, specify the acceptable margin of error to account for possible miscalculations">
                            <InfoOutlined sx={{ height: '13px' }} color="primary"></InfoOutlined>
                        </Tooltip>
                    </Box>
                    <TextField
                        sx={{
                            width: '80px',
                            '& .MuiOutlinedInput-input': {
                                padding: '2px 5px',
                            }
                        }}
                        size="small"
                        type="number"
                        onChange={(e: any) => e.target.value > 100 ? null : handleFilterChange('travelThresholdFlexibility', e.target.value)}
                        value={filters?.travelThresholdFlexibility}
                        InputProps={{ endAdornment: '%' }}
                    />
                </Box> */}

                {/* TRAVEL TIME */}
                <Box
                    width={'100%'}
                    paddingTop={'15px'}>
                </Box>
                <Box width={'100%'}>
                    {/* <FlexBetween padding='0px'> */}
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                        <BoldTitle fontSize={'13px'}>Max Travel Time</BoldTitle>
                        <Button onClick={() => setAdvancedTravel(!advancedTravel)} sx={{ fontSize: '10px', margin: '0px' }} size="small">{advancedTravel ? 'Advanced' : 'Basic'}</Button>
                    </div>
                    {/* </FlexBetween> */}
                    <Slider
                        // sx={{ display: advancedTravel ? 'none' : 'inline-block', '&.MuiSlider-root': { padding: '10px' } }}
                        sx={{ display: advancedTravel ? 'none' : 'block', '&.MuiSlider-root': { padding: '2px 0px' } }}
                        value={filters?.maxTravel_HomeToPatient || 0}
                        size="small"
                        onChange={(e: any, value: any) => handleFilterChange('maxTravel_HomeToPatient', value)}
                        max={60}
                        valueLabelFormat={(value: any) => sliderText(value, 'min')}
                        valueLabelDisplay="auto"
                    />
                </Box>

                {/* ADVANCED TRAVEL */}
                <Box width={'100%'} display={advancedTravel ? 'block' : 'none'} margin={'15px 0px'}>
                    <Box marginTop={'10px'} display={'flex'} justifyContent={'space-between'}>
                        <Tooltip title='Max travel time from caregivers home to patient' placement="top">
                            <Title fontSize="12px">Home to Home</Title>
                        </Tooltip>
                        <TextField
                            sx={{
                                width: '45%',
                                '& .MuiOutlinedInput-input': {
                                    padding: '3px 0px 0px 3px',
                                },
                                '& .MuiInputAdornment-root .MuiTypography-root': {
                                    fontSize: '12px', // Adjust the font size here
                                    padding: '0px',
                                    margin: '0px',
                                }
                            }}
                            size="small"
                            type="number"
                            onChange={(e: any) => e.target.value > 180 ? null : handleFilterChange('maxTravel_HomeToPatient', e.target.value)}
                            value={filters?.maxTravel_HomeToPatient}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        min
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box marginTop={'10px'} display={'flex'} justifyContent={'space-between'}>
                        <Tooltip title='Max travel time from previous visit to this visit' placement="top">
                            <Title fontSize="12px">Previous to Current</Title>
                        </Tooltip>
                        <TextField
                            sx={{
                                width: '45%',
                                '& .MuiOutlinedInput-input': {
                                    padding: '3px 0px 0px 3px',
                                },
                                '& .MuiInputAdornment-root .MuiTypography-root': {
                                    fontSize: '12px', // Adjust the font size here
                                    padding: '0px',
                                    margin: '0px',
                                }
                            }}
                            size="small"
                            type="number"
                            onChange={(e: any) => e.target.value > 180 ? null : handleFilterChange('maxTravel_PrevPtToCurr', e.target.value)}
                            value={filters?.maxTravel_PrevPtToCurr}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        min
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box marginTop={'10px'} display={'flex'} justifyContent={'space-between'}>
                        <Tooltip title='Max travel time from this visit to next visit' placement="top">
                            <Title fontSize="12px">Current to Next</Title>
                        </Tooltip>
                        <TextField
                            sx={{
                                width: '45%',
                                '& .MuiOutlinedInput-input': {
                                    padding: '3px 0px 0px 3px',
                                },
                                '& .MuiInputAdornment-root .MuiTypography-root': {
                                    fontSize: '12px', // Adjust the font size here
                                    padding: '0px',
                                    margin: '0px',
                                }
                            }}
                            size="small"
                            type="number"
                            onChange={(e: any) => e.target.value > 180 ? null : handleFilterChange('maxTravel_CurrPtToNext', e.target.value)}
                            value={filters?.maxTravel_CurrPtToNext}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        min
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Box>
            </Box >

            <Button onClick={() => load()} sx={{ position: 'absolute', bottom: 1, left: 0 }} fullWidth variant='contained'>
                Apply
            </Button>
        </Paper >
    )
}

export default MatchesFilters

interface Props {
    label: string;
    value: boolean | undefined;
    setValue: (label: string, value: boolean | undefined) => void;
    tootlip?: string;
}

const InlineSelect: React.FC<Props> = ({ label, value, setValue, tootlip = '' }) => {
    // Convert the boolean/null value to a string for the select element
    const valueToString = (val: boolean | undefined): string => {
        if (val === undefined) return 'Unselected';
        return val ? 'Yes' : 'No';
    };

    // Handle change event of the select element
    const handleChange = (event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value;
        console.log(selectedValue)
        if (selectedValue === '') {
            setValue(label, undefined);
        } else if (selectedValue === 'Yes') {
            setValue(label, true);
        } else {
            setValue(label, false);
        }
    };

    return (
        <div style={{ display: 'flex', padding: '0px 0px 2px 0px', alignItems: 'center', justifyContent: 'space-between' }}>
            {/* < label style={{ fontSize: '14px', marginRight: '10px' }}> {label}</label > */}
            <Tooltip title={tootlip || label} placement={'left'}>
                <InputLabel sx={{ fontSize: '14px', padding: '0px 3px 0px 0px' }}>{label}</InputLabel>
            </Tooltip>
            <Select
                value={valueToString(value)}
                onChange={handleChange}
                sx={{
                    width: '50px', // Fixed width
                    '& .MuiSelect-select': {
                        paddingRight: '0px !important', // Override padding-right to 10px
                        padding: '0 0 0 2px', // Adjust the padding to suit your needs
                        background: app_Light_bg,
                        fontSize: '13px'
                    },
                    '& .MuiSelect-icon': {
                        right: '0px', // Position the expanding arrow from the right
                    },
                }}
            >
                {/* <MenuItem value="">SELECT</MenuItem> */}
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
            </Select>
        </div >
    );
};