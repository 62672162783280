import React from 'react'
import { Circles } from 'react-loader-spinner';

type ReactLoaderProps = {
  isLoading: boolean,
  color?: string,
  height?: string,
  width?: string
}

const ReactLoader = ({ isLoading, color = "#7846ff", height = "80", width = "80" }: ReactLoaderProps) => {
  return (
    <div style={{
      width: '100%',
      height: '100%',
      background: 'rgba(256, 256, 256, 0.5)',
      position: 'absolute',
      display: isLoading ? "flex" : "none",
      justifyContent: 'center',
      alignItems: 'center',
      top: '0px',
      left: '0px',
      zIndex: 1000
    }}>
      <Circles
        height={height}
        width={width}
        color={color}
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />

    </div>
  )
}

export default ReactLoader