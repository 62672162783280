import React from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from './ErrorFallBack';
import Layout from './layout';
import useKeyTracker, { IKeyTrackerProps } from './hooks/useKeyTracker';
import getFullObject from "./lib/browser-devtools";
import { ThemeProvider, createTheme } from '@mui/material';
import { FiltersProvider } from './utils/context/FiltersContext';
import { LocationsProvider } from './utils/context/LocationsContext';
import './Fonts.css'
import YourComponent from './YourComponent';

LicenseInfo.setLicenseKey(
  'f8e0ea77d88894f2923146145467be6fTz05MTgxNCxFPTE3NDkwNTAxOTAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const App: React.FC = () => {
  const ktProps: IKeyTrackerProps = {
    keyEvents: [
      {
        key: 'd', ctrlKey: true, altKey: true, shiftKey: false, action: () => {
          localStorage.setItem("envMode", 'debug');
          console.log("Changed to debug mode");
        }
      },
      {
        key: 'p', ctrlKey: true, altKey: true, shiftKey: false, action: () => {
          localStorage.setItem("envMode", 'prod');
          console.log("Changed to prod mode");
        }
      },
      {
        key: 't', ctrlKey: true, altKey: true, shiftKey: false, action: () => {
          localStorage.setItem("envMode", 'test');
          console.log("Changed to test mode");
        }
      },
    ]
  };
  const keyTracker = useKeyTracker(ktProps);

  const Theme = createTheme({
    palette: {
      primary: {
        main: '#7846ff'
      }
    },
    typography: {
      fontFamily: 'ConnectAideSecondary'
    },
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: '#e4dafc', // Change this to your desired color
              '&:hover': {
                backgroundColor: '#cdbbff', // Optional: Change this to your desired color on hover
              },
            },
          },
        },
      },
    },
  })
  return (
    //<ErrorBoundary FallbackComponent={(props) => ErrorFallback(props)}>
    <LocationsProvider>
      <FiltersProvider>
        <ThemeProvider theme={Theme}>
          <div>
            {/* <button
        onClick={() => {
          getFullObject().then(({ consoleLogs, networkLogs, screenshot }) => {
            //console.log(consoleLogs);
            //console.log(networkLogs);
            //console.log(screenshot); // Screenshot have 2 fields -> url and file
          });
        }}
        >
        Save Data
      </button> */}
            <Layout />
            <ToastContainer
              position={toast.POSITION.TOP_CENTER}
              autoClose={2400}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
          </div>
        </ThemeProvider>
      </FiltersProvider>
    </LocationsProvider>
    //</ErrorBoundary>
  )
}

export default App