import { useEffect, useState } from "react";
import axiosClient from "../../../helper/axiosConfig";
import { urlSettingAll } from "./FactorUrlConstants";
import { FactorSettingI, FactorSettingLevelI } from "./FactorInterfaces";
import FactorSettingLevel from "./FactorSettingLevel";
import useFetcher from "../../../hooks/useFetcher";
import { MenuOption } from "./FactorConstants";

interface FactorSettingsMainProps {
	selectedProfileId: number | undefined,
	setIsUpdating: Function,
	setResponse: Function,
	isMain: Boolean,
}

const promiseWrapper = (promise: Promise<any>) => {
	let status = "pending";
	let result: any;

	const s = promise.then(
		(value: any) => {
			status = "success";
			result = value;
		},
		(error: any) => {
			status = "error";
			result = error;
		}
	);

	return () => {
		switch (status) {
			case "pending":
				throw s;
			case "success":
				return result;
			case "error":
				throw result;
			default:
				throw new Error("Unknown status");
		}
	};
};

function FactorSettingsMain({ selectedProfileId, setIsUpdating, setResponse, isMain }: FactorSettingsMainProps) {
	const [levelsArr, setLevelsArr] = useState<FactorSettingLevelI[]>([]);
	const [abilityFactors, setAbilityFactors] = useState<MenuOption[]>([]);

	const settingFetcher = useFetcher();
	const fetchSettings = () => {
		const newUrl = `${urlSettingAll}?profileId=${selectedProfileId}`;

		const secondPromise = settingFetcher.get(newUrl).then((firstData) => {
			const data = firstData.data.data;

			const levelsArr = getLevels(data);
			const levelsSettingsArr: FactorSettingLevelI[] = groupByLevels(
				data,
				levelsArr
			);

			return levelsSettingsArr;
		});

		setLevelsArr(promiseWrapper(secondPromise));
	};

	function setSettingsArr(level: number, newSettingsArr: FactorSettingI[]) {
		const newArr_levelsArr = (prevLevels: FactorSettingLevelI[]) => {
			return prevLevels.map((fsl: FactorSettingLevelI) =>
				fsl.level === level
					? { level: level, factorSettings: newSettingsArr }
					: { ...fsl }
			);
		};
		setLevelsArr(newArr_levelsArr);
	}

	// fetch ability-factors
	useEffect(() => {
		fetchAbilityFactors();
	}, []);
	const abilityFactorsFetcher = useFetcher();
	const fetchAbilityFactors = () => {
		const url = `api/factorSetting/ability-factors`;

		const secondPromise = abilityFactorsFetcher.get(url).then((res: any) => {
			const data = res.data.data//.filter((pref: any) => !!pref.isActive);

			console.log(data);
			setAbilityFactors(data);;
		});
	};

	useEffect(() => {
		console.log('changed levelsArr', levelsArr);

		let newLevel: FactorSettingLevelI = {} as FactorSettingLevelI;

		for (let i = 0; i < levelsArr.length; i++) {
			if (
				levelsArr[i].factorSettings.length !== 0 &&
				typeof levelsArr[i + 1] === "undefined"
			) {
				newLevel.level = levelsArr[i].level + 1;
				newLevel.factorSettings = [];

				setLevelsArr([...levelsArr, newLevel]);

				break;
			}
		}
	}, [levelsArr]);

	useEffect(() => {
		if (typeof selectedProfileId !== "undefined") fetchSettings();
	}, [selectedProfileId]);

	return (
		<>
			{levelsArr.length !== 0 &&
				levelsArr.map((level) => (
					<FactorSettingLevel
						key={level.level}
						level={level.level}
						isMain={isMain}
						settingsArray={level.factorSettings}
						setSettingsArray={setSettingsArr}
						setIsUpdating={setIsUpdating}
						setResponse={setResponse}
						abilityFactors={abilityFactors}
						selectedProfileId={
							typeof selectedProfileId === "undefined"
								? 0
								: selectedProfileId
						}
					/>
				))}
		</>
	);
}

/**
 * Helper Functions
 */
function groupByLevels(data: FactorSettingI[], levelsArr: number[]) {
	let newLevel: FactorSettingLevelI = {} as FactorSettingLevelI;
	let levelsSettingsArr: FactorSettingLevelI[] = [] as FactorSettingLevelI[];

	// checks if there are any settings for this profile
	if (levelsArr.length !== 0) {
		for (const level of levelsArr) {
			newLevel.level = level;
			// check if data is array
			if (!Array.isArray(data)) {
				// return empty
				return levelsSettingsArr;
			}

			newLevel.factorSettings = data.filter(setting => setting.severityLevel === level);

			levelsSettingsArr = [...levelsSettingsArr, newLevel];

			newLevel = {} as FactorSettingLevelI;
		}
	} else { // for an empty profile create a new level
		newLevel.level = 1;
		newLevel.factorSettings = [];

		levelsSettingsArr = [newLevel];
	}

	return levelsSettingsArr;
}

function getLevels(data: FactorSettingI[]) {
	const levelsArr: number[] = [];

	// check if data is array
	if (!Array.isArray(data)) {
		// return empty
		return levelsArr;
	}

	for (const element of data) {
		const currLevel = element.severityLevel;
		if (!levelsArr.includes(currLevel))
			levelsArr.push(currLevel);
	}

	return levelsArr;
}

export default FactorSettingsMain;