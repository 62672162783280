import { DialogActions, MuiButton} from '../../../../lib/MUI/mui.components'

type ModalFooterProps = {
  onSave?: Function | any,
  onCancel: Function | any
}
const ModalFooter: React.FC<ModalFooterProps> = ({onSave, onCancel}) => {
  return (
    <DialogActions>
        {typeof onSave === 'function' && <MuiButton onClick={(e) => onSave?.(e)}>Save</MuiButton> }
        <MuiButton onClick={(e) => onCancel(e)}>Cancel</MuiButton>
    </DialogActions>
  )
}

export default ModalFooter