import { Grid, TextField, Typography } from '@mui/material';
import { getPrefLevelName } from './Helpers';

interface CommutePreferenceFieldProps {
  label: string;
  preferenceLevel?: string | undefined
  maxMinutes?: number | undefined;
}

const CommutePreferenceField: React.FC<CommutePreferenceFieldProps> = ({ label, preferenceLevel, maxMinutes }) => {
  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={3} style={{ paddingTop: 15 }}>
          <Typography variant="caption" align="left" color="black" fontSize={'14px'} style={{ fontWeight: 'normal' }}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            value={getPrefLevelName(preferenceLevel || '')}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              style: { fontSize: '14px', pointerEvents: 'none' }
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            value={maxMinutes || ''}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              style: { fontSize: '14px', pointerEvents: 'none' }
            }}
          />
        </Grid>
        <Grid item xs={1} style={{ paddingTop: 15, fontSize: 14 }}>
          Minutes
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CommutePreferenceField;