import { useState, useEffect, useRef } from 'react';
import axios, { AxiosResponse, Method } from 'axios';
import { BASE_URL, LOCAL_URL, MODES, STAGING_URL, TEST_URL } from '../data/constants';
import useAuth from '../features/authentication/hooks/useAuth';
import useToast from '../hooks/useToast';
import { useParams } from 'react-router-dom';

interface Params {
  [key: string]: string | number;
}

interface Headers {
  [key: string]: string;
}

interface ActionHandlers {
  [key: number]: (response: AxiosResponse) => void;
}

const useFetcher = (
  initialUrl = '',
  initialPayload: any = {},
  initialQueryParams: Params = {},
  initialHeaders: Headers = { 'Authorization': '' },
  actionHandlers: ActionHandlers = {},
  environmentMode?: string,
  serverApp?: string,
  showResponseToastP: boolean = false,
  loadOnInit: boolean = true,
  initToken: string | undefined | null = undefined
) => {
  const [urlState, setUrlState] = useState<string>(initialUrl);
  const [payload, setPayload] = useState<any>(initialPayload);
  const [queryParams, setQueryParams] = useState<Params>(initialQueryParams);
  const [headers, setHeaders] = useState<Headers>(initialHeaders);
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const getEnvMode = () => localStorage.getItem("envMode");
  const controller = useRef<AbortController>(new AbortController());
  const [showResponseToast, setShowResponseToast] = useState<boolean>(showResponseToastP);
  const [loadOnMount, setLoadOnMount] = useState<boolean>(loadOnInit);
  const [mounted, setMounted] = useState<boolean>(false);
  const { getUser } = useAuth();

  const { showError, showSuccess } = useToast()

  const getUrl = (mode?: string) => {
    let url =
      (environmentMode || getEnvMode()) === 'debug' ? LOCAL_URL :
        (environmentMode || getEnvMode()) === 'test' ? TEST_URL :
          (environmentMode || getEnvMode()) === 'prod' ? STAGING_URL : BASE_URL;

    return url;
  }

  const setShowResponseToastAs = (show: boolean) => {
    setShowResponseToast(show);
  }

  const user = getUser();

  const executePromise = (promise: Promise<any>) => {
    setIsLoading(true);
    return promise
      .then((res) => {
        //console.log(res);
        setIsLoading(false);
        return res;
      })
      .catch((err) => {
        //console.log(err);
        setIsLoading(false);
        setError(err);
        throw err;
      });
  };

  const fetchData = async (method: Method, url?: string | undefined, newPayload: any = null) => {
    const endpoint = getEndpoint(url ?? '');
    //console.log(method, getUrl() + url);
    //console.log(getEnvMode());

    const clientHeader = { 'AppName': 'CareMatchClientApp' };
    const authHeader = !!headers['Authorization'] || (!initToken && !getUser()?.token) ? {} : { 'Authorization': `Bearer ${initToken || getUser()?.token}` };
    const headersToSend = { ...headers, ...clientHeader, ...authHeader };

    return executePromise(
      axios({
        method,
        url: getUrl() + "" + endpoint,
        headers: headersToSend,
        params: queryParams,
        data: newPayload || payload,
        signal: controller.current.signal,
        maxRedirects: 5,  // This is the default
      })
    ).then((response) => {
      let data: any = {};
      if (!!response.data.status || !!response.data.data)
        data = response.data.data;
      else
        data = response.data;
      //console.debug('data', data);

      if (!!data?.message || typeof data === 'string') {
        if (showResponseToast)
          showSuccess(data?.message || data);
      }

      const redirectTo: string = response.data?.redirectTo ?? response.redirectTo;
      //console.debug(redirectTo);
      if (!!redirectTo) {

        //console.debug("pushing to:", redirectTo)
        window.location.href = redirectTo;
        window.history.pushState({}, '', redirectTo);
        return response;
      }

      const actionHandler = actionHandlers[response.status];
      if (actionHandler) {
        actionHandler(response);
      }
      //console.debug('full response', response);
      setData(data);
      const returnRes = { ...response, resultStatus: 'success' };
      return returnRes;
    }).catch((error) => {
      let responseData = error?.response?.data;
      let responseMessage = responseData?.message || responseData;

      if (!!responseMessage || !!error?.message) {
        if (showResponseToast)
          showError(responseMessage || error.message || "an unknown error has occured");
      }
      //console.debug('full error response', error);
      throw error;
    }).finally(() => {
    });
  };

  
  useEffect(() => {
    if (!urlState || !loadOnMount || mounted) return;
    setMounted(true);

    fetchData('get', '/' + urlState);
    return () => {
      //controller.current.abort();
    };
  }, [urlState, loadOnMount, mounted]); 

  const getEndpoint = (url: string): string => {
    let endpoint: string = '';

    if (!!url && url.startsWith('/')) {
      // override entire thing
      endpoint = url;
    }
    else if (!!initialUrl) {
      // use initial, if it has any value
      endpoint = initialUrl + (!!url ? '/' + url : '');
    }
    else {
      // just use currently passed url
      endpoint = url;
    }

    endpoint = replaceAllDoubleSlash(endpoint);
    endpoint = endpoint.startsWith('/') ? endpoint.slice(1) : endpoint;

    return endpoint;
  }
  const replaceAllDoubleSlash = (str: string) => {
    while (str.includes('//')) {
      str = str.replace('//', '/');
    }
    return str;
  }


  const get = (url?: string | undefined) => fetchData('get', url);
  const post = (url: string, payload: any) => {
    setPayload(payload);
    return fetchData('post', url, payload);
  };
  const put = (url: string, payload: any) => {
    setPayload(payload);
    return fetchData('put', url, payload);
  };
  const del = (url: string) => fetchData('delete', url);

  const addPayload = (newPayload: any) => {
    setPayload({ ...payload, ...newPayload });
  };

  const addQueryParameters = (newParams: Params) => {
    setQueryParams({ ...queryParams, ...newParams });
  };

  const addHeader = (key: string, value: string) => {
    setHeaders({ ...headers, [key]: value });
  };

  const removeHeader = (key: string) => {
    setHeaders((oldHeaders) => {
      const newHeaders = { ...oldHeaders };
      delete newHeaders[key];
      return newHeaders;
    });
  };

  const clear = () => {
    setData(null);
  };

  const abort = () => {
    controller.current.abort();
  };

  const abortAndReload = () => {
    controller.current = new AbortController(); // Reset abort controller
    setUrlState(urlState); // Trigger useEffect to fetch again
  };

  return {
    data,
    isLoading,
    error,
    addPayload,
    addQueryParameters,
    addHeader,
    removeHeader,
    clear,
    abort,
    abortAndReload,
    get,
    post,
    put,
    delete: del,

    showResponseToastAs: setShowResponseToastAs,
    setLoadOnMount
  } as FetcherHook;
};

export type FetcherHook = {
  data: any,
  isLoading: boolean,
  error: any,
  addPayload: (newPayload: any) => void,
  addQueryParameters: (newParams: Params) => void,
  addHeader: (key: string, value: string) => void,
  removeHeader: (key: string) => void,
  clear: () => void,
  abort: () => void,
  abortAndReload: () => void,
  get: (url?: string | undefined) => Promise<any>,
  post: (url: string, payload: any) => Promise<any>,
  put: (url: string, payload: any) => Promise<any>,
  delete: (url: string) => Promise<any>,

  showResponseToastAs: (show: boolean) => void
  setLoadOnMount: React.Dispatch<React.SetStateAction<boolean>>
}

export default useFetcher;