import React from 'react';
import { BoldTitle } from '../../../assets/styles/styledComponents';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Box } from '@mui/material';
import { app_Light_bg, app_x_light_bg, app_xx_light_bg, app_xxx_light_bg } from '../../../data/constants';

interface ExpandableWidgetProps {
    title: string;
    children: React.ReactNode;
    index: number;
    expandedIndex: number | null;
    onToggle: (index: number) => void;
    padding?: string;
    containerStyle?: string;
    titleSize?: string;
}

const ExpandableWidget: React.FC<ExpandableWidgetProps> = ({ title, titleSize, children, index, expandedIndex, onToggle, padding, containerStyle = 'border' }) => {
    const isExpanded = expandedIndex === index;

    return (
        <Box
            sx={{
                zIndex: 100,
                backgroundColor: containerStyle === 'border' ? 'white' : app_xxx_light_bg,
                border: containerStyle === 'border' ? '1px solid lightgrey' : 'none',
                borderRadius: '5px',
                margin: '0px',
                padding: padding || '5px',
                boxShadow: '20px'
            }}
        >
            <Box
                onClick={() => onToggle(index)}
                sx={{
                    cursor: 'pointer',
                    padding: '5px ' + containerStyle === 'border' ? '10px' : '3px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: containerStyle === 'border' ? 'white' : app_xx_light_bg,
                    border: containerStyle === 'border' ? '1px' : 'none',
                    borderRadius: '2px',
                    height: isExpanded ? 'auto' : '20px',
                }}
            >
                <BoldTitle fontSize={titleSize}>{title}</BoldTitle>
                {isExpanded ? <ArrowDropUp /> : <ArrowDropDown />}
            </Box>
            {isExpanded && <div style={{ padding: containerStyle === 'border' ? '10px' : '2px' }}>{children}</div>}
        </Box>
    );
};

export default ExpandableWidget;
