import React from 'react';
import { useParams } from 'react-router-dom';

const RequestAccess = () => {
    const params = useParams();
    const { email, ip, message } = useParams();

    console.log(params);

    switch (message) {
        case "EmailNotRegistered":
            return (
                <html>
                    <head>
                        <style>
                            {`
                body {
                  font-family: Arial, sans-serif;
                  background-color: #fff;
                }

                .container {
                  max-width: 600px;
                  margin: auto;
                  padding: 40px;
                  background-color: #e9ecf0;
                  border-radius: 10px;
                  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
                }

                .header {
                  font-size: 2rem;
                  margin-bottom: 20px;
                  color: #333;
                }

                .content {
                  font-size: 1.2rem;
                  margin-bottom: 30px;
                  color: #777;
                }
              `}
                        </style>
                    </head>
                    <body>
                        <div className="container">
                            <p className="header">Email Not Registered</p>
                            <p className="content">The provided email address is not registered in our system.</p>
                        </div>
                    </body>
                </html>
            );

        case "IpAddressVerificationSent":
            return (
                <html>
                    <head>
                        <style>
                            {`
                body {
                  font-family: Arial, sans-serif;
                  background-color: #fff;
                }

                .container {
                  max-width: 600px;
                  margin: auto;
                  padding: 40px;
                  background-color: #e9ecf0;
                  border-radius: 10px;
                  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
                }

                .header {
                  font-size: 2rem;
                  margin-bottom: 20px;
                  color: #333;
                }

                .content {
                  font-size: 1.2rem;
                  margin-bottom: 30px;
                  color: #777;
                }
              `}
                        </style>
                    </head>
                    <body>
                        <div className="container">
                            <p className="header">IP Address Verification Sent</p>
                            <p className="content">Your IP address: {ip}</p>
                            <p className="content">An email with a verification link has been sent to {email}.</p>
                            <p className="content">Please check your inbox and click the link to verify your IP address.</p>
                        </div>
                    </body>
                </html>
            );

        default:
            return (
                <form method="post">
                    <input type="email" name="email" required />
                    <input type="text" name="agency" required />
                    <button type="submit">Submit</button>
                </form>
            );
    }
};

export default RequestAccess;
