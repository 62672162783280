import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import PieChart from './PieChart'; // Assuming PieChart is a separate component
import { ApexOptions } from "apexcharts";
import BarChart from './BarChart';

interface DashboardBarChartProps {
    title: string;
    secondaryTextTop?: string;
    value: string | number;
    secondaryTextBottom?: string;
    chartData: ApexAxisChartSeries;
    chartOptions: ApexOptions; // Import ApexOptions from 'apexcharts'
}

const DashboardBarChart: React.FC<DashboardBarChartProps> = ({
    title,
    secondaryTextTop,
    value,
    secondaryTextBottom,
    chartData,
    chartOptions,
}) => {

    return (
        <Paper elevation={4} sx={{ borderRadius: '10px', backgroundColor: 'white', padding: '10px' }}>
            <Box>
                <Typography variant="h4" component="h4" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                    {title}
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '12px' }}>{secondaryTextTop || "-"}</Typography>
                <Typography variant="body1" sx={{ fontSize: '17px' }}>{value}</Typography>
                {secondaryTextBottom && (
                    <Typography variant="body2" sx={{ fontSize: '12px' }}>{secondaryTextBottom}</Typography>
                )}
            </Box>
            <Box>
                <BarChart
                    chartData={chartData}
                    chartOptions={chartOptions}
                />
            </Box>
        </Paper>
    );
};

export default DashboardBarChart;