import { Box } from "@mui/material";

type BadgeNumberProps = {
    count: number,
    position: 'left' | 'right',
}

const BadgeNumber = ({ count, position } : BadgeNumberProps) => {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: '8px',
                right: position === 'right' ? '-8px' : 'unset',
                left: position === 'left' ? '-8px' : 'unset',
                backgroundColor: '#7846ff',
                color: 'white',
                borderRadius: '50%',
                padding: '6px',
                fontSize: '12px',
                width: '5px',
                height: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {count}
        </Box>
    )
}

export default BadgeNumber;