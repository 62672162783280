import { ArrowBack, ArrowForward, ExpandLessOutlined, ExpandMoreOutlined, Filter1Outlined, FilterSharp, TuneOutlined } from "@mui/icons-material";
import { Box, Button, Checkbox, Chip, Divider, Fab, FormControl, FormControlLabel, FormGroup, MenuItem, Paper, Radio, RadioGroup, Select, Switch, TextField, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { Bold, BoldTitle, FlexBetween, FlexContainer, Text } from "../../assets/styles/styledComponents";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SearchBox } from "@mapbox/search-js-react";
import { Dayjs } from "dayjs";
import useFetcher from "../../hooks/useFetcher";
import { MAPBOX_TOKEN } from "../../data/constants";

const ControlPanel = (props: any) => {
  const [expanded, setExpanded] = useState(false)
  const [openAdvanced, setOpenAdvanced] = useState(false)
  const [from, setFrom] = useState<Dayjs | null>(null);
  const [to, setTo] = useState<Dayjs | null>(null);
  const [offices, setOffices] = useState<any[]>([]);
  const [coordinators, setCoordinators] = useState<any[]>([]);

  const expandFilters = () => {
    setExpanded(!expanded)
    setOpenAdvanced(false)
  }

  const officesFetcher = useFetcher("filter/offices");
  const coordinatorsFetcher = useFetcher("filter/coordinators");

  useEffect(() => {
    setOffices(officesFetcher.data || []);
    setCoordinators(coordinatorsFetcher.data || []);
  }, [
    officesFetcher.data,
    coordinatorsFetcher.data,
  ]);

  const handleAddress = (e: any) => {
    props.setFilters({
      ...props.filters,
      address: {
        addressLine1: e.features[0].properties.context.address?.name,
        city: e.features[0].properties?.context?.locality?.name || e.features[0].properties.context.place?.name,
        state: e.features[0].properties.context.region?.name,
        country: e.features[0].properties.context.country?.name,
        zipCode: e.features[0].properties.context.postcode?.name,
        latitude: e.features[0].geometry.coordinates[1],
        longitude: e.features[0].geometry.coordinates[0],
        full: e.features[0].properties.full_address
      }
    })
  }

  const handleChange = (e: any) => {
    props.setFilters({
      ...props.filters,
      [e.target.name]: e.target.value
    })
  }

  const handleTimeChange = (e: any, field: any, fieldString: any) => {
    field(e)
    props.setFilters({
      ...props.filters,
      timing: props.filters?.timing?.map((d: any) => ({
        ...d,
        [fieldString]: e,
      }))
    })
  }

  useEffect(() => {
    props.setFilters({
      ...props.filters,
      timing: props.filters?.timing?.map((d: any) => ({
        ...d,
        from: from?.format('hh:mm a'),
        to: to?.format('hh:mm a')
      }))
    })
  }, [from, to, props.filters.timing])

  const handleSwitch = (e: any) => {
    props.setFilters({
      ...props.filters,
      [e.target.name]: !props.filters[e.target.name]
    })
  }

  const handleOpenAdvanced = () => {
    setOpenAdvanced(!openAdvanced)
  }

  const handleDays = (e: any) => {
    let action: any;
    if (e.target.checked == true)
      action = [...props.filters.timing, { day: e.target.id, from: from, to: to }]
    if (e.target.checked == false)
      action = props.filters.timing.filter((d: any) => d.day != e.target.id)

    props.setFilters({
      ...props.filters,
      timing: action
    })
  }

  const setAddressFor = (value: string) => {
    props.setFilters({
      ...props.filters,
      addressFor: value
    })
  }

  const handleSubmit = () => {
    props.submit()
    setExpanded(false)
  }

  const statusOptions = [
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' },
    { value: 'Discharged', label: 'Discharged' },
    { value: 'On Leave', label: 'On Leave' },
  ]

  const disciplineOptions = [
    { value: 'RN' },
    { value: 'PCA' },
    { value: 'HHA' },
    { value: 'PA' },
    { value: 'All' }
  ]

  const genderOptions = [
    { value: 'Male' },
    { value: 'Female' },
  ]

  return (
    <Box zIndex={10000}>
      <Tooltip title='Filters'>
        <Fab
          // variant="contained"
          size="small"
          onClick={() => expandFilters()}
          sx={{
            backgroundColor: 'white',
            position: 'absolute',
            top: '10px',
            right: '10px',
          }}
        >
          {expanded
            ? <ExpandLessOutlined color="primary" />
            : <TuneOutlined color="primary" />
          }
        </Fab>
      </Tooltip>
      <Paper elevation={8} sx={{ position: 'absolute', right: 10, top: '55px', display: expanded ? 'block' : 'none', zIndex: 1000 }}>
        <FlexContainer>
          <Box sx={{ width: '300px' }} padding={'5px'} borderRight={'2px solid lightgrey'} display={openAdvanced && expanded ? 'block' : 'none'}>
            <FlexBetween>
              <BoldTitle>Availability</BoldTitle>
              {/* <Button sx={{ fontSize: '10px' }} onClick={() => setTimingType(timingType == 'daily' ? 'custom' : timingType == 'custom' ? 'daily' : '')} size="small">{timingType == 'daily' ? 'custom' : timingType}</Button> */}
            </FlexBetween>
            <FlexBetween>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Bold>From</Bold>
                <TimePicker
                  onChange={(e) => handleTimeChange(e, setFrom, 'from')}
                  value={from}
                  renderInput={(params) =>
                    <TextField
                      sx={{ margin: '5px' }}
                      {...params}
                    />}
                />
                <Bold>To</Bold>
                <TimePicker

                  onChange={(e) => handleTimeChange(e, setTo, 'to')}
                  value={to}
                  renderInput={(params) =>
                    <TextField
                      sx={{ margin: '5px' }}
                      {...params}
                    />}
                />
              </LocalizationProvider>
            </FlexBetween>
            <FlexBetween>
              <Bold>Days</Bold>
              <FormGroup row onChange={(e) => handleDays(e)}>
                <FormControlLabel control={<Checkbox id="1" size="small" sx={{ marginRight: -1 }} />} label='S' sx={{ marginRight: '3px' }} />
                <FormControlLabel control={<Checkbox id="2" size="small" sx={{ marginRight: -1 }} />} label='M' sx={{ marginRight: '3px' }} />
                <FormControlLabel control={<Checkbox id="3" size="small" sx={{ marginRight: -1 }} />} label='T' sx={{ marginRight: '3px' }} />
                <FormControlLabel control={<Checkbox id="4" size="small" sx={{ marginRight: -1 }} />} label='W' sx={{ marginRight: '3px' }} />
                <FormControlLabel control={<Checkbox id="5" size="small" sx={{ marginRight: -1 }} />} label='T' sx={{ marginRight: '3px' }} />
                <FormControlLabel control={<Checkbox id="6" size="small" sx={{ marginRight: -1 }} />} label='F' sx={{ marginRight: '3px' }} />
                <FormControlLabel control={<Checkbox id="7" size="small" sx={{ marginRight: -1 }} />} label='S' sx={{ marginRight: '3px' }} />
              </FormGroup>
            </FlexBetween>
            <div style={{ marginTop: '15px' }}>
              <BoldTitle>Address</BoldTitle>
              <SearchBox value={props.filters?.address?.full} accessToken={MAPBOX_TOKEN} onRetrieve={(e) => handleAddress(e)} />
              {/* <FormGroup row sx={{ justifyContent: 'center' }}>
                <FormControlLabel label='Caregiver' control={<Radio onClick={() => setAddressFor('caregiver')} checked={props.filters.addressFor == 'caregiver'} />} />
                <FormControlLabel label='Patient' control={<Radio onClick={() => setAddressFor('patient')} checked={props.filters.addressFor == 'patient'} />} />
              </FormGroup> */}
            </div>
          </Box>

          <Box sx={{ background: 'white', height: '100%', padding: '5px', width: '300px' }} display={expanded ? 'block' : 'none'}>
            <Button startIcon={openAdvanced ? <ArrowForward /> : <ArrowBack />} onClick={() => handleOpenAdvanced()} size="small" variant="text">Advanced Filters</Button>

            {/* General */}
            <BoldTitle>General</BoldTitle>
            <Bold>Office</Bold>
            <Select
              renderValue={(selected) => (
                selected.map((select: any) => {
                  const option = offices.find((o: any) => o.id == select);
                  return (
                    <Chip size="small" color="primary" label={option?.officeName} sx={{ margin: '3px', height: '20px' }} />
                  )
                }))}
              multiple
              name="offices"
              sx={{ height: '35px', overflow: 'visible' }}
              fullWidth
              value={props.filters.offices}
              onChange={(e) => handleChange(e)}
            >
              {offices.map((option: any) => (
                <MenuItem value={option?.id} title={option?.officeName}>{option?.officeName}</MenuItem>
              ))}
            </Select>

            <Bold>Coordinator</Bold>
            <Select
              renderValue={(selected) => (
                selected.map((select: any) => {
                  const option = coordinators.find((o: any) => o.id == select);
                  return (
                    <Chip size="small" color="primary" label={option?.name} sx={{ margin: '3px', height: '20px' }} />
                  )
                }))}
              multiple
              name="coordinators"
              sx={{ height: '35px' }}
              fullWidth
              value={props.filters.coordinators}
              onChange={(e) => handleChange(e)}
            >
              {coordinators.map((option: any) => (
                <MenuItem value={option?.id} title={option?.name}>{option?.name}</MenuItem>
              ))}
            </Select>

            <Bold>Gender</Bold>
            <Select
              name="gender"
              sx={{ height: '35px' }}
              fullWidth
              value={props.filters.gender}
              onChange={(e) => handleChange(e)}
            >
              {genderOptions.map((option: any) => (
                <MenuItem value={option?.value} title={option?.value}>{option?.value}</MenuItem>
              ))}
            </Select>
            <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />



            {/* Caregiver */}
            <FlexBetween>
              <BoldTitle>Caregivers</BoldTitle>
              <Switch
                name="showCaregivers"
                value={props.filters.showCaregivers}
                checked={props.filters.showCaregivers}
                onClick={(e) => handleSwitch(e)}
              />
            </FlexBetween>

            <Box sx={{ display: props.filters.showCaregivers ? 'block' : 'none' }}>
              <Bold>Status</Bold>
              <Select
                renderValue={(selected) => (
                  selected.map((select: any) => (
                    <Chip size="small" color="primary" label={select} sx={{ margin: '3px', height: '20px' }} />
                  )))}
                multiple
                name="CStatus"
                sx={{ height: '35px' }}
                fullWidth
                value={props.filters.CStatus}
                onChange={(e) => handleChange(e)}
              >
                {statusOptions.map((option) => (
                  <MenuItem
                    value={option?.value}
                    title={option?.label}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </Select>

              <Bold>Discipline</Bold>
              <Select
                multiple
                name="discipline"
                sx={{ height: '35px' }}
                fullWidth
                value={props.filters.discipline}
                onChange={(e) => handleChange(e)}
              >
                {disciplineOptions.map((option) => (
                  <MenuItem value={option?.value} title={option?.value}>{option?.value}</MenuItem>
                ))}
              </Select>

              <Bold>Has Schedule</Bold>
              <Select name="hasSched" sx={{ height: '35px' }} fullWidth value={props.filters.hasSched} onChange={(e) => handleChange(e)}>
                <MenuItem value={'yes'}>Yes</MenuItem>
                <MenuItem value={'no'}>No</MenuItem>
                <MenuItem value={'all'}>All</MenuItem>
              </Select>
              <Bold>Hired After</Bold>
              <Select name="hiredAfter" sx={{ height: '35px' }} fullWidth value={props.filters.hiredAfter} onChange={(e) => handleChange(e)}>
                <MenuItem value={'30'}>30 Days</MenuItem>
                <MenuItem value={'60'}>60 Days</MenuItem>
                <MenuItem value={'90'}>90 Days</MenuItem>
              </Select>
              <Bold>Last Worked After</Bold>
              <Select name="workedAfter" sx={{ height: '35px' }} fullWidth value={props.filters.workedAfter} onChange={(e) => handleChange(e)}>
                <MenuItem value={'30'}>30 Days</MenuItem>
                <MenuItem value={'60'}>60 Days</MenuItem>
                <MenuItem value={'90'}>90 Days</MenuItem>
              </Select>
              <Divider sx={{ marginTop: '10px', marginBottom: '10px' }} />
            </Box>

            {/* Patient */}
            <FlexBetween>
              <BoldTitle>Patient</BoldTitle>
              <Switch
                name="showPatients"
                value={props.filters.showPatients}
                checked={props.filters.showPatients}
                onClick={(e) => handleSwitch(e)}
              />
            </FlexBetween>

            <Box sx={{ display: props.filters.showPatients ? 'block' : 'none' }}>
              <Bold>Status</Bold>
              <Select
                renderValue={(selected) => (
                  selected.map((select: any) => (
                    <Chip size="small" color="primary" label={select} sx={{ margin: '3px', height: '20px' }} />
                  )))}
                multiple
                name="PStatus"
                sx={{ height: '35px' }}
                fullWidth
                value={props.filters.PStatus}
                onChange={(e) => handleChange(e)}
              >
                {statusOptions.map((option) => (
                  <MenuItem
                    value={option?.value}
                    title={option?.label}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </Select>
              <Bold>Has Open Visit</Bold>
              <Select name="needsCare" sx={{ height: '35px' }} fullWidth value={props.filters.needsCare} onChange={(e) => handleChange(e)}>
                <MenuItem value={'yes'}>Yes</MenuItem>
                <MenuItem value={'no'}>No</MenuItem>
                <MenuItem value={'all'}>All</MenuItem>
              </Select>
            </Box>
          </Box>
        </FlexContainer>
        <Button onClick={handleSubmit} sx={{ float: 'right', marginRight: '15px' }}>Apply</Button>
      </Paper>
    </Box>
  );
}

export default ControlPanel;