import React from 'react';
import { styled } from '@mui/material/styles';
import { theme } from '../theme/theme';
import { AppBar, AppBarProps, Badge, BadgeProps, TableCell, TableCellProps } from '@mui/material';

export const AppBarStyled = styled(AppBar)<AppBarProps>(({ theme }) => ({

}));

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -30,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

export const StyledTableCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
    head: {
        backgroundColor: "#e2eefa",
        color: theme.palette.common.white,
        lineHeight: "0.4rem",
        fontSize: 12,
        minWidth: 20,
        padding: '10px 5px !important',
        overflow: "hidden",
        maxWidth: '70px',
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        textAlign: 'center'
    },
}));

export const DivContainer: any = styled("div")((props: any) => ({
    position: props.position || 'unset',
    background: props.bgColor || 'unset',
    display: 'block',
    padding: props.padding || 'unset',
    margin: props.margin || 'unset',
    width: props.width || 'unset',
    height: props.height || 'unset',
    borderBottom: props.borderBottom || 'unset'
}));

export const Container: any = styled("div")((props: any) => ({
    position: props.position || 'unset',
    background: props.bgColor || 'unset',
    display: 'block',
    padding: props.padding || 'unset',
    margin: props.margin || 'unset',
    width: props.width || 'unset',
    height: props.height || 'unset',
    borderBottom: props.borderBottom || 'unset'
}));

export const FlexAlignCenter: any = styled("div")((props: any) => ({
    background: props.bgColor || 'unset',
    display: 'flex',
    alignItems: 'center',
    flexWrap: props.wrap || 'unset',
    padding: props.padding || 'unset',
    margin: props.margin || 'unset',
}))

export const FlexExactCenter: any = styled("div")((props: any) => ({
    padding: props.padding || 'unset',
    background: props.bgColor || 'unset',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minHeight: props.minHeight || 'unset'

}));

export const FlexColExactCenter: any = styled("div")((props: any) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    minHeight: props.minHeight || 'unset'

}));

export const ScrollContainer: any = styled("div")((props: any) => ({
    display: 'block',
    overflowY: 'scroll',
    maxHeight: props.maxHeight || 'unset',
    minHeight: props.minHeight || 'unset',
    '&::-webkit-scrollbar': {
        display: 'none'
    }

}));

export const BoxContainer: any = styled("div")((props: any) => ({
    position: props.position || 'unset',
    display: props.display || 'block',
    alignItems: props.alignItems || 'unset',
    padding: props.padding || 'unset',
    backgroundColor: props.background || 'unset',
    border: props.borderPx || '1px solid rgba(0, 0, 0, 0.12)',
    minHeight: props.minHeight || 'unset',
    height: props.height || 'unset',
    margin: props.margin || 'unset',
    width: props.width || 'unset',
    float: props.float || 'unset',
    style: props.style
}));

export const VerticalDivider: any = styled("div")((props: any) => ({
    height: props.height || '50%',
    width: '1px',
    background: 'rgba(0, 0, 0, 0.12)'
}));

export const Row: any = styled("div")((props: any) => ({
    display: props.display || 'block',
    padding: props.padding || '10px 5px',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
}));

export const Block = styled("div")({
    display: 'block'
});

export const FlexJustifyEnd: any = styled("div")((props: any) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    margin: props.margin || 'unset',
    alignItems: 'center'
}));

export const FlexJustifyStart: any = styled("div")((props: any) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    margin: props.margin || 'unset',
    alignItems: 'center'
}));

export const TableBodyContainer = styled("div")({
    height: '300px',
    overflowY: 'scroll',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

export const ColumnHeader: any = styled("div")((props: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: props.bgColor || theme.palette.primary.main,
    color: props.color || '#fff',
    padding: '5px'
}));

export const ColumnItem: any = styled("div")((props: any) => ({
    display: props.hide ? 'none' : 'block',
    padding: '5px 5px',
    width: props.width || 'unset',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

}));

export const ColumnItemEditMode: any = styled("div")((props: any) => ({
    display: props.hide ? 'none' : 'block',
    padding: '5px 5px 0px',
    width: props.width || 'unset',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

}));

export const SpacingBlock: any = styled("div")((props: any) => ({
    marginTop: props.top || 'unset',
    marginBottom: props.bottom || 'unset'
}));

export const RowItem: any = styled("div")({
    padding: '3px 5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: `1px solid ${theme.palette.background.paper}`,
    '&:hover': {
        background: 'rgba(0, 0, 0, 0.04)'
    }
});

export const CardHeaderAction: any = styled('div')((props: any) => ({
    padding: '0px 10px',
    display: 'flex',
    justifyContent: props.justifyContent || 'flex-end',
    alignItems: 'center',
    border: '1px solid rgba(0, 0, 0, 0.12)'
}));

export const FlexContainer: any = styled('div')((props: any) => ({
    display: props.display || 'flex',
    justifyContent: props.justify || 'unset',
    alignItems: props.alignItems || 'unset',
    margin: props.margin || '10px 3px',
    padding: props.padding || 'unset',
}));

export const Flex: any = styled('div')((props: any) => ({
    width: props.width || 'unset',
    display: 'flex',
    marginTop: props.marginTop || '0px'
}));

export const FlexGrow: any = styled('div')((props: any) => ({
    flexGrow: props.grow || 1,
    padding: props.padding || 'unset',
    margin: props.margin || 'unset',

}));

export const FlexBetween: any = styled('div')((props: any) => ({
    width: props.width || 'unset',
    background: props.bgColor || "unset",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: props.padding || '5px',
    borderRadius: props.borderRadius || 'unset',
    borderWidth: props.borderWidth || '0px',
    borderStyle: props.borderStyle || 'solid',
    borderColor: props.borderColor || 'lightgray',
}));

export const FlexEvenly: any = styled('div')((props: any) => ({
    width: props.width || 'unset',
    background: props.bgColor || "unset",
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: props.alignItems || 'center',
    padding: props.padding || 'unset',
    margin: props.margin || 'unset',
    float: props.float || 'unset',
}));

export const FlexAround: any = styled('div')((props: any) => ({
    width: props.width || 'unset',
    background: props.bgColor || "unset",
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '5px',

}));

export const TextButton: any = styled('div')((props: any) => ({
    display: props.hide ? 'none' : 'block',
    color: props.color === 'primary' ? theme.palette.primary.main : '#000',
    textAlign: props.textAlign || 'default',
    padding: '10px 15px',
    cursor: 'pointer',
    opacity: props.disable ? 0.4 : 1,
    pointerEvents: props.disable ? 'none' : 'unset'
}));

export const TitleContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

export const Text: any = styled('div')((props: any) => ({
    display: props.display || 'unset',
    wordBreak: 'break-word',
    fontSize: props.fontSize || '14px',
    textAlign: props.textAlign || 'unset',
    fontWeight: props.fontWeight || 400,
    marginRight: props.marginRight || '10px',
    cursor: props.pointer ? 'pointer' : 'unset',
    width: props.width || 'unset',
    color: props.color || '#000',
    fontFamily: props.fontFamily || 'ConnectAideSecondary'
}));

export const BoldTitle: any = styled('div')((props: any) => ({
    padding: props.padding || 'unset',
    fontSize: props.fontSize || '14px',
    fontWeight: props.fontWeight || 600,
    fontFamily: props.fontFamily || 'ConnectAideSecondary',
    margin: props.margin || '0px 10px 0px 0px',
    width: props.width || 'unset',
    color: props.color || '#000',
    textAlign: props.textAlign || 'unset',
    overflowX: 'visible'
}));

export const UnderlinedTitle: any = styled('div')((props: any) => ({
    textDecoration: 'underline',
    fontSize: props.fontSize || '14px',
    fontWeight: props.fontWeight || 600,
    marginRight: '10px',
    fontFamily: props.fontFamily || 'ConnectAideSecondary',
    width: props.width || 'unset',
    color: props.color || '#000'
}));
export const Title: any = styled('div')((props: any) => ({
    padding: props.padding || 'unset',
    fontSize: props.fontSize || '14px',
    fontWeight: props.fontWeight || 500,
    fontFamily: props.fontFamily || 'ConnectAideSecondary',
    margin: props.margin || '0px 10px 0px 0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: props.width ? `${props.width}px` : 'unset',
    color: !!props.color ? props.color : props.completed ? theme.palette.success.main : `#000`,
    textAlign: props.textAlign || 'unset',
}));

export const Bold: any = styled('b')((props: any) => ({
    fontWeight: props.fontWeight ? props.fontWeight : 500,
    fontSize: props.fontSize,
    margin: props.margin || 'unset',
    fontFamily: props.fontFamily,
    color: props.color
}));

export const Header: any = styled('h2')((props: any) => ({
    borderBottom: props.borderBottom || '1px solid rgba(0, 0, 0, 0.12)',
    padding: props.padding,
}))