import { Autocomplete, Box, Button, Drawer, IconButton, MenuItem, Select, TextField } from "@mui/material"
import { DateRangePicker, SingleInputDateRangeField } from "@mui/x-date-pickers-pro"
import dayjs, { Dayjs } from "dayjs"
import { useEffect, useState } from "react"
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { Bold, BoldTitle } from "../../../assets/styles/styledComponents";
import { Close, UnfoldLess } from "@mui/icons-material";
import useFetcher from "../../../hooks/useFetcher";

type FilterProps = {
    open: boolean,
    setOpenFilters: any,
    getInvites: any,
    setQuery: any
    sentDates: [Dayjs | null, Dayjs | null],
    setSentDates: any,
    visitDates: [Dayjs | null, Dayjs | null],
    setVisitDates: any,
    selectedCoordinator: any,
    setSelectedCoordinator: any,
    selectedStatus: any,
    setSelectedStatus: any
}

const StatusOptions = [
    'Pending',
    'Accepted',
    'Declined',
]

const InviteFilters = ({ open, setOpenFilters, getInvites, setQuery, sentDates, setSentDates, visitDates, setVisitDates, selectedCoordinator, setSelectedCoordinator, selectedStatus, setSelectedStatus }: FilterProps) => {
    const fetcher = useFetcher();
    const [coordinators, setCoordinators] = useState<any[]>([]);

    const getCoordinators = () => {
        fetcher.get('filter/coordinators').then((res: any) => {
            setCoordinators(res.data.data)
        })
    }

    const handleSelectedCoordinator = (id: any) => {
        const coordinator = coordinators.find(c => c.id == id)
        setSelectedCoordinator(coordinator)
    }

    useEffect(() => {
        getCoordinators()
    }, [])

    const handleApply = () => {
        getInvites()
        setOpenFilters(false)
    }

    return (
        <Drawer anchor="right" open={open} onClose={() => setOpenFilters(false)}
            componentsProps={{ backdrop: { style: { backgroundColor: "rgba(255,255,255, 0)" } } }}
            PaperProps={{
                sx: {
                    width: '265px',
                    padding: '5px 20px',
                }
            }}
        >
            <div style={{ display: 'flex', justifyContent: 'right' }}>
                <IconButton onClick={() => setOpenFilters(false)}>
                    <Close fontSize="small" />
                </IconButton>
            </div>
            <div style={{ marginTop: '20px' }}>
                <BoldTitle>Sent Date</BoldTitle>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateRangePicker
                        calendars={1}
                        slots={{ field: SingleInputDateRangeField }}
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ padding: 0.5, width: "250px" }}
                        value={sentDates}
                        onChange={(value) => setSentDates(value)}
                        closeOnSelect={false}
                    />
                </LocalizationProvider>
            </div>
            <div style={{ marginTop: '20px' }}>
                <BoldTitle>Visit Date</BoldTitle>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateRangePicker
                        calendars={1}
                        slots={{ field: SingleInputDateRangeField }}
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{ padding: 0.5, width: "250px" }}
                        value={visitDates}
                        onChange={(value) => setVisitDates(value)}
                        closeOnSelect={false}
                    />
                </LocalizationProvider>
            </div>
            <div style={{ marginTop: '20px' }}>
                <BoldTitle>Status</BoldTitle>
                <Select
                    multiple
                    value={selectedStatus}
                    onChange={(e: any) => setSelectedStatus(e.target.value)}
                    size="small"
                    margin='dense'
                    sx={{
                        width: '250px',
                        marginLeft: '5px'
                    }}
                    endAdornment={selectedStatus.length > 0 &&
                        <IconButton onClick={() => { setSelectedStatus([]) }} sx={{ marginRight: '20px' }}>
                            <Close fontSize="small" />
                        </IconButton>}
                >
                    {StatusOptions.map((option: any) =>
                        <MenuItem key={option} value={option}>{option}</MenuItem>
                    )}
                </Select>
            </div>
            <div style={{ marginTop: '20px' }}>
                <BoldTitle>Sent By</BoldTitle>
                <Autocomplete
                    multiple
                    disableClearable
                    filterSelectedOptions
                    onChange={(e, option) => { handleSelectedCoordinator(option.map(o => coordinators?.find((of: any) => of.name == o))) }}
                    size='small'
                    getOptionLabel={(option) => option}
                    defaultValue={selectedCoordinator?.map((o: any) => o.name)}
                    renderInput={(params) => <TextField {...params} value={selectedCoordinator} label="Coordinator" />}
                    options={coordinators?.map((coordinator: any) => coordinator.name)}
                />
            </div>
            <Button sx={{ position: 'absolute', bottom: '40px', width: '260px' }} variant="contained" onClick={() => handleApply()}>Apply</Button>
        </Drawer>
    )
}

export default InviteFilters