import React, { useState } from 'react';
import useApi_DEPRACATED from '../../../../hooks/useApi';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '../../../../lib/MUI/mui.components';
import ReactLoader from '../../../common/ReactLoader';
import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import NewModal from '../../NewModal';

interface FeedbackModalProps {
  open: boolean;
  action: string | null,
  title: string,
  isLoading?: boolean,
  onClose: (data?: { action: string | null, description: string } | any) => void;
}

const FeedbackModal = ({ open, onClose, title, action, isLoading }: FeedbackModalProps) => {
  const [description, setDescription] = useState<string>('');
  const [fieldError, setFieldError] = useState('');
  const [reason, setReason] = useState('');
  const [checked, setChecked] = useState(false);
  const [tillDate, setTillDate] = useState<Date>(new Date())

  const resetState = () => {
    setDescription('');
    setFieldError('');
    setChecked(false);
  }

  const handleCheck = () => {
    setChecked(!checked);
  }

  const handleSaveClick = () => {
    if (!description) {
      setFieldError('This field is required');
      return
    }
    resetState()
    onClose({
      action: action,
      description: description,
      reason: reason,
      tillDate: tillDate
    });
  };

  const handleCancelClick = (event: any, reason: string | undefined) => {
    if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) return
    onClose();
    resetState()
  };

  const handleDate = (value: any) => {
    setTillDate(value)
  }

  const declineReasons = [
    { key: '', value: 'None' },
    { key: 'patient', value: 'Patient does not prefer this caregiver' },
    { key: 'caregiver', value: 'Caregiver does not prefer this patient' },
    { key: 'available', value: 'Caregiver is not available at this time' }
  ]

  return (
    <Dialog open={open} onClose={handleCancelClick} sx={{ width: '100%' }}>
      <ReactLoader isLoading={isLoading as boolean} />
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{
        '&.MuiDialogContent-root': {
          padding: '10px',
          width: '500px'
        }
      }}>
        {title == 'Decline' &&
          <FormControl fullWidth >
            <InputLabel id='decline-select'>Select Decline Reason</InputLabel>
            <Select
              labelId='decline-select'
              label='Select Decline Reason'
              value={reason}
              onChange={(event) => setReason(event.target.value)}
              sx={{ marginBottom: '10px' }}
            >
              {declineReasons.map((option: any) => (
                <MenuItem key={option.key} value={option.key}>{option.value}</MenuItem>
              ))}
            </Select>
          </FormControl>}
        <TextField
          id="feedback"
          label="Description"
          multiline
          value={description}
          maxRows={4}
          minRows={4}
          fullWidth={true}
          onChange={(event) => {
            setFieldError('')
            setDescription(event.target.value)
          }}
          error={!!fieldError}
          helperText={fieldError}
        />
        {title == 'Decline' && reason != 'available' && <FormControlLabel
          sx={{ padding: '2px' }}
          control={
            <Checkbox
              onClick={handleCheck}
              value={checked}
              color='primary'
            />}
          label="Valid Through"
        />}
        {checked &&
          <LocalizationProvider dateAdapter={AdapterDayjs as any}>
            <DatePicker value={tillDate} onChange={(value: any) => handleDate(value)}
              renderInput={(params: any) => <TextField sx={{
                mt: 1, mb: 2,
                '& .MuiOutlinedInput-input': {
                  padding: '4px 10px',
                }
              }}
                InputLabelProps={{
                  shrink: true,
                }}
                {...params} helperText={null} />}
            >
            </DatePicker>
          </LocalizationProvider>}
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={(e) => handleCancelClick(e, undefined)}>Cancel</Button>
        <Button variant='outlined' onClick={handleSaveClick}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackModal;
