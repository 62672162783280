import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { dateTime } from "../../../../../helper/datetime";
import { BoldTitle, Text } from "../../../../../assets/styles/styledComponents";
import DataGrid from "../../../../../components/ui/DataGrid";
import useFetcher from "../../../../../hooks/useFetcher";
import InviteDetailsModal from "./InviteDetailsModal";
import useInviteUI from "../../../../invites/hooks/useInviteUI";

type HistoryProps = {
  caregiverId?: string,
  patientId?: string,
  visitId?: string,
  title?: string,
  headerHeight?: number,
  height?: string,
  from?: Date,
  to?: Date
}

const InviteHistory = ({ caregiverId, patientId, visitId, title, headerHeight, height, from, to }: HistoryProps) => {
  const fetcher = useFetcher();
  const [history, setHistory] = useState<any[]>([]);
  const [groupedRows, setGroupedRows] = useState<any[]>([])
  const [openDetails, setOpenDetails] = useState(false)
  const [selectedCode, setSelectedCode] = useState<any>(null)
  const { getStatusColor, columns } = useInviteUI();

  useEffect(() => {
    if (!caregiverId && !patientId && !visitId) {
      setHistory([]);
      return;
    }

    fetcher.get(`Invite/history?caregiverId=${caregiverId || '0'}&patientId=${patientId || '0'}&visitId=${visitId || 0}`).then((res: any) => {
      setHistory(res.data.data);
    }).catch(err => {
      if (err?.response?.status == 404)
        return;
      throw err;
    })
  }, [caregiverId, patientId, visitId])

  const organizeInvitesByInviteCode = (invites: any) => {
    const invitesByPatient: Record<string, any> = {};

    invites.forEach((invite: any) => {
      const { inviteCode, patientId, patientName, caregiverName, dateSent, visitStatus, clicks, status, sentBy } = invite;

      if (!invitesByPatient[inviteCode]) {
        invitesByPatient[inviteCode] = {
          inviteCode,
          patientId,
          patientName,
          caregiverName,
          dateSent,
          sentBy,
          visitStatus,
          clicks,
          status,
          inviteCount: 0,
        };
      }
      invitesByPatient[inviteCode].inviteCount++;
    });

    const result = Object.values(invitesByPatient);

    return result;
  }

  useEffect(() => {
    setGroupedRows(organizeInvitesByInviteCode(history))
  }, [history])

  const handleRowClick = (row: any) => {
    if (!row.inviteCode)
      return;

    setSelectedCode(row.inviteCode)
    setOpenDetails(true)
  }

  let columnsToDisplay: string[] = ['inviteCode', 'dateSent', 'status', 'visitStatus', 'clicks'];
  if (!!patientId)
    columnsToDisplay.push('caregiverName')
  if (!!caregiverId)
    columnsToDisplay.push('patientName')

  return (
    <div>
      <DataGrid
        loading={fetcher.isLoading}
        data={{
          columns: columns(columnsToDisplay),
          rows: groupedRows,
        }}
        options={{
          hideExport: true,
          hideFiltering: true,
          hideColumns: true,
          headerBGColor: 'rgb(232,224,255)',
          headerTextColor: '#222',
          height: height || '200px',
          pagination: false,
          headerHeight: headerHeight || 25,
          rowHeight: 25,
          disableColumnReorder: true,
          hideFooterRowCount: true,
          hideFooter: true,
        }}
        onRowClick={(e: any) => handleRowClick(e.row)}
        headerMessage={<BoldTitle textAlign='center'>{title || 'Past Invites'}</BoldTitle>}
      />
      <InviteDetailsModal open={openDetails} setOpen={setOpenDetails} inviteCode={selectedCode} />
    </div>
  )
}

export default InviteHistory