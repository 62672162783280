import React, { useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

export interface EditTextFieldProps {
    size?: 'small' | 'medium' | 'large';
    isOpen?: boolean;
    onSetFocused?: (isOpen: boolean) => void;
    // Other TextField props (label, variant, etc.)
}

const EditTextField: React.FC<EditTextFieldProps & TextFieldProps> = ({
    size = 'medium',
    isOpen = false,
    onSetFocused,
    ...otherProps
}) => {
    const [focused, setFocused] = useState(isOpen);

    const handleFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        setFocused(true);
        onSetFocused?.(true);
    };

    const handleBlur = () => {
        setFocused(false);
        onSetFocused?.(false);
    };

    return (
        <TextField
            {...otherProps}
            fullWidth // Set full width for consistent look
            onFocus={handleFocus}
            onBlur={handleBlur}
            sx={{
                '& .MuiInputBase-root': { // Target the root element
                    padding: '0px 4px',
                },
                '& .MuiInputBase-input': { // Target the input element within
                    padding: '0px 4px',
                },
                ...(size === 'small'
                    ? { fontSize: '14px', padding: '0px' }
                    : size === 'medium'
                        ? { fontSize: '16px', padding: '0px' }
                        : { fontSize: '18px', padding: '0px' }),
                backgroundColor: focused ? 'white' : '#ECEFF7',
                border: focused ? 'none' : 'none',
            }}
            InputProps={{
              sx: {
                fontSize: size === 'small' ? '14px' : size === 'medium' ? '18px' : '18px',
                padding: '0px',
              },
            }}
        />
    );
};

export default EditTextField;