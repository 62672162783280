import { useCallback } from 'react';
import debounce from 'lodash.debounce';

function useDebounce2(callback: Function, delay: number) {
	const debouncedFn = useCallback(
		debounce((...args) => callback(...args), delay),
		[delay], // will recreate if delay changes
    );
    
	return debouncedFn;
}

export default useDebounce2;