import { Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Modal, Paper, TextareaAutosize, Tooltip } from "@mui/material";
import { BoldTitle, Flex, FlexEvenly, FlexJustifyStart } from "../../../../../assets/styles/styledComponents";
import InviteHistory from "./InviteHistory";
import useFetcher from "../../../../../hooks/useFetcher";
import { EditOff, EditOutlined, Repeat } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { dateTime, getShortTimeFormat } from "../../../../../helper/datetime";
import ChatMessages from "../../../../../pages/Chat/ChatMessages";
import { MessageDto } from "../../../../../pages/Chat/types";
import useAuth from "../../../../authentication/hooks/useAuth";
import Loader from "../../../../../components/ui/Loader";
import { CUSTOM_SCROLL, app_light } from "../../../../../data/constants";
import { Link, useNavigate } from "react-router-dom";
import OptionButton from "../../../../../components/common/OptionButton/OptionButton";
import { dateSortComparator } from "../../../../../data/GridColumnDefinitions";
import { strings } from "../../../../../helper/strings";

type modalProps = {
    info: any,
    closeModal: any,
    openModal: any,
    message: string,
    caseBased: boolean,
    setExistingCode: any,
    setOpenEdit: any,
    openEdit: boolean,
    selectedIds?: any,
    onInviteSent?: () => void;
}

type SelectedInviteVisit = {
    visitId: number,
    permanency: 'Permanent' | 'Temporary'
}

const InviteModal = ({ info, closeModal, openModal, message, caseBased, setExistingCode, setOpenEdit, openEdit, selectedIds, onInviteSent }: modalProps) => {
    const fetcher = useFetcher();
    const messagesFetcher = useFetcher();
    const statusFetcher = useFetcher();
    const [editMode, setEditMode] = useState(false);
    const [selectedInviteVisits, setSelectedInviteVisits] = useState<SelectedInviteVisit[]>([])
    const [errorMessage, setErrorMessage] = useState('');
    const [inviteMessage, setInviteMessage] = useState(message);
    const [selectedChat, setSelectedChat] = useState<any>();
    const [messages, setMessages] = useState<MessageDto[]>([]);
    const [openInvites, setOpenInvites] = useState<any[]>([]);
    const [action, setAction] = useState<string>("");
    const [warningMessage, setWarningMessage] = useState<string>();
    const [disableSend, setDisableSend] = useState<boolean>(false);
    const otherVisits = info?.otherVisits

    const { getUser } = useAuth();
    const user = getUser();

    useEffect(() => {
        getCaregiverMessages();
        getOpenInvites()
    }, [info]);

    useEffect(() => {
        setInviteMessage(message)
    }, [message])

    const setSelectedVisits = () => {
        let tempList: SelectedInviteVisit[] = [];
        if (otherVisits) {
            for (let visitObj of info?.otherVisits ?? []) {
                if (selectedIds?.includes(visitObj.visit.visitId)) {
                    const newSelectedVisit: SelectedInviteVisit = {
                        visitId: visitObj.visit.visitId,
                        permanency: visitObj.visit.staffingPermanency == 'Permanent' ? 'Permanent' : 'Temporary'
                    };
                    tempList.push(newSelectedVisit);
                }
            }
            setSelectedInviteVisits(tempList)
        }
    }
    useEffect(() => {
        setSelectedVisits();
    }, [info]);

    useEffect(() => {
        getSendingStatus();
    }, [info]);

    useEffect(() => {
        setMaxAction()
    }, [action])

    const getSendingStatus = () => {
        if (info?.caregiverId == undefined)
            return;
        statusFetcher.get(`Invite/status/${info.caregiverId}`).then((res: any) => {
            setAction(res?.data?.action)
            setWarningMessage(res?.data?.message)
        })
    }

    const setMaxAction = () => {
        const prevent = action.toLowerCase() == 'prevent';
        const admin = action.toLowerCase().includes('admin');

        const disable = (admin && !user?.isAdminOrSuperAdmin) || prevent;
        setDisableSend(disable);
    }

    const sendInvite = (info: any, contactMethod: string) => {
        if (selectedInviteVisits.length < 1) {
            setErrorMessage("Please select at least one.")
            return;
        }
        const payload = {
            Visits: selectedInviteVisits,
            Message: inviteMessage,
            ContactMethod: contactMethod
        }
        console.log(payload);
        fetcher.post(`Invite/sms/${info?.caregiverId}`, payload).then(res => {
            if (typeof onInviteSent === 'function')
                onInviteSent();
            handleClose();
        });
    }

    const getTimeString = (date: Date | string, convertToLocal?: boolean): string => {
        const dateObj: Date = new Date(date);
        const timeStr: string = convertToLocal ? dateObj.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        }) : dateTime.getTimeString(dateObj);

        return timeStr.replace(/\s?m$/i, '');
    };

    const handleCheckbox = (changeSelect: boolean, visitDynamics: any) => {
        let existingVisitIndex = selectedInviteVisits.findIndex(s => s.visitId === visitDynamics.visit.visitId);
        const row = selectedInviteVisits[existingVisitIndex] ?? undefined;
        const permanency = row?.permanency == 'Temporary' ? 'Permanent' : 'Temporary';

        if (changeSelect) {
            if (existingVisitIndex !== -1) {
                selectedInviteVisits.splice(existingVisitIndex, 1);
            } else {
                const newSelected: SelectedInviteVisit = { visitId: visitDynamics.visit.visitId, permanency: permanency };
                selectedInviteVisits.push(newSelected);
            }
        } else {
            if (existingVisitIndex !== -1) {
                selectedInviteVisits[existingVisitIndex].permanency = permanency;
            } else {
                const newSelected: SelectedInviteVisit = { visitId: visitDynamics.visit.visitId, permanency: permanency };
                selectedInviteVisits.push(newSelected);
            }
        }

        setSelectedInviteVisits([...selectedInviteVisits]);
    };

    const getCaregiverMessages = () => {
        if (!info)
            return;

        messagesFetcher.get(`Chat/messages/bycaregiver/${info?.caregiverId}`).then((res: any) => {
            setMessages(res.data.messages)
            setSelectedChat(res.data.chat)
        })
    }

    const handleClose = () => {
        closeModal();
        setInviteMessage('')
        setSelectedInviteVisits([])
        setErrorMessage('')
        setEditMode(false)
        setSelectedChat(null)
        setMessages([])
        setOpenInvites([])
    }

    const getOpenInvites = () => {
        if (info != null)
            fetcher.get(`Invite/open/${info.patientId}?caregiverId=${info.caregiverId}`).then((res: any) => {
                setOpenInvites(res.data.data);
            })
    }

    const handleClickEdit = () => {
        setExistingCode(openInvites[0].inviteCode)
        setOpenEdit(true)
    }

    const getVisitDateTime = (visit: any) => {
        const formatted: string = dateTime.getDayAndDate(visit.visitDate) + ' ' + getShortTimeFormat(visit.scheduleStartTime, false) +
            ' - ' + getShortTimeFormat(visit.scheduleEndTime, false)

        return formatted;
    }

    return (
        <Modal open={openModal} onClose={() => handleClose()} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{
                marginTop: '10px',
                width: '800px',
                //height: '55%',
                maxHeight: '820px',
                backgroundColor: "white",
                padding: 2,
                paddingBottom: '0px',
                borderRadius: '10px'
            }}>
                {openInvites.length > 0 && <div style={{ marginBottom: '10px' }}>
                    <BoldTitle color='red' textAlign='center'>{info?.caregiver?.name} has a open invite for this patient, you can modify it <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => handleClickEdit()}>here </span></BoldTitle>
                </div>}
                <Loader isLoading={fetcher.isLoading} />
                <Box height={'95%'} sx={{ overflow: 'scroll', ...CUSTOM_SCROLL }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row-reverse' }}>

                        {/* Select Visits */}
                        <Paper sx={{ height: '350px', width: '35%' }} elevation={1}>
                            <BoldTitle textAlign='center'>Select visits to invite</BoldTitle>
                            {errorMessage && <BoldTitle textAlign='center' color='red'>{errorMessage}</BoldTitle>}
                            <List sx={{ paddingTop: '20px', height: '90%', overflowY: 'scroll', ...CUSTOM_SCROLL }} disablePadding>
                                {otherVisits && otherVisits
                                    .filter((visit: any) => caseBased ? true : visit?.visit?.visitId === info?.visitId)
                                    .map((visit: any) => {
                                        return { ...visit, scheduleStartTime: visit.scheduleStartTime === '' ? '' : visit.scheduleStartTime };
                                    })
                                    .sort((a: any, b: any) => {
                                        return dateSortComparator(dateTime.getDateTime(a.scheduleStartTime), dateTime.getDateTime(b.scheduleStartTime));
                                    })
                                    .map((visitDynamics: any) =>
                                        <ListItem key={visitDynamics.visit.visitId} disablePadding sx={{ height: '30px' }}>
                                            <Grid container spacing={5} style={{ margin: 0, padding: 0, height: '30px' }}>
                                                <Grid item xs={1.5} style={{ paddingLeft: 0, paddingTop: 0 }}>
                                                    <Checkbox
                                                        size="small"
                                                        onChange={() => handleCheckbox(true, visitDynamics)}
                                                        defaultChecked={!!selectedIds?.length && selectedIds.includes(visitDynamics.visit.visitId)}
                                                    />
                                                </Grid>
                                                <Grid item xs={8.5} style={{ paddingLeft: 0, paddingTop: 0 }}>
                                                    <ListItemText sx={{ padding: 'none' }}
                                                        primary={
                                                            <Flex marginTop={'8px'}>
                                                                <BoldTitle fontSize='14px' fontWeight='200px'>{getVisitDateTime(visitDynamics.visit)}</BoldTitle>
                                                                {visitDynamics.visit.schedulePermanency?.toLowerCase() === 'permanent' ?
                                                                    <Tooltip placement="right"
                                                                        title={
                                                                            strings.firstLetterUpperCase(visitDynamics.visit.schedulePermanency?.toLowerCase()) + " case, " +
                                                                            (
                                                                                visitDynamics.visit.staffingPermanency == "None" ? "has a permanently assigned caregiver" :
                                                                                    "needs a " + (visitDynamics.visit.staffingPermanency == 'Permanent' ? 'permanent' : 'temporary') +
                                                                                    " staffing")}
                                                                    >
                                                                        <Repeat sx={{
                                                                            height: '11px', width: '12px', fontWeight: 'bold', paddingRight: '4px',
                                                                            color:
                                                                                (visitDynamics.visit.staffingPermanency == 'Temporary') ? 'orange' :  // caregiver on assignment is not assigned to shift
                                                                                    (visitDynamics.visit.staffingPermanency == 'Permanent') ? 'red' : 'inherit'
                                                                        }} />
                                                                    </Tooltip> : null}
                                                            </Flex>
                                                        }
                                                    //secondary={visitDynamics.hasMasterCaregiver && selectedInviteVisits.find(s => s.visitId == visitDynamics.visit.visitId)?.permanency == 'Permanent' && <BoldTitle sx={{ padding: 0, marginTop: '-5px' }} fontSize='10px' color='red'>A caregiver is permenantly assigned</BoldTitle>}
                                                    // primary={`${dateTime.getDayAndDate(visit.visit.visitDate)} ${getTimeString(visit.visit.scheduleStartTime, false)} - ${getTimeString(visit.visit.scheduleEndTime, false)}`}
                                                    // primaryTypographyProps={{ fontSize: '14px' }}
                                                    // secondary={
                                                    //   <Chip
                                                    //     sx={{

                                                    //       height: '20px',
                                                    //       borderRadius: '5px',
                                                    //     }}
                                                    //     disabled={!selectedVisits.find(s => s.visitId == visit.visit.visitId)}
                                                    //     onClick={() => handleCheckbox(false, visit, selectedVisits.find(s => s.visitId == visit.visit.visitId)?.type == 'Permanent' ? 'Temporary' : 'Permanent')}
                                                    //     label={(selectedVisits.find(s => s.visitId == visit.visit.visitId)?.type) || (visit.visit.isPermanent ? 'Permanent' : 'Temporary')}
                                                    //   />}
                                                    />
                                                </Grid>
                                                <Grid item xs={2} style={{ paddingLeft: 0, paddingTop: 0 }}>
                                                    {//visitDynamics.visit.schedulePermanency?.toLowerCase() === 'permanent' &&
                                                        <Tooltip
                                                            title={
                                                                (selectedInviteVisits.find(s => s.visitId == visitDynamics.visit.visitId)?.permanency == 'Permanent' ? 'P' : 'T') +
                                                                " = Invite to serve this shift " +
                                                                (selectedInviteVisits.find(s => s.visitId == visitDynamics.visit.visitId)?.permanency == 'Permanent' ? 'permanently' : 'temporary')}
                                                            placement="left"
                                                        >
                                                            <Chip
                                                                sx={{
                                                                    height: '20px',
                                                                    marginTop: '8px',
                                                                    borderRadius: '3px',
                                                                    cursor: 'default',
                                                                    background: app_light,
                                                                    '.MuiChip-label': {
                                                                        paddingLeft: '7px',
                                                                        paddingRight: '7px',
                                                                    }
                                                                }}
                                                                disabled={!selectedInviteVisits.find(s => s.visitId == visitDynamics.visit.visitId)}
                                                                onClick={() => handleCheckbox(false, visitDynamics)}
                                                                label={(selectedInviteVisits.find(s => s.visitId == visitDynamics.visit.visitId) ? selectedInviteVisits.find(s => s.visitId == visitDynamics.visit.visitId)?.permanency == 'Permanent' ? 'P' : 'T' : visitDynamics.visit.isPermanent ? 'P' : 'T')}
                                                            />
                                                        </Tooltip>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </ListItem>)}
                            </List>
                        </Paper>

                        {/* Chats */}
                        <Paper elevation={1} sx={{ height: '350px', width: '63%' }}>
                            <div style={{ marginLeft: '10px' }}>
                                <BoldTitle textAlign='center'>Recent Messages</BoldTitle>
                            </div>
                            <ChatMessages
                                selectedChat={selectedChat}
                                messages={messages}
                                user={user}
                                messagesFetcher={messagesFetcher}
                                dateTime={dateTime}
                                height="332px"
                                hideSender={true}
                                size="small"
                            />
                        </Paper>
                    </div>

                    {/* Invite History */}
                    <Paper sx={{ width: '100%', height: '200px', margin: '10px 0px' }} elevation={1}>
                        <InviteHistory
                            caregiverId={info?.caregiverId}
                        />
                    </Paper>

                    {/* Message Edit */}
                    <div style={{ width: '100%', margin: '10px 0px' }}>

                        <Paper sx={{ padding: '5px' }}>
                            <FlexJustifyStart style={{ alignItems: 'center' }}>
                                <BoldTitle>Message</BoldTitle>
                                <IconButton onClick={() => setEditMode(!editMode)}>
                                    {editMode ? <EditOff fontSize="small" sx={{ width: '15px' }} /> : <EditOutlined fontSize="small" sx={{ width: '15px' }} />}
                                </IconButton>
                            </FlexJustifyStart>
                            <TextareaAutosize
                                style={{
                                    resize: 'none',
                                    borderRadius: '10px',
                                    background: 'white',
                                    fontFamily: 'ConnectAideSecondary',
                                    minWidth: '99%',
                                    maxWidth: '99%',
                                    minHeight: '70px',
                                    maxHeight: '70px',
                                    margin: 'auto',
                                    border: editMode ? '1px solid #ccc' : '1px solid #fff',
                                }}
                                minRows={3}
                                maxRows={3}
                                disabled={!editMode}
                                defaultValue={message}
                                onChange={(e) => setInviteMessage(e.target.value)}
                            />
                        </Paper>
                    </div>
                    {
                        warningMessage &&
                        <BoldTitle color='red' textAlign='center'>{warningMessage} </BoldTitle>
                    }
                    <FlexEvenly padding='0px 0px 0px 0px' float='right' margin='0px 0px 0px 0px'>
                        <Button sx={{ marginRight: '15px', color: 'red' }} onClick={() => handleClose()}>Cancel</Button>
                        {/* <Button onClick={() => sendInvite(info, 'call')}>Manual</Button>
          <Button onClick={() => sendInvite(info, 'sms')}>SMS</Button> */}
                        <OptionButton
                            disable={disableSend}
                            text={'Send'}
                            options={['Save without Send']}
                            handleMenuItemClick={(option: string) => {
                                const text = option == 'Save without Send' ? 'call' : 'sms';
                                sendInvite(info, text);
                            }}
                            handleMainButtonClick={() => sendInvite(info, 'sms')}
                        ></OptionButton>
                    </FlexEvenly>
                </Box>
            </Box>
        </Modal >
    )
}

export default InviteModal