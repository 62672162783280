import { Schedule, Checklist, Rule, Send } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react"
import { Popup } from 'react-map-gl';
import { BoldTitle } from "../../assets/styles/styledComponents";
import { dateTime } from "../../helper/datetime";

type InfoType = 'Patient' | 'Caregiver' | undefined

type props = {
    info: any,
    setInfo: any
    setRow: any
    setShowCaregiverSchedule?: any,
    setShowCaregiverMatch?: any
    setShowCaregiverPreference?: any,
    handleOpenDetails?: any
    handleOpenInvite?: any
}

const DetailsPopUp = ({ info, setInfo, setRow, setShowCaregiverSchedule, setShowCaregiverMatch, setShowCaregiverPreference, handleOpenInvite, handleOpenDetails }: props) => {
    const [selectedType, setSelectedType] = useState<InfoType>(undefined)
    const [coordinates, setCoordinates] = useState({
        latitude: '',
        longitude: '',
    })

    useEffect(() => {
        setSelectedType(info.type)
        setCoordinates({
            latitude: info.address.latitude ? info.address.latitude : info.latitude,
            longitude: info.address.longitude ? info.address.longitude : info.longitude
        })
    }, [info])

    return (
        <Popup
            anchor="top"
            longitude={Number(coordinates.longitude)}
            latitude={Number(coordinates.latitude)}
            closeOnClick={false}
            maxWidth='350px'
            onClose={() => setInfo(null)}
        >
            <Box borderRadius={'20px'} width={'350px'}>
                <Box>
                    <BoldTitle margin='0px 10px 20px 0px'><a>{info.type}</a> | {info.name}</BoldTitle>

                    <BoldTitle fontWeight='200px' fontSize='12px'><b>HHX ID:</b> {info.hhaId} </BoldTitle>
                    <BoldTitle fontWeight='200px' fontSize='12px'><b>{selectedType == 'Caregiver' ? 'Caregiver Code' : 'AdmissionId'}:</b> {info.code} </BoldTitle>
                    <BoldTitle fontWeight='200px' fontSize='12px'><b>Address:</b> {info.address.addressLine1} {info.address.addressLine2}, {info.address.city}, {info.address.state}, {info.address.zipCode} </BoldTitle>
                    <BoldTitle fontWeight='200px' fontSize='12px'><b>Language:</b> {info.language} </BoldTitle>
                    {/* <BoldTitle fontWeight='200px' fontSize='12px'><b>Visit:</b> {dateTime.getDateString(info.visitDate)}{" "} */}
                    {/* {dateTime.getTimeStringNew(info.scheduleStartTime, false)} - {dateTime.getTimeStringNew(info.scheduleEndTime, false)} </BoldTitle> */}
                </Box>


                {selectedType == 'Caregiver' &&
                    <Box width={'300px'}>
                        {info.distance > 0 && <BoldTitle fontWeight='200px' fontSize='12px'><b>Distance:</b> {info.distance} min.</BoldTitle>}
                        {info.availabaility && <BoldTitle fontWeight='200px' fontSize='12px'><b>Availability:</b>{info.availabaility?.map((avail: any) => (
                            <div key={avail.day} style={{ textIndent: '20px', }}>
                                {`${avail.day} - ${avail.percentAvail}%`}
                            </div>
                        ))} </BoldTitle>}
                        <BoldTitle fontWeight='200px' fontSize='12px'><b>Last Worked:</b> {dateTime.getUSAFormattedDateString(info.lastWorked)}</BoldTitle>
                        <Box display={'flex'} justifyContent={'start'} >
                            {selectedType == 'Caregiver' && setShowCaregiverSchedule && <Tooltip
                                title={
                                    info.availabilityStatus === 'No-Schedule' ? 'Availability data not available' :
                                        info.availabilityStatus == 'Outdated' ? 'Availability data not up-to-date' :
                                            "See schedule"
                                }
                            >
                                <IconButton onClick={(e: any) => { setRow(info); setShowCaregiverSchedule(true) }}>
                                    <Schedule fontSize='small' sx={{ color: info.availabilityStatus == 'No-Schedule' ? 'red' : info.availabilityStatus == 'Outdated' ? '#D5B85A' : '#7846ff' }} />
                                </IconButton>
                            </Tooltip>}
                            {selectedType == 'Caregiver' && setShowCaregiverMatch && <Tooltip title={info.summary}>
                                <IconButton onClick={(e: any) => { setRow(info); setShowCaregiverMatch(true) }}>
                                    <Checklist fontSize='small' style={{ color: !!info.summary ? "#7846ff" : "gray" }} />
                                </IconButton>
                            </Tooltip>}
                            {selectedType == 'Caregiver' && setShowCaregiverPreference && <IconButton onClick={() => { setRow(info); setShowCaregiverPreference(true) }}>
                                <Rule fontSize={"small"} color={"primary"} />
                            </IconButton>}
                            {selectedType == 'Caregiver' && handleOpenInvite && <Tooltip title={!!info.wasInvited ? "This caregiver was invited to this visit" : "Invite"}>
                                <IconButton onClick={() => handleOpenInvite(info)}>
                                    <Send fontSize='small' color={!!info.wasInvited ? "success" : "primary"} />
                                </IconButton>
                            </Tooltip>}
                        </Box>
                    </Box>}
            </Box>
        </Popup >
    )
}

export default DetailsPopUp