import DataGrid from "../../../components/ui/DataGrid";
import { useEffect, useState } from "react";
import { dateTime } from "../../../helper/datetime";
import useFetcher from "../../../hooks/useFetcher";
import InviteDetailsModal from "./Modals/InviteModal/InviteDetailsModal";
import { getStatusColor } from "../../../data/constants";
import { dateSortComparator } from "../../../data/GridColumnDefinitions";
import { Box, Button, Dialog, Tab, Tabs, Typography } from "@mui/material";
import { BoldTitle, Text } from "../../../assets/styles/styledComponents";
import useInviteUI from "../../invites/hooks/useInviteUI";


type modalProps = {
  openPendingInvites: boolean,
  setOpenPendingInvites: React.Dispatch<React.SetStateAction<boolean>>;
  visitId?: number
  filterCaregiverId?: number
  filterPatientId?: number
  groupingPatientId?: number
  selectedVisitIDs?: number[] | string[]
}

const PendingInvites = ({ openPendingInvites, setOpenPendingInvites, visitId, groupingPatientId, filterCaregiverId, filterPatientId, selectedVisitIDs = [] }: modalProps) => {
  const fetcher = useFetcher();
  const [history, setHistory] = useState<any[]>([]);
  const [openDetails, setOpenDetails] = useState(false)
  const [currentCaseGroupedRows, setCurrenCaseGroupedRows] = useState<any[]>([])
  const [otherVisitsGroupedRows, setOtherVisitsGroupedRows] = useState<any[]>([])
  const [otherPatientGroupedRows, setOtherPatientGroupedRows] = useState<any[]>([])
  const [selectedCode, setSelectedCode] = useState<any>(null)
  const [selectedVisitIDs_local, set_selectedVisitIDs_local] = useState<number[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [query, setQuery] = useState<any>(null)
  const { columns, GroupInvitesByInviteCode, getGroupedVisitStatus } = useInviteUI();

  useEffect(() => {
    if (!!selectedVisitIDs?.length)
      set_selectedVisitIDs_local(selectedVisitIDs.map((s: any) => Number(s)));
  }, [selectedVisitIDs])
  const prepareQuery = () => {
    setQuery(() => {
      if (!!filterCaregiverId) {
        return `caregiverId=${filterCaregiverId}`;
      } else if (!!filterPatientId) {
        return `patientId=${filterPatientId}`;
      }
    });
  };

  const getHistory = () => {
    var from = new Date();
    from.setHours(0, 0, 0, 0);
    // to.setDate(to.getDate() + 7);

    fetcher.get(`Invite/history?${query}&from=${from?.toISOString()}`).then((res: any) => {
      setHistory(res.data.data);
    }).catch(err => {
      if (err.response?.status === 404)
        return;
      throw err;
    })
  }

  useEffect(() => {
    prepareQuery();
  }, [openPendingInvites, visitId, filterCaregiverId, filterPatientId]);

  useEffect(() => {
    if (!!openPendingInvites && !!query)
      getHistory();
  }, [query, openPendingInvites]);

  const organizeInvites = (invites: any) => {
    const localCurrentCases: any[] = [];
    const localOtherVisits: any[] = [];
    const localOtherPatients: any[] = [];

    invites.forEach((invite: any) => {
      const { inviteCode, visitId, visitDate, patientId, patientName, caregiverName, dateSent, visitStatus, clicks, status, sentBy, caregiverId } = invite;

      // Current Cases
      if (
        //selectedVisitIDs_local.some((id: number) => id == visitId) //&&
        filterPatientId === patientId
        // patientId === appyPatientId 
        // &&
        //status === 'Pending'
      ) {
        localCurrentCases.push({
          inviteCode,
          patientId,
          patientName,
          caregiverId,
          caregiverName,
          visitDate,
          dateSent,
          sentBy,
          visitStatus,
          clicks,
          status,
        });
      }

      // Other Visits
      if (
        !selectedVisitIDs_local.some((id: number) => id == visitId) &&
        patientId === filterPatientId &&
        status === 'Pending'
      ) {
        localOtherVisits.push({
          inviteCode,
          patientId,
          patientName,
          caregiverId,
          caregiverName,
          visitDate,
          dateSent,
          sentBy,
          visitStatus,
          clicks,
          status,
        });
      }

      // Other Patients
      if (
        //!selectedVisitIDs_local.some((id: number) => id == visitId) &&
        patientId !== filterPatientId &&
        status === 'Pending'
      ) {
        localOtherPatients.push({
          inviteCode,
          patientId,
          patientName,
          caregiverId,
          caregiverName,
          dateSent,
          visitDate,
          sentBy,
          visitStatus,
          clicks,
          status,
        });
      }
    });

    const localCurrentCasesResult =
      GroupInvitesByInviteCode(Object.values(localCurrentCases).flat());
    const localOtherVisitsResult =
      GroupInvitesByInviteCode(Object.values(localOtherVisits).flat());
    const localOtherPatientsResult =
      GroupInvitesByInviteCode(Object.values(localOtherPatients).flat());

    setCurrenCaseGroupedRows(localCurrentCasesResult);
    setOtherVisitsGroupedRows(localOtherVisitsResult);
    setOtherPatientGroupedRows(localOtherPatientsResult);
  };

  useEffect(() => {
    organizeInvites(history)
  }, [history])
  useEffect(() => {
    console.log(currentCaseGroupedRows);
  }, [currentCaseGroupedRows])
  useEffect(() => {
    console.log(otherVisitsGroupedRows);
  }, [otherVisitsGroupedRows])
  useEffect(() => {
    console.log(otherPatientGroupedRows);
  }, [otherPatientGroupedRows])

  const handleRowClick = (row: any) => {
    if (!row.inviteCode)
      return;

    setSelectedCode(row.inviteCode)
    setOpenDetails(true)
  }

  const handleClosePendingInvites = () => {
    setHistory([]);
    setOpenPendingInvites(false)
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  let columnsToDisplay: string[] = ['inviteCode', 'dateSent', 'status', 'visitStatus', 'clicks', 'visitDate'];
  if (!!filterPatientId)
    columnsToDisplay.push('caregiverName')
  if (!!filterCaregiverId)
    columnsToDisplay.push('patientName')

  return (
    <div style={{ padding: '10px' }}>
      <Dialog open={openPendingInvites} onClose={handleClosePendingInvites}>
        <Box width="800px" height="300px" sx={{ background: '#fff', padding: 0 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px 0px 0 10px' }}>
            <Typography variant="h6">Pending Invites</Typography>
            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Pending Invites Tabs">
              <Tab label="Current Case" />
              {/* <Tab label="Other Visits" /> */}
              {!!filterCaregiverId && <Tab label="Other Patients" />}
              {/* {!!filterPatientId && <Tab label="Other Caregivers" />} */}
            </Tabs>
          </Box>
          <Box sx={{ background: '#fff', }}>
            {selectedTab === 0 && (
              <DataGrid
                style={{ border: '0px solid #ccc' }}
                // title = 'test'
                hideFooter
                loading={fetcher.isLoading}
                data={{
                  columns: columns(columnsToDisplay),
                  rows: !!currentCaseGroupedRows && currentCaseGroupedRows,
                }}
                options={{
                  hideExport: true,
                  hideFiltering: true,
                  hideColumns: true,
                  headerBGColor: 'rgb(232,224,255)',
                  headerTextColor: '#222',
                  height: '200px',
                  pagination: false,
                  headerHeight: 30,
                  rowHeight: 25,
                  disableColumnReorder: true,
                  hideFooterRowCount: true,
                  hideFooter: true,
                }}
                onRowClick={(e: any) => handleRowClick(e.row)}
              />
            )}
            {/* {selectedTab === 1 && (
              <DataGrid
                style={{ border: '0px solid #ccc' }}
                loading={fetcher.isLoading}
                data={{
                  columns: columns(columnsToDisplay),
                  rows: !!otherVisitsGroupedRows && otherVisitsGroupedRows,
                }}
                options={{
                  hideExport: true,
                  hideFiltering: true,
                  hideColumns: true,
                  headerBGColor: 'rgb(232,224,255)',
                  headerTextColor: '#222',
                  height: '200px',
                  pagination: false,
                  headerHeight: 30,
                  rowHeight: 25,
                  disableColumnReorder: true,
                  hideFooterRowCount: true,
                  hideFooter: true,
                }}
                onRowClick={(e: any) => handleRowClick(e.row)}
              />
            )} */}
            {selectedTab === 1 && (
              <DataGrid
                style={{ border: '0px solid #ccc' }}
                loading={fetcher.isLoading}
                data={{
                  columns: columns(columnsToDisplay),
                  rows: !!otherPatientGroupedRows && otherPatientGroupedRows,
                }}
                options={{
                  hideExport: true,
                  hideFiltering: true,
                  hideColumns: true,
                  headerBGColor: 'rgb(232,224,255)',
                  headerTextColor: '#222',
                  height: '200px',
                  pagination: false,
                  headerHeight: 30,
                  rowHeight: 25,
                  disableColumnReorder: true,
                  hideFooterRowCount: true,
                  hideFooter: true,
                }}
                onRowClick={(e: any) => handleRowClick(e.row)}
              />
            )}
          </Box>
          <InviteDetailsModal open={openDetails} setOpen={setOpenDetails} inviteCode={selectedCode} />
        </Box>
      </Dialog>
    </div>
  );
};

export default PendingInvites;