import FactorSettingRow from './FactorSettingRow';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import { FactorSettingI, newSettingDefaults } from './FactorInterfaces';
import { urlSettingDelete } from './FactorUrlConstants';
import axiosClient from '../../../helper/axiosConfig';
import useFetcher from '../../../hooks/useFetcher';
import { MenuOption } from './FactorConstants';

interface FactorSettingLevelProps {
	level: number,
	settingsArray: FactorSettingI[],
	setSettingsArray: Function,
	setIsUpdating: Function,
	setResponse: Function,
	selectedProfileId: number,
	isMain: Boolean,
	abilityFactors: MenuOption[],
}

const FactorSettingLevel = ({
	level,
	settingsArray,
	setSettingsArray,
	setIsUpdating,
	setResponse,
	selectedProfileId,
	isMain,
	abilityFactors,
}: FactorSettingLevelProps) => {
	const [settingToDeleteId, setSettingToDeleteId] = useState<number>(-1);

	const deleteRowFetcher = useFetcher();
	const deleteSettingApi = async (settingId: number) => {

		try {
			const response = await deleteRowFetcher.delete(
				`${urlSettingDelete}?settingId=${settingId}`
			);
			const resObj = { statusCode: response?.data.statusCode ?? '0', message: response?.data.message ?? 'Error' };
			setResponse(resObj);
		} finally {
		}
	};

	const handleAddSetting = (event: any) => {
		if (!!isMain)
			return;

		const newSetting = { ...newSettingDefaults };
		newSetting.severityLevel = level;
		newSetting.profileId = selectedProfileId;

		// console.debug(`handleAddSetting, newSetting:`, newSetting);

		const newSettingsArr: FactorSettingI[] = [...settingsArray, newSetting];

		// console.debug(`handleAddSetting, newSettingsArr:`, newSettingsArr);

		setSettingsArray(level, newSettingsArr);
	};

	function updateSetting(settingId: number, newSetting: FactorSettingI) {
		setSettingsArray((prevSettings: FactorSettingI[]) => {
			return prevSettings.map((setting: FactorSettingI) =>
				setting.settingId === settingId ? newSetting : setting
			);
		});
	}

	useEffect(() => {
		if (settingToDeleteId !== -1) {
			const newArray: FactorSettingI[] = settingsArray.filter(
				(obj) => obj.settingId !== settingToDeleteId
			);

			console.debug("settingToDelete", settingToDeleteId);
			deleteSettingApi(settingToDeleteId);

			setSettingsArray(level, newArray);
		}
	}, [settingToDeleteId]);

	return (
		<div style={{ backgroundColor: "#f4f0ff", borderRadius: "0px", margin: "10px 0 15px 0" }}>
			<div style={{ textAlign: "left" }}>
				<Typography variant="h6" sx={{padding: '10px 15px'}}>Round {level}</Typography>

				<div
					style={{
						display: "box",
						flexDirection: "row",
					}}
				>
					{settingsArray.length !== 0 &&
						settingsArray.map((fs) => (
							<FactorSettingRow
								key={fs.settingId}
								setting={fs}
								deleteHandler={setSettingToDeleteId}
								settingUpdater={updateSetting}
								setIsUpdating={setIsUpdating}
								setResponse={setResponse}
								isMain={isMain}
								abilityFactors={abilityFactors}
							/>
						))}
				</div>

				<div
					style={{
						display: "box",
						flexDirection: "row",
						textAlign: "center",
					}}
				>
					{!isMain && <IconButton color="primary" size="small" onClick={handleAddSetting}>
						<AddIcon fontSize="small" />
					</IconButton>}
				</div>
			</div>
		</div>
	);
};

export default FactorSettingLevel
