import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { BASE_URL, RESET_PASS_PATH, homePageUrl, loginPageUrl } from '../../../data/constants';
import useAuth from '../hooks/useAuth';
import useToast from '../../../hooks/useToast';
import useFetcher from '../../../hooks/useFetcher';

export default function ResetPassword() {
    console.log("ResetPassword");
    const { token, email } = useParams(); // Retrieve the token and email values from the URL params
    const navigate = useNavigate();
    if (token === undefined) {
        navigate(loginPageUrl);
    }
    const resetPasswordApi = useFetcher(); // Replace with your API hook
    const { saveUserLogin } = useAuth(); // Replace with your authentication hook
    const { showError, showSuccess } = useToast(); // Replace with your toast notification hook

    const [resetForm, setResetForm] = useState({
        email: email,
        password: '',
        confirmPassword: '',
        resetPasswordToken: token
    });

    const handleChange = (e: any) => {
        setResetForm({
            ...resetForm,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        resetPasswordApi
            .post(RESET_PASS_PATH, resetForm)
            .then((response: any) => {
                const data = response.data;
                // Handle the response from the API
                if (!!data) {
                    //The password reset is successful and the user is logged in
                    saveUserLogin(data); // Save user login data in the authentication hook
    
                    // Show success message and navigate to the home page
                    showSuccess('Password reset successful');
                    console.log("navigate to homePageUrl");
                    navigate(homePageUrl);
                } else {
                    showError('Password reset failed');
                }
            })
            .catch((error) => {
                // Handle the error from the API
                console.log(error);
                // Show error message
                showError(error.response?.data?.message ?? 'Password reset failed');
            });
    };

    return (
        <Grid container justifyContent="center" alignItems="center" minHeight="100vh">
            <Grid item xs={12} sm={6} md={4}>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '50vh', justifyContent: 'center' }}>
                        <Box mb={2}>
                            <Typography variant="h6" align="center" gutterBottom>
                                Reset Password
                            </Typography>
                        </Box>
                        <Box mb={2}>
                            <TextField
                                type="email"
                                name="email"
                                label="Email"
                                value={resetForm.email}
                                onChange={handleChange}
                                required
                                fullWidth
                                disabled
                                sx={{ width: '300px' }}
                            />
                        </Box>
                        <Box mb={2}>
                            <TextField
                                type="password"
                                name="password"
                                label="New Password"
                                value={resetForm.password}
                                onChange={handleChange}
                                required
                                fullWidth
                                autoComplete="off"
                                sx={{ width: '300px' }}
                            />
                        </Box>
                        <Box mb={2}>
                            <TextField
                                type="password"
                                name="confirmPassword"
                                label="Confirm Password"
                                value={resetForm.confirmPassword}
                                onChange={handleChange}
                                required
                                fullWidth
                                sx={{ width: '300px' }}
                            />
                        </Box>
                        <Box mb={2}>
                            <Button
                                sx={{ width: '300px' }} type="submit" variant="contained" color="primary" fullWidth>
                                Setup Password
                            </Button>
                        </Box>
                    </Box>
                </form>
            </Grid>
        </Grid>
    );
}

export { ResetPassword };
