import { useEffect, useState } from "react";
import useFetcher from "./useFetcher";
import useAuth from "../features/authentication/hooks/useAuth";
import { AppyUser } from "./types";
import { useLocation, useNavigate } from "react-router-dom";
import { homePageUrl, loginPageUrl } from "../data/constants";

const useAuthMonitor = () => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
    const [user, setUser] = useState<AppyUser>();
    const { getUser, userAccount, logoutUser } = useAuth();
    const tokenCheckFether = useFetcher();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        checkIfAuthenticated();
    }, [])
    useEffect(() => {
        checkIfIsLoggedIn();
    }, [])

    // useEffect(() => {
    //     if (location.pathname === '/')
    //         navigate(loginPageUrl);
    //     if (!isLoggedIn && !location.pathname.includes('reset') && !location.pathname.includes('pages') && location.pathname !== loginPageUrl
    //         && location.pathname !== '/terms-of-service') {
    //         navigate(loginPageUrl);
    //     }
    //     if (location.pathname === loginPageUrl) {
    //         console.log('nav to home')
    //         navigate(homePageUrl);
    //     }
    // }, [location, user, isLoggedIn])

    // useEffect(() => {
    //     const interval = setInterval(() => {

    //         //if (isLoggedIn === true)
    //         checkIfIsLoggedIn();

    //     }, 60000); // 60000 milliseconds = 1 minute

    //     return () => clearInterval(interval); // Cleanup interval on component unmount
    // }, []);
    useEffect(() => {
        const interval = setInterval(() => {

            checkIfAuthenticated();

        }, 500); // 60000 milliseconds = 1 minute

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [user, isLoggedIn]);
    useEffect(() => {
        if (!!user?.token)
            checkIfIsLoggedIn();
    }, [user]);
    
    const checkIfAuthenticated = () => {
        const updatedUser = getUser();
        if (user?.token != updatedUser?.token || (!user?.token && !updatedUser?.token && isLoggedIn === null)) {
            setUser(updatedUser);
            return !!updatedUser?.token;
        }

        return false;
    }

    const logout = () => {
        logoutUser();
        setUser(undefined);
        setIsLoggedIn(false);
    }
    const checkIfIsLoggedIn = () => {
        let statusCode: number = 0;
        tokenCheckFether.get('api/AaTest/hi').then((res: any) => {
            statusCode = res?.data?.statusCode || res?.response?.status;
        }).catch((res: any) => {
            statusCode = res?.data?.statusCode || res?.response?.status;
        }).finally(() => {
            if (statusCode === 401)
                setIsLoggedIn(false);
            else if (statusCode === 200)
                setIsLoggedIn(true);

            if ((statusCode === 200 && isLoggedIn !== true) || (statusCode === 401 && isLoggedIn !== false)) {
                const updatedUser = getUser();
                setUser(updatedUser);
            }
        });
    }

    return {
        isLoggedIn,
        user,
        logout,
        checkIfIsLoggedIn
    }
}

export default useAuthMonitor;
