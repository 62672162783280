import { Box, Autocomplete, TextField } from "@mui/material"
import NewModal from "../../../../../components/ui/NewModal"
import { dateTime } from "../../../../../helper/datetime"
import { useEffect, useState } from "react"
import useFetcher from "../../../../../hooks/useFetcher"
import Loader from "../../../../../components/ui/Loader"

type props = {
    open: boolean,
    setOpen: any,
    onSave: any,
    patient?: any,
    visit?: any
}

const SendInvite = ({ open, setOpen, onSave, visit, patient }: props) => {
    const patientsFetcher = useFetcher()
    const fetcher = useFetcher('')
    const [patients, setPatients] = useState<any[]>([])
    const [selectedPatient, setSelectedPatient] = useState<any>(null)
    const [visits, setVisits] = useState<any[]>([])
    const [selectedVisit, setSelectedVisit] = useState<any>(null)
    const [caregivers, setCaregivers] = useState<any[]>([])
    const [selectedCaregiver, setSelectedCaregiver] = useState<any>(null)

    const getPatients = () => {
        patientsFetcher.get('Patient')
    }

    useEffect(() => {
        getPatients();
    }, [])
    useEffect(() => {
        setPatients(patientsFetcher.data)
    }, [patientsFetcher.data])

    const handleChangePatient = (option: string, reason: string) => {
        if (reason == 'clear') {
            setSelectedPatient(null)
            setSelectedVisit(null)
            setSelectedCaregiver(null)
        }

        const selected = patients.find((p: any) => p.name == option)
        if (selected != null)
            setSelectedPatient(selected)
    }

    const handleChangeCaregiver = (option: string, reason: string) => {
        if (reason == 'clear')
            setSelectedCaregiver(null)

        const selected = caregivers.find((p: any) => p.name == option)
        if (selected != null)
            setSelectedCaregiver(selected)
    }

    useEffect(() => {
        if (selectedPatient == null)
            return;

        GetPatientsVisit()
    }, [selectedPatient])

    const GetPatientsVisit = () => {
        fetcher.get(`Visit/patient/${selectedPatient?.patientId}/7`).then((res: any) => {
            setVisits(res.data.data)
        })
    }

    const getCaregivers = () => {
        fetcher.get('Caregiver').then((res: any) => {
            setCaregivers(res.data)
        })
    }

    useEffect(() => {
        if (selectedVisit == null)
            return;

        getCaregivers()
    }, [selectedVisit])

    useEffect(() => {
        console.log(selectedCaregiver)
    }, [selectedCaregiver])

    const handleCloseCreate = () => {
        setOpen(false)
        setSelectedPatient(null)
        setSelectedCaregiver(null)
        setSelectedVisit(null)
    }

    const handleSendInvite = () => {
        if (selectedCaregiver == null || selectedVisit == null)
            return;

        fetcher.post(`Invite/sms/${selectedCaregiver.caregiverId}/${selectedVisit.visitId}`, null).then(res => {
            handleCloseCreate()
            onSave()
        })
    }

    return (
        <NewModal open={open} onClose={() => handleCloseCreate()} title='Send Invite' btnTitle='Send' disabled={!selectedCaregiver} onSave={() => handleSendInvite()}>
            <Box>
                <Loader isLoading={fetcher.isLoading} />
                <Autocomplete
                    disabled={patient}
                    onChange={(e, option, reason) => { handleChangePatient(option, reason) }}
                    size='small'
                    getOptionLabel={(option) => option}
                    defaultValue={selectedPatient}
                    renderInput={(params) => <TextField {...params} value={selectedPatient?.name} label="Select Patient" />}
                    options={patients?.map((patient: any) => patient.name)}
                />
                {selectedPatient && visits &&
                    <Autocomplete
                        disabled={visit}
                        fullWidth
                        size='small'
                        value={selectedVisit}
                        onChange={(e, newValue) => setSelectedVisit(newValue)}
                        options={visits}
                        getOptionLabel={(option) =>
                            dateTime.getDateOnly(option.visitDate)?.toDateString() + ' - ' + dateTime.getTimeString(option.scheduleStartTime) + ' - ' + dateTime.getTimeString(option.scheduleEndTime) || ''
                        }
                        sx={{ marginTop: '10px' }}
                        renderInput={(params) => <TextField {...params} label="Select Visit" />}
                        isOptionEqualToValue={(option, value) => option.visitId === value.visitId}
                        noOptionsText="No visits in the next week"
                    />}
                {selectedVisit && caregivers &&
                    <Autocomplete
                        sx={{ marginTop: '10px' }}
                        onChange={(e, option, reason) => { handleChangeCaregiver(option, reason) }}
                        size='small'
                        getOptionLabel={(option) => option}
                        defaultValue={selectedCaregiver}
                        renderInput={(params) => <TextField {...params} value={selectedCaregiver?.name} label="Select Caregiver" />}
                        options={caregivers?.map((patient: any) => patient.name)}
                    />}
            </Box>
        </NewModal>
    )
}

export default SendInvite