import { Popup } from "react-map-gl";
import LocationI from './LocationI';
import './PopupInfo.css'
import { Button, Paper } from "@mui/material";
import { Bold, BoldTitle, DivContainer, Title } from "../../assets/styles/styledComponents";
import useFetcher from "../../hooks/useFetcher";
import ReactLoader from "../../components/common/ReactLoader";
import { dateTime } from "../../helper/datetime";
import { useNavigate } from "react-router-dom";
import { strings } from "../../helper/strings";

interface PopupInfoI {
    popupInfo: LocationI;
    setPopupInfo: Function;
}

const PopupInfo = (props: any) => {
    const info = props.popupInfo;
    const fetcher = useFetcher();
    const navigate = useNavigate();

    const findCaregiver = () => {
        fetcher.get(`Map/matches/${info.visitId}`).then(res => {
            props.setLocations(res.data.data)
        })
    }

    const getAddress = () => {
        if (info.address.addressLine1 == undefined)
            return info.address

        return `${info.address.addressLine1} ${info.address.addressLine2}, ${info.address.city} ${info.address.state}, ${info.address.zipCode}`
    }

    return (
        <Popup
            anchor="top"
            longitude={Number(info.address?.longitude)}
            latitude={Number(info.address?.latitude)}
            closeOnClick={false}
            onClose={() => props.setPopupInfo(null)}
        >
            <ReactLoader isLoading={fetcher.isLoading} />
            <Paper sx={{ width: '100%' }} elevation={0}>
                <DivContainer>
                    <BoldTitle onClick={() => navigate(`/${strings.firstLetterLowerCase(info.type)}/${info.id}/details`)} target='_blank' margin='0px 10px 20px 0px'><a>{info.type}</a> | {info.name} | {info.address?.city} ,{" "}
                        {info.address?.state}</BoldTitle>

                    {info.status != 'Active' && <BoldTitle color='red'>{info.status}</BoldTitle>}
                    <Bold><b>Address:</b> {getAddress()}</Bold>
                    {/* <Bold><b>Address:</b> {info.address.addressLine1} {info.address.addressLine2}, {info.address.city} {info.address.state}, {info.address.zipCode}</Bold> */}
                    <BoldTitle margin='10px 0px 5px 0px'>{info.type} Info:</BoldTitle>
                    <Title>{info.type == "Caregiver" ? "Caregiver Code" : "Admission Id"} : {info.code}</Title>
                    <Title>HHAX {info.type} Id: {info.hhaId}</Title>
                    <Title>Language: {info.language}</Title>
                    <div style={{ textAlign: 'center', marginTop: '10px' }}>
                        {info.visitId && <Button variant="outlined" size="small" onClick={() => findCaregiver()}>Find Caregiver</Button>}
                    </div>
                    {info.visit && <div>
                        <BoldTitle>Visit Info:</BoldTitle>
                        <Title>Distance: {info.visit.duration} min.</Title>
                        <Title>Available: {info.visit.availability}%</Title>
                        <Title>Last Worked: {dateTime.getDateString(info.visit.lastWorked)}</Title>
                        <Title>Last Worked W/ Patient: {info.visit.lastWithPatient || 'Never'}</Title>
                        <Title></Title>
                    </div>}
                    {props.popUpAdditions}
                </DivContainer>
            </Paper>
        </Popup>
    );
};

export default PopupInfo;
