import { Paper, Box, Typography, CircularProgress, Skeleton } from "@mui/material"
import { FlexJustifyStart } from "../../assets/styles/styledComponents"

type CardProps = {
    title: string,
    value: string,
    cardIcon: any
    height?: string
    marginTop?: string
    isLoading: boolean
}

const DashboardBoxCard = ({ title, value, cardIcon, height, marginTop, isLoading }: CardProps) => {

    const LoadingSkeleton = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'start' }}>
                <div style={{ padding: '5px' }}>
                    <Skeleton variant='rounded' width={30} height={30} animation='wave' />
                </div>
                <div style={{ marginLeft: '20px' }}>
                    <Skeleton variant='rectangular' animation='wave' height={20} width={100} />
                    <Skeleton variant='rectangular' animation='wave' height={30} width={50} sx={{ marginTop: '5%' }} />
                </div>
            </div>
        )
    }

    return (
        <Paper elevation={4} sx={{ borderRadius: '10px', marginTop: marginTop, height: height, backgroundColor: 'white', padding: '10px', '&:Hover': { transform: 'scale(1.05)' } }}>
            {isLoading ? <LoadingSkeleton /> :
                <FlexJustifyStart>
                    <Paper elevation={4} sx={{ background: '#7846ff', padding: '5px' }}>
                        {cardIcon}
                    </Paper>
                    <Box sx={{ marginLeft: '20px' }}>
                        <Typography>{title}</Typography>
                        <Typography fontWeight={700} variant='h5'>{value}</Typography>
                    </Box>
                </FlexJustifyStart>
            }
        </Paper>
    )
}

export default DashboardBoxCard