import _ from 'lodash';

type AnyObject = { [key: string]: any };

export const arrays = {
    joinWithOxfordComma,
    mergeArrRemoveDuplicates,
    groupByProperties,
    filterArrayWithProperty,
    getMaxObjectByProperty,
    getMinObjectByProperty
};

declare global {
    interface Window { arrays: typeof arrays; }
}

window.arrays = arrays;

/**
 * 
 * @param {Array<any>} arr 
 * @returns {String}    Formatted string of all the items. Depending on array length - 
 * separated by "and" when the array length is 2,
 * separated by commas and an oxford comma at the end when array length is 3 or more
 */
function joinWithOxfordComma(arr: any[]): string {
    if (arr.length <= 1) {
        return arr.join();
    }

    if (arr.length === 2) {
        return arr.join(' and ');
    }

    return [
        arr.slice(0, -2).join(', '),
        arr.slice(-2).join(', and ')
    ].join(', ');
}

function mergeArrRemoveDuplicates(array1: any[], array2: any[]): any[] {
    const mergedArray = [...array1, ...array2];
    return mergedArray.filter((item, index) => mergedArray.indexOf(item) === index);
}

function groupByProperties({ Group: data, By: fields }: { Group: AnyObject[], By: string[] }): AnyObject[][] {
    const getGroupedItems = (item: AnyObject): any[] => {
        return fields.map(field => item[field]);
    };

    let groups: { [key: string]: AnyObject[] } = {};

    for (let i = 0; i < data.length; i++) {
        const arrayRecord = data[i];
        const group = JSON.stringify(getGroupedItems(arrayRecord));
        groups[group] = groups[group] || [];
        groups[group].push(arrayRecord);
    }

    return Object.keys(groups).map((group) => {
        return groups[group];
    });
}

function filterArrayWithProperty(array: AnyObject[], property: string, field: any): AnyObject[] {
    return array.filter((item) => item[property] === field);
}

function getMaxObjectByProperty(array: AnyObject[], property: string): AnyObject | undefined {
    return _.maxBy(array, function (o) {
        return o[property];
    });
}

function getMinObjectByProperty(array: AnyObject[], property: string): AnyObject | undefined {
    return _.minBy(array, function (o) {
        return o[property];
    });
}

export const hasChanges = (data1: any, data2: any): boolean => {
    return !_.isEqual(data1, data2);
};

export const generateTempId = (objects: any[]): number => {
    // Find the lowest negative id in the array
    const lowestNegativeId = objects.reduce((minId, obj) => {
        const isN = typeof obj === "number";
        const id = !!isN ? Number(obj) : obj.id;
        if (id < 0 && id < minId) {
            return id;
        }
        return minId;
    }, 0);

    // If there are no negative ids, return -1
    if (lowestNegativeId === 0) {
        return -1;
    }

    // Otherwise, return one less than the lowest negative id
    return lowestNegativeId - 1;
}

type GenericObject = { [key: string]: any };

export const sortByStringProperty = <T extends GenericObject>(
    array: T[],
    property: keyof T,
    numberFirst: boolean = true
): T[] => {
    const isNumberStart = (str: string) => /^\d/.test(str);

    // Create a copy of the array to avoid mutating the original array
    const arrayCopy = [...array];

    return arrayCopy.sort((a, b) => {
        const aValue = a[property].toString();
        const bValue = b[property].toString();

        const aIsNumber = isNumberStart(aValue);
        const bIsNumber = isNumberStart(bValue);

        if (numberFirst) {
            if (aIsNumber && !bIsNumber) return -1;
            if (!aIsNumber && bIsNumber) return 1;
        }

        if (aIsNumber && bIsNumber) {
            const numA = parseInt(aValue.match(/^\d+/)?.[0] ?? '0', 10);
            const numB = parseInt(bValue.match(/^\d+/)?.[0] ?? '0', 10);
            return numA - numB;
        }

        return aValue.localeCompare(bValue);
    });
};