import { Grid, TextField, Typography } from '@mui/material';

interface GeneralPreferenceFieldProps {
  label: string;
  value?: number | string;
}

const GeneralPreferenceField: React.FC<GeneralPreferenceFieldProps> = ({ label, value }) => {
  return (
    <Grid item xs={12} style={{padding: 0, margin: '4px 0px 0px 15px'}}>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item xs={6}>
          <Typography variant="body2" style={{ fontWeight: 'normal' }}>{label}</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            value={value || ''}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              style: { fontSize: '14px', pointerEvents: 'none' }
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GeneralPreferenceField;