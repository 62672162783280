import Login from '../features/authentication/components/Login';
import Patients from '../pages/Patients';
import Caregiver from '../pages/Caregivers';
import Explorer from '../components/ui/Explorer';
import MatchProvider from '../features/schedules/components/VisitsBoard';
import Import from '../features/schedules/components/Import';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import Invites from '../features/schedules/components/Invites';
import LocalPostOfficeOutlinedIcon from '@mui/icons-material/LocalPostOfficeOutlined';
import UsersList from '../pages/Users/UsersList';
import Chat from '../pages/Chat/Chat';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import ManagePreferencePage from '../pages/Preference/ManagePreference';
import ManageAccountsOutlined from '@mui/icons-material/ManageAccountsOutlined';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import CoordinatorList from '../pages/Coordinators/CoordinatorList';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import {
    AccessibleOutlined, HandshakeOutlined,
    SearchOutlined, SettingsOutlined, WheelchairPickupOutlined, DashboardOutlined,
} from '@mui/icons-material';
import SettingsList from '../pages/Settings/SettingsList';
import Browse from '../pages/Browse/Browse';
import Dashboard from '../pages/Dashboard';
import { getUser } from '../helper/fetchHelpers';

const routes = () => {
    const user = getUser();
    const manageSubMenu = [
        {
            id: '8',
            label: 'Users',
            icon: <Groups2OutlinedIcon />,
            link: '/admin/users',
            component: <UsersList />,
        },
        {
            id: '14',
            label: 'Coordinators',
            icon: <SupervisorAccountIcon />,
            link: '/admin/coordinators',
            component: <CoordinatorList />,
        },
        {
            id: '13',
            icon: <SettingsAccessibilityIcon />,
            label: 'Preferences',
            link: '/admin/preference',
            component: <ManagePreferencePage />,
        },
        {
            id: '15',
            label: 'Settings',
            icon: <SettingsOutlined />,
            link: '/admin/settings',
            component: <SettingsList />
        },
     
    ];

    if (user?.roles?.roles?.includes('SuperAdmin')) {
        manageSubMenu.push({
            id: '7',
            icon: <CompareArrowsOutlinedIcon />,
            label: 'Data Syncing',
            link: '/admin/sync',
            component: <Import />,
        });
    }
    return [
        {
            id: '0',
            label: 'Login',
            hideMenu: true,
            link: '/',
            component: <Login />,
        },
        {
            id: '1',
            label: 'Login',
            hideMenu: true,
            link: '/login',
            component: <Login />,
        },
        {
            id: '16',
            icon: <DashboardOutlined />,
            label: 'Dashboard',
            link: '/dashboard',
            component: <Dashboard />,
        },
           // {
        //     id: '14',
        //     icon: <ChecklistOutlined />,
        //     label: 'My Tasks',
        //     link: '/tasks',
        //     component: <Tasks />,
        // },
        {
            id: '2',
            icon: <AccessibleOutlined />,
            label: 'Patients',
            link: '/patients',
            component: <Patients />,
           // subMenu: [
            //     {
            //         label: "Suggestions",
            //         value: 'suggestions',
            //         link: '/Patients/suggestions',
            //         component: <PreferenceSuggestion/>
            //     },
            // ]
        },
        {
            id: '3',
            icon: <WheelchairPickupOutlined />,
            label: 'Caregivers',
            link: '/caregivers',
            component: <Caregiver />,
            // subMenu: [
            //     {
            //         label: "Suggestions",
            //         value: 'suggestions',
            //         link: '/caregivers/suggestions',
            //         component: <PreferenceSuggestion/>
            //     },
            // ]
        },
        {
            id: '4',
            label: 'Details',
            hideMenu: true,
            link: '/:module/:id/details',
            component: <Explorer />,
            // {
        //     id: '17',
        //     icon: <PersonSearchOutlined />,
        //     label: 'Staffing',
        //     subMenu: [
        },
        {
            id: '19',
            icon: <SearchOutlined />,
            label: 'Browse',
            link: '/browse',
            component: <Browse />,
        },
        {
            id: '6',
            label: 'Visits',
            icon: <HandshakeOutlined />,
            link: '/visits',
            component: <MatchProvider />,
        },
        {
            id: '8',
            label: 'Invites',
            icon: <LocalPostOfficeOutlinedIcon />,
            link: '/invites',
            component: <Invites />,
        },
        {
            id: '10',
            label: 'Chat',
            icon: <ChatOutlinedIcon />,
            link: '/chat',
            component: <Chat />,
        },
        {
            id: '10',
            hideMenu: true,
            label: 'Chat',
            icon: <ChatOutlinedIcon />,
            link: '/chat/:selectedChatId?',
            component: <Chat />,
        },
        {
            id: '12',
            icon: <ManageAccountsOutlined />,
            label: 'Admin',
            admin: 'true',
            subMenu: manageSubMenu,
        },
    ];
};

export default routes;