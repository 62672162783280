import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate, BrowserRouter, useParams } from "react-router-dom";
import Login from '../features/authentication/components/Login'
import ManuDrawer from '../components/ui/ManuDrawer/ManuDrawer';
import AppHeader from '../components/ui/AppHeader/AppHeader';
import { Container, DivContainer, Flex, FlexGrow } from '../assets/styles/styledComponents';
import routes from '../routes';
import useAuth from '../features/authentication/hooks/useAuth';
import { RESET_PASS_PATH, homePageUrl, loginPageUrl } from '../data/constants';
import useUserInactivity from '../hooks/useUserInactivity';
import ResetPassword from '../features/authentication/components/ResetPassword';
import RequestAccess from '../features/authentication/components/RequestAccess';
import useFetcher from '../hooks/useFetcher';
import Availability from '../components/ui/Explorer/Tabs/Availability';
import UpdatePreference from '../pages/Preference/UpdatePreference';
import ThankYou from '../pages/ThankYou';
import ErrorPage from '../pages/Error/ErrorPage';
import FreshdeskWebWidget from '../utils/Freshdesk/FreshdeskWebWidget';
import InviteDetails from '../pages/Invite/InviteDetails';
import UpdateLink from '../pages/UpdateLink/UpdateLink';
import TermsOfService from '../components/Documents/TermsOfService';
import { strings } from '../helper/strings';
import PdfDoc from '../components/Documents/PdfDoc';
import OfficeDashboard from '../pages/OfficeDashboard';
import useAuthMonitor from '../hooks/useAuthMonitor';
import Loader from '../components/ui/Loader';

const Layout = () => {
    const location = useLocation();
    const isInactive = useUserInactivity(30)
    const navigate = useNavigate();
    const [isSideBarOpened, setSideBardOpened] = useState(false);
    const [openSideMenu, setOpenSideMenu] = useState(false)
    const { getUser, userAccount, setUserAccount } = useAuth();
    const { isLoggedIn, user, logout, checkIfIsLoggedIn } = useAuthMonitor();
    const [checkedAuth, setCheckedAuth] = useState<boolean>(false);

    useEffect(() => {
        if (isLoggedIn === null)
            return;

        setCheckedAuth(true);
    }, [isLoggedIn])

    useEffect(() => {
        console.log(checkedAuth, isLoggedIn);
        if (location.pathname === '/') {
            if (isLoggedIn === false && checkedAuth) {
                navigate(loginPageUrl);
            }
        }
        if ((location.pathname === loginPageUrl || location.pathname === '/') && isLoggedIn === true) {
            navigate(homePageUrl);
        }
        if (isLoggedIn === false && !!checkedAuth && !location.pathname.includes('reset') && !location.pathname.includes('pages') && location.pathname !== loginPageUrl
            && location.pathname !== '/terms-of-service') {
            navigate(loginPageUrl);
        }
    }, [location.pathname, isLoggedIn, checkedAuth])

    useEffect(() => {
        if (!!isInactive) { // (!!user || !user?.token)
            //TODO: revoke and refresh token
            console.log("user obj failed to provide auth");
            checkIfIsLoggedIn();
        }
    }, [isInactive])

    const handleOpenNavMenu = (open: boolean) => {
        setSideBardOpened(open)
    }

    const handleClickMenu = (open: boolean) => {
        setOpenSideMenu(open)
    }

    const isInnerApp = () => {
        if (location.pathname === '/' || location.pathname === '/login' || location.pathname.includes('reset') || location.pathname.includes('pages') ||
            location.pathname === '/terms-of-service') {
            return false
        }
        return true
    }

    return (
        <Flex>
            {isInnerApp() && <ManuDrawer open={isSideBarOpened || openSideMenu} handleOpenNavMenu={handleOpenNavMenu} handleClickMenu={handleClickMenu} logout={logout} user={user} />}
            {/* {isInnerApp() && <AppHeader handleOpenNavMenu={handleOpenNavMenu} handleClickMenu={handleClickMenu} isSideBarOpened={isSideBarOpened || openSideMenu} />} */}
            <FlexGrow grow={1}>
                {location.pathname !== loginPageUrl
                    && (
                        // null
                        < FreshdeskWebWidget user={user} />
                    )}
                <Routes>
                    <Route path="*" element={
                        isLoggedIn === null || !checkedAuth ? <><Loader isLoading={true}></Loader></> : <ErrorPage code='404' />
                    } />
                    <Route path="/terms-of-service" element={
                        <TermsOfService />
                        //<PdfDoc url={'https://drive.google.com/uc?export=view&id=1uIw84celFw5UUxHztwtwrb_OinsuKvkz'} />
                    } />
                    <Route path="/login" element={<Login />} />
                    <Route path="/reset/:token/:email" element={<ResetPassword />} />
                    <Route path="/pages/request-access/:email/:ip/:message" element={<RequestAccess />} />
                    {/* <Route path="/pages/availability/:id/:agencyId" element={<Availability />} /> */}
                    {/* <Route path="/pages/preference/:id/:agencyId" element={<UpdatePreference />} /> */}
                    {/* <Route path="/pages/thank-you" element={<ThankYou />} /> */}
                    <Route path="/pages/invite/:inviteCode/:agencyId" element={<InviteDetails />} />
                    <Route path="/office-dashboard" element={<OfficeDashboard />} />
                    {/* TESTING */}
                    <Route path="/pages/update/:id/:agencyId" element={<UpdateLink />} />
                    {routes().map((route: any, index: number) => {
                        //if (location.pathname !== loginPageUrl && isLoggedIn === false) return
                        // if (route.label === "Dashboard" && user?.agency !== 'Dev Care') return;
                        return (
                            <>
                                <Route
                                    key={route.id}
                                    path={route.link}
                                    element={route.component}
                                />
                                {
                                    route?.subMenu &&
                                    route?.subMenu.map((route: any, index: number) => {
                                        return (
                                            <Route
                                                key={route.id}
                                                path={route.link}
                                                element={route.component}
                                            />
                                        )
                                    })
                                }
                            </>
                        );
                    })}
                </Routes>
            </FlexGrow>
        </Flex>
    )
}

export default Layout