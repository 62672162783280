import { useState, useEffect } from "react";

function useUserInactivity(timeToInactive: number = 30) {
  const [isInactive, setIsInactive] = useState<boolean>(false);
  const [lastActiveTime, setLastActiveTime] = useState<number>(Date.now());

  useEffect(() => {
    let timerId: number;

    const handleUserInactivity = () => {
      setIsInactive(true);
    };

    const checkUserActivity = () => {
      const currentTime = Date.now();
      const timeSinceLastActive = currentTime - lastActiveTime;
      if (timeSinceLastActive > (timeToInactive * 60 * 1000)) {
        handleUserInactivity();
      }
    };

    checkUserActivity();
    timerId = window.setInterval(checkUserActivity, timeToInactive * 60 * 1000);

    return () => {
      window.clearInterval(timerId);
    };
  }, [lastActiveTime, timeToInactive]);

  const handleUserActivity = () => {
    setIsInactive(false);
    setLastActiveTime(Date.now()); // Update the last active time to the current time
  };

  useEffect(() => {
    // window.addEventListener("mousemove", handleUserActivity);
    // window.addEventListener("keydown", handleUserActivity);
    // window.addEventListener("scroll", handleUserActivity);

    return () => {
      // window.removeEventListener("mousemove", handleUserActivity);
      // window.removeEventListener("keydown", handleUserActivity);
      // window.removeEventListener("scroll", handleUserActivity);
    };
  }, []);

  return isInactive;
}

export default useUserInactivity;