import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Tooltip, Typography } from '@mui/material';
import { useEffect } from 'react';
import { weekDays } from '../../../../../src/data/constants';
import { BoldTitle, BoxContainer, FlexBetween, Text, Flex } from '../../../../assets/styles/styledComponents'
import Loader from '../../Loader';
import useFetcher from '../../../../hooks/useFetcher';
import { InfoOutlined } from '@mui/icons-material';


type byDaysType = {
    day: string,
    id: string,
    name: string,
    from: string,
    to: string
}

type byAssigneeType = {
    name: string,
    days: string[]
}

type byWorkedType = {
    Id: string,
    Name: string,
    lastVisitDate: string
}

export default function PatientInfo() {
    const [expanded, setExpanded] = React.useState<string>("");
    const { module, id } = useParams();
    const moduleId = id;
    const [byAssignees, setByAssignees] = React.useState([]);
    const [byDays, setByDays] = React.useState([]);
    const [byWork, setByWork] = React.useState([]);
    const [declined, setDeclined] = React.useState<any[]>([])
    const fetcher = useFetcher();

    useEffect(() => {
        loadAssignmentData();
        loadByWork();
        getDeclined()
    }, [])

    const loadAssignmentData = () => {
        fetcher.get(`assignments/${module}/${moduleId}/assigned`).then((res: any) => {
            setByAssignees(res.data.byAssignees)
            setByDays(res.data.byDays)
        })
    }

    const loadByWork = () => {
        fetcher.get(`assignments/${module}/${moduleId}/worked`).then((res: any) => {
            setByWork(res.data)
        })
    }

    const handleChange = (panel: string) => {
        setExpanded(panel === expanded ? "" : panel);
    };

    const getFilteredDayInfo = (day: string) => {
        const filtedDays = byDays?.filter((byDay: byDaysType) => byDay.day === day)
        return filtedDays ?? []
    }

    const getDeclined = () => {
        fetcher.get(`declined/${module}/${id}`).then((res: any) => {
            setDeclined(res.data.data)
        })
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={6} md={4} lg={3}>
                <Loader isLoading={fetcher.isLoading} />
                <BoldTitle margin="0px 0px 5px 0px">Assignments</BoldTitle>
                <BoxContainer padding="10px">
                    {
                        weekDays.map((day: string, index: number) => {
                            return (
                                <Accordion key={`${day}-${index}`} expanded={expanded === day} onChange={() => handleChange(day)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        disabled={getFilteredDayInfo(day).length <= 0 ? true : false}
                                    >
                                        {day}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {
                                            getFilteredDayInfo(day).map((byDay: byDaysType, index: number) => {
                                                return (
                                                    <FlexBetween key={`${byDay.id}-${index}`} style={{ borderBottom: "1px solid #ddd" }}>
                                                        <Text>{byDay.id}</Text>
                                                        <Text>{byDay.name}</Text>
                                                        <Text>{byDay.from}</Text>
                                                        <Text>{byDay.to}</Text>
                                                    </FlexBetween>
                                                )
                                            })
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })
                    }
                </BoxContainer>
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
                <BoldTitle margin="0px 0px 5px 0px">By Patients</BoldTitle>
                <BoxContainer minHeight="33px" padding="10px">
                    {
                        byAssignees?.map((patientData: byAssigneeType, index: number) => {
                            return (
                                <FlexBetween key={`${patientData.name}-${index}`} style={{ borderBottom: "1px solid #ddd" }}>
                                    <Text>{patientData.name}</Text>
                                    <Text>{patientData.days}</Text>
                                </FlexBetween>
                            )
                        })
                    }
                </BoxContainer>
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
                <Flex>
                    <BoldTitle margin="0px 0px 5px 0px">Worked</BoldTitle>
                    <Tooltip title="Patients they had worked with in the past">
                        <span>
                            <InfoOutlined
                                style={{ marginLeft: '2px', marginTop: '-4px', width: '13px', height: '15px' }}
                                fontSize={"small"}
                                // icon={'Info'}
                                color='primary'
                            />
                        </span>
                    </Tooltip>
                </Flex>
                <BoxContainer minHeight="33px" padding="10px">
                    {
                        byWork.map((byWorks: any, index: number) => {
                            return (
                                <FlexBetween key={`${byWorks.name}-${index}`} style={{ borderBottom: "1px solid #ddd" }}>
                                    <Text>{byWorks.name}</Text>
                                    <Text>{byWorks.lastVisitDate}</Text>
                                </FlexBetween>
                            )
                        })
                    }
                </BoxContainer>
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
                <Flex>
                    <BoldTitle margin="0px 0px 5px 0px">Declined {module == 'patient' ? 'Caregivers' : 'Patients'}</BoldTitle>
                    <Tooltip title="Declined by caregiver / Declined by patient">
                        <span>
                            <InfoOutlined
                                style={{ marginLeft: '2px', marginTop: '-4px', width: '13px', height: '15px' }}
                                fontSize={"small"}
                                // icon={'Info'}
                                color='primary'
                            />
                        </span>
                    </Tooltip>
                </Flex>
                <BoxContainer minHeight="33px" padding="10px">
                    {
                        declined.map((decline: any, index: number) => {
                            return (
                                <FlexBetween key={`${decline.name}-${index}`} style={{ borderBottom: "1px solid #ddd" }}>
                                    <Typography fontSize={'14px'} fontWeight={400}><Link style={{ textDecoration: 'underline', color: 'black' }} to={`/${decline.type}/${decline.declinedId}/details`} target='_blank'>{decline.name}</Link></Typography>
                                    <Text>{decline.reason}</Text>
                                </FlexBetween>
                            )
                        })
                    }
                </BoxContainer>
            </Grid>
        </Grid>

    )
}