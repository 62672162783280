import { Box, Grid, Typography } from '@mui/material';
import FeaturedIcon from '../../components/common/FeaturedIcon';
import GeneralPreferenceField from './GeneralPreferencesField';
import { useEffect } from 'react';

interface GeneralPreferencesProps {
    module: string; // Assuming module is a string type
    personalPreferences: {
        maxCaseHours?: number;
        maxTravelTime?: number;
        preferredGender?: string;
        preferredCommunicationMethod?: string;
        languageFlexibility?: string;
        notes?: string;
    };
    set_editAddtPrefs_open: (open: boolean) => void;
}

const GeneralPreferences: React.FC<GeneralPreferencesProps> = ({ module, personalPreferences, set_editAddtPrefs_open }) => {

    return (
        <div style={{ border: '1px solid #ccc', borderRadius: '3px', padding: '10px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center" style={{ borderBottom: '1px solid #d1d1d1', paddingBottom: '5px' }}>
                        <Grid item xs={2}>
                            {/* Placeholder for left-aligned content */}
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant="subtitle1" align="center" color="black" fontSize={'16px'} style={{ fontWeight: 'bold' }}>
                                Additional Preferences
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <FeaturedIcon icon="Edit" fontSize="small" onClick={() => set_editAddtPrefs_open(true)} />
                        </Grid>
                    </Grid>
                </Grid>
                <Box sx={{ marginTop: 1, width: '100%' }}>
                    {module === "patient" ? (
                        <>
                            <GeneralPreferenceField label="Preferred Gender" value={personalPreferences?.preferredGender} />
                            <GeneralPreferenceField label="Notes" value={personalPreferences?.notes} />
                        </>
                    ) : (
                        <>
                            <GeneralPreferenceField label="Max Case Hours" value={personalPreferences?.maxCaseHours} />
                            <GeneralPreferenceField label="Max Travel Time" value={personalPreferences?.maxTravelTime} />
                            <GeneralPreferenceField label="Preferred Gender" value={personalPreferences?.preferredGender} />
                            <GeneralPreferenceField label="Preferred Communication" value={personalPreferences?.preferredCommunicationMethod} />
                            <GeneralPreferenceField label="Language Flexibility" value={personalPreferences?.languageFlexibility} />
                            <GeneralPreferenceField label="Notes" value={personalPreferences?.notes} />
                        </>
                    )}
                </Box>
            </Grid>
        </div>
    );
};

export default GeneralPreferences;