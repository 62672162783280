import {
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
} from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import { useEffect, useState } from "react";
import { ProfileI } from "./FactorInterfaces";
import {
	urlDefaultProfile,
	urlProfileAdd,
	urlProfileUpdate,
	urlProfiles,
	urlSetDefaultProfile,
} from "./FactorUrlConstants";
import useDebounce2 from "../../../hooks/useDebounce2";
import axiosClient from "../../../helper/axiosConfig";
import useFetcher from "../../../hooks/useFetcher";

interface FactorSettingProfileProps {
	// UseState variable to store the selected profile
	selectedProfileId: number | undefined;

	// UseSate method to update the selectedProfileId
	setSelectedProfileIdGlobal: Function;

	setIsUpdating: Function;

	setResponse: Function;

	isMain: Boolean,
	setIsMain: Function,
}

export default function FactorSettingProfile({
	selectedProfileId,
	setSelectedProfileIdGlobal,
	setIsUpdating,
	setResponse,
	isMain,
	setIsMain,
}: FactorSettingProfileProps) {
	const [profiles, setProfiles] = useState<ProfileI[]>([]);
	const [name, setName] = useState<string | undefined>("");
	const [description, setDescription] = useState<string | undefined>("");
	const [defaultProfile, setDefaultProfile] = useState<number>();

	const debouncedUpdateProfile = useDebounce2(
		(
			profilesRef: ProfileI[],
			selectedProfileIndex: number,
			updatedProfile: any
		) =>
			updateProfileApi(profilesRef, selectedProfileIndex, updatedProfile),
		1000
	);

	const profileFetcher = useFetcher();
	const fetchProfilesApi = async (defaultProfileId: number) => {
		try {
			const response = await profileFetcher.get(urlProfiles);
			const { data } = response.data;

			setProfiles(data);

			typeof selectedProfileId === "undefined"
				? setSelectedProfileIdGlobal(defaultProfileId)
				: setSelectedProfileIdGlobal(selectedProfileId);
		} finally { }
	};

	const defaultFetcher = useFetcher();
	const fetchDefaultProfileApi = async () => {
		let defaultProfileId: number = 0;

		try {
			const response = await defaultFetcher.get(urlDefaultProfile)

			defaultProfileId = response.data.data;

			setDefaultProfile(defaultProfileId);

			await fetchProfilesApi(defaultProfileId);
		} finally { }

		return defaultProfileId;
	}

	const newProfileFetcher = useFetcher();
	const addProfileApi = async () => {
		const newProfile = {
			factorProfileId: 0,

			// default name
			name: " ",

			// default description
			description: " ",
		};

		try {
			const response = await newProfileFetcher.post(urlProfileAdd, newProfile);

			setProfiles((profiles) => [...profiles, response.data.data]);
			setSelectedProfileIdGlobal(response.data.data.factorProfileId);
		} finally {
		}
	};

	const profileUpdateFetcher = useFetcher();
	const updateProfileApi = (
		profilesRef: ProfileI[],
		selectedProfileIndex: number,
		updatedProfile: ProfileI
	) => {
		profileUpdateFetcher.post(urlProfileUpdate, updatedProfile).then((res: any) => {
			updateProfiles(profilesRef, selectedProfileIndex, updatedProfile);
			const resObj = { statusCode: res?.data.statusCode ?? '0', message: res?.data.message ?? 'Error' };
			setResponse(resObj);
		}).catch((err: any) => {
			const resObj = { statusCode: err?.response.data.statusCode ?? '0', message: err?.response.data.message ?? 'Error' };
			setResponse(resObj);
		}).finally(() => {
			setIsUpdating(false);
		});
	};

	const updateDefaultProfileApi = () => {
		axiosClient.post(`${urlSetDefaultProfile}/?newDefaultProfileId=${selectedProfileId}`);
		setDefaultProfile(selectedProfileId);
	}

	/**
	 * Event Handlers
	 */
	const handleProfileChange = async (event: any /* SelectChangeEvent */) => {
		const selectedValue: number = event.target.value;

		// if selectedValue is -1 --
		// the user wants to add a new profile
		if (selectedValue !== -1) {
			setSelectedProfileIdGlobal(selectedValue);
		} else {
			addProfileApi();
		}
	};

	const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIsUpdating(true);

		const newValue: string = event.target.value;

		setName(newValue);

		const { selectedProfile, selectedProfileIndex } = getCurrentProfile();

		const updatedProfile = { ...selectedProfile, name: newValue };

		debouncedUpdateProfile(profiles, selectedProfileIndex, updatedProfile);
	};

	const handleDescriptionChange = (event: any) => {
		setIsUpdating(true);

		const newValue = event.target.value;

		setDescription(newValue);

		const { selectedProfile, selectedProfileIndex } = getCurrentProfile();

		const updatedProfile = { ...selectedProfile, description: newValue };

		debouncedUpdateProfile(profiles, selectedProfileIndex, updatedProfile);
	};

	const handleCheckClick = () => {
		updateDefaultProfileApi();
	}

	/**
	 * Helper Functions
	 */
	const getCurrentProfile = () => {
		const selectedProfileIndex = profiles.findIndex(
			(p: ProfileI) => p.factorProfileId === selectedProfileId
		);

		const selectedProfile: ProfileI = { ...profiles[selectedProfileIndex] };

		return { selectedProfile, selectedProfileIndex };
	};

	const updateProfiles = (
		profiles: ProfileI[],
		selectedProfileIndex: number,
		updatedProfile: ProfileI
	) => {
		if (profiles[selectedProfileIndex].factorProfileId !== -1) {
			const updatedProfiles = [...profiles]; // Create a copy of the array
			updatedProfiles[selectedProfileIndex] = updatedProfile;
			setProfiles(updatedProfiles);
		}
	};

	/**
	 * Use Effects
	 */
	useEffect(() => {
		const selectedProfile = profiles?.find(
			(p) => p.factorProfileId === selectedProfileId
		);

		setName(selectedProfile?.name);
		setDescription(selectedProfile?.description);
		setSelectedProfileIdGlobal(selectedProfile?.factorProfileId);
	}, [selectedProfileId]);

	useEffect(() => {
		fetchDefaultProfileApi();
	}, []);

	useEffect(() => {
		if (name?.toLowerCase() === 'main')
			setIsMain(true);
		else
			setIsMain(false);
	}, [name]);

	return (
		<Stack
			direction="row"
			spacing={1}
			justifyContent="flext-start"
			alignItems="center"
			sx={{
				backgroundColor: "#eae3fd",
				borderRadius: "0px",
				padding: "15px 6px",
			}}
		>
			<FormControl size="small">
				<InputLabel>Select Profile</InputLabel>
				<Select
					id="profile"
					value={selectedProfileId ?? ""}
					label="Select Profile"
					onChange={handleProfileChange}
					sx={{
						width: 240, backgroundColor: "white"
					}}
				>
					<MenuItem key={-1} value={-1}>
						<b>Add New Profile</b>
					</MenuItem>
					{profiles &&
						profiles.map((prof) => (
							<MenuItem
								key={prof.factorProfileId}
								value={prof.factorProfileId}
								sx={{ color: defaultProfile === prof.factorProfileId ? "#7846ff" : "black" }}
							>
								{prof.name}
							</MenuItem>
						))}
				</Select>
			</FormControl>

			<TextField
				id="name"
				label="Name"
				variant="outlined"
				value={name}
				size="small"
				sx={{ width: 230, backgroundColor: "white" }}
				onChange={handleNameChange}
				disabled={!!isMain}
			/>

			<TextField
				id="description"
				label="Description"
				variant="outlined"
				value={description}
				size="small"
				sx={{ width: 290, backgroundColor: "white" }}
				onChange={handleDescriptionChange}
				disabled={!!isMain}
			/>

			<IconButton
				color={defaultProfile === selectedProfileId ? "success" : "default"}
				size="small"
				onClick={handleCheckClick}
				disabled={defaultProfile === selectedProfileId ? true : false}
				sx={{
					"&.Mui-disabled": {
						color: "#7846ff"
					}
				}}
			>
				<StarIcon fontSize="small" />
			</IconButton>
		</Stack>
	);
}
