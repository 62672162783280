import { Box } from "@mui/material"
import { BoldTitle } from "../../assets/styles/styledComponents"

type ErrorProps = {
    code: string
}

const ErrorPage: React.FC<ErrorProps> = ({ code }) => {
    return (
        <Box justifyContent={'center'} textAlign={'center'}>
            <Box marginTop={'15%'}>
                <BoldTitle color='red' fontSize='100px'>{code}</BoldTitle>
                {code == '403' &&
                    <>
                        <BoldTitle fontSize='50px'>Access Denied.</BoldTitle>
                        <BoldTitle fontSize='25px'>You Are Not Authorized To Access This Page.</BoldTitle>
                    </>
                    || code == '404' &&
                    <>
                        <BoldTitle fontSize='50px'>Page Not Found.</BoldTitle>
                        <BoldTitle fontSize='25px'>Looks Like The Page You Are Looking For Does Not Exist.</BoldTitle>
                    </>
                    || code == '500' &&
                    <>
                        <BoldTitle fontSize='50px'>Internal Server Error.</BoldTitle>
                        <BoldTitle fontSize='25px'>Our Apologies, But It Seems There's Been A Hiccup.</BoldTitle>
                    </>
                }
            </Box>
        </Box>
    )
}

export default ErrorPage