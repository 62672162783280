import { useRef, useCallback } from 'react';
import React, { useEffect, useState } from 'react';
import useFetcher, { FetcherHook } from '../../hooks/useFetcher';
import useToast from '../../hooks/useToast';
import { VisitData } from './types';

type useVisitsProps = {
  visitId?: number;
  onDelete?: (id: number) => void;
  onSave?: (data: any) => void;
  setList: (value: React.SetStateAction<VisitData[]>) => void;

}

export type VisitsHook = {
  visitData: VisitData,
  deleteRow: () => Promise<any>,
  onDelete?: (id: number) => void;
  save: (updatedVisit?: VisitData) => Promise<any>,
  load: () => Promise<any>,
  fetcher: FetcherHook,
  setVisitId: React.Dispatch<React.SetStateAction<number | undefined>>,
  updateRow: (updatedVisit: VisitData, addIfNotFound?: boolean) => void,
  addSubscriber: (func: (visits: VisitData[]) => void) => void,
  removeSubscriber: (func: (visits: VisitData[]) => void) => void,
  //setList: (value: React.SetStateAction<VisitData[]>) => void;
}

const useVisits = ({ visitId: visitId_prop, onDelete, onSave, setList }: useVisitsProps) => {
  const [visitData, setVisitData] = useState<VisitData>({} as VisitData);
  const fetcher = useFetcher('visits', {}, undefined, undefined, undefined, undefined, undefined, true, false);
  const [visitId, setVisitId] = useState<number | undefined>(visitId_prop);
  const { showError, showSuccess } = useToast();

  const deleteRow = async () => {
    if (!visitData.visitId) return;

    try {
      const response = await fetcher.delete(visitData.visitId.toString());
      showSuccess('Visit Deleted!');

      // updating state
      setList((prevVisits) => {
        let updatedList: VisitData[] = [];

        const visitIndex = prevVisits.findIndex((visit) => visit.visitId === visitData.visitId);
        if (visitIndex !== -1) {
          updatedList = [...prevVisits.slice(0, visitIndex), ...prevVisits.slice(visitIndex + 1)];
        } else {
          console.warn('Visit with ID', visitData.visitId, 'not found in state');
          return prevVisits;
        }
        subscribers.current.forEach(func => func(updatedList));
        return updatedList;
      });

      if (typeof onDelete === 'function') {
        onDelete(visitData.visitId);
      }
      return response;
    } catch (error: any) {
      console.error('Error deleting visit:', error);
      return error;
    }
  };

  const save = async (updatedVisit: VisitData = visitData) => {
    if (!updatedVisit) return;

    const payload = {
      ...updatedVisit,
    };

    try {
      const response = await fetcher.post('', payload);
      showSuccess('Visit Saved!');
      if (typeof onSave === 'function') {
        onSave(response.data);
      }
      setVisitData(updatedVisit);
      return response;
    } catch (error: any) {
      console.error('Error saving visit:', error);
      return error;
    }
  };

  // Load visit details on component mount
  useEffect(() => {
    if (!visitId) return;

    load();
  }, [visitId]);

  const load = async () => {
    if (!visitId) return; // No need to fetch if no visitId

    try {
      const response = await fetcher.get(`${visitId}`);
      setVisitData(response.data.data);
      return response;
    } catch (error: any) {
      console.error('Error fetching visit details:', error);
      return error;
    }
  };

  const updateRow = (
    updatedVisit: VisitData,
    addIfNotFound = false
  ) => {
    if (!setList || !updatedVisit) return;

    setList((prevVisits) => {
      let updatedList = [...prevVisits];  
      const visitIndex = prevVisits.findIndex(
        (visit) => visit.visitId === updatedVisit.visitId
      );
  
      if (visitIndex !== -1) {
        // Update the visit at the found index
        if (JSON.stringify(prevVisits[visitIndex]) !== JSON.stringify(updatedVisit)) {
          updatedList[visitIndex] = updatedVisit;
        }
      } else {
        // Visit not found
        if (addIfNotFound) {
          // Add the visit if addIfNotFound is true
          updatedList.push(updatedVisit);
        } else {
          console.warn('Visit with ID', updatedVisit.visitId, 'not found in state');
          return prevVisits; // Don't modify state if not found and addIfNotFound is false
        }
      }
  
      subscribers.current.forEach(func => func(updatedList));
  
      return updatedList;
    });
  };
  

  // event listening:
  const subscribers = useRef<((visits: VisitData[]) => void)[]>([]);

  const addSubscriber = useCallback((func: (visits: VisitData[]) => void) => {
    subscribers.current.push(func);
  }, []);

  const removeSubscriber = useCallback((func: (visits: VisitData[]) => void) => {
    subscribers.current = subscribers.current.filter(sub => sub !== func);
  }, []);

  // You can add additional logic for modal handling or data manipulation here

  return {
    visitData,
    deleteRow,
    save,
    load, // Optional to expose for manual loading
    fetcher,
    setVisitId,
    updateRow,
    addSubscriber,
    removeSubscriber
    //setList,
  } as VisitsHook;
};

export default useVisits;