import { Box, Button, Chip, IconButton, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Paper, Select, Tooltip } from "@mui/material"
import { FlexBetween, Text } from "../../../../../assets/styles/styledComponents"
import useFetcher from "../../../../../hooks/useFetcher"
import { useEffect, useState } from "react"
import NewModal from "../../../../../components/ui/NewModal"
import { dateTime } from "../../../../../helper/datetime"
import { CUSTOM_SCROLL, getStatusColor } from "../../../../../data/constants"
import { AddCircleOutline, InfoOutlined, RemoveCircleOutline, Repeat } from "@mui/icons-material"
import ReactLoader from "../../../../../components/common/ReactLoader"
import InviteStatusEdit from "./InviteStatusEdit"
import useToast from "../../../../../hooks/useToast"
import { strings } from "../../../../../helper/strings"

type Props = {
    inviteCode: any,
    setInviteCode?: any,
    open: boolean,
    setOpen: any,
    setRow?: any,
    fetch?: any
}

type EditedVisit = {
    inviteId: string,
    status: string,
    isRevoked: boolean
}

type AddedType = {
    visitId: any
    permanency: string
}

const EditInvite = ({ inviteCode, setInviteCode, open, setOpen, setRow, fetch }: Props) => {
    const fetcher = useFetcher();
    const [invite, setInvite] = useState<any>();
    const [modifiedInvites, setModifiedInvites] = useState<EditedVisit[]>([]);
    const [addedVisits, setAddedVisits] = useState<AddedType[]>([]);
    const [patientVisits, setPatientVisits] = useState<any[]>([]);
    const [openStatus, setOpenStatus] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { showSuccess } = useToast();
    const [localRow, setLocalRow] = useState<any>()

    const getInvite = () => {
        if (!inviteCode)
            return;

        fetcher.get(`Invite/details/${inviteCode}`).then((res: any) => {
            setInvite(res.data.data)
        })
    }

    useEffect(() => {
        getInvite();
    }, [inviteCode])

    const handleSave = () => {
        const payload = {
            modifiedInvites: modifiedInvites,
            addedVisits: addedVisits
        }
        fetcher.post(`Invite/update/${inviteCode}`, payload).then(res => {
            setInvite(res.data.data);
            setModifiedInvites([])
            setAddedVisits([])
            //handleClose()
            //!!fetch && fetch()
        })
    }

    const getVisits = () => {
        fetcher.get(`Visit/patient/${invite.patientId}/14`).then((res: any) => {
            setPatientVisits(res.data.visits?.filter((pv: AddedType) => !invite.visits.some((v: any) => v.visitId === pv.visitId)))
        })
    }

    const handleClose = () => {
        setRow && setRow([])
        setInvite(null)
        setInviteCode && setInviteCode(null)
        setOpen(false)
        setPatientVisits([])
        setModifiedInvites([])
        setAddedVisits([])
    }

    useEffect(() => {
        if (!!modifiedInvites?.length || !!addedVisits?.length)
            handleSave();
    }, [modifiedInvites, addedVisits]);
    const handleModify = (inviteid: string, status: string, revoked: boolean) => {
        const edited: EditedVisit = {
            inviteId: inviteid,
            status: status,
            isRevoked: revoked
        }

        let existing = modifiedInvites.find(i => i.inviteId == inviteid);

        if (existing) {
            const removed = modifiedInvites.filter(m => m.inviteId != inviteid)
            setModifiedInvites(removed)
        } else {
            const added = [...modifiedInvites, edited]
            setModifiedInvites(added)
        }
    }

    useEffect(() => {
        console.log(patientVisits);
    }, [patientVisits])

    const handleAdded = (e: any) => {
        const incomingIds = e.target.value;

        const updatedIds: AddedType[] = incomingIds.map((id: any) => {
            let visit = patientVisits.find(v => v.visitId == id);
            return {
                visitId: id,
                permanency: visit?.isPermanent ? 'Permanent' : 'Temporary'
            };
        });

        if (!!updatedIds?.length)
            setAddedVisits(updatedIds);
    };

    const handlePermChange = (id: string, permanency: 'Permanent' | 'Temporary') => {
        fetcher.get(`Invite/update-permanency/${id}/${permanency}`).then(() => {
            getInvite()
        })
    }

    const handleStatusChange = (status: string) => {
        fetcher.get(`Invite/update-status/${localRow.inviteId}/${status}`).then(() => {
            showSuccess("Status updated.")
            setOpenStatus(false);
            getInvite()
        })
    }


    const handleOpenStatus = (event: React.MouseEvent<HTMLElement>, row: any) => {
        setLocalRow(row)
        setOpenStatus(true)
        setAnchorEl(event.currentTarget);
    }


    return (
        <NewModal hideBtn onSave={() => handleSave()} title={'Edit Invite'} height="500px" open={open} onClose={() => handleClose()}>
            <ReactLoader isLoading={fetcher.isLoading} />
            <Box>
                <Box display={'flex'} flexDirection={'column'}>
                    <Text>Patient: {invite?.patientName}</Text>
                    <Text>Caregiver: {invite?.caregiverName}</Text>
                    <Text>Sent On: {invite ? dateTime.getFormattedDateTime(invite?.sentOn) : ''}</Text>
                </Box>
                <Paper elevation={1} sx={{ border: '1px solid gray' }}>
                    <List sx={{ height: '250px', overflowX: 'scroll', ...CUSTOM_SCROLL }}>
                        {invite && invite.visits.map((visit: any) =>
                            <ListItem disablePadding>
                                <ListItemAvatar>
                                    <Tooltip title={visit.isRevoked ? "Re-invoke invite" : 'Revoke invite'}>
                                        <IconButton onClick={() => handleModify(visit.inviteId, visit.inviteStatus, !visit.isRevoked)}>
                                            {visit.isRevoked ? <AddCircleOutline /> : <RemoveCircleOutline color="error" />}
                                        </IconButton>
                                    </Tooltip>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={visit?.visitDateTime}
                                    secondary={<Box display={'flex'} justifyContent={'start'}>
                                        <Tooltip
                                            title={"The status of the caregiver's response on this shift"}
                                            placement="left"
                                        >
                                            <Button
                                                sx={{ width: '95px', height: '25px', fontSize: '0.7rem', color: getStatusColor(visit?.inviteStatus), borderColor: getStatusColor(visit?.inviteStatus), '&:hover': { borderColor: getStatusColor(visit?.inviteStatus) } }}
                                                size='small'
                                                onClick={(e) => handleOpenStatus(e, visit)}
                                                variant='outlined'
                                            >
                                                {visit?.inviteStatus}
                                            </Button>
                                        </Tooltip>
                                        {visit.responsePermanency &&
                                            <Box sx={{ paddingBottom: '10px' }}>
                                                <span>Temp</span>
                                                <Tooltip title={'Accepted temporary' + ('.\nWas invited to serve as ' + visit.permanency)}>
                                                    <IconButton disableRipple>
                                                        <InfoOutlined fontSize='small' color='primary' sx={{ width: '15px' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        }
                                        <Tooltip
                                            title={
                                                "Invite to serve this shift " + (visit.permanency == 'Permanent' ? 'permanently' : 'temporary')}
                                            placement="top"
                                        >
                                            <Chip
                                                sx={{
                                                    width: '100px',
                                                    height: '25px',
                                                    borderRadius: '2px',
                                                    marginLeft: '10px'
                                                }}
                                                onClick={() => handlePermChange(visit.inviteId, visit.permanency == 'Temporary' ? 'Permanent' : 'Temporary')}
                                                label={visit.permanency || 'Temporary'}
                                            />
                                        </Tooltip>
                                        {visit.schedulePermanency?.toLowerCase() === 'permanent' ?
                                            <Tooltip
                                                title={
                                                    strings.firstLetterUpperCase(visit.schedulePermanency?.toLowerCase()) + " case, " +
                                                    (
                                                        visit.staffingPermanency == "None" ? "has a permanently assigned caregiver" :
                                                            "needs a " + (visit.staffingPermanency == 'Permanent' ? 'permanent' : 'temporary') +
                                                            " staffing")}
                                                placement="right"
                                            >
                                                <Repeat sx={{
                                                    height: '15px', width: '16px', fontWeight: 'bold', paddingLeft: '10px',
                                                    color:
                                                        (visit.staffingPermanency == 'Temporary') ? 'orange' :  // caregiver on assignment is not assigned to shift
                                                            (visit.staffingPermanency == 'Permanent') ? 'red' : 'inherit'
                                                }} />
                                            </Tooltip> : null}
                                    </Box>}
                                />
                            </ListItem>
                        )}
                    </List>
                </Paper>
                <FlexBetween>
                    <Button onClick={() => getVisits()} size="small">Add Visits</Button>
                    {patientVisits?.length > 0 &&
                        <Select multiple value={addedVisits.map(v => v.visitId)} onChange={(e) => handleAdded(e)} size="small" sx={{ width: '80%' }}>
                            {patientVisits.map((visit: any) =>
                                <MenuItem key={visit.visitId} value={visit.visitId} sx={{ color: invite.visits.find((iv: any) => iv.visitId == visit.visitId) ? 'lightgrey' : 'unset' }}>{`${dateTime.getDate(visit.visitDate)} - ${dateTime.getTimeString(visit.scheduleStartTime)} - ${dateTime.getTimeString(visit.scheduleEndTime)}`}</MenuItem>
                            )}
                        </Select>
                    }
                </FlexBetween>
            </Box>
            <InviteStatusEdit openStatus={openStatus} setOpenStatus={setOpenStatus} anchorEl={anchorEl} handleStatusChange={handleStatusChange} />
        </NewModal>
    )
}

export default EditInvite