import React, { useEffect, useRef, useState } from 'react';
import { Paper, Box, ListItem, ListItemIcon, ListItemText, IconButton, Tooltip, Link as MuiLink, Avatar, Typography } from '@mui/material';
import { Card as CardPaper } from '../../components/ui/Card'
import MessageOutlined from '@mui/icons-material/MessageOutlined';
import PhoneOutlined from '@mui/icons-material/PhoneOutlined';
import MoreVert from '@mui/icons-material/MoreVert';
import Loader from '../../components/ui/Loader';
import { BoldTitle } from '../../assets/styles/styledComponents';
import { CustomScrollbarStyles, MessageDto } from './types';
import RichTextField from '../../components/form/RichTextField/RichTextField';
import { StickyNote2Outlined } from '@mui/icons-material';
import ReactLoader from '../../components/common/ReactLoader';
import InviteDetailsModal from '../../features/schedules/components/Modals/InviteModal/InviteDetailsModal';
import { Link } from "react-router-dom";
import { app_purple } from '../../data/constants';
import { AppyUser } from '../../hooks/types';

type ChatAvatarProps = {
    senderPosition: string;
};

const blueVariant = '#e2f0fe';
const lightGreenVariant = '#ddf7de';
const orangeVariant = '#ffecde'; // Orange variant
const redVariant = '#ffdede'; // Red variant
const purpleVariant = '#e8e0ff';

const bluerVariant = '#b3e0ff';
const purplerVariant = '#e2c0fe'; // Purple variant
const waGreen = '#daffdb';
const greenerVariant = '#d2f5d3';
const evenGreenerVariant = '#bbffb3';
const lighterGreenVariant = '#e2fee3';

const greenVariant = redVariant;

const caregiverMsgBackground = purpleVariant; // blue
const coordinatorMsgBackground = redVariant; // orange
const ownerMsgBackground = lightGreenVariant; // green
const getAvatarColor = (senderPosition: string) => {
    console.log(senderPosition);
    switch (senderPosition) {
        case 'aide':
            return caregiverMsgBackground;
        case 'coordinator':
            return coordinatorMsgBackground;
        case 'owner':
            return ownerMsgBackground;
        default:
            return 'info';
    }
}

const ChatAvatar = ({ senderPosition }: ChatAvatarProps) => {
    const avatarStyle = {
        position: 'absolute',
        top: '20px',
        backgroundColor: getAvatarColor(senderPosition),
        marginRight: senderPosition !== 'aide' ? '0px' : '10px',
        marginLeft: senderPosition === 'aide' ? '0px' : '10px',
    };

    return (
        <Avatar sx={avatarStyle} />
    );
};

interface ChatMessagesProps {
    selectedChat: {
        chatName: string;
        chatNumber: string;
        id: string;
        chatCaregiverId: string
        lastMessage: {
            sent: Date;
            message: string;
            userId: string;
            // Add other properties for lastMessage as needed
        };
        unreadMessages: number;
        // Add other properties for selectedChat as needed
    } | null;
    messages: MessageDto[];
    user: AppyUser;
    messagesFetcher: {
        isLoading: boolean;
    };
    dateTime: {
        getFormattedDateTime: (date: Date) => string;
        getDisplayValue: (date: Date) => string;
        // Add other methods or properties for dateTime as needed
    };
    height?: string;
    hideSender?: boolean,
    showHeader?: boolean,
    size?: 'small' | 'normal';
    timeline?: any,
    setOpenNotes?: (value: boolean) => void,
    setNoteMessage?: (value: string) => void,

    // all from here is not needed in external read-only mode
    msgBody?: string;
    setMsgBody?: (value: string) => void;
    sendMessage?: () => void;
    handleOpenInvites?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleShowEmoji?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    markAsRead?: (chatId: string) => void;
    readFetcher?: {
        isLoading: boolean;
    };
    handleKeyClicks?: (e: any) => void;
}

const ChatMessages: React.FC<ChatMessagesProps> = ({
    selectedChat,
    messages,
    user,
    messagesFetcher,
    dateTime,
    height,
    hideSender,
    showHeader,
    size = 'normal',
    timeline,

    // all from here is not needed in external read-only mode
    setNoteMessage,
    setOpenNotes,
    msgBody,
    setMsgBody,
    sendMessage,
    handleOpenInvites,
    handleShowEmoji,
    markAsRead,
    readFetcher,
    handleKeyClicks,
}) => {
    const smsContainerRef = useRef<HTMLDivElement | null>(null);
    const [messagesMerged, setMessagesMerged] = useState<any[]>([]);
    const [filteredTimeline, setFilteredTimeline] = useState<any[]>([]);
    const [selectedInviteCode, setSelectedInviteCode] = useState('');
    const [openInviteDetails, setOpenInviteDetails] = useState(false)

    const scrollToBottom = () => {
        if (smsContainerRef.current) {
            smsContainerRef.current.scrollTop = smsContainerRef.current.scrollHeight;
        }
    };

    const formatPhoneNumber = (phoneNumber: string): string => {
        if (phoneNumber == null || phoneNumber == undefined)
            return "";

        let numericPhoneNumber = phoneNumber?.replace(/\D/g, '');
        if (numericPhoneNumber?.length === 11 && numericPhoneNumber[0] === '1') {
            numericPhoneNumber = numericPhoneNumber.slice(1);
        }
        const formattedPhoneNumber = `(${numericPhoneNumber.slice(0, 3)})-${numericPhoneNumber.slice(3, 6)}-${numericPhoneNumber.slice(6)}`;
        return formattedPhoneNumber;
    };

    const customScrollbarStyles: CustomScrollbarStyles = {
        '&::-webkit-scrollbar': {
            width: '7px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'lightgrey',
            borderRadius: '6px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#7846ff',
        },
    };

    const messageStyle = {
        maxWidth: '600px',
        borderRadius: '10px',
        padding: size == 'small' ? '5px' : '10px'
    }

    useEffect(() => {
        // Scroll to the bottom when the component mounts
        scrollToBottom();
    }, [selectedChat, messagesMerged]);

    const setMsgBody_local = (e: string) => {
        if (!!setMsgBody)
            setMsgBody(e);
    }

    const sendMessage_local = () => {
        if (!!sendMessage) {
            sendMessage();
        }
    }

    const handleOpenInvites_local = (e: any) => {
        if (!!handleOpenInvites)
            handleOpenInvites(e);
    }

    const handleShowEmoji_local = (e: any) => {
        if (!!handleShowEmoji)
            handleShowEmoji(e);
    }

    const handleKeyClicks_local = (e: any) => {
        if (!!handleKeyClicks)
            handleKeyClicks(e);
    }

    useEffect(() => {
        if (!timeline)
            return;

        const uniqueEntries: Set<string> = new Set();

        const filtered = timeline.filter((entry: any) => {
            const trimmedTimestamp = entry?.createdOn.substring(0, entry?.createdOn.indexOf(':') + 3);
            const key = `${trimmedTimestamp}-${entry?.summary}`;
            if (!uniqueEntries.has(key)) {
                uniqueEntries.add(key);
                return true;
            }
            return false;
        });

        setFilteredTimeline(filtered);
    }, [timeline]);

    useEffect(() => {
        if (!messages)
            return;

        if (messages.length < 1 && filteredTimeline.length > 0)
            setFilteredTimeline([])

        let merged = messages.concat(filteredTimeline || [])
        merged = merged.sort((a: any, b: any) => {
            const sentA = a.sent || a.createdOn;
            const sentB = b.sent || a.createdOn;
            return sentA > sentB ? 1 : sentA < sentB ? -1 : 0;
        })
        setMessagesMerged(merged)
    }, [filteredTimeline, timeline, messages])

    const setOpenNotes_local = () => {
        if (!!setOpenNotes)
            setOpenNotes(true);
    }

    const setNoteMessage_local = (value: string) => {
        if (!!setNoteMessage)
            setNoteMessage(value);
    }

    const handleOpenNotes = (message: any) => {
        setOpenNotes_local()
        setNoteMessage_local(message.message)
    }

    const AddNoteButton = (props: any) => {
        return (
            <Tooltip title='Save as note'>
                <IconButton onClick={() => handleOpenNotes(props.message)} sx={{ color: 'rgb(0, 0, 0, 0.08)', '&:Hover': { color: '#7846ff' } }}>
                    <StickyNote2Outlined fontSize='small' />
                </IconButton>
            </Tooltip>
        )
    }

    const handleClickCode = (code: any) => {
        setSelectedInviteCode(code)
        setOpenInviteDetails(true)
    }

    return (
        <div>
            <CardPaper variant="contained">
                <Paper
                    elevation={0}
                    sx={{
                        height: height || '100vh',
                        backgroundColor: '#f5f5f5',
                        overflow: 'hidden',
                        position: 'relative',
                    }}
                >
                    {showHeader && selectedChat &&
                        <Paper
                            elevation={0}
                            style={{
                                position: 'absolute',
                                top: '0px',
                                background: 'white',
                                width: '100%',
                                zIndex: 100,
                                height: '60px',
                                color: '#7846ff',
                                borderRadius: '0'
                            }}
                        >
                            <div style={{ margin: '-5px 0px 0px 40px', display: 'flex', justifyContent: 'space-between' }}>
                                <ListItem sx={{ borderRadius: '0' }}>
                                    <ListItemIcon sx={{ marginRight: '-20px' }}>
                                        <MessageOutlined sx={{ color: "#7846ff" }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{ marginTop: '0px', color: "#7846ff" }}
                                        primaryTypographyProps={{ color: '#7846ff' }}
                                        primary={
                                            <Typography fontWeight={'bold'} color="#7846ff" fontSize='1.1em'>
                                                <Link
                                                    style={{ textDecoration: 'none', color: app_purple }}
                                                    target='_blank' to={`/caregiver/${selectedChat.chatCaregiverId}/details`}>{selectedChat?.chatName}
                                                </Link>
                                            </Typography>}
                                        secondary={<BoldTitle color="#7846ff">Last Messaged: {dateTime.getFormattedDateTime(selectedChat?.lastMessage?.sent)}</BoldTitle>}
                                    />
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <IconButton href={`tel:${selectedChat.chatNumber}`}>
                                            <PhoneOutlined fontSize="small" sx={{ color: '#7846ff' }} />
                                        </IconButton>
                                        <BoldTitle color='#7846ff' >{formatPhoneNumber(selectedChat?.chatNumber)}</BoldTitle>
                                    </div>
                                    {/* {selectedChat?.unreadMessages && <div>
                                                    <Tooltip title='Mark as read'>
                                                        <IconButton sx={{ zIndex: 10000, marginRight: '10px', marginLeft: '10px' }} onClick={() => markAsRead(selectedChat.id)}>
                                                            <MarkChatRead sx={{ color: '#7846ff' }} />
                                                            <ReactLoader width="30px" height="30px" isLoading={readFetcher.isLoading} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>} */}
                                    <IconButton onClick={(e) => handleOpenInvites_local(e)}>
                                        <MoreVert color="primary" />
                                    </IconButton>
                                </ListItem>
                            </div>
                        </Paper>}
                    <Box id={'smsContainer'} ref={smsContainerRef} sx={{ width: '100%', overflowY: 'scroll', height: hideSender ? '100%' : '85%', position: 'absolute', top: showHeader ? '60px' : '0px', ...customScrollbarStyles }}>
                        <div style={{ marginTop: '10px' }}>
                            {showHeader ? <Loader isLoading={messagesFetcher.isLoading} /> : <ReactLoader isLoading={messagesFetcher.isLoading} />}
                            {selectedChat && messages?.length < 1 && !messagesFetcher.isLoading && <BoldTitle margin='25% 45%'>No Messages</BoldTitle>}
                            {messagesMerged.filter((item) => item !== null).map((item: any, index: any) => {
                                if ('message' in item) {
                                    const message = item;
                                    console.log(message.senderPosition);
                                    const isLastMessage = index === messages.length - 1;
                                    const isFirstInSequence = index === 0 || messages[index - 1]?.userId !== message.userId;
                                    const isLastInSequence = isLastMessage || messages[index + 1]?.userId !== message.userId;

                                    return (
                                        <div style={{ marginTop: '-20px', display: 'flex', flexDirection: 'column' }}>
                                            <ListItem
                                                key={index}
                                                sx={{
                                                    display: 'inline-flex',
                                                    alignSelf: message.senderPosition != 'aide' ? 'flex-end' : 'flex-start',
                                                    width: 'fit-content',
                                                    ...messageStyle,
                                                }}
                                            >
                                                {setNoteMessage && message.senderPosition != 'aide' && <AddNoteButton message={message} />}
                                                {
                                                    message.senderPosition === 'aide' && isLastInSequence &&
                                                    <ListItemIcon>
                                                        <ChatAvatar
                                                            senderPosition={message.senderPosition}
                                                        />
                                                    </ListItemIcon>
                                                }
                                                {
                                                    message.senderPosition === 'aide' && !isLastInSequence &&
                                                    <ListItemIcon>
                                                        <div />
                                                    </ListItemIcon>
                                                }
                                                <ListItemText
                                                    sx={{
                                                        backgroundColor:
                                                            message.userId == user?.userId ? ownerMsgBackground :
                                                                message.senderPosition === 'aide' ? caregiverMsgBackground : coordinatorMsgBackground,
                                                        ...messageStyle
                                                    }}
                                                    primary={
                                                        message.senderPosition === 'aide' ?
                                                            <span style={{ fontSize: size === 'small' ? '0.7em' : '0.8em', color: 'black' }}>
                                                                {message.message}
                                                            </span>
                                                            :
                                                            <span
                                                                style={{ fontSize: size === 'small' ? '0.7em' : '0.8em', color: 'black' }}
                                                                // we don't need to worry so much for now, for messages coming from the 'agency' side
                                                                // in any case, we do screen and sanitize on server side to some extent
                                                                dangerouslySetInnerHTML={{ __html: message.message }}
                                                            />
                                                    }
                                                    secondary={
                                                        <span style={{ display: 'flex', justifyContent: 'space-between', fontSize: size == 'small' ? '0.7em' : '0.8em', color: '#4D4D4D' }}>
                                                            <div style={{ marginRight: '15px' }}>
                                                                {message.senderName && message.senderName}
                                                            </div>
                                                            <div>
                                                                {message.sent && dateTime.getDisplayValue(message.sent)}
                                                            </div>
                                                        </span>}
                                                />
                                                {
                                                    message.senderPosition != 'aide' && isLastInSequence &&
                                                    <ListItemIcon>
                                                        <ChatAvatar
                                                            senderPosition={message.senderPosition}
                                                        />
                                                    </ListItemIcon>}
                                                {
                                                    // this is needed to give align messages, when there's multiple consecutive messages of same user
                                                    message.senderPosition != 'aide' && !isLastInSequence &&
                                                    <ListItemIcon>
                                                        <div />
                                                    </ListItemIcon>
                                                }
                                                {setNoteMessage && message.senderPosition === 'aide' && <AddNoteButton message={message} />}
                                            </ListItem>
                                        </div>
                                    );
                                } else if ('summary' in item) {
                                    const timelineEntry = item;
                                    return (
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div style={{ borderRadius: '5px', padding: '10px', margin: '5px', maxWidth: '450px', width: '95%' }}>
                                                <BoldTitle textAlign='center' fontWeight='200px' fontSize='10px'>{dateTime.getDisplayValue(timelineEntry.createdOn)}</BoldTitle>
                                                <BoldTitle textAlign='center' fontWeight='200px' fontSize='12px'>{timelineEntry.summary} - <MuiLink onClick={() => handleClickCode(timelineEntry.inviteCode)} sx={{ cursor: 'pointer' }}>{timelineEntry.inviteCode}</MuiLink></BoldTitle>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </Box>
                    {!hideSender
                        &&
                        <div style={{ width: '100%', position: 'absolute', bottom: 0, display: 'flex', justifyContent: 'center' }}>
                            <RichTextField
                                disabled={!selectedChat}
                                value={msgBody || ''}
                                onChange={(newValue: any) => {
                                    setMsgBody_local(newValue)
                                }}
                                fullWidth
                                onKeyDown={(e: any) => handleKeyClicks_local(e)}
                                placeholder={"Write something..."}
                                style={{
                                    width: '100%', background: '#f5f5f5', padding: '10px',
                                }}
                                handleShowEmoji={handleShowEmoji_local}
                                sendMessage={() => {
                                    sendMessage_local();
                                    setMsgBody_local('');
                                }}
                            />
                        </div>}
                </Paper>
            </CardPaper >
            <InviteDetailsModal open={openInviteDetails} setOpen={setOpenInviteDetails} inviteCode={selectedInviteCode} />
        </div>
    );
};

export default ChatMessages;