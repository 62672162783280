import * as React from 'react';
import Box from '@mui/material/Box';
import { PieChart } from '@mui/x-charts/PieChart';

const data1 = [
    { label: 'Group A', value: 400 },
    { label: 'Group B', value: 300 },
    { label: 'Group C', value: 300 },
    { label: 'Group D', value: 200 },
];
const data2 = [
    { label: '1', value: 100, color: 'red' },
    { label: '2', value: 300 },
    { label: '3', value: 100 },
    { label: '4', value: 80 },
    { label: '5', value: 40 },
];

type dataType = {
    label: string,
    value: number,
    color?: string
}

interface DonutChartProps {
    chartData: [dataType[], dataType[]];
    colors: any
}

const DonutDoubleChart = ({ chartData, colors }: DonutChartProps) => {
    const outerCircleStyles = {
        innerRadius: 60,
        outerRadius: 80,
        paddingAngle: 1,
        cornerRadius: 0,
        cx: 120,
        cy: 120,
        // color: '#7846ff'//, '#a584ff', '#d2c1ff']

    };
    const innerCircleStyles = {
        innerRadius: 10,
        outerRadius: 40,
        paddingAngle: 1,
        cornerRadius: 0,
        startAngle: 0,
        endAngle: 360,
        cx: 120,
        cy: 120,
    };

    return (
        <Box sx={{ width: '100%' }}>
            <PieChart
                // colors={colors}
                sx={{ color: 'ActiveBorder' }}
                height={255}
                series={[
                    {
                        color: '#7846ff',
                        data: chartData[0],
                        ...outerCircleStyles,
                    },
                    {
                        color: '#fdb462',
                        data: chartData[1],
                        ...innerCircleStyles,
                    },
                ]}
            // slotProps={{ legend: { hidden: true } }}
            />
        </Box>
    );
}

export default DonutDoubleChart
