import { FlexBetween } from '../../../../assets/styles/styledComponents'
import {DialogTitle} from '../../../../lib/MUI/mui.components'
import FeaturedIcon from '../../../common/FeaturedIcon'

type ModalHeaderProps = {
  title: string,
  onClear?: Function | any
}

const ModalHeader: React.FC<ModalHeaderProps> = ({title, onClear=null}) => {
  return (
    <FlexBetween padding="10px">
        <DialogTitle>{title}</DialogTitle>
        {typeof onClear === 'function' && <FeaturedIcon icon="Clear" onClick={onClear}/> }
    </FlexBetween>
  )
}

export default ModalHeader