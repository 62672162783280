import { Avatar, Box, Button, Chip, FormControlLabel, IconButton, List, ListItem, ListItemButton, ListItemText, Paper, Switch, TextareaAutosize, Typography } from "@mui/material"
import { useParams } from "react-router-dom";
import useFetcher from "../../../../hooks/useFetcher";
import { useEffect, useState } from "react";
import { BoldTitle, DivContainer, Text } from "../../../../assets/styles/styledComponents";
import { strings } from "../../../../helper/strings";
import ReactLoader from "../../../common/ReactLoader";
import Loader from "../../Loader";
import { dateTime } from "../../../../helper/datetime";
import useAuth from "../../../../features/authentication/hooks/useAuth";
import { Delete } from "@mui/icons-material";
import useToast from "../../../../hooks/useToast";
import NewModal from "../../NewModal";
import InviteHistory from "../../../../features/schedules/components/Modals/InviteModal/InviteHistory";
import LanguagesContainer from "../../../../features/schedules/components/LangaugesContainer";
import FeaturedIcon from "../../../common/FeaturedIcon";
import ContactEdit, { ContactFormData } from "../../../../pages/Caregivers/ContactEdit";

const Details = () => {
    const { module, id } = useParams();
    const fetcher = useFetcher();
    const { getUser } = useAuth();
    const user = getUser();
    const [info, setInfo] = useState<any>();
    const [openNote, setOpenNote] = useState(false);
    const [notes, setNotes] = useState<any[]>([])
    const [noteBody, setNoteBody] = useState('');
    const [deleteNote, setOpenDelete] = useState(false);
    const [selectedNote, setSelectedNote] = useState<any>(null);
    const [editModalOpen, set_editModalOpen] = useState<boolean>(false);
    const { showSuccess, showError } = useToast();
    const [forMatches, setForMatches] = useState<boolean>();

    // caregiver
    const [open_contactEdit, set_open_contactEdit] = useState<boolean>(false);
    const [contactData, setContactData] = useState<ContactFormData>();
    useEffect(() => {
        if (!info)
            return;

        const cgFormData = mapToContactFormData(info);
        setContactData(cgFormData);
        if (info?.isForMatches != undefined) {
            setForMatches(info.isForMatches)
        }
    }, [info])
    const mapToContactFormData = (data: any): ContactFormData => {
        const formatDate = (dateString: string): string => {
            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        return {
            rowId: data.caregiverId || data.patientId,
            hhxId: data.hhaCaregiverId || data.hhaPatientId,
            firstName: data.firstName || '',
            middleName: data.middleName || '',
            lastName: data.lastName || '',
            email: data.email || '',
            dateOfBirth: !!data.dateOfBirth ? formatDate(data.dateOfBirth) : '',
            phone: data.phone || '',
            gender: data.gender || '',
            addressLine1: data.detailedAddress?.addressLine1 || '',
            addressLine2: data.detailedAddress?.addressLine2 || '',
            city: data.detailedAddress?.city || '',
            state: data.detailedAddress?.state || '',
            zipCode: data.detailedAddress?.zipCode || '',
            status: data.status || '',
            languages: data.languages ? data.languages.split(',').map((lang: string) => lang.trim()) : [],
            disciplines: data.discipline ? data.discipline.split(',').map((disc: string) => disc.trim()) : [],
            ethnicity: data.ethnicity || '',
            coordinators: data.coordinators || [],
        };
    };

    useEffect(() => {
        reload()
    }, [])
    const reload = () => {
        fetcher.get(`${module as string}/${id}`).then((res: any) => {
            setInfo(res.data.data)
        })
    }

    const InfoField = ({ title, value }: { title: string, value: string }) => {
        return (
            <DivContainer>
                <Text fontWeight='200'>{title}</Text>
                <div style={{ marginTop: '5px' }}>
                    <Text fontWeight='600' fontSize='15px'>{value || '-'}</Text>
                </div>
            </DivContainer>
        )
    }

    const getNotes = () => {
        fetcher.get(`notes/${module}/${id}`).then(res => {
            setNotes(res.data.data);
        })
    }

    useEffect(() => {
        getNotes();
    }, [])

    type note = {
        Body: string,
        UserId: number,
        InviteId?: number | null,
        PatientId?: string | null,
        CaregiverId?: string | null,
    }

    const SaveNote = () => {
        let newNote: note = {
            Body: noteBody,
            UserId: user?.userId,
            InviteId: null
        }
        if (module == 'caregiver')
            newNote = { ...newNote, CaregiverId: id, PatientId: null }
        if (module == 'patient')
            newNote = { ...newNote, PatientId: id, CaregiverId: null }

        fetcher.post('notes/create', newNote).then(res => {
            handleClickNote()
            getNotes();
        })
    }

    const handleClickNote = () => {
        setOpenNote(!openNote)
        setNoteBody('')
    }

    const postForMatches = (caregiverId: number, value: boolean) => {
        setForMatches(value);
        fetcher.post(`caregiver/forMatches/${caregiverId}/${value}`, null)
    }

    const handleDeleteNote = () => [
        fetcher.get(`notes/delete/${selectedNote.id}`).then(res => {
            setOpenDelete(false)
            getNotes()
            showSuccess('Note Successfully Deleted.')
        })
    ]

    const handleClickDelete = (note: any) => {
        setSelectedNote(note)
        setOpenDelete(true)
    }

    const customScrollbarStyles = {
        '&::-webkit-scrollbar': {
            width: '2px',
            height: '7px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#e8e0ff',
            borderRadius: '6px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#7846ff',
        },
    };

    const getStatusColor = (status: string) => {
        if (status == 'Active')
            return 'green'
        if (status != 'Active')
            return 'red'
    }

    const getWithSuffix = (info: any | undefined): string => {
        const suffix = info?.source == 'Candidate' ? '[CANDIDATE]' : info?.source == 'Referral' ? '[REFERRAL]' : '';
        return `${info?.name}  ${suffix}`;
    }

    return (
        <Box>
            <Box display={'flex'} justifyContent={'center'} sx={{ width: '100%' }}>
                <Loader isLoading={fetcher.isLoading} />
                <Paper elevation={4} sx={{ width: '15%', padding: '20px', marginRight: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px', marginBottom: '20px' }}>
                        <Avatar sx={{ width: '72px', height: '72px', borderRadius: '5px', backgroundColor: '#7846ff' }} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <BoldTitle textAlign='center' fontSize='20px' fontWeight='700'>{getWithSuffix(info)}</BoldTitle>
                        {
                            info?.source?.toLowerCase() === 'candidate' || info?.source?.toLowerCase() === 'referral' ?
                                <FeaturedIcon
                                    onClick={() => { set_open_contactEdit(true) }}
                                    icon='Edit' fontSize='small'
                                /> : null
                        }
                    </div>
                    <BoldTitle textAlign='center' fontWeight='200'>{info?.admissionId || info?.caregiverCode}</BoldTitle>
                    <div style={{ textAlign: 'center', marginTop: '30px' }}>
                        {info && <Chip label={info?.status} sx={{ width: '100px', borderRadius: '3px', border: `1px solid ${getStatusColor(info?.status)}`, color: getStatusColor(info?.status), background: 'white', height: '30px' }} />}
                        {module == 'caregiver' && <FormControlLabel
                            sx={{ height: '25px' }}
                            labelPlacement="start"
                            label={<BoldTitle fontSize='12px'>For matching</BoldTitle>}
                            control={
                                <Switch
                                    onClick={(e: any) => postForMatches(info?.caregiverId, e.target.checked)}
                                    checked={forMatches}
                                />
                            }
                        />}
                    </div>
                </Paper>
                <div style={{ width: '85%' }}>
                    <Paper elevation={4} sx={{ display: 'flex', justifyContent: 'space-between', padding: '40px 30px' }}>
                        {/* <InfoField title='Name' value={getWithSuffix(info)} /> */}
                        {
                            module == 'caregiver' && 
                            <InfoField title='Hired' value={!info?.hiredOn ? '' : dateTime.getDateObject(info.hiredOn).toDateString()} />
                        }
                        {
                            module == 'caregiver' && 
                            <InfoField title='First Worked' value={!info?.firstWorkedOn ? '' : dateTime.getDateObject(info.firstWorkedOn).toDateString()} />
                        }
                        {
                            module == 'caregiver' && 
                            <InfoField title='Last Worked' value={!info?.lastWorkedOn ? '' : dateTime.getDateObject(info.lastWorkedOn).toDateString()} />
                        }
                        {
                            info?.source?.toLowerCase() === 'candidate' || info?.source?.toLowerCase() === 'referral' ?
                                <InfoField title='Created' value={!info?.createdOn ? '' : dateTime.getDateObject(info.createdOn).toDateString()} /> : null
                        }
                        {
                            module == 'patient' &&
                            <InfoField title={`HHA ${strings.toTitleCase(module || '')} Id`} value={info?.hhaPatientId || info?.hhaCaregiverId} />
                        }
                        {module == 'patient' && <InfoField title='Admission Id' value={info?.admissionId} />}
                        {module == 'caregiver' && <InfoField title='Caregiver Code' value={info?.caregiverCode} />}
                        {/* {module == 'caregiver' && <InfoField title='Caregiver Id' value={info?.caregiverId} />} */}
                        {module == 'patient' && <InfoField title='Patient Id' value={info?.patientId} />}
                        <InfoField title='Office' value={info?.officeName} />
                        {module == 'patient' && <InfoField title='Coordinator' value={info?.coordinatorName} />}
                        {module === 'caregiver' && <InfoField title='Discipline' value={info?.discipline} />}
                        {module == 'caregiver' ? !!info?.userId ?
                            <InfoField title='Can log in' value={"Yes"} /> :
                            user?.isAdminOrSuperAdmin ?
                                <Button
                                    onClick={() => {
                                        fetcher.get('caregiver/newuser/' + id).then(() => {
                                            reload();
                                        })
                                    }}
                                >
                                    Create User
                                </Button> : null : null}
                    </Paper>
                    <Paper elevation={4} sx={{ display: 'flex', justifyContent: 'space-between', padding: '40px 30px', marginTop: '10px' }}>
                        <InfoField title='Address' value={info?.address || '-'} />
                        <InfoField title={`Phone`} value={info?.phone || '-'} />
                        <InfoField title='DOB' value={info?.dateOfBirth || '-'} />
                        <InfoField title='Languages' value={info?.languages} />
                        <InfoField title='Gender' value={info?.gender} />
                        {module === 'caregiver' && <InfoField title='Ethnicity' value={info?.ethnicity} />}
                    </Paper>
                </div>
            </Box>
            <Box display={'flex'} justifyContent={'start'} marginTop={'20px'}>
                <div style={{ width: '465px' }}>
                    <Paper elevation={4} sx={{ height: '230px', display: 'flex', justifyContent: 'space-between', padding: '10px 10px' }}>
                        <div style={{ width: '100%', }}>
                            <List disablePadding sx={{ overflowY: 'scroll', maxWidth: '1600px', maxHeight: '230px', ...customScrollbarStyles }}>
                                <ListItemButton
                                    onClick={() => handleClickNote()}
                                    sx={{
                                        borderRadius: '5px',
                                        minWidth: '150px',
                                        margin: '5px',
                                        border: '1px solid #e8e0ff',
                                        background: openNote ? '#e8e0ff' : '#fff',
                                        '&:Hover': {
                                            background: '#e8e0ff',
                                        }
                                    }}>
                                    <ListItemText
                                        secondary={"Add Note"} secondaryTypographyProps={{ textAlign: 'center' }} />
                                </ListItemButton>
                                {openNote &&
                                    <ListItemButton
                                        disableRipple
                                        disableTouchRipple
                                        sx={{
                                            minWidth: '150px',
                                            margin: '5px',
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #e8e0ff',
                                            '&:Hover': {
                                                background: '#fff',
                                            }
                                        }}>
                                        <div>
                                            <TextareaAutosize
                                                onChange={(e) => setNoteBody(e.target.value)}
                                                value={noteBody}
                                                maxRows={5}
                                                minRows={3}
                                                style={{
                                                    border: '1px solid #e8e0ff',
                                                    maxWidth: '400px',
                                                    minWidth: '400px',
                                                    borderRadius: '5px',
                                                    fontFamily: 'ConnectAideSecondary',
                                                    padding: '5px'
                                                }}
                                            />
                                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                <Button size="small" disabled={noteBody.trim().length > 0 ? false : true} variant='outlined' onClick={() => SaveNote()} >Save</Button>
                                            </div>
                                        </div>
                                    </ListItemButton>}
                                {notes?.map((note: any) =>
                                    <ListItemButton
                                        disableRipple
                                        disableTouchRipple
                                        sx={{
                                            minWidth: '150px',
                                            margin: '5px',
                                            borderRadius: '5px',
                                            border: '1px solid #e8e0ff',
                                            '&:Hover': {
                                                background: '#fff',
                                            }
                                        }}
                                    >
                                        <ListItemText
                                            primary={note.body}
                                            secondary={`${note.userName} - ${dateTime.getDisplayValue(note.createdOn)} ${note.inviteId != null ? '- Noted On Invite' : ''}`}
                                            secondaryTypographyProps={{ fontSize: '12px' }}
                                        />
                                        <IconButton onClick={() => handleClickDelete(note)}>
                                            <Delete fontSize='small' color='primary' />
                                        </IconButton>
                                    </ListItemButton>)}
                                {notes?.length < 1 && <ListItemText primary="No Notes" primaryTypographyProps={{ marginTop: '25px', textAlign: 'center' }} />}
                            </List>

                        </div>
                    </Paper>
                </div>
                <div style={{ marginLeft: '10px', width: '550px' }}>
                    <Paper elevation={4}>
                        <InviteHistory
                            height='250px'
                            title='Invite History'
                            headerHeight={35}
                            caregiverId={module == 'caregiver' ? id : undefined}
                            patientId={module == 'patient' ? id : undefined}
                        />
                    </Paper>
                </div>
                <div style={{ marginLeft: '10px', width: '550px' }}>
                    <Paper elevation={4}>
                        <LanguagesContainer module={module} id={id} />
                    </Paper>
                </div>
            </Box>
            <ContactEdit
                open={!!module && open_contactEdit}
                onSave={() => { set_open_contactEdit(false); reload(); }}
                onClose={() => { set_open_contactEdit(false) }}
                formData={contactData}
                module={module || ''}
            />
            <NewModal title='Delete Note' btnTitle='Delete' height='200px' open={deleteNote} onClose={() => setOpenDelete(false)} onSave={() => handleDeleteNote()}>
                <Box>
                    <Typography>Are you sure you want to delete this note?</Typography>
                </Box>
            </NewModal>
        </Box>
    )
}

export default Details 