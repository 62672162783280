import { useState } from "react";
import { dateSortComparator } from "../../../data/GridColumnDefinitions";
import { dateTime } from "../../../helper/datetime";
import { Box, Button, Tooltip } from "@mui/material";
import { BoldTitle, Text } from "../../../assets/styles/styledComponents";


const getStatusColor = (status: string) => {
  switch (status) {
    case 'Pending':
    case 'Open':
      return 'primary';
    case 'Accepted':
      return 'green';
    case 'Declined':
      return 'orange';
    case 'Assigned':
    case 'Serviced':
      return 'darkgreen';
    case 'Taken':
      return 'lightblue';
    case 'Missed':
    case 'Deleted':
      return 'red';
    default:
      return 'info';
  }
};

const getGroupedRowStatus = (statuses: string[]) => {
  const overallStatus = statuses?.every((s: any) => s == 'Accepted') ? "Accepted" :
    statuses?.every((s: any) => s == 'Declined') ? "Declined" :
      statuses?.some((s: any) => s == 'Accepted') ? "Partially Accepted" :
        statuses?.some((s: any) => s == 'Declined') ? "Partially Declined" :
          statuses?.some((s: any) => s == 'Pending') ? "Pending" :
            "";

  return overallStatus;
}
const getGroupedVisitStatus = (statuses: string[]) => {
  const overallStatus = statuses?.every((s: any) => s == 'Open') ? "Open" :
    statuses?.some((s: any) => s == 'Open') ? "Partially Open" :
      statuses?.some((s: any) => s == 'Assigned') ? "Assigned" :
        statuses?.some((s: any) => s == 'Taken') ? "Taken" :
          statuses?.some((s: any) => s == 'Missed') ? "Missed" : "";

  return overallStatus;
}
const StatusButton = (status: string) => {
  const getStatusDescription = (status: string): string => {
    switch (status) {
      case 'Pending':
        return 'No status change since this invite was sent';
      case 'Accepted':
        return 'The aide accepted one or more of the shifts in this case';
      case 'Declined':
        return 'The aide declined this case';
      default:
        break;
    }

    return "";
  }
  const getStatusLabel = (status: string): string => {
    switch (status) {
      case 'Pending':
        return 'Pending';
      case 'Accepted':
        return 'Accepted';
      case 'Declined':
        return 'Declined';
      case 'Assigned':
        return 'Assigned';
      case 'Taken':
        return 'Taken';
      case 'Open':
        return 'Open';
      case 'Partially Open':
        return 'Partially Open';
      case 'Missed':
        return 'Missed';
      default:
        return status;
        break;
    }

    return "";
  }

  return (
    <Tooltip title={getStatusDescription(status)}>
      <span> {/* Wrapping span */}
        <Button
          sx={{ height: '20px', minWidth: '80px', padding: '2px', fontSize: '0.7rem', color: getStatusColor(status), borderColor: getStatusColor(status) }}
          size='small'
          variant='outlined'
          style={{
            pointerEvents: 'none',
          }}
        >
          {getStatusLabel(status)}
        </Button>
      </span>
    </Tooltip>
  );
};

const useInviteUI = () => {

  const GroupInvitesByInviteCode = (invites: any) => {
    const invitesByInviteCode: Record<string, any> = {};

    invites.forEach((invite: any) => {
      const {
        inviteCode, patientId, patientName, visitStatus, clicks, caregiverId, caregiverName, sentBy,
        sentOn, dateSent, body, coordinatorNotSeen, hhaVisitId, id, inviteId, key, notes, type,
        visitDate, visitDateTime, visitId, chatId, status, coordinatorContact, declineReasons, isActive
      } = invite;


      if (!invitesByInviteCode[inviteCode]) {
        invitesByInviteCode[inviteCode] = {
          inviteCode, patientId, patientName, clicks, caregiverId, caregiverName, sentBy,
          sentOn, dateSent, body, coordinatorNotSeen, hhaVisitId, id, inviteId, key, notes, type,
          visitDate, visitDateTime, visitId, chatId, declineReasons, visitStatus: [] as string[], status: [] as string[], invites: [], inviteCount: 0,
          coordinatorContact, isActive
        };
      }

      if (!invitesByInviteCode[inviteCode].status.includes(status)) {
        invitesByInviteCode[inviteCode].status.push(status);
      }
      if (!invitesByInviteCode[inviteCode].visitStatus.includes(visitStatus)) {
        invitesByInviteCode[inviteCode].visitStatus.push(visitStatus);
      }
      invitesByInviteCode[inviteCode].inviteCount++;
      invitesByInviteCode[inviteCode].invites.push(invite);
    });

    return Object.values(invitesByInviteCode);
  }
  const columns = (columnNames: string[] = []) => {
    const allColumns = [
      { field: 'inviteCode', headerName: 'Code', flex: 0.05 },
      {
        field: 'caregiverName',
        headerName: 'Caregiver',
        navUrl: '/caregiver/{caregiverId}/details',
      },
      {
        field: 'patientName',
        headerName: 'Patient',
        navUrl: '/patient/{patientId}/details',
      },
      {
        field: 'dateSent',
        headerName: 'Sent',
        flex: 0.08,
        sortComparator: dateSortComparator,
        renderCell: (params: any) => (
          <Text>{dateTime.getShortDisplayDate(params.row.dateSent)}</Text>
        ),
      },
      {
        field: 'visitDate',
        headerName: 'Visit Date',
        flex: 0.08,
        sortComparator: dateSortComparator,
        renderCell: (params: any) => (
          <Text>{dateTime.getShortDisplayDate(params.row.visitDate)}</Text>
        ),
      },
      {
        field: 'status',
        headerName: 'Response',
        flex: 0.1,
        renderCell: (params: any) => {
          const statusName = Array.isArray(params.row.status) ? getGroupedRowStatus(params.row.status) : params.row.status;
          return (
            <Box>
              {StatusButton(statusName)}
            </Box>
          );
        },
      },
      {
        field: 'visitStatus',
        headerName: 'Visit Status',
        flex: 0.08,
        renderCell: (params: any) => {
          const statusName = Array.isArray(params.row.visitStatus) ? getGroupedVisitStatus(params.row.visitStatus) : params.row.visitStatus;
          return (
            <Box>
              {StatusButton(statusName)}
            </Box>
          );
        },
      },
      { field: 'sentBy', headerName: 'Sent By' },
      { field: 'clicks', headerName: 'Views', flex: 0.04 },
    ];
    const filteredColumns = columnNames.length > 0
      ? allColumns.filter(column => columnNames.includes(column.field))
      : allColumns;
  
    return filteredColumns.map(column => ({
      ...column,
      headerName: column.headerName || column.field,
    }));
  };

  return {
    getStatusColor,
    columns, GroupInvitesByInviteCode,
    StatusButton,
    getGroupedRowStatus,
    getGroupedVisitStatus
  };
};


export default useInviteUI