import React from 'react';
import { TextField, MenuItem, TextFieldProps } from '@mui/material';

type MenuOption = {
  key: string;
  value: string;
};

const states: MenuOption[] = [
  { key: 'al', value: 'Alabama' },
  { key: 'ak', value: 'Alaska' },
  { key: 'az', value: 'Arizona' },
  { key: 'ar', value: 'Arkansas' },
  { key: 'ca', value: 'California' },
  { key: 'co', value: 'Colorado' },
  { key: 'ct', value: 'Connecticut' },
  { key: 'de', value: 'Delaware' },
  { key: 'fl', value: 'Florida' },
  { key: 'ga', value: 'Georgia' },
  { key: 'hi', value: 'Hawaii' },
  { key: 'id', value: 'Idaho' },
  { key: 'il', value: 'Illinois' },
  { key: 'in', value: 'Indiana' },
  { key: 'ia', value: 'Iowa' },
  { key: 'ks', value: 'Kansas' },
  { key: 'ky', value: 'Kentucky' },
  { key: 'la', value: 'Louisiana' },
  { key: 'me', value: 'Maine' },
  { key: 'md', value: 'Maryland' },
  { key: 'ma', value: 'Massachusetts' },
  { key: 'mi', value: 'Michigan' },
  { key: 'mn', value: 'Minnesota' },
  { key: 'ms', value: 'Mississippi' },
  { key: 'mo', value: 'Missouri' },
  { key: 'mt', value: 'Montana' },
  { key: 'ne', value: 'Nebraska' },
  { key: 'nv', value: 'Nevada' },
  { key: 'nh', value: 'New Hampshire' },
  { key: 'nj', value: 'New Jersey' },
  { key: 'nm', value: 'New Mexico' },
  { key: 'ny', value: 'New York' },
  { key: 'nc', value: 'North Carolina' },
  { key: 'nd', value: 'North Dakota' },
  { key: 'oh', value: 'Ohio' },
  { key: 'ok', value: 'Oklahoma' },
  { key: 'or', value: 'Oregon' },
  { key: 'pa', value: 'Pennsylvania' },
  { key: 'ri', value: 'Rhode Island' },
  { key: 'sc', value: 'South Carolina' },
  { key: 'sd', value: 'South Dakota' },
  { key: 'tn', value: 'Tennessee' },
  { key: 'tx', value: 'Texas' },
  { key: 'ut', value: 'Utah' },
  { key: 'vt', value: 'Vermont' },
  { key: 'va', value: 'Virginia' },
  { key: 'wa', value: 'Washington' },
  { key: 'wv', value: 'West Virginia' },
  { key: 'wi', value: 'Wisconsin' },
  { key: 'wy', value: 'Wyoming' },
];

const UsStateField: React.FC<TextFieldProps> = (props) => {
  return (
    <TextField
      label="State"
      fullWidth
      variant="outlined"
      select
      InputLabelProps={{
          shrink: true,
      }}
      {...props}
      value={(props?.value as string).toLowerCase() ?? ''}
    >
      {states.map((state) => (
        <MenuItem key={state.value} value={state.key.toLowerCase()}>
          {state.value}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default UsStateField;