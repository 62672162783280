import { Autocomplete, Box, Button as MuiButton, Fab, Grid, IconButton, Modal, Paper, TextField, Skeleton, FormLabel, Tooltip, Menu, MenuItem, Popover, Chip, TextareaAutosize } from "@mui/material"
import { useEffect, useState } from "react";
import useFetcher from "../../hooks/useFetcher";
import useAuth from "../../features/authentication/hooks/useAuth";
import AddIcon from '@mui/icons-material/Add';
import { Bold, BoldTitle, FlexBetween } from "../../assets/styles/styledComponents";
import { Button } from "../../components/ui";
import { Close } from "@mui/icons-material";
import { dateTime } from "../../helper/datetime";
import ReactLoader from "../../components/common/ReactLoader";
import DataGrid from "../../components/ui/DataGrid";
import SearchInput from "../../components/common/SearchInput/SearchInput";
import EmojiPicker, { EmojiStyle } from "emoji-picker-react";
import { useSignalR } from "../../hooks/useSignalREvents";
import ChatsList from "../../components/ui/Chat/ChatsList";
import ChatMessages from "./ChatMessages";
import { CustomScrollbarStyles, MessageDto } from "./types";
import { useParams } from "react-router-dom";
import NewModal from "../../components/ui/NewModal";
import useToast from "../../hooks/useToast";

interface ChatProps {
    size?: 'small' | 'normal';
}

const Chat: React.FC<ChatProps> = ({ size = 'normal' }) => {
    const { selectedChatId } = useParams();
    const [msgBody, setMsgBody] = useState('');
    const { get, post, isLoading } = useFetcher();
    const chatFetcher = useFetcher();
    const readFetcher = useFetcher();
    const inviteFetcher = useFetcher();
    const messagesFetcher = useFetcher();
    const [messages, setMessages] = useState<MessageDto[]>([]);
    const [chats, setChats] = useState<any>([]);
    const [contacts, setContacts] = useState<any>([]);
    const [selectedChat, setSelectedChat] = useState<any>();
    const [selectedContact, setSelectedContact] = useState<any>();
    const [invites, setInvites] = useState<any[]>([])
    const [invitesLoaded, setInvitesLoaded] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [filteredRows, setFilteredRows] = useState<any[]>([]);
    const [open, setOpen] = useState(false);
    const [openMore, setOpenMore] = useState(false);
    const [openInvites, setOpenInvites] = useState(false);
    const [anchorEl, setAnchorEl] = useState();
    const [emojiAnchorEl, setEmojiAnchorEl] = useState();
    const [showEmojis, setShowEmojis] = useState(false)
    const { getUser } = useAuth();
    const [isIncomingFilterActive, setIsIncomingFilterActive] = useState(false);
    const [memberFilterActive, setMemberFilterActive] = useState(false);
    const user = getUser();
    const [inviteTimeline, setInviteTimeline] = useState<any[]>([]);
    const [openNotes, setOpenNotes] = useState(false);
    const [noteMessage, setNoteMessage] = useState('');
    const { showSuccess } = useToast();
    const [filteredContacts, set_filteredContacts] = useState<any>([]);

    const getInviteTimeline = () => {
        inviteFetcher.get(`InviteTimeline/chat/${selectedChat?.id}`).then((res: any) => {
            setInviteTimeline(res.data.data)
        })
    }

    useEffect(() => {
        if (!selectedChat)
            return;
        getInviteTimeline()
    }, [selectedChat, messages])

    const messageStyle = {
        maxWidth: '600px',
        borderRadius: '10px',
        padding: '10px'
    }

    const customScrollbarStyles: CustomScrollbarStyles = {
        '&::-webkit-scrollbar': {
            width: '7px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'lightgrey',
            borderRadius: '6px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#7846ff',
        },
    };

    const handleSearch = (query: string) => {
        setSearchTerm(query)
    }

    useEffect(() => {
        const results = chats?.filter((item: any) => {
            const values = Object.values(item);
            return values.some((value) => String(value).toLowerCase().includes(searchTerm.toLowerCase()));
        });

        let filteredRowsForDisplay = isIncomingFilterActive
            ? results.filter((row: any) => row.lastMessage.senderPosition == 'aide')
            : results;

        filteredRowsForDisplay = memberFilterActive
            ? filteredRowsForDisplay.filter((row: any) => row.isMember == true)
            : filteredRowsForDisplay

        setFilteredRows(filteredRowsForDisplay)
    }, [searchTerm, chats, isIncomingFilterActive, memberFilterActive])
    useEffect(() => {
        set_filteredContacts(contacts.filter((contact: any) => chats.some((c: any) => c.chatName != contact.name)))
    }, [chats, contacts]);

    // SIGNAL R
    const newMessageHandler = (hubMessage: any, hubUserId: any, hubChatId: any, senderPosition: any) => {
        if (selectedChat.id == hubChatId) {
            const newMessage = {
                message: hubMessage,
                chatId: hubChatId,
                userId: hubUserId,
                senderPosition: senderPosition,
                isRead: false
            };
            setMessages(prevMessages => [...prevMessages, newMessage]);
        }
        var newArray = chats.map((row: any) => {
            if (row.id === hubChatId) {
                const lastMessage = {
                    chatId: hubChatId,
                    isRead: false,
                    message: hubMessage,
                    senderPosition: senderPosition,
                    sent: dateTime.getCurrentFormattedDateTime(), // gets the current time
                    userId: hubUserId
                };
                row.lastMessage = lastMessage;
            }

            return row;
        });
        setChats(newArray);
    };

    const eventConfigs = [
        {
            eventName: "NewMessage",
            action: newMessageHandler
        },
    ];
    const signalR = useSignalR(eventConfigs, chats);

    useEffect(() => {
        getChats()
    }, [messages])

    useEffect(() => {
        if (!filteredRows || filteredRows.length < 1) {
            clearMessages();
            return;
        }

        if (!selectedChat || !selectedChat.id || !filteredRows.some((r: any) => r.id === selectedChat.id)) {
            let firstChat: any
            if (!!selectedChatId)
                firstChat = chats.find((c: any) => c.id == selectedChatId);
            else
                firstChat = filteredRows[0];

            getMessages(firstChat)
        }
    }, [filteredRows, selectedChat, selectedChatId])

    const sendMessage = () => {
        const message: MessageDto = {
            chatId: selectedChat.id,
            message: msgBody,
            userId: user?.userId,
            isRead: false
        }

        setMsgBody('')

        post(`Chat/send/${selectedChat?.id}`, { body: message.message }).then((resposne) => {
            const newArray = [...messages, resposne.data];
            setMessages(newArray);
        })
    }

    const getChats = () => {
        if (!user?.userId)
            return;

        chatFetcher.get(`Chat`).then(response => {
            setChats(response.data)
        })
    }

    const getInvites = () => {
        inviteFetcher.get(`Invite/${selectedChat.id}`).then(res => {
            setInvites(res.data.data)
            setInvitesLoaded(true)
        })
    }

    useEffect(() => {
        if (!!openInvites && !!selectedChat?.id)
            getInvites();
    }, [openInvites, selectedChat?.id])

    const getMessages = (chat: any) => {
        setMessages([])
        setMsgBody('')
        setSelectedChat(chat)

        messagesFetcher.get(`Chat/messages/${chat.id}?markAsRead=false`).then(response => {
            setMessages(response.data)
            console.log(response.data);
        })
    }
    const clearMessages = () => {
        setMessages([])
        setMsgBody('')
        setSelectedChat(null)
    }

    const handleNewChat = () => {
        setOpen(true)
        get(`Chat/contacts`).then(response => {
            setContacts(response.data)
        })
    }

    const handleChangeContact = (option: any) => {
        let c = filteredContacts.find((contact: any) => contact.name == option)
        setSelectedContact(c)
    }

    const handleStartChat = () => {
        get(`Chat/create/${selectedContact.caregiverId}?patientId=0`).then((res: any) => {
            setOpen(false)
            setSelectedContact('')
            getChats()
            setSelectedChat(res.data)
        })
    }

    const handleClose = () => {
        setOpen(false)
        setSelectedContact('')
    }

    const [markingRead, setMarkingRead] = useState<any[]>([]);
    const markAsRead = (chatId: any) => {
        // add to the markingRead array
        setMarkingRead([...markingRead, chatId]);
        readFetcher.get(`Chat/mark/${chatId}/true`).then(res => {
            // remove from the markingRead array
            const newArray = markingRead.filter((item: any) => item !== chatId);
            setMarkingRead(newArray);

            // reload chats and message
            getChats()
            getMessages(selectedChat)
        })
    }

    const ChatSkeleton = (isLoading: any) => {
        const repeat = 4;
        return (
            <Box style={{ margin: '4px, 8px', display: isLoading.isLoading && chats.length < 1 ? "block" : "none", }}>
                {[...Array(repeat)].map(() => (<div style={{ margin: '20px 5px 20px 15px', display: 'flex' }}>
                    <Skeleton width='48px' height='48px' variant="circular" animation='wave' />
                    <div>
                        <Skeleton sx={{ marginLeft: '10px' }} width='125px' height='25px' variant="text" animation='wave' />
                        <Skeleton sx={{ marginLeft: '10px' }} width='100px' height='25px' variant="text" animation='wave' />
                    </div>
                </div>))}
            </Box>
        )
    }

    const getStatusColor = (status: string) => {
        switch (status) {
            case 'Pending':
                return 'primary';
            case 'Accepted':
                return 'green';
            case 'Declined':
                return 'orange';
            default:
                return 'info';
        }
    }

    const inviteCols = () => {
        return [
            { field: 'inviteCode', headerName: 'Invite Code' },
            { field: 'patientName', headerName: 'Patient', navUrl: "patient/{patientId}/details" },
            { field: 'caregiverName', headerName: 'Sent to', navUrl: "caregiver/{caregiverId}/details" },
            { field: 'sentOn', headerName: 'Sent On', renderCell: (params: any) => (dateTime.getFormattedDateTime(params.row.sentOn)) },
            {
                field: 'status', headerName: 'Response', renderCell: (params: any) => {
                    return (
                        <MuiButton
                            sx={{ width: '95px', fontSize: '0.7rem', color: getStatusColor(params.row.status), borderColor: getStatusColor(params.row.status) }}
                            size='small'
                            //color={getStatusBtnColor(params.row.status)}
                            variant='outlined'
                            style={{
                                pointerEvents: 'none',
                                //textTransform: 'none',
                            }}
                        >
                            {params.row.status}
                        </MuiButton>
                    )
                }
            },

        ]
    }

    const formatPhoneNumber = (phoneNumber: string): string => {
        let numericPhoneNumber = phoneNumber.replace(/\D/g, '');
        if (numericPhoneNumber.length === 11 && numericPhoneNumber[0] === '1') {
            numericPhoneNumber = numericPhoneNumber.slice(1);
        }
        const formattedPhoneNumber = `(${numericPhoneNumber.slice(0, 3)})-${numericPhoneNumber.slice(3, 6)}-${numericPhoneNumber.slice(6)}`;
        return formattedPhoneNumber;
    };

    const handleOpenInvites = (e: any) => {
        setOpenMore(true)
        setAnchorEl(e.currentTarget)
    }

    const handleShowEmoji = (e: any) => {
        setEmojiAnchorEl(e.currentTarget)
        setShowEmojis(!showEmojis)
    }

    const handleEmojiClick = (selectedEmoji: any) => {
        setMsgBody((prevMsg) => prevMsg + selectedEmoji.emoji)
    }

    const handleKeyClicks = (e: any) => {
        if (!msgBody || msgBody.length < 1 || !selectedChat)
            return;

        if (e.key == 'Enter') {
            if (e.shiftKey || e.ctrlKey || e.altKey) {
                setMsgBody((prevMsg) => prevMsg);
            } else {
                sendMessage()
            }
        }
    }
    const toggleIncomingFilter = () => {
        setIsIncomingFilterActive(!isIncomingFilterActive);
    };
    const toggleMemberFilter = () => {
        setMemberFilterActive(!memberFilterActive);
    };

    const handleLeaveChat = () => {
        if (!user?.userId)
            return;
        get(`Chat/leave/${user?.userId}/${selectedChat.id}`).then(() => {
            setOpenMore(false)
            getChats()
            getMessages(selectedChat)
            showSuccess('You have left this chat.')
        })
    }

    const saveNote = () => {
        inviteFetcher.post(`notes/from_chat`, { ChatId: selectedChat.id, body: noteMessage }).then(() => {
            showSuccess("Note created.")
            setOpenNotes(false)
        })
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '30px 0px 0px 0px' }}>
            <ReactLoader isLoading={isLoading} />
            <Box height={'100vh'} marginTop={'-30px'} marginBottom={'-14px'} display={'flex'} justifyContent={'space-between'} position={'relative'}>
                <Grid container sx={{ width: '1500px' }}>
                    <Grid item xs={2.5}>
                        {/* CHAT LIST */}
                        <Paper elevation={0} >
                            {chats.length < 1 && !chatFetcher.isLoading &&
                                <Box textAlign='center'>
                                    <BoldTitle margin='300px 0px 20px 0px' fontSize='20px'>No Chat History</BoldTitle>
                                    <MuiButton sx={{ borderRadius: '10px' }} variant='outlined' onClick={() => handleNewChat()}>Start Now</MuiButton>
                                </Box>
                            }
                            <Box>
                                {chats.length > 0 && <>
                                    <div style={{ height: '5vh', margin: 8, display: 'flex' }}>
                                        <SearchInput width='90%' getSearchQuery={handleSearch} />
                                        <Fab
                                            onClick={() => handleNewChat()}
                                            sx={{
                                                boxShadow: 'none',
                                                marginLeft: '-10px',
                                                width: '40px',
                                                color: '#7846ff',
                                                backgroundColor: '#e8e0ff',
                                                borderRadius: '5px',
                                                zIndex: 100,
                                            }}
                                            size="small"
                                        >
                                            <AddIcon />
                                        </Fab>
                                    </div>
                                    <Tooltip title="Show only chats where the last message was received from aides">
                                        <Chip
                                            label="Incoming"
                                            onClick={toggleIncomingFilter}
                                            color={isIncomingFilterActive ? "primary" : "default"}
                                            variant={isIncomingFilterActive ? "filled" : "outlined"}
                                            size="small" // Makes the chip smaller
                                        />
                                    </Tooltip>
                                    {user?.roles.includes('Admin' || 'Supervisor') && <Tooltip title="Show only chats where I am a member">
                                        <Chip
                                            label="As Member"
                                            onClick={toggleMemberFilter}
                                            color={memberFilterActive ? "primary" : "default"}
                                            variant={memberFilterActive ? "filled" : "outlined"}
                                            size="small" // Makes the chip smaller
                                            sx={{ marginLeft: '5px' }}
                                        />
                                    </Tooltip>}
                                </>}
                                <ChatSkeleton isLoading={chatFetcher.isLoading} />
                                <ChatsList
                                    title=""
                                    filteredRows={filteredRows}
                                    selectedChat={selectedChat}
                                    getMessages={getMessages}
                                    customScrollbarStyles={customScrollbarStyles}
                                    markingRead={markingRead} // Your state for tracking which chats are being marked as read
                                    markAsRead={markAsRead} // Function to mark a chat as read
                                />
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item sm={4} md={5} lg={7} xl={9}>
                        {/* MESSAGES BOX */}
                        <ChatMessages
                            setOpenNotes={setOpenNotes}
                            setNoteMessage={setNoteMessage}
                            timeline={inviteTimeline}
                            selectedChat={selectedChat}
                            messages={messages}
                            user={user}
                            msgBody={msgBody}
                            setMsgBody={setMsgBody}
                            sendMessage={sendMessage}
                            handleOpenInvites={handleOpenInvites}
                            handleShowEmoji={handleShowEmoji}
                            markAsRead={markAsRead}
                            readFetcher={readFetcher}
                            messagesFetcher={messagesFetcher}
                            dateTime={dateTime}
                            handleKeyClicks={handleKeyClicks}
                            showHeader={true}
                            size={size}
                        ></ChatMessages>
                    </Grid>
                </Grid>
            </Box>
            <Menu onClose={() => setOpenMore(false)} open={openMore} anchorEl={anchorEl}>
                <MenuItem onClick={() => setOpenInvites(true)}>View Invites</MenuItem>
                {selectedChat?.isMember && <MenuItem onClick={() => handleLeaveChat()}>Leave Chat</MenuItem>}
            </Menu>
            <Modal open={openInvites} onClose={() => { setOpenInvites(false); setInvites([]) }} sx={{ top: '25%', left: '35%' }}>
                <Box width={'50%'}>
                    <DataGrid
                        title="Sent Invites"
                        data={{
                            columns: inviteCols(),
                            rows: invites
                        }}
                        loading={inviteFetcher.isLoading}
                        options={{
                            height: '400px',
                            hideToolbarIconText: true,
                            hideExport: true,
                            hideFiltering: true,
                            hideColumns: true,
                            noRowsOverlayText: invites.length < 1 && invitesLoaded ? 'You did not send any invites the past week' : 'No rows'
                        }}
                    />
                </Box>
            </Modal>
            <Modal onClose={() => handleClose()} open={open} sx={{ top: '25%', left: '38%', zIndex: 999 }}>
                <Box sx={{ height: '210px', width: '500px', background: '#fff', borderRadius: '5px' }}>
                    <FlexBetween borderRadius='5px 5px 0 0' padding='10px' bgColor='#e8e0ff'>
                        <BoldTitle fontSize='20px'>Start Chat</BoldTitle>
                        <IconButton onClick={() => handleClose()}>
                            <Close fontSize="small" />
                        </IconButton>
                    </FlexBetween>
                    <Box padding={'10px'} display={'block'}>
                        <FormLabel><Bold>Search Contact</Bold></FormLabel>
                        <Autocomplete
                            options={filteredContacts.map((contact: any) => contact.name)}
                            filterOptions={(options, state) => {
                                const displayOptions = options.filter((option) => {
                                    const inputValue = state.inputValue.toLowerCase().trim();
                                    const optionValue = option.toLowerCase().trim();
                                    const isMatch = !state.inputValue || optionValue.includes(inputValue);

                                    console.log('option', optionValue);
                                    console.log('input', inputValue);
                                    console.log('match', isMatch);

                                    return isMatch;
                                });

                                console.log('displayOptions', displayOptions);

                                return displayOptions;
                            }}
                            value={selectedContact?.name}
                            renderInput={(params) => {
                                console.log(params);

                                return <TextField {...params} value={selectedContact?.name} />
                            }}
                            onChange={(e, o) => handleChangeContact(o)}
                            size="small"
                        />
                        <Button
                            variant='outlined'
                            style={{ float: 'right', marginTop: '30px' }}
                            onClick={() => handleStartChat()}
                            label="Start Chat"
                        />
                    </Box>
                </Box>
            </Modal>
            <Popover
                onClose={() => setShowEmojis(false)}
                open={showEmojis}
                anchorEl={emojiAnchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                <EmojiPicker
                    width={300}
                    height={400}
                    onEmojiClick={handleEmojiClick}
                    emojiStyle={EmojiStyle.NATIVE}
                />
            </Popover>
            <NewModal isLoading={inviteFetcher.isLoading} open={openNotes} onClose={() => setOpenNotes(false)} onSave={() => saveNote()} title="Add Note">
                <Box display={'flex'} justifyContent={'center'}>
                    <TextareaAutosize style={{ borderRadius: '5px', fontFamily: 'ConnectAideSecondary', minWidth: '90%', maxWidth: '90%', minHeight: '175px', maxHeight: '175px' }} value={noteMessage} onChange={(e) => setNoteMessage(e.target.value)} maxRows={5} />
                </Box>
            </NewModal>
        </div >
    )
}

export default Chat
