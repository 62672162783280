import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Drawer, Divider } from '../../../lib/MUI/mui.components';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { DivContainer } from '../../form/style'
import { ListItemButton, ListItemText, List, Collapse, IconButton, Button, Icon, Tooltip, Typography, Badge, Avatar, Popover, MenuItem, Box } from '@mui/material';
import listOfMenuItems from '../../../routes';
import { Bold, BoldTitle, FlexBetween, Title } from '../../../assets/styles/styledComponents';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import KeyboardDoubleArrowRightOutlined from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import useFetcher from '../../../hooks/useFetcher';
import { LogoutOutlined, OutputOutlined } from '@mui/icons-material';
import useAuth from '../../../features/authentication/hooks/useAuth';
import '../../../Fonts.css'
import { useSignalR } from '../../../hooks/useSignalREvents';

const drawerWidth = 240;
const ManuDrawer = (props: any) => {
    const { open, handleOpenNavMenu, handleClickMenu, logout, user } = props;
    const location = useLocation();
    const subMenuURL = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    const navigate = useNavigate();
    const [openSubMenu, setOpenSubMenu] = useState('');
    const theme = useTheme();
    const [notifications, setNotifications] = useState([]);
    const fetcher = useFetcher();
    const [openNotif, setOpenNotif] = useState(false);
    const [notifAnchorEl, setNotifAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [unreadMessages, setUnreadMessages] = useState<any>(0)

    const menuItems = () => {
        return listOfMenuItems().filter((item) => !item.hideMenu)
    }

    const handleClickItem = (item: any) => {
        openSubMenu !== item.label && navigate(Array.isArray(item.subMenu) ? item.subMenu[0].link : item.link)
        item.subMenu && setOpenSubMenu(openSubMenu === item.label ? "" : item.label)
    }

    const handleExpandCollapse = (e: any, item: any) => {
        setOpenSubMenu(item);
        e.preventDefault();
        e.stopPropagation();
    }

    const handleClose = () => {
        handleClickMenu(false)
        handleOpenNavMenu(false)
    }

    const handleCloseLogin = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        getNotifications()
    }, [location.pathname])

    const getNotifications = () => {
        if (!user?.userId)
            return;
        fetcher.get(`Notification/${user?.userId}`).then(res => {
            setNotifications(res.data.data)
        })
    }

    const handleNotif = (e: any) => {
        setOpenNotif(true)
        setNotifAnchorEl(e.currentTarget)
    }

    const closeNotif = () => {
        setOpenNotif(false)
        setNotifAnchorEl(null)
    }

    const handleNotifClick = (id: any, type: any) => {
        if (type == "task")
            navigate('/tasks')
        if (type == "message")
            navigate('/chat')

        fetcher.post(`Notification/mark/${id}`, null).then(() => {
            closeNotif()
            getNotifications()
        })
    }

    useEffect(() => {
        getUnreadChats();
    }, [location.pathname])

    const getUnreadChats = () => {
        if (!user?.userId)
            return;
        fetcher.get(`Chat/unreadChats/${user?.userId}`).then(res => {
            setUnreadMessages(res.data.count)
        })
    }

    const newMessageHandler = (hubCount: any, hubUserId: any,) => {
        if (!user?.userId)
            return;
        if (hubUserId == user?.userId)
            setUnreadMessages(hubCount)
    };

    const eventConfigs = [
        {
            eventName: "unreadMessages",
            action: newMessageHandler
        },
    ];

    const signalR = useSignalR(eventConfigs, [])

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const customScrollbarStyles = {
        '&::-webkit-scrollbar': {
            width: '7px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'white',
            borderRadius: '6px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'lightgray',
        },
    };

    const isSuperAdminUser = () => {
        if (!user?.username)
            return false;

        const email = user?.username;
        if (email === 'daminov@emerest.com' || email?.endsWith('techonsoft.com')) {
            return true
        } else {
            return false;
        }
    }

    return (
        <DivContainer>
            {/* Closed Drawer */}
            <Drawer
                variant='permanent'
                anchor={props.anchor}
                sx={{
                    background: '#7846ff',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    width: open ? 0 : 80,
                    flexShrink: 0,
                    zIndex: '100',
                    [`& .MuiDrawer-paper`]: { width: open ? 0 : 85, boxSizing: 'border-box', background: '#7846ff' },
                }}>
                {/* <FlexBetween>
                    <IconButton onClick={() => handleClickMenu(true)} edge="start" color="inherit" aria-label="menu" sx={{ marginTop: '10px', marginLeft: '20%' }}>
                        <KeyboardDoubleArrowRightOutlined sx={{ color: 'white' }} />
                    </IconButton>
                </FlexBetween> */}
                <div style={{height: '47px'}}>

                </div>
                <List sx={{ overflowY: 'scroll', overflowX: 'hidden', marginTop: '15px', height: '80%', ...customScrollbarStyles }}>
                    {menuItems().map((item: any, index: number) => {
                        if (!user?.roles?.find((r: any) => r == "Admin") && item.admin)
                            return;
                        if (item.label === "Dashboard" && !isSuperAdminUser())
                            return;

                        return (
                            <>
                                <Tooltip title={item.label} placement='right'>
                                    <ListItemButton
                                        sx={{
                                            paddingTop: '5px',
                                            paddingBottom: '2px',
                                            pl: 0.7,
                                            borderRadius: '5px',
                                            margin: '0px',
                                            marginLeft: '5px',
                                            paddingLeft: '0px',
                                            display: 'grid',
                                            width: '72px',
                                            border: '2px solid transparent',
                                            '&:hover': {
                                                //border: '2px solid white',
                                                backgroundColor: item.link === location.pathname ? 'white' : 'transparent',
                                            },
                                            '&.Mui-selected': {
                                                //border: '2px solid white',
                                                background: 'white',
                                                '&:hover': {
                                                    backgroundColor: item.link === location.pathname ? 'white' : 'transparent',
                                                }
                                            }
                                        }}
                                        key={item.id ?? index}
                                        selected={item.link === location.pathname}
                                        onClick={() => handleClickItem(item)}
                                    >
                                        <div style={{ paddingLeft: '20px', paddingBottom: '2px' }}>
                                            {item.label == 'Chat' && <Badge anchorOrigin={{ horizontal: 'left', vertical: 'top' }} badgeContent={unreadMessages} color='error' />}
                                            <Icon sx={{ float: "left", color: item.link === location.pathname ? '#7846ff' : 'white', }}>{item.icon}</Icon>
                                            {
                                                item.subMenu && openSubMenu === item.label ?
                                                    <ExpandLess onClick={(e) => handleClickItem("")} sx={{ color: 'white' }} /> :
                                                    item.subMenu ? <ExpandMore onClick={(e) => handleClickItem(item)} sx={{ color: 'white' }} /> : null
                                            }
                                        </div>
                                        <Button
                                            sx={{
                                                margin: 0,
                                                padding: 0,
                                                paddingLeft: '2px',
                                                fontSize: '11px',
                                                color: item.link === location.pathname ? '#7846ff' : 'white'
                                            }}
                                        >
                                            {item.label}
                                        </Button>
                                    </ListItemButton>
                                </Tooltip>
                                {item.subMenu && item.subMenu.map((subMenu: any, index: number) => (
                                    <Collapse in={openSubMenu === item.label} key={`${index}-${subMenu.label}-navbar`} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding onClick={(e: any) => {
                                            e.stopPropagation();
                                            navigate(subMenu.link);
                                        }}
                                        >
                                            <Tooltip title={subMenu.label} placement='right'>
                                                <ListItemButton
                                                    sx={{
                                                        paddingTop: '10px',
                                                        height: '40px',
                                                        pl: 1,
                                                        borderRadius: '5px',
                                                        margin: '2.5px 8px',
                                                        border: '2px solid transparent',
                                                        '&:hover': {
                                                            border: '2px solid white',
                                                            backgroundColor: subMenu.link === location.pathname ? 'white' : 'transparent',
                                                        },
                                                        '&.Mui-selected': {
                                                            border: '2px solid white',
                                                            background: 'white',
                                                            '&:hover': {
                                                                backgroundColor: subMenu.link === location.pathname ? 'white' : 'transparent',
                                                            }
                                                        },
                                                    }}
                                                    selected={subMenu.link === location.pathname}
                                                >
                                                    <Icon
                                                        sx={{ paddingBottom: "5px", float: "left", paddingLeft: '8px', color: subMenu.link === location.pathname ? '#7846ff' : 'white', }}
                                                    >
                                                        {subMenu.icon}
                                                    </Icon>
                                                </ListItemButton>
                                            </Tooltip>
                                        </List>
                                    </Collapse>
                                ))}
                            </>

                        )
                    })}
                </List>
                {/* <div style={{ position: 'absolute', display: 'grid', bottom: '10px', left: '10px' }}> */}
                <div style={{ display: 'grid', marginLeft: '10px' }}>
                    <IconButton disabled={!notifications.length} sx={{ width: '40px', marginLeft: '12px' }} onClick={(e) => handleNotif(e)}>
                        <Badge overlap='circular' badgeContent={notifications.length} color='error'>
                            <Avatar sx={{ border: '2px solid white', width: '40px', height: '40px' }} alt='logo' src={`/${user?.agency?.replace(' ', '')}.png`} />
                        </Badge>
                    </IconButton>
                    <IconButton
                        sx={{ marginLeft: '-10px', marginTop: '5px' }}
                        onClick={() => {
                            logout()
                            handleCloseLogin()
                        }}
                    >
                        <OutputOutlined fontSize='medium' sx={{ color: 'white' }} />
                    </IconButton>
                </div>
            </Drawer>

            {/* Open Drawer */}
            <Drawer
                variant="permanent"
                anchor={props.anchor}
                sx={{
                    whiteSpace: 'nowrap',
                    width: open ? drawerWidth : 0,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { overflow: 'hidden', width: open ? drawerWidth : 0, boxSizing: 'border-box', background: '#7846ff', height: '100%' },
                }}>
                <FlexBetween>
                    <img src='ConnectAideFull.png' width={'200px'} height={'50px'} />
                    <IconButton sx={{ alignSelf: 'flex-end', height: "60px" }} onClick={() => handleClose()}>
                        <KeyboardDoubleArrowLeftOutlinedIcon sx={{ color: 'white' }} />
                    </IconButton>
                </FlexBetween>
                <Divider sx={{ marginTop: '4px', background: 'white' }} />
                <List sx={{ overflowY: 'scroll', overflowX: 'hidden', marginTop: '15px', height: '70%', ...customScrollbarStyles }}>
                    {menuItems().map((item: any, index: number) => {
                        if (!user?.roles?.find((r: any) => r == "Admin") && item.admin)
                            return

                        if (item.label === "Dashboard" && !isSuperAdminUser())
                            return;

                        return (
                            <>
                                <ListItemButton
                                    key={item.id ?? index}
                                    selected={item.link === location.pathname}
                                    onClick={() => handleClickItem(item)}
                                    sx={{
                                        height: '40px',
                                        pl: 4,
                                        borderRadius: '5px',
                                        margin: '5px',
                                        border: '2px solid transparent',
                                        '&:hover': {
                                            border: '2px solid white',
                                            backgroundColor: item.link === location.pathname ? 'white' : 'transparent',
                                        },
                                        '&.Mui-selected': {
                                            border: '2px solid white',
                                            background: 'white',
                                            '&:hover': {
                                                backgroundColor: item.link === location.pathname ? 'white' : 'transparent',
                                            }
                                        }
                                    }}
                                >
                                    {item.label == 'Chat' && <Badge anchorOrigin={{ horizontal: 'left', vertical: 'top' }} sx={{ '& .MuiBadge-badge': { marginTop: '-10px' } }} badgeContent={unreadMessages} color='error' />}
                                    <Icon sx={{ paddingBottom: "5px", float: "left", color: item.link === location.pathname ? '#7846ff' : 'white', }}>{item.icon}</Icon>
                                    <ListItemText primary={<Bold fontFamily={'ConnectAideMain'}>{item.label}</Bold>} disableTypography sx={{ color: item.link === location.pathname ? '#7846ff' : 'white', marginLeft: "10px" }} />
                                    {item.subMenu && openSubMenu === item.label ? <ExpandLess onClick={(e) => handleClickItem("")} sx={{ color: 'white' }} /> : item.subMenu && <ExpandMore onClick={(e) => handleClickItem(item)} sx={{ color: 'white' }} />}
                                </ListItemButton>
                                {item.subMenu && item.subMenu.map((subMenu: any, index: number) => (
                                    <Collapse in={openSubMenu === item.label} key={`${index}-${subMenu.label}-navbar`} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding onClick={(e: any) => {
                                            e.stopPropagation();
                                            navigate(subMenu.link);
                                        }}
                                        >
                                            <ListItemButton
                                                selected={subMenu.link === location.pathname}
                                                sx={{
                                                    height: '40px',
                                                    border: '2px solid transparent',
                                                    '&:hover': {
                                                        border: '2px solid white',
                                                        backgroundColor: subMenu.link === location.pathname ? 'white' : 'transparent',
                                                    },
                                                    '&.Mui-selected': {
                                                        border: '2px solid white',
                                                        background: 'white',
                                                        '&:hover': {
                                                            backgroundColor: subMenu.link === location.pathname ? 'white' : 'transparent',
                                                        }
                                                    },
                                                    borderRadius: '5px',
                                                    margin: '5px 5px 5px 55px'
                                                }}
                                            >
                                                <Icon sx={{ paddingBottom: '5px', float: "left", color: subMenu.link === location.pathname ? '#7846ff' : 'white', }}>{subMenu.icon}</Icon>
                                                <ListItemText primary={<Bold fontFamily={'ConnectAideMain'}>{subMenu.label}</Bold>} disableTypography sx={{ marginLeft: "10px", color: subMenu.link === location.pathname ? '#7846ff' : 'white', }} />
                                            </ListItemButton>
                                        </List>
                                    </Collapse>
                                ))}
                            </>

                        )
                    })}
                </List>
                <div style={{ borderTop: '2px solid white' }}>
                    <div style={{ display: 'grid', marginLeft: '10px', }}>
                        <IconButton disabled={!notifications.length} sx={{ width: '40px' }} onClick={(e) => handleNotif(e)}>
                            <Badge overlap='circular' badgeContent={notifications.length} color='error'>
                                <Avatar sx={{ border: '2px solid white', width: '40px', height: '40px' }} alt='logo' src={`/${user?.agency?.replace(' ', '')}.png`} />
                            </Badge>
                        </IconButton>
                        <BoldTitle fontFamily={'ConnectAideMain'} color='white' fontSize='18px' margin="0px 5px 0px 0px">{user?.username} </BoldTitle>
                        <BoldTitle fontFamily={'ConnectAideMain'} color='white' fontSize='14px' margin="0px 5px 0px 0px">{user?.agency} </BoldTitle>
                        <Button
                            sx={{
                                width: '200px', height: '40px', marginTop: '15px', color: 'white', fontFamily: 'ConnectAideMain'
                            }}
                            variant='contained'
                            onClick={() => {
                                logout()
                                handleCloseLogin()
                            }}
                        // startIcon={<OutputOutlined fontSize='small' sx={{ marginRight: '10px', color: 'white' }} />}
                        >
                            Logout
                        </Button>
                    </div>
                </div>
                <Popover
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseLogin}
                >
                    <MenuItem disableRipple divider>
                        <Avatar
                            sx={{ margin: "5px 10px 5px -5px" }}
                            alt='logo'
                            src={`/${user?.agency?.replace(' ', '')}.png`}
                            sizes='small'
                        />
                        <div style={{ display: 'grid' }}>
                            <BoldTitle fontSize="16">{user?.username}</BoldTitle>
                            {user?.agency}
                        </div>
                    </MenuItem>
                    <MenuItem onClick={() => {
                        logout()
                        handleCloseLogin()
                    }}>
                        <LogoutOutlined fontSize='small' sx={{ marginRight: '10px' }} />
                        Logout
                    </MenuItem>
                </Popover>
                <Popover
                    onClose={() => closeNotif()}
                    anchorEl={notifAnchorEl}
                    open={openNotif}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {notifications.length < 1 &&
                        <MenuItem
                            disableRipple
                            disableTouchRipple
                            sx={{ width: '300px', padding: '10px' }}
                        >
                            <BoldTitle>No New Notifications</BoldTitle>
                        </MenuItem>
                    }
                    {notifications.map((notif: any) => {
                        return (
                            <MenuItem onClick={() => handleNotifClick(notif.id, notif.type)} sx={{ width: '300px', padding: '10px' }}>
                                <BoldTitle>You have {notif.count} new {notif.type}(s)</BoldTitle>
                            </MenuItem>
                        )
                    })}
                </Popover>
            </Drawer >
        </DivContainer >
    )
}

export default ManuDrawer