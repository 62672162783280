import React, { useEffect, useState } from 'react';
import { Grid, TextField, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { prefLevelValue } from './types';

interface EditCommutePreferenceFieldProps {
    label: string;
    maxMinutes: number;
    preferenceLevel: prefLevelValue;
    onMaxMinutesChange: (value: number) => void;
    onPreferenceLevelChange: (value: prefLevelValue) => void;
}

const EditCommutePreferenceField: React.FC<EditCommutePreferenceFieldProps> = ({
    label,
    maxMinutes,
    preferenceLevel,
    onMaxMinutesChange,
    onPreferenceLevelChange,
}) => {
    return (
        <>
            <Grid item xs={8}>
                <FormControl fullWidth>
                    <InputLabel>{`${label} Preference`}</InputLabel>
                    <Select
                        label={`${label} Preference`}
                        value={preferenceLevel || 'Select'}
                        onChange={(e) => onPreferenceLevelChange(e.target.value as prefLevelValue)}
                    >
                        <MenuItem value={"Select"}><i>SELECT</i></MenuItem>
                        <MenuItem value="Preferred">Preferred</MenuItem>
                        <MenuItem value="Rather_Not">Rather Not</MenuItem>
                        <MenuItem value="Never">Never</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label={`Max Minutes`}
                    type="number"
                    value={maxMinutes}
                    onChange={(e) => onMaxMinutesChange(parseInt(e.target.value))}
                    inputProps={{ min: 0, max: 1440 }}
                />
            </Grid>
        </>
    );
};

export default EditCommutePreferenceField;
