import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TextField, Tooltip, TextFieldProps as MuiTextFieldPropsType, Dialog, DialogContent, Box, IconButton, Paper, Grid } from '@mui/material';
import { Divider, MuiButton } from '../../../../lib/MUI/mui.components';
import { getISODate } from '../../../../helper/datetime';
import DataGrid from '../../DataGrid';
import Chip from '@mui/material/Chip';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close';
import { BoxContainer, FlexAlignCenter, DivContainer, FlexBetween, Title, AppBarStyled, BoldTitle } from '../../../../assets/styles/styledComponents';
import useApi_DEPRACATED from '../../../../hooks/useApi';
import DateEditor from '../../../form/TimeDate/DateEditor';
import { EXPLORER_VISITS_COL, VISIT_MODAL, CAREGIVER_VISITS_MODAL } from '../../../../data/GridColumnDefinitions';
import Button from '../../Button';
import ModalContainer from '../../Modals/ModalContainer';
import FeedbackModal from '../../Modals/components/FeedbackModal';
import MenuPopup from '../../Modals/components/MenuPopup';
import { strings } from '../../../../helper/strings';
import { dateTime } from '../../../../helper/datetime';
import FeaturedIcon from '../../../common/FeaturedIcon';
import ReactLoader from '../../../common/ReactLoader';
import { match } from 'assert';
import { appModules, BASE_URL } from '../../../../data/constants';
import SearchInput from '../../../common/SearchInput/SearchInput';
import VisitMatches from '../../../../features/schedules/components/VisitMatches';
import Loader from '../../Loader';
import { Add, CalendarMonth, Check, CheckCircle, CheckCircleOutline, CheckOutlined, Create, InfoOutlined, List, Search } from '@mui/icons-material';
import Calendar from '../../../../pages/Calendar/Calendar';
import { VisitData } from '../../../../features/visits/types';
import CalendarDetails from '../../../../pages/Calendar/CalendarDetails';
import { PatientProps_Type } from '../../../../features/patients/type';
import useMultiSelect from '../../../../hooks/useMultiSelect';
import useVisits from '../../../../features/visits/useVisits';

type VisitsProps = PatientProps_Type & {
  title: string,
  module?: string,
}

const Visits = ({ title, patientId, patientName, patientAddress }: VisitsProps) => {
  const { module, id } = useParams();
  const moduleId = id;
  const [visitRow, setVisitRow] = useState<VisitData | null>(null);
  const [feedbackRow, setFeedbackRow] = useState<any>(null);
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date())
  const { getList, isLoading, data } = useApi_DEPRACATED('Visit', BASE_URL);
  const [feedbackModalAction, setFeedbackModalAction] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const matchVisitApi = useApi_DEPRACATED(module === appModules.patient ? 'match/by_visit' : 'match', BASE_URL);
  const feedbackApi = useApi_DEPRACATED('match', BASE_URL);
  const syncVisitApi = useApi_DEPRACATED('HhaData', BASE_URL);
  const [modalRows, setModalRows] = useState<any[]>([])
  const [visitList, setVisitList] = useState<VisitData[]>([])
  const [visitList_filtered, setVisitList_filtered] = useState<VisitData[]>([])
  const [searchTerm, setSearchTerm] = useState('');
  const [searchModalTerm, setSearchModalTerm] = useState('');
  const [calendarView, setCalenderView] = useState(true)
  const [open_CreateVisit, setOpen_CreateVisit] = useState<boolean>(false);
  const [open_Matches, setOpen_Matches] = useState<boolean>(false);
  const [patientProps, set_patientProps] = useState<PatientProps_Type>({});
  const [selectMode, setSelectMode] = useState<boolean>(false);
  const multiSelection = useMultiSelect([])
  const [visit, setVisit] = useState<any>(null);

  const visitsHook = useVisits({
    setList: setVisitList_filtered
  });

  useEffect(() => {
    if (!!module && !!id && !!selectedMonth)
      getVisits();
  }, [module, id, selectedMonth])

  useEffect(() => {
    set_patientProps({ patientId, patientName, patientAddress });
  }, [patientId, patientName, patientAddress])

  useEffect(() => {
    if (!data?.list?.visits)
      return;

    setVisitList(data.list.visits)
  }, [data])

  useEffect(() => {
    if (multiSelection.selected) {
      if (!data?.list?.visits)
        return;
      let foundVisit = undefined;
      for (const visit of data.list.visits) {
        if (multiSelection.hasValue(visit.visitId)) {
          foundVisit = visit;
          break;
        }
      }
      if (foundVisit) {
        setVisit(foundVisit);
      }
    }
  }, [multiSelection])

  useEffect(() => {
    modalRows.length < 1 && setModalRows(loadVisitsMatchRows())
  }, [matchVisitApi?.data?.list])

  useEffect(() => {
    const visits = visitList;
    const results = Array.isArray(visits) ? visits.filter((item: VisitData) => {
      const values = Object.values(item);
      return values.some((value) => String(value).toLowerCase().includes(searchTerm.toLowerCase()));
    }) : [];
    setVisitList_filtered(results)
  }, [searchTerm, visitList])

  useEffect(() => {
    const results = loadVisitsMatchRows()?.filter((item: VisitData) => {
      const values = Object.values(item);
      return values.some((value) => String(value).toLowerCase().includes(searchModalTerm.toLowerCase()));
    });
    setModalRows(results ?? [])
  }, [searchModalTerm, matchVisitApi?.data])


  // TODO: if want to load modal data initially uncomment below code
  // useEffect(() => {
  //   if (!!visitRow) {
  //     getMatches()
  //   }
  // }, [visitRow])

  const getVisits = () => {
    getList(`/${module}/by_month/${id}/${selectedMonth.toISOString()}`)
  }

  const getMatches = () => {
    module === appModules.caregiver ?
      matchVisitApi.getList(`/${selectedMonth.toISOString()}/${visitRow?.caregiverId}`) :
      matchVisitApi.getList(`/${visitRow?.visitId}`)
  }

  const getVisitColumns = () => {
    let actionColumn = module === 'patient' && {
      field: 'findAid', headerName: 'Find Caregiver', flex: 0.02, headerClassName: 'hidden-header', renderCell: (param: any) => (
        <Tooltip title="Find Caregiver">
          <DivContainer>
            <FeaturedIcon
              onClick={() => {
                // if (!!param.row.caregiverId) return
                setVisitRow(param.row);
                setOpen_Matches(true);
              }}
              color={!!param.row.caregiverId ? "default" : "primary"}
              icon="Search"
            />
          </DivContainer>
        </Tooltip>
      ),
    }

    let syncColumn = {
      field: 'sync', headerName: 'Sync', flex: 0.04, headerClassName: 'hidden-header', renderCell: (param: any) => (
        <Button
          label="Sync"
          onClick={() => { handleSyncVisit(param.row.hhaVisitId) }}
        />
      ),
    }
    return [...EXPLORER_VISITS_COL(module as string), actionColumn, syncColumn]
  }

  const reshuffle = () => {
    getMatches()
  }

  const handleOpenMatches = () => {
    if (multiSelection.count() != 0 && !!selectMode) {
      setOpen_Matches(true)
    }
  }

  const handleCloseFeedbackModal = (data?: { action: string | null, description: string }) => {
    if (!!data) {
      feedbackApi.create({
        visitId: feedbackRow?.visitId,
        description: data.description,
        updateType: data.action,
        caregiverId: feedbackRow?.caregiverId,
        reportedBy_UserId: 0
      }, `/${data.action}`).then(() => {
        if (data.action?.toLowerCase() === 'decline')
          matchVisitApi.removeVisitMatch(feedbackRow.caregiverId);
        closeFeedbackModal()
      })
    } else {
      closeFeedbackModal()
    }
  }

  const handleSyncVisit = (visitId: number) => {
    syncVisitApi.create({}, `/visit/${visitId}`).then(() => {
      getVisits()
    })
  }

  const closeFeedbackModal = () => {
    setFeedbackModalAction(null)
    setFeedbackRow(null)
  }

  const handleCloseMenu = (selected = null) => {
    if (selected) setFeedbackModalAction(selected);
    setAnchorEl(null)
  }

  const getVisitModalCol = () => {
    const columns = module === appModules.caregiver ? [...CAREGIVER_VISITS_MODAL] : [...VISIT_MODAL]
    return [
      ...columns,

      {
        field: "isAssignee", headerName: "IsAssignee", flex: 0.06, renderCell: (param: any) => {
          if (param.row.isAssignee) {
            return <span> {'Yes'} </span>
          }
          return <span> {'No'}</span>

        }
      },

      {
        field: 'overTime', flex: 0.04, renderCell: (params: any) => {
          if (params.row.flags?.length > 0) {
            return <Tooltip title={params.row.flags[0]}>
              <span style={{ color: 'red' }}>{params.row.flags[0]}</span>
            </Tooltip>
          }
          return null
        }
      },
      {
        field: 'bestMatch', flex: 0.04, renderCell: (params: any) => {
          if (params.row.isBestMatch) {
            return <Chip label="Best Match" color="success" />
          }
          return null
        }
      },
      {
        field: 'info', flex: 0.02, renderCell: (params: any) => (
          <Tooltip title={params.row.summary}>
            <DivContainer>
              <FeaturedIcon
                fontSize={"small"}
                params={params}
                icon={'Info'}
                color={!!params.row.summary ? 'primary' : 'default'}
              />
            </DivContainer>
          </Tooltip>

        )
      },
      {
        field: 'menu', flex: 0.02, renderCell: (params: any) => (
          <FeaturedIcon
            fontSize={"small"}
            params={params}
            icon={'Menu'}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              setFeedbackRow(params.row)
              setAnchorEl(e.currentTarget)
            }}
          />
        )
      },
    ]
  }

  const loadVisitsMatchRows = () => {
    let matches: any = [];
    let recommended: any = { ...matchVisitApi?.data?.list?.recommendedMatch }
    matches = matchVisitApi?.data?.list?.matches.map((row: any) => {
      return { ...row.caregiver, isAssignee: row.isAssignee, ...row.visit, minutesAvailable: row.minutesAvailable, percentAvailable: `${row.percentAvailable}%`, flags: row.flags, summary: row.summary }
    })
    matches = matches?.filter((match: any) => module === appModules.caregiver ? match.visitId !== recommended?.visit?.visitId : match.caregiverId !== recommended?.caregiver?.caregiverId);
    if (matches?.length > 0 || !!recommended.visit) {
      if (!!recommended?.caregiver) {
        matches.unshift({ ...recommended?.caregiver, isAssignee: recommended?.isAssignee, ...recommended.visit, minutesAvailable: recommended.minutesAvailable, percentAvailable: `${recommended.percentAvailable}%`, flags: recommended?.flags, summary: recommended?.summary, isBestMatch: true })
      }
      return matches
    }
    return []
  }

  const handleSearch = (query: string) => {
    setSearchTerm(query)
  }

  const handleModalSearch = (query: string) => {
    setSearchModalTerm(query)
  }

  const customActionButton = <FlexAlignCenter>
    <Button
      label="Load"
      onClick={getVisits}
    />
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        views={['month', 'year']}
        label="Select Month"
        openTo='month'
        value={selectedMonth}
        onChange={(newValue) => {
          if (newValue === null) return;
          var newDate = new Date(newValue as Date);
          setSelectedMonth(newDate);
        }}
        renderInput={(params: MuiTextFieldPropsType) => <TextField sx={{
          '& .MuiOutlinedInput-input': {
            padding: '4px 10px',
          }

        }}
          InputLabelProps={{
            shrink: true,
          }}
          {...params} helperText={null} />}
      />
      {/* {module === appModules.caregiver &&
        <>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Button
            label="Find Visits"
            onClick={() => {
              setVisitRow(
                {
                  caregiverId: Number(moduleId)
                }
              )
              setOpen_Matches(true);
            }}
          />
        </>
      } */}
    </LocalizationProvider>

  </FlexAlignCenter>

  const customModalActionButton = <FlexAlignCenter>
    <Button
      label="Load"
      onClick={reshuffle}
    />
    {module === appModules.caregiver && (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Select Date"
          value={selectedMonth}
          onChange={(newValue) => {
            setSelectedMonth(newValue as Date);
          }}
          renderInput={(params: MuiTextFieldPropsType) => <TextField sx={{
            '& .MuiOutlinedInput-input': {
              padding: '4px 10px',
            }

          }}
            InputLabelProps={{
              shrink: true,
            }}
            onClick={(e) => { e.stopPropagation() }}
            {...params} helperText={null} />}
        />
      </LocalizationProvider>
    )}
  </FlexAlignCenter>

  const handleCloseDetailsModal = () => {
    setSelectedMonth(new Date())
    setModalRows([])
    setVisitRow(null)
    setSearchModalTerm('')
    setOpen_Matches(false);
  }
  return (
    <DivContainer>
      <Loader isLoading={isLoading || syncVisitApi.isLoading} />

      <Grid container>
        <Grid item xs={4}>
          {!calendarView && <SearchInput getSearchQuery={handleSearch} />}
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              {!!patientId &&
                <Box sx={{ marginRight: '10px' }}>
                  <Tooltip title={'Find Matches'}>
                    <Paper elevation={4} sx={{ marginBottom: '10px', borderRadius: '100px' }}>
                      <IconButton
                        disabled={multiSelection.count() === 0 || !selectMode}
                        onClick={() => {
                          // if (multiSelection.count() === 0 || !selectMode)
                          // return;
                          handleOpenMatches()
                        }}>
                        {/* NEED TO SWITCH BELOW ROW TO THE FOLLOWING: <Search color={multiSelection.count() === 0 ? 'disabled' : 'primary'}/> */}
                        <Search color={multiSelection.count() === 0 || !selectMode ? 'disabled' : 'primary'} />
                      </IconButton>
                    </Paper>
                  </Tooltip>
                </Box>
              }
              {!!patientId &&
                <Box sx={{ marginRight: '10px' }}>
                  <Tooltip title={'Select Mode'}>
                    <Paper elevation={4} sx={{ marginBottom: '10px', borderRadius: '100px' }}>
                      <IconButton
                        onClick={() => {
                          setSelectMode(!selectMode);
                        }}
                        sx={{
                          backgroundColor: selectMode ? 'primary.main' : 'white',
                          color: selectMode ? 'white' : 'primary.main',
                          borderRadius: '50%',
                          padding: '8px', // Adjust padding as needed
                          '&:hover': {
                            backgroundColor: selectMode ? 'primary.dark' : 'grey.300',
                          },
                        }}
                      >
                        <Check
                          sx={{
                            color: selectMode ? 'white' : 'primary.main',
                            width: '24px', // Adjust size as needed
                            height: '24px', // Adjust size as needed
                          }}
                        />
                      </IconButton>
                    </Paper>
                  </Tooltip>
                </Box>
              }
              {
                !!patientId &&
                <Tooltip title={'Create Visit'}>
                  <Paper elevation={4} sx={{ marginBottom: '10px', borderRadius: '100px' }}>
                    <IconButton onClick={() => {
                      setOpen_CreateVisit(true);
                      const now = dateTime.getDateTime(new Date());
                      const today = new Date();
                      today.setHours(0, 0, 0, 0);
                      const midNight = dateTime.getDateTime(today);
                      setVisitRow({
                        patientId,
                        patientName,
                        patientAddress,
                        visitDate: now,
                        scheduleStartTime: midNight,
                        scheduleEndTime: midNight,
                      } as VisitData)
                    }}>
                      {<Add color='primary' />}
                    </IconButton>
                  </Paper>
                </Tooltip>
              }
              <Tooltip title={calendarView ? 'Switch to list view' : 'Switch to calendar view'}>
                <Paper elevation={4} sx={{ marginBottom: '10px', borderRadius: '100px', marginLeft: '10px' }}> {/* Add margin-left for spacing */}
                  {/* <IconButton onClick={() => setCalenderView(!calendarView)}>
                    {!calendarView && <CalendarMonth color='primary' />}
                    {calendarView && <List color='primary' />}
                  </IconButton> */}
                </Paper>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <BoxContainer borderPx='none'>
        {!!calendarView &&
          <div>
            <Calendar
              module={module || ''} id={id || '0'}
              dateBoxHeight='115px'
              showPatient={module === 'caregiver'}
              showCaregiver={module === 'patient'}
              {...patientProps}
              selectMode={selectMode}
              setSelectedIds={multiSelection.setSelected}
              visitsHook={visitsHook}
            />
          </div>
        }
        {!calendarView && <DataGrid
          title={title}
          data={{
            columns: getVisitColumns(),
            rows: visitList_filtered ?? []
          }}
          options={{
            hideExport: true,
            toolbarComponent: customActionButton,
            hideToolbarIconText: true
          }}
        />}
      </BoxContainer>
      {
        !!visitRow &&
        <CalendarDetails
          visit={visitRow}
          openDetails={open_CreateVisit}
          handleCloseDetails={() => { setOpen_CreateVisit(false); setVisitRow(null) }}
          onVisitUpdate={(updatedVisit: VisitData) => {
            setVisitRow(updatedVisit)
          }}
          visitsHook={visitsHook}
        />
      }
      <Dialog
        open={!!visitRow?.visitId && !!open_Matches || visit != undefined && !!open_Matches}
        onClose={handleCloseDetailsModal}
        fullWidth
        fullScreen
        sx={{
          '& .MuiDialog-paper': {
            width: '90%',
            height: '90%',
          },
        }}
      >
        <DialogContent>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            {/* {"Caregivers"} */}
            {!!visitRow && <p>{visitRow.patientName} |  {dateTime.getTimeString(visitRow.scheduleStartTime)} - {dateTime.getTimeString(visitRow.scheduleEndTime)}</p>}
            <IconButton aria-label="close" onClick={handleCloseDetailsModal}>
              <CloseIcon />
            </IconButton>
          </Box>

          {/* <VisitMatches
            caseBased={true}
            selectedIds={multiSelection.selected}
            selectedIsDirty={selectedIsDirty}
            setSelectedIsDirty={setSelectedIsDirty}
            visits={visits} // perhaps pass this if it helps anything
            factorSettingsId={globalFactorPSettingId} // perhaps move this value retrieve call to inside matches-board
          /> */}
          <VisitMatches
            caseBased={false}
            visit={multiSelection.selected ? visit : visitRow}
            visitDates={[
              new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1).toISOString(),
              new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0).toISOString()
            ]}
            factorSettingsId={undefined}
            selectedIds={multiSelection.selected} />
        </DialogContent>
      </Dialog>
      <FeedbackModal
        title={strings.firstLetterUpperCase(feedbackModalAction ?? '')}
        action={feedbackModalAction}
        open={!!feedbackModalAction}
        onClose={handleCloseFeedbackModal}
        isLoading={feedbackApi.isLoading}
      />
      <MenuPopup
        id={'mtch-febck'}
        open={!!anchorEl}
        anchorEl={anchorEl}
        options={[
          { label: 'Accept', value: 'accept' },
          { label: 'Decline', value: 'decline' },
          { label: 'Feedback', value: 'feedback' },
          { label: 'Update', value: 'update' },
        ]}
        onCloseMenu={handleCloseMenu}
      />
    </DivContainer>
  )
}

export default Visits