export const factorTypes = {
    Logistic: 'Logistic',
    Ability: 'Ability'
};

export const logisticFactors : MenuOption[] = [
    { key: "Availability", value: "Availability" },
    { key: "Travel", value: "Travel" }
];

export type MenuOption = {
    key: string,
    value: string,
}

// SHOULD COME FROM SERVER DB
export const abilityFactors : MenuOption[] = [
    { key: "_12_hr_case", value: "12 hr Case" },
    { key: "_2_hr_case", value: "2 hr Case" },
    { key: "_3_hr_case", value: "3 hr Case" },
    { key: "_4_hr_case", value: "4 hr Case" },
    { key: "_8_hr_case", value: "8 hr Case" },
    { key: "_Animals", value: "Animals" },
    { key: "_Babies", value: "Babies" },
    { key: "_Bedbound", value: "Bedbound" },
    { key: "_Dementia", value: "Dementia" },
    { key: "_Hoyer_Lift", value: "Hoyer Lift" },
    { key: "_Kosher_Home", value: "Kosher Home" },
    { key: "_Live_in_case", value: "Live In Case" },
    { key: "_Men", value: "Men" },
    { key: "_Peds_Training", value: "Peds Training" },
    { key: "_Smoking", value: "Smoking" },
    // { key: "_Available_every_other_weekend", value: "Available Every Other Weekend" },
    /* { key: "_Max_Travel_Duration", value: "Max Travel Duration" },
    { key: "_Max_Walking_Duration", value: "Max Walking Duration" },
    { key: "_Max_Car_Duration", value: "Max Car Duration" },
    { key: "_Max_Transit_Duration", value: "Max Transit Duration" },
    { key: "_Max_Bicycle_Duration", value: "Max Bicycle Duration" },
    { key: "_Language_Match", value: "Language Match" } */
];
  
export const operators = [
    { key: "Greater_Than", value: "Greater Than" },
    { key: "Less_Than", value: "Less Than" },
    { key: "Greater_Than_Or_Equals", value: "Greater Than Or Equals" },
    { key: "Less_Than_Or_Equals", value: "Less Than Or Equals" },
];
