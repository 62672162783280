import { Dialog, DialogTitle, Skeleton, DialogContent, Typography, CircularProgress } from '@mui/material';
import { Suspense, useEffect, useState } from 'react';
import FactorSettingsMain from './FactorSettingsMain';
import FactorSettingProfile from './FactorSettingProfile';

interface FactorSettingsProps {
	setGlobalFactorPSettingId: Function,
	selectedProfileId: number | undefined,
	setOpen: any,
	open: any,
}

export default function FactorSettings({
    setGlobalFactorPSettingId: setGlobalSelectedProfileId,
    selectedProfileId: globalSelectedProfileId,
    setOpen,
    open,
}: FactorSettingsProps) {
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [response, setResponse] = useState<any>(undefined);
    const [isMain, setIsMain] = useState<Boolean>(false);

    const handleClose = () => {
        setOpen(false);
    };
    
    useEffect(() => {
        if (!!response) {
            const timeoutId = setTimeout(() => {
                setResponse(undefined);
            }, 3000);

            return () => clearTimeout(timeoutId);
        }
    }, [response]);

    return (
        <div>
            <Dialog
                fullWidth
                open={open}
                onClose={handleClose}
                maxWidth={"md"}
                PaperProps={{
                    sx: {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch",
                        height: "900px",
                    },
                }}
            >
                <DialogTitle>
                    Factor Settings Configuration
                    {isUpdating && (
                        <CircularProgress
                            size={20}
                            sx={{ position: "absolute", right: 30, top: 20 }}
                        />
                    )}
                    {!!response && (
                        <Typography
                            sx={{
                                color: response.statusCode.toString().includes('4') ? 'red' : 'green',
                                position: "absolute",
                                right: 30,
                                top: 20,
                            }}
                        >
                            {response.statusCode === 200 ? 'Saved' : response.message}
                        </Typography>
                    )}
                </DialogTitle>

                <DialogContent>
                    <FactorSettingProfile
                        setSelectedProfileIdGlobal={setGlobalSelectedProfileId}
                        selectedProfileId={globalSelectedProfileId}
                        setIsUpdating={setIsUpdating}
                        setResponse={setResponse}
                        isMain={isMain}
                        setIsMain={setIsMain}
                    />

                    <Suspense fallback={<SkeletonLoader />}>
                        <FactorSettingsMain
                            selectedProfileId={globalSelectedProfileId}
                            setIsUpdating={setIsUpdating}
                            setResponse={setResponse}
                            isMain={isMain}
                        />
                    </Suspense>
                </DialogContent>
            </Dialog>
        </div>
    );
}

const SkeletonLoader = () => {
	return (
		<div>
			<Skeleton
				animation="wave"
				variant="text"
				sx={{ fontSize: "2rem", width: "75%" }}
			/>

			<Skeleton
				animation="wave"
				variant="text"
				sx={{ fontSize: "2rem", width: "65%" }}
			/>

			<Skeleton
				animation="wave"
				variant="text"
				sx={{ fontSize: "2rem", width: "55%" }}
			/>
		</div>
	);
};