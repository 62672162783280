import { useEffect, useState } from "react";

const useMultiSelect = (selectedIDs: string[] = []) => {
    const [selected, setSelected] = useState<string[]>(selectedIDs);

    const toggleItem = (id: string) => {
        setSelected(prevSelected => {
            if (prevSelected.some((prev: string) => prev == String(id))) {
                console.log('is already in ')
                return prevSelected.filter((item: string) => item != id);
            } else {
                console.log('does not exist')
                return [...prevSelected, id];
            }
        });
    };

    useEffect(() => {
        // console.log('after', selected);
    }, [selected])

    const setSelectedValues = (ids: string[]) => {
        setSelected(ids);
    }
    const clear = () => {
        setSelected([]);
    }
    const hasValue = (id: string): boolean => {
        return selected.some((prev: string) => prev == String(id));
    }
    const count = (): number => {
        return selected.length;
    }

    return {
        toggleItem,
        selected,
        setSelected: setSelectedValues,
        clear,
        hasValue,
        count,
    };
};

export default useMultiSelect;