import React from 'react'
import {MuiCardContent} from '../../../lib/MUI/mui.components';

const CardContent = (props: any) => {
  return (
    <MuiCardContent {...props}>
        {props.children}
    </MuiCardContent>
  )
}

export default CardContent