import { Button, Paper, Popover } from "@mui/material"
import { StatusOptions, getStatusColor } from "../../../../../data/constants"

type Props = {
    openStatus: boolean,
    setOpenStatus: any,
    anchorEl: any,
    handleStatusChange: any
}

const InviteStatusEdit = ({ openStatus, setOpenStatus, anchorEl, handleStatusChange }: Props) => {
    return (
        <Popover
            onClose={() => setOpenStatus(false)}
            sx={{ marginLeft: '-10px', marginTop: '30px', zIndex: 10000 }}
            anchorEl={anchorEl}
            open={openStatus}
        >
            <Paper sx={{ display: 'flex', flexDirection: 'column', padding: '10px' }}>
                {StatusOptions.map((status: string) =>
                    <Button
                        sx={{ margin: '2.5px', width: '95px', fontSize: '0.7rem', color: getStatusColor(status), borderColor: getStatusColor(status) }}
                        onClick={(e: any) => handleStatusChange(status)}
                        size='small'
                        variant='outlined'
                    >
                        {status}
                    </Button>
                )}
            </Paper>
        </Popover>
    )
}

export default InviteStatusEdit