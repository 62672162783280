import React, { createContext, useState, useContext } from 'react';

interface LocationsContextType {
    locations: any[];
    setLocations: React.Dispatch<React.SetStateAction<any[]>>;
}

export const LocationsContext = createContext<LocationsContextType | undefined>(undefined);;

export function LocationsProvider({ children }: any) {
    const [locations, setLocations] = useState<any[]>([]);

    return (
        <LocationsContext.Provider value={{ locations, setLocations }}>
            {children}
        </LocationsContext.Provider>
    );
}
