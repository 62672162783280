import { Numbers, Person, Person4, CalendarMonth, SendOutlined } from "@mui/icons-material"
import { Box, List, ListItem, Tooltip, ListItemAvatar, ListItemText, Button } from "@mui/material"
import { BoldTitle } from "../../../../../assets/styles/styledComponents"
import ReactLoader from "../../../../../components/common/ReactLoader"
import NewModal from "../../../../../components/ui/NewModal"
import { CUSTOM_SCROLL } from "../../../../../data/constants"
import { dateTime } from "../../../../../helper/datetime"
import { useEffect, useState } from "react"
import useFetcher from "../../../../../hooks/useFetcher"
import useInviteUI from "../../../../invites/hooks/useInviteUI"

type DetailsProps = {
    inviteCode: string;
    open: boolean;
    setOpen: any;
}

const InviteDetailsModal = ({ open, setOpen, inviteCode }: DetailsProps) => {
    const [code, setCode] = useState<any>(null)
    const [details, setDetails] = useState<any>();
    const [timeline, setTimeline] = useState<any>();
    const [filteredTimeline, setFilteredTimeline] = useState<any[]>([])
    const fetcher = useFetcher();
    const { getStatusColor } = useInviteUI();

    useEffect(() => {
        if (!timeline)
            return;

        const uniqueEntries: Set<string> = new Set();

        const filtered = timeline.filter((entry: any) => {
            const trimmedTimestamp = entry?.createdOn.substring(0, entry?.createdOn.indexOf(':') + 3);
            const key = `${trimmedTimestamp}-${entry?.summary}`;
            if (!uniqueEntries.has(key)) {
                uniqueEntries.add(key);
                return true;
            }
            return false;
        });

        setFilteredTimeline(filtered);
    }, [timeline]);

    const getDetails = () => {
        fetcher.get(`Invite/details/${code}`).then((res: any) => {
            setDetails(res.data.data)
        })
    }

    const getTimeline = () => {
        fetcher.get(`InviteTimeline/${code}`).then((res: any) => {
            setTimeline(res.data.data)
        })
    }

    useEffect(() => {
        setCode(inviteCode)
    }, [inviteCode, open])

    useEffect(() => {
        if (!code)
            return;

        getDetails();
        getTimeline()
    }, [code])

    const handleClose = () => {
        setCode(null)
        setOpen(false)
        setDetails(null)
        setTimeline([])
    }

    return (
        <NewModal open={open} onClose={() => handleClose()} title="Invite Details" height="400px" hideBtn onSave={() => { }}>
            <Box sx={{ maxHeight: '320px', overflowY: 'scroll', ...CUSTOM_SCROLL }}>
                <ReactLoader isLoading={fetcher.isLoading} />
                {details && <List>
                    <ListItem disablePadding>
                        <Tooltip title='Invite Code'>
                            <ListItemAvatar>
                                <Numbers fontSize="small" />
                            </ListItemAvatar>
                        </Tooltip>
                        <ListItemText primary={details?.inviteCode} />
                    </ListItem>
                    <ListItem disablePadding>
                        <Tooltip title='Patient'>
                            <ListItemAvatar>
                                <Person fontSize="small" />
                            </ListItemAvatar>
                        </Tooltip>
                        <ListItemText primary={details?.patientName} />
                    </ListItem>
                    <ListItem disablePadding>
                        <Tooltip title='Caregiver'>
                            <ListItemAvatar>
                                <Person4 fontSize="small" />
                            </ListItemAvatar>
                        </Tooltip>
                        <ListItemText primary={details?.caregiverName} />
                    </ListItem>
                    <ListItem disablePadding>
                        <Tooltip title='Sent On'>
                            <ListItemAvatar>
                                <SendOutlined fontSize="small" />
                            </ListItemAvatar>
                        </Tooltip>
                        <ListItemText primary={dateTime.getDateWithDay(details?.sentOn) || null} />
                    </ListItem>
                    <ListItem>
                        <List>
                            {details?.visits.map((visit: any) =>
                                <ListItem disablePadding>
                                    <Tooltip title='Visit'>
                                        <ListItemAvatar>
                                            <CalendarMonth fontSize="small" />
                                        </ListItemAvatar>
                                    </Tooltip>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '350px',
                                        padding: '10px'
                                    }} >
                                        <Box sx={{ flexGrow: 1 }}>
                                            <BoldTitle>{visit.visitDateTime}</BoldTitle>
                                            <BoldTitle>{`HHX Visit Id: ${visit.hhaVisitId}`}</BoldTitle>
                                            <BoldTitle>{`Caregiver: ${visit.caregiverName}`}</BoldTitle>
                                        </Box>
                                        <Button
                                            sx={{ width: '95px', fontSize: '0.7rem', color: getStatusColor(visit.inviteStatus), borderColor: getStatusColor(visit.inviteStatus), '&:hover': { borderColor: getStatusColor(visit.inviteStatus), cursor: 'initial' } }}
                                            size='small'
                                            variant='outlined'
                                            style={{
                                                pointerEvents: 'none',
                                            }}
                                        >
                                            {visit.inviteStatus}
                                        </Button>
                                    </Box>
                                </ListItem>
                            )}
                        </List>
                    </ListItem>
                </List>}
                {filteredTimeline?.length > 0 && <>
                    <BoldTitle>Invite Timeline</BoldTitle>
                    <List>
                        {filteredTimeline?.map((record: any) =>
                            <ListItem>
                                <ListItemText primary={record.summary} secondary={dateTime.getFormattedDateTime(record.createdOn)} />
                            </ListItem>
                        )}
                    </List>
                </>}
            </Box>
        </NewModal>
    )
}

export default InviteDetailsModal