import React, { useState } from 'react';
import { Button, Menu, MenuItem, IconButton, Box } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface OptionButtonProps {
    text: string;
    options: string[];
    handleMenuItemClick: (option: string) => void;
    handleMainButtonClick: any;
    disable?: boolean;
}

const OptionButton: React.FC<OptionButtonProps> = ({ text, options, handleMenuItemClick, handleMainButtonClick, disable }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleButtonClick = () => {
        handleMainButtonClick()
    };

    const handleArrowClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #7846ff80',
                borderRadius: '5px',
                overflow: 'hidden',
                padding: '4px 0px',
                width: 'fit-content',
            }}
        >
            <Button
                disabled={disable}
                variant="text"
                color="primary"
                onClick={handleButtonClick}
                sx={{ padding: '0px', borderRight: '1px solid #7846ff80', borderRadius: '0px' }}
            >
                {text}
            </Button>
            <IconButton
                disabled={disable}
                color="primary"
                onClick={handleArrowClick}
                sx={{ padding: '0px 8px', borderRadius: '0px' }}
            >
                <ArrowDropDownIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {options.map(option => (
                    <MenuItem key={option} onClick={() => {
                        handleMenuItemClick(option)
                        handleClose();
                    }}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default OptionButton;