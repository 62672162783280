import {
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	Stack
} from "@mui/material";
import { useEffect, useState } from "react";
import RemoveIcon from '@mui/icons-material/Remove';
import {
	MenuOption,
	factorTypes,
	logisticFactors,
	abilityFactors as abilityFactors_ORIGINAL,
	operators,
} from "./FactorConstants";
import { FactorSettingI } from "./FactorInterfaces";
import { urlSettingAdd, urlSettingUpdate } from "./FactorUrlConstants";
import useDebounce2 from "../../../hooks/useDebounce2";
import axiosClient from "../../../helper/axiosConfig";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PercentIcon from '@mui/icons-material/Percent';
import useFetcher from "../../../hooks/useFetcher";

interface FactorSettingRowProps {
	setting: FactorSettingI;
	deleteHandler: Function;
	settingUpdater: Function;
	setIsUpdating: Function;
	setResponse: Function;
	isMain?: Boolean;
	abilityFactors: MenuOption[];
}

const getNewSetting = (setting: FactorSettingI, settingId: number) => {
	return {
		profileId: setting.profileId,
		settingId: settingId,
		severityLevel: setting.severityLevel,
		factorType: setting.factorType,
		factor: setting.factor,
		operator: setting.operator,
		value: setting.value,
		points: setting.points
	}
};

const FactorSettingRow = ({
	setting,
	settingUpdater,
	deleteHandler,
	setIsUpdating,
	setResponse,
	isMain,
	abilityFactors,
}: FactorSettingRowProps) => {
	/**
	 * useStates
	 */
	const [settingId, setSettingId] = useState<number>(setting.settingId);
	const [type, setType] = useState<string>(setting.factorType);
	const [factor, setFactor] = useState<string>(setting.factor);
	const [operator, setOperator] = useState<string>(
		setting.operator === null ? "" : setting.operator
	);
	const [value, setValue] = useState<string | undefined>(
		setting.value as string
	);
	const [points, setPoints] = useState<number>(setting.points);
	const [factorMenuOptions, setFactorMenuOptions] = useState<MenuOption[]>(
		setting.factorType === factorTypes.Logistic
			? logisticFactors
			: abilityFactors
	);
	const [typeDisabled, setTypeDisabled] = useState<boolean>(
		setting.settingId !== 0 ? true : false
	);
	const [factorDisabled, setFactorDisabled] = useState<boolean>(
		setting.settingId !== 0 || !type ? true : false
	);
	const [operatorDisabled, setOperatorDisabled] = useState<boolean>(
		setting.operator === "" ? false : true
	);
	const [valueDisabled, setValueDisabled] = useState<boolean>(false);

	const debouncedSave = useDebounce2((nextValue: FactorSettingI) => updateSettingApi(nextValue), 1000);

	const settingFetcher = useFetcher();
	const updateSettingApi = async (updatedSetting: FactorSettingI) => {
		setIsUpdating(true);
		settingFetcher.post(urlSettingUpdate, updatedSetting).then((response: any) => {
			const resObj = { statusCode: response?.data.statusCode ?? '0', message: response?.data.message ?? 'Error' };
			setResponse(resObj);
		}).catch((err: any) => {
			const resObj = { statusCode: err?.response.data.statusCode ?? '0', message: err?.response.data.message ?? 'Error' };
			setResponse(resObj);
		}).finally(() => {
			setIsUpdating(false);
		});
	}

	const addSettingApi = async (newSetting: FactorSettingI) => {
		try {
			const response = await settingFetcher.post(urlSettingAdd, newSetting);
			const { data } = response.data;
			setting.settingId = data.settingId;

			return setting;
		} finally {
		}
	}

	/**
	 * Event Handlers
	 */
	const handleTypeChange = (event: SelectChangeEvent) => {
		const typeSelection = event.target.value as string;

		if (!!typeSelection && !factor)
			setFactorDisabled(false);
		setType(typeSelection);
	};

	const handleFactorChange = async (event: SelectChangeEvent) => {
		setIsUpdating(true);
		const nextValue: string = event.target.value as string;
		setFactor(nextValue);

		setting.factorType = type;
		setting.factor = nextValue;

		const newSetting: any = await addSettingApi(setting);

		settingUpdater(newSetting.settingId, newSetting);
		setSettingId(newSetting.settingId);

		setTypeDisabled(true);
		setFactorDisabled(true);
		setIsUpdating(false);

		const resObj = { statusCode: '200', message: 'Saved' };
		setResponse(resObj)
	};

	const handlePointsChange = (event: any) => {
		setIsUpdating(true);
		const nextValue = event.target.value;
		setPoints(nextValue);

		if (settingId !== 0) {
			const updatedSetting = getNewSetting(setting, settingId);

			updatedSetting.points = nextValue;

			debouncedSave(updatedSetting);
		}
	};

	const handleOperatorChange = (event: SelectChangeEvent) => {
		setIsUpdating(true);
		const nextValue = event.target.value;

		setOperator(nextValue as string);

		if (settingId !== 0) {
			const updatedSetting = getNewSetting(setting, settingId);

			updatedSetting.operator = nextValue;

			debouncedSave(updatedSetting);
		};
	}

	const handleValueChange = (event: any) => {
		setIsUpdating(true);
		const nextValue = event.target.value;

		setValue(nextValue);

		if (settingId !== 0) {
			const updatedSetting = getNewSetting(setting, settingId);

			updatedSetting.value = nextValue;

			debouncedSave(updatedSetting);
		};
	};

	const handleDeleteSetting = (event: any) => {
		deleteHandler(settingId);
	};

	/**
	 * Use Effects
	 */
	useEffect(() => {
		switch (type) {
			case factorTypes.Logistic:
				setFactorMenuOptions(logisticFactors);
				setOperatorDisabled(false);
				setValueDisabled(false);
				break;
			case factorTypes.Ability:
				setFactorMenuOptions(abilityFactors);
				setOperatorDisabled(true);
				setValueDisabled(true);
				break;
			default:
				break;
		}
	}, [type]);

	return (
		<Stack direction="row" alignItems="center" spacing={1} sx={{ padding: "5px 5px" }}>
			<FormControl size="small">
				<InputLabel id="demo-simple-select-label">Type</InputLabel>
				<Select
					sx={{ width: "195px", backgroundColor: "white" }}
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={type}
					label="Type"
					onChange={handleTypeChange}
					disabled={typeDisabled || !!isMain}
				>
					<MenuItem value={factorTypes.Logistic}>
						{factorTypes.Logistic}
					</MenuItem>
					<MenuItem value={factorTypes.Ability}>
						{factorTypes.Ability}
					</MenuItem>
				</Select>
			</FormControl>

			<FormControl size="small">
				<InputLabel id="demo-simple-select-label">Factor</InputLabel>
				<Select
					sx={{ width: "210px", backgroundColor: "white" }}
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={factor || 'NA'} // somehow needed this OR expression to update the render when factor is updated
					label="Type"
					onChange={handleFactorChange}
					disabled={factorDisabled || !!isMain}
				>
					{!!factorMenuOptions && factorMenuOptions.map((item: any) => (
						<MenuItem key={item.key} value={item.key}>
							{item.value}
						</MenuItem>
					))}
				</Select>
			</FormControl>

			<FormControl>
				<InputLabel>Points</InputLabel>
				<OutlinedInput
					sx={{ width: "60px", backgroundColor: "white" }}
					label="Points"
					id="points"
					size="small"
					value={points}
					onChange={handlePointsChange}
					disabled={!!isMain}
				/>
			</FormControl>

			<FormControl size="small">
				<InputLabel>Operator</InputLabel>
				<Select
					sx={{ width: "200px", backgroundColor: "white" }}
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={operator}
					label="Operator"
					onChange={handleOperatorChange}
					disabled={operatorDisabled || !!isMain}
				>
					{operators.map((item: any) => (
						<MenuItem key={item.key} value={item.key}>
							{item.value}
						</MenuItem>
					))}
				</Select>
			</FormControl>

			<FormControl>
				<InputLabel>{!value ? "" : "Value"}</InputLabel>
				<OutlinedInput
					sx={{ width: "85px", backgroundColor: "white" }}
					id="value"
					label={!value ? "" : "Value"}
					size="small"
					value={!valueDisabled ? value : 'NA'}
					onChange={handleValueChange}
					endAdornment={
						factor === "Travel" ?
							<InputAdornment position="end"><AccessTimeIcon fontSize="small"></AccessTimeIcon></InputAdornment> :
							<InputAdornment position="end"><PercentIcon fontSize="small"></PercentIcon></InputAdornment>}
					disabled={valueDisabled || !!isMain}
				/>
			</FormControl>
			{!isMain && <IconButton disabled={!settingId || !!isMain} onClick={handleDeleteSetting} size="small">
				<RemoveIcon color={(!!settingId ? "warning" : "disabled")} fontSize="small" />
			</IconButton>}
		</Stack>
	);
};

export default FactorSettingRow;
