import React, { createContext, useState, useContext } from 'react';

interface FiltersContextType {
  filters: mapFilters;
  setFilters: React.Dispatch<React.SetStateAction<mapFilters>>;
}

type mapFilters = {
  showCaregivers: boolean,
  showPatients: boolean,
  CStatus: string[],
  PStatus: string[],
  hasSched: string,
  needsCare: string,
  discipline: string[],
  offices: any[],
  coordinators: any[],
  timing: days[],
  gender: string,
  addressFor: string,
  address: addressDto
  workedAfter: string,
  hiredAfter: string,
}

type days = {
  day: string,
  from: string,
  to: string
}

type addressDto = {
  addressLine1: string,
  city: string,
  state: string,
  country: string,
  zipCode: string,
  latitude: string,
  longitude: string,
  full: string
}

export const FiltersContext = createContext<FiltersContextType | undefined>(undefined);;

export function FiltersProvider({ children }: any) {
  const [filters, setFilters] = useState<mapFilters>({
    showCaregivers: true,
    showPatients: false,
    CStatus: ['Active'],
    PStatus: ['Active'],
    hasSched: 'all',
    needsCare: 'all',
    discipline: [''],
    offices: [],
    coordinators: [],
    timing: [],
    gender: '',
    hiredAfter: 'all',
    workedAfter: 'all',
    addressFor: 'patient',
    address:
    {
      addressLine1: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
      latitude: '',
      longitude: '',
      full: '',
    },
  });

  return (
    <FiltersContext.Provider value={{ filters, setFilters }}>
      {children}
    </FiltersContext.Provider>
  );
}
