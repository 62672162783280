import React from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

interface MenuPopupProps {
    id: string,
    open: boolean,
    anchorEl: null | HTMLElement,
    options: any,
    onCloseMenu: Function
}

const MenuPopup: React.FC<MenuPopupProps> = ({open, anchorEl, options, onCloseMenu, id}) => {
  return (
    <Menu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={() => onCloseMenu()}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
    >
        {
            options.map((option: any) => (
                <MenuItem onClick={() => onCloseMenu(option.value)}>{option.label}</MenuItem>
            ))
        }
  </Menu>
  )
}

export default MenuPopup