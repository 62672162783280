import React, { useEffect, useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

interface PhoneFieldProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    setValue?: (value: string) => void;
  }
  
  const PhoneField: React.FC<PhoneFieldProps> = ({ value = '', onChange, setValue, ...rest }) => {
    const [phone, setPhone] = useState<string>(value);
    const [error, setError] = useState<boolean>(false);
  
    useEffect(() => {
      setPhone(value);
    }, [value]);

    const formatPhoneNumber = (value: string) => {
        const cleaned = ('' + value).replace(/\D/g, '');
        const match = cleaned
            //.substring(0, 10)
            .match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
            const part1 = match[1];
            const part2 = match[2] ? '-' + match[2] : '';
            const part3 = match[3] ? '-' + match[3] : '';
            return `${part1}${part2}${part3}`;
        }

        return value;
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const newValue = formatPhoneNumber(value);
        setPhone(newValue);
        setError(newValue.replace(/\D/g, '').length !== 10);

        if (typeof setValue === 'function') {
            setValue(newValue);
        }
        
        if (onChange) { // I think this is not really needed, since the onChange would anyway override the handleChange if onChange is available from props
            onChange({
                ...event,
                target: {
                    ...event.target,
                    value: newValue,
                },
            });
        };
    };

    return (
        <TextField
            label="Phone"
            placeholder="222-456-7890"
            fullWidth
            variant="outlined"
            value={phone}
            onChange={handleChange}
            error={error}
            helperText={error ? "Phone number must be 10 digits" : ""}
            {...rest}
        />
    );
};

export default PhoneField;