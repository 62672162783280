import { useState, useEffect, useRef } from 'react';
import { MatchTagType, PersonPrefType } from '../../pages/Preference/types';
import useFetcher from '../../hooks/useFetcher';
import useApi_DEPRACATED from '../../hooks/useApi';
import { BASE_URL } from '../../data/constants';
import useToast from '../../hooks/useToast';

type usePreferencesHook = {
    getPrefs: Function;
    isPrefsLoading: boolean;
    unusedPreferences: MatchTagType[];
    prefsData: any;
    capModule: string;
    isRequiredAsk: (id: number) => boolean;
    filteredRows: PersonPrefType[];
    personalPreferences?: GeneralPreference;
    setPersonalPreferences: React.Dispatch<React.SetStateAction<GeneralPreference | undefined>>;
    getPreferenceLabel: (id: number, party: string) => string;
    addPreferenceToRows: (pref: PersonPrefType | undefined) => void;
    updatePersonalPreference: (value: any, column: string) => void;
    deletePreference: (selectedRowId: number) => void;
    updatePreference: (params: any, column: string) => void;
    getUnusedPreferences: () => MatchTagType[];
    getPrefsAsTags: () => MatchTagType[];
    getGeneralPreferences: () => void;
};
type PreferencesProps = {
    module: string | undefined;
    moduleRefRowId: string | number | undefined;
    token?: string | null
}
interface Preference {
    id: number;
    name: string;
    value: number | string;
}
interface GeneralPreference {
    id: number;
    preferences: Preference[];

    maxCaseHours?: number;
    maxTravelTime?: number;
    preferredGender?: string;
    preferredCommunicationMethod?: string;

    commuteByCar_MaxMinutes?: number;
    commuteByCar_PreferenceLevel?: string;
    commuteByPublicTransit_MaxMinutes?: number;
    commuteByPublicTransit_PreferenceLevel?: string;
    commuteByWalk_MaxMinutes?: number;
    commuteByWalk_PreferenceLevel?: string;
    commuteByBike_MaxMinutes?: number;
    commuteByBike_PreferenceLevel?: string;
}

const usePreferences = (params: PreferencesProps) => {
    const { module, moduleRefRowId, token } = params;
    const [capModule, setCapModule] = useState('');

    // fetching
    const { get: getPrefs, data: prefsData, isLoading: isPrefsLoading } = useFetcher(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, token);
    const preferencesApi = useApi_DEPRACATED(`preference`, BASE_URL, undefined, token ?? null);
    const updateFetcher = useFetcher(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, token);
    const deletePreference_Fetcher = useFetcher(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, token);
    const fetcher = useFetcher(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, token);
    const { showSuccess, showError } = useToast();

    // rows and arrays
    const [rows, setRows] = useState<PersonPrefType[]>([]);
    const [preferences, setPreferences] = useState<MatchTagType[]>([]);
    const [personalPreferences, setPersonalPreferences] = useState<GeneralPreference>()
    const [filteredRows, setFilteredRows] = useState<PersonPrefType[]>([]);
    const [unusedPreferences, setUnusedPreferences] = useState<MatchTagType[]>([]);

    useEffect(() => {
        if (!!module) {
            const capitalizedModule = module?.charAt(0).toUpperCase() + module?.slice(1);
            setCapModule(capitalizedModule);
        }
    }, [module])

    useEffect(() => {
        preferencesApi.getList();
    }, [])
    useEffect(() => {
        if (!module || !moduleRefRowId)
            return;

        if (rows?.length < 1) // only if no data
            getPrefs(`/preference/${module}/${moduleRefRowId}`);
        getGeneralPreferences()
    }, [module, moduleRefRowId])
    useEffect(() => {
        setRows(prefsData?.map((row: PersonPrefType) => ({ ...row, id: !!row.id ? row.id : 0 - row.preferenceId, preferenceId: row.preferenceId })) ?? [])
    }, [prefsData])
    useEffect(() => {
        setPreferences(preferencesApi?.data?.list ?? [])
    }, [preferencesApi?.data?.list])

    const getGeneralPreferences = () => {
        fetcher.get(`preference/personal/${module}/${moduleRefRowId}`).then(res => {
            setPersonalPreferences(res.data)
        })
    }

    const getPrefsAsTags = () => {
        const pref: MatchTagType[] = preferences.map((item: MatchTagType) => {
            return item
        })

        return pref ?? [];
    }
    const getUnusedPreferences = () => {
        const pref: MatchTagType[] = preferences.filter((item: MatchTagType) => {
            return rows.every((row: PersonPrefType) => row.name !== item.name)
        })

        return pref ?? [];
    }
    const isRequiredAsk = (id: number) => {
        const persPrefRow = rows?.find(r => r.id == id);
        const prefRow = preferences.find((p: any) => p.id == persPrefRow?.preferenceId);
        return !!prefRow && prefRow.isRequiredAsk == true && prefRow.isActive == true;
    }

    const updatePreference = (row: PersonPrefType, column: string) => {
        let pref = filteredRows.find(p => p.id === row.id || p.id === 0 - row.preferenceId);
        console.log (filteredRows)
        console.log (row)

        if (pref === undefined) {
            const preferenceFromPrefs = unusedPreferences.find(p => p.id === row.preferenceId);
            if (preferenceFromPrefs) {
                pref = {
                    id: 0 - row.preferenceId,
                    name: preferenceFromPrefs.name,
                    appliedParty: preferenceFromPrefs.appliedParty,
                    isRequired: preferenceFromPrefs.isRequiredAsk ?? null,
                    preferenceId: preferenceFromPrefs.id,
                    value: undefined,
                    canMeetRequirement: false,
                    mandatory: false
                };
            }
        }

        if (!!pref) {
            if (column === "value") pref.value = row.value;
            if (column === "meets") pref.canMeetRequirement = !!row.value;
            if (column === "mandatory") pref.mandatory = !pref.mandatory;

            updateFetcher.post(`preference/${module}`, pref)
                .then(res => {
                    showSuccess('Preference was updated.')
                    if (pref !== undefined)
                        pref.id = res.data.id;
                    addPreferenceToRows(pref)
                })
                .catch(error => showError(error.response.data.message));
        } else {
            showError('Preference not found.');
        }
    };
    const deletePreference = (selectedRowId: number) => {
        let pref = filteredRows.find(p => p.id === selectedRowId);

        if (!!pref) {
            deletePreference_Fetcher.delete(`preference/${module}/${moduleRefRowId}/${pref.name}`)
                .then(res => {
                    showSuccess('Preference was deleted.')

                    const existingIndex = rows.findIndex(row => row.id === selectedRowId);
                    if (existingIndex !== -1) {
                        const updatedRows = rows.filter((r: PersonPrefType, i: number) => i !== existingIndex);
                        setRows(updatedRows);
                    }
                })
                .catch(error => showError(error.response.data.message));
        } else {
            showError('Preference not found.');
        }
    };
    function validStringOrNumber(input: any) {
        return /[0-9]/.test(input) && /[a-zA-Z]/.test(input);
    }

    const updatePersonalPreference = (value: any, column: string) => {
        if (validStringOrNumber(value))
            return

        let pref = personalPreferences;
        let fieldToUpdate = pref?.preferences.find(p => p.name == column)
        if (fieldToUpdate != undefined) {
            fieldToUpdate.value = value
        }
        updateFetcher.post(`preference/personal/${module}/${moduleRefRowId}`, pref).then(res => {
            setPersonalPreferences(res.data);
            showSuccess('Preference was updated.')
        }).catch(error => {
            showError(error.response.data.message)
        })
    }

    useEffect(() => {
        const filteredRowsArray: PersonPrefType[] = [
            ...getUnusedPreferences()
                .filter((pref: MatchTagType) => pref.isActive) //pref.isRequiredAsk && 
                .map((pref: MatchTagType) => ({
                    name: pref.name,
                    isRequired: pref.isRequiredAsk ?? false,
                    preferenceId: pref.id,
                    id: 0 - pref.id,
                    appliedParty: pref.appliedParty,
                    value: undefined,
                    canMeetRequirement: false,
                    mandatory: false,
                    caregiverId: !moduleRefRowId || module == 'patient' ? 0 : Number(moduleRefRowId),
                    patientId: !moduleRefRowId || module == 'caregiver' ? 0 : Number(moduleRefRowId),
                })),
            ...rows
                .map((row: PersonPrefType) => ({
                    name: row.name,
                    isRequired: row.isRequired ?? false,
                    preferenceId: row.preferenceId,
                    id: row.id,
                    appliedParty: row.appliedParty,
                    value: !!row?.id && row.id > 0 ? row.value : undefined,
                    canMeetRequirement: row.canMeetRequirement,
                    mandatory: false,
                    caregiverId: !moduleRefRowId || module == 'patient' ? 0 : Number(moduleRefRowId),
                    patientId: !moduleRefRowId || module == 'caregiver' ? 0 : Number(moduleRefRowId),
                } as PersonPrefType)) // Ensure rows are mapped to definedPreferenceType
        ];

        const additionalRows: MatchTagType[] = preferences
            .filter(pref => !filteredRowsArray.some(row => row.id === pref.id))
            .map(pref => ({
                id: 0,
                name: pref.name,
                clientLabel: pref.clientLabel,
                providerLabel: pref.providerLabel,
                appliedParty: pref.appliedParty,
                discipline: pref.discipline,
                isRequiredAsk: pref.isRequiredAsk,
                isActive: pref.isActive,
                requisiteLevel: pref.requisiteLevel,
                severityPoints: pref.severityPoints,
                editorType: pref.editorType
            }));

        setFilteredRows([...filteredRowsArray]);
        setUnusedPreferences(additionalRows);
    }, [preferences, rows]);
    const addPreferenceToRows = (pref: PersonPrefType | undefined) => {
        if (!pref) {
            return;
        }

        const existingIndex = filteredRows.findIndex(row => row.preferenceId === pref.preferenceId);

        if (existingIndex !== -1) {
            const updatedFilteredRows = [...filteredRows];
            updatedFilteredRows[existingIndex] = pref;
            setRows(updatedFilteredRows);
        } else {
            const newFilteredRows = [
                ...filteredRows,
                pref
            ];
            setRows(newFilteredRows);
        }
    };

    const getPreferenceLabel = (id: number, party: string): string => {
        const pref = preferences.find(p => p.id === id);
        if (!pref)
            return '';

        switch (party) {
            case 'patient':
                return pref.clientLabel || pref.name;
            case 'caregiver':
                return pref.providerLabel || pref.name;
            default:
                return pref.name;
        }
    }

    const hookHelper : usePreferencesHook = {
        // fetching
        getPrefs,
        isPrefsLoading,
        unusedPreferences,

        // state data
        prefsData,
        capModule,
        isRequiredAsk,
        filteredRows,
        personalPreferences,
        setPersonalPreferences,

        // functions
        getPreferenceLabel,
        addPreferenceToRows,
        updatePersonalPreference,
        deletePreference,
        updatePreference,
        getUnusedPreferences,
        getPrefsAsTags,
        getGeneralPreferences,
    }

    return hookHelper;
};

export default usePreferences;