import React, {useEffect, useState} from 'react';
import {Autocomplete, InputBase, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search'
import useDebounce from '../../../hooks/useDebounce'
import {DivContainer, FlexExactCenter} from '../../../assets/styles/styledComponents';

type SearchInputProps = {
    getSearchQuery: Function,
    width?: string,
    autocomplete?: boolean,
    debounce?: number,
    isLoading?: boolean,
    searchResult?: any,
    setSearchResult?: any,
    module?: string,
    sx?: any
}

const SearchInput = ({getSearchQuery, width="300px", searchResult, setSearchResult, debounce = 500, isLoading, autocomplete=false, sx}: SearchInputProps) => {
    const [searchTerm, setSearchTerm] = useState("");
    const debounceValue = useDebounce(searchTerm, 500);
    
    useEffect(() => {
        getSearchQuery(debounceValue)
    }, [debounceValue])

  return (
    <Autocomplete
        fullWidth
        loading={isLoading}
        value={searchTerm}
        freeSolo
        sx={{
            width: width,
            ...sx
        }}
        disableClearable
        id={`gloabal-search-${module}`}
        options={[]}
        renderInput={(params) => (
          <TextField
          {...params}
           
            sx={{
              width: width,
              '& .MuiOutlinedInput-root': {
                padding: '2px',
                margin: '0px 0px 15px 0px'
              },
             
          }}
          size='small'
            onChange={(e) => {
                setSearchTerm(e.target.value);
            }}
            label={
              <FlexExactCenter>
                  {<SearchIcon />}
                  &nbsp;&nbsp;Search
              </FlexExactCenter>
            }
          />
        )}
      />
  )
}

export default SearchInput